// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
// $FlowFixMe
import { Button, Card, Container, Row, Col, ButtonToolbar, ButtonGroup, Popover, Tooltip, OverlayTrigger, Fade } from 'react-bootstrap';
import { FormGroup, FormControl, Alert, Badge } from 'react-bootstrap';
import { Player, ControlBar, BigPlayButton, CurrentTimeDisplay, TimeDivider, PlaybackRateMenuButton, VolumeMenuButton } from 'video-react';
import Slider from 'rc-slider';
import { getImageSmall, getTransObject } from '../misc/utils';
import { PATH_VIDEO } from '../backend/paths';
import i18next from 'i18next';
import StationVideoPopoutBtn from './StationVideoPopoutBtn';
import { AiOutlineCloudDownload } from 'react-icons/ai';

import 'rc-slider/assets/index.css';
import '../css/videoplayer.css';
import 'video-react/dist/video-react.css';
import withKeyboardCommand from './hoc/withKeyboardCommands';

import { addStationVideoViewInstance, updateStationVideoViewInstance } from '../actions/viewActions';
import { backToProject } from '../actions/dataActions';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleLeft, faPlay, faPause, faBackward, faForward, faFastBackward, faStepBackward, faStepForward, faFastForward, faExpandArrowsAlt } from '@fortawesome/free-solid-svg-icons';

type Props = {
	currentObject: Object,
	backToProject: () => mixed,
	currentStation: Object,
	seekStation: boolean,
	addStationVideoViewInstance: () => mixed,
	updateStationVideoViewInstance: () => mixed,
};

type State = {
	activePlayer: number,
	activePlayerState: Object,
	mainPlayerPlaybackRate: number,
	mainPlayerCurrentTime: number,
	mainPlayerStep: number,
	secondaryPlayerPlaybackRate: number,
	secondaryPlayerCurrentTime: number,
	secondaryPlayerStep: number,
	showPlayerButtonToolbar: boolean,
	showPlayerControlsDisabled: boolean,
	showStationVideoPopoutBtn: boolean,
};

const PLAYER = {
	MAIN: 0,
	SECONDARY: 1,
};

const VIDEODEFAULTS = {
	PLAYBACKRATEMIN: 0.5,
	PLAYBACKRATEMAX: 8,
	PLAYERSTEPMIN: 1,
	PLAYERSTEPMAX: 60,
};

/**
 * React Komponente fuer den Filmtab
 * @type {Object}
 * @constructor
 */
class StationVideoView extends Component<Props, State> {
	selectInspectionVideo: ?HTMLInputElement;
	viewMainPlayer: boolean = false;
	viewSecondaryPlayer: boolean = false;

	constructor(props: Object) {
		super(props);

		this.state = {
			activePlayer: 0,
			activePlayerState: {},
			mainPlayerPlaybackRate: 1,
			mainPlayerCurrentTime: 0,
			mainPlayerStep: 5,
			secondaryPlayerPlaybackRate: 1,
			secondaryPlayerCurrentTime: 0,
			secondaryPlayerStep: 5,
			showPlayerButtonToolbar: true,
			showPlayerControlsDisabled: true,
			showStationVideoPopoutBtn: true,
		};
	}

	/**
	 * React Lifecycle Methode
	 * @memberof StationVideoView
	 */
	componentDidMount() {
		this.props.addStationVideoViewInstance(this.props.instance);

		if (!this.viewMainPlayer) {
			this.setState({
				showPlayerButtonToolbar: false,
			});
		} else {
			this.refs.player.subscribeToStateChange(this.handlePlayer);
		}
	}

	/**
	 * React Lifecycle Methode
	 *
	 * @param {Object} prevPros
	 * @memberof StationVideoView
	 */
	componentDidUpdate(_prevPros: Object) {
		const { currentStation, seekStation } = this.props;

		if (currentStation && seekStation) {
			this.setStationVideoValue();
		}
	}

	/**
	 * Setzt das Video auf die Position der Station
	 *
	 * @memberof StationVideoView
	 */
	setStationVideoValue() {
		const { instance, currentStation, updateStationVideoViewInstance } = this.props;
		const { activePlayer } = this.state;

		// wechsel ggf. zwischen Haupt- oder Gegenuntersuchung
		if(currentStation.hasOwnProperty("G") && String(currentStation.G) !== String(activePlayer)) {
			this.switchVideo(parseInt(currentStation.G, 10))
		}

		// convert video length into seconds
		const videoLengthInSeconds = this.timeToSeconds(currentStation.fields.Video);
		this.setCurrentVideoPosition(videoLengthInSeconds);

		updateStationVideoViewInstance(instance, false);
	}

	/**
	 * Keyboardevent fuer den Videoplayer
	 * - Taste um die Abspielgeschwindigkeit zu verringern
	 * @memberof StationVideoView
	 */
	keyEventPlaybackRateSlow = (): void => {
		this.changePlaybackRate(-0.5);
	};

	/**
	 * Keyboardevent fuer den Videoplayer
	 * + Taste um die Abspielgeschwindigkeit zu erhoehen
	 * @memberof StationVideoView
	 */
	keyEventPlaybackRateFast = (): void => {
		this.changePlaybackRate(0.5);
	};

	/**
	 * Keyboardevent fuer den Videoplayer
	 * J oder left Taste fuer das Zurueckspringen im Video
	 * je nach Einstellung
	 * @memberof StationVideoView
	 */
	keyEventVideoStepBackward = (): void => {
		this.setKeyEventVideoStep(false);
	};

	/**
	 * Keyboardevent fuer den Videoplayer
	 * L oder right Taste fuer das Vorspringen im Video
	 * je nach Einstellung
	 * @memberof StationVideoView
	 */
	keyEventVideoStepForward = (): void => {
		this.setKeyEventVideoStep(true);
	};

	/**
	 * Keyboardevent fuer den Videoplayer
	 * K oder space Taste fuer Video start oder stop
	 * @memberof StationVideoView
	 */
	keyEventVideoPlayOrStop = (): void => {
		this.setVideoPlayOrStop();
	};

	/**
	 * Keyboardevent fuer den Videoplayer
	 * Pos1 (Home) Taste um zum Start des Videos zu springen
	 * @memberof StationVideoView
	 */
	keyEventVideoStart = (): void => {
		this.setCurrentVideoPosition(0);
	};

	/**
	 * Keyboardevent fuer den Videoplayer
	 * End (Ende) Taste um zum Ende des Videos zu springen
	 * @memberof StationVideoView
	 */
	keyEventVideoEnd = (): void => {
		const { activePlayerState } = this.state;

		if (activePlayerState.readyState === 4) {
			this.setCurrentVideoPosition(activePlayerState.duration);
		}
	};

	/**
	 * Keyboardevent fuer den Videoplayer
	 * F Taste um in den Vollbildmodus fuer den Videoplayer zu wechseln
	 * oder aus dem Vollbildmodus in den Normalmodus wieder zurueck
	 * @memberof StationVideoView
	 */
	keyEventVideoFullScreen = (): void => {
		this.showFullScreenVideo();
	};

	/**
	 * setzt fuer die Keyboardevents vor oder zurueck springen
	 * die Position im Video neu unter Verwendung der aktuellen Position
	 * @memberof StationVideoView
	 */
	setKeyEventVideoStep = (forward: boolean): void => {
		const { activePlayer, activePlayerState, mainPlayerStep, secondaryPlayerStep } = this.state;

		if (activePlayerState.readyState === 4) {
			const playerStep: number = activePlayer === PLAYER.MAIN ? mainPlayerStep : secondaryPlayerStep;
			const step: number = forward === true ? playerStep : -playerStep;

			this.stepCurrentVideoPosition(step, activePlayerState.currentTime);
		}
	};

	/**
	 * Callback fuer das Select Event
	 * wenn die Auswahl des angezeigten Films sich aendert
	 * (Hauptuntersuchung oder Gegenuntersuchung)
	 * @param {Object} e 	Event aus der Selectbox
	 * @memberof StationVideoView
	 */
	handleSelectChange(e: Object): void {
		this.switchVideo(parseInt(e.target.value, 10));
	}

	/**
	 * Video zwische Haupt und Gegenuntersuchung wird gewechselt
	 * @param {Number} selectValue 	Event aus der Selectbox
	 * @memberof StationVideoView
	 */
	switchVideo(selectValue: Number): void {
		const { activePlayer, activePlayerState, mainPlayerCurrentTime, secondaryPlayerCurrentTime } = this.state;
		// const selectValue: number = parseInt(e.target.value, 10);

		let newMainPlayerCurrentTime: number = mainPlayerCurrentTime;
		let newSecondaryPlayerCurrentTime: number = secondaryPlayerCurrentTime;

		if (activePlayerState.readyState === 4) {
			if (!activePlayerState.paused) {
				this.refs.player.pause();
			}

			if (activePlayer === PLAYER.MAIN && selectValue === PLAYER.SECONDARY) {
				newMainPlayerCurrentTime = activePlayerState.currentTime;
			} else if (activePlayer === PLAYER.SECONDARY && selectValue === PLAYER.MAIN) {
				newSecondaryPlayerCurrentTime = activePlayerState.currentTime;
			}
		}

		// $FlowFixMe
		this.selectInspectionVideo.blur();

		this.setState({
			activePlayer: selectValue,
			mainPlayerCurrentTime: newMainPlayerCurrentTime,
			secondaryPlayerCurrentTime: newSecondaryPlayerCurrentTime,
		});

		this.refs.player.load();
	}

	/**
	 * Events des Videoplayers umsetzen
	 * @param {Object} state
	 * @param {Object} prevState
	 * @memberof StationVideoView
	 */
	handlePlayer = (state: Object, prevState: Object) => {
		if (state.readyState === 4) {
			this.setState({
				activePlayerState: state,
			});
		}
	};

	/**
	 * startet das Video wenn Pause
	 * stoppt das Video wenn Play
	 * @memberof StationVideoView
	 */
	setVideoPlayOrStop(): void {
		const { activePlayerState } = this.state;

		if (activePlayerState.readyState === 4) {
			if (activePlayerState.paused) {
				this.setVideoPlay();
			} else {
				this.setVideoStop();
			}
		}
	}

	/**
	 * startet das Videos
	 * @memberof StationVideoView
	 */
	setVideoPlay(): void {
		const { activePlayerState } = this.state;

		if (activePlayerState.readyState === 4) {
			this.refs.player.play();
		}
	}

	/**
	 * stoppt das Videos
	 * @memberof StationVideoView
	 */
	setVideoStop(): void {
		const { activePlayerState } = this.state;

		if (activePlayerState.readyState === 4) {
			this.refs.player.pause();
		}
	}

	/**
	 * Events fuer das Springen auf Position vor oder zurueck
	 * @param {number} value	neue Position in Sekunden
	 * @memberof StationVideoView
	 */
	onChangeSliderSteps = (value: number) => {
		const { activePlayer } = this.state;

		if (activePlayer === PLAYER.MAIN) {
			this.setState({
				mainPlayerStep: parseInt(value, 10),
			});
		} else if (activePlayer === PLAYER.SECONDARY) {
			this.setState({
				secondaryPlayerStep: parseInt(value, 10),
			});
		}
	};

	/**
	 * Abspielgeschwindigkeit für VideoPlayer setzen
	 * @param {number} steps 	Wert um der die aktelle Abspielgeschwindigkeit erhoeht oder verringert werden soll
	 * @memberof StationVideoView
	 */
	changePlaybackRate(steps: number): void {
		const { activePlayer, mainPlayerPlaybackRate, secondaryPlayerPlaybackRate } = this.state;

		if (activePlayer === PLAYER.MAIN) {
			const newRate: number = mainPlayerPlaybackRate + steps;

			if (newRate >= VIDEODEFAULTS.PLAYBACKRATEMIN && newRate <= VIDEODEFAULTS.PLAYBACKRATEMAX) {
				this.setState({
					mainPlayerPlaybackRate: newRate,
				});
				this.refs.player.playbackRate = newRate;
			}
		} else if (activePlayer === PLAYER.SECONDARY) {
			const newRate = secondaryPlayerPlaybackRate + steps;
			if (newRate >= VIDEODEFAULTS.PLAYBACKRATEMIN && newRate <= VIDEODEFAULTS.PLAYBACKRATEMAX) {
				this.setState({
					secondaryPlayerPlaybackRate: newRate,
				});
				this.refs.player.playbackRate = newRate;
			}
		}
	}

	/**
	 * Event Slider Video-Position wird veraendert
	 * @param {number} value als Sekunden
	 * @memberof VideoPlayer
	 */
	onChangeVideoSlider = (value: number) => {
		const { activePlayerState } = this.state;

		if (activePlayerState.readyState === 4) {
			this.refs.player.seek(parseFloat(value));
		}
	};

	/**
	 * wechselt in den Fullscreen-Videomodus
	 * oder aus dem Fullscreen-Videomodus wieder zurueck in den Normal-Modus
	 * @memberof StationVideoView
	 */
	showFullScreenVideo(): void {
		const { activePlayerState } = this.state;

		if (activePlayerState.readyState === 4) {
			this.refs.player.toggleFullscreen();
		}
	}

    downloadVideo(): void {
        const { activePlayer } = this.state;
        const file = this.props.currentObject.inspection[activePlayer === PLAYER.MAIN ? 0 : 1].videoRef;
        const link = document.createElement('a');
        link.href = `${PATH_VIDEO}/${file}`;
        document.body.appendChild(link);
        link.click();
    }

	/**
	 * setzt die Position im Video neu
	 * @param {number} pos 			aktuelle Position in Sekunden
	 * @memberof StationVideoView
	 */
	setCurrentVideoPosition(pos: number): void {
		const { activePlayerState } = this.state;
		if (activePlayerState.readyState === 4) {
			// if pos is not within video duration, jump to end of video
			if (pos > this.refs.player.video.video.duration) {
				pos = Number(this.refs.player.video.video.duration - 1).toFixed(2);
			}
			this.refs.player.seek(pos);
		}
	}

	/**
	 * setzt die Position im Video neu unter Verwendung der aktuellen Position
	 * @param {number} step 	Step, wie Einstellung
	 * @param {number} pos 		aktuelle Position in Sekunden
	 * @memberof StationVideoView
	 */
	stepCurrentVideoPosition(step: number, pos: number): void {
		const { activePlayerState } = this.state;

		if (activePlayerState.readyState === 4) {
			const playerDuration: number = activePlayerState.duration;
			let posNew: number = step + pos;

			if (posNew > playerDuration) {
				posNew = playerDuration;
			}

			this.setCurrentVideoPosition(posNew);
		}
	}

	/**
	 * wandelt Sekunden in eine Zeitstempel um
	 * @param {number} sec      die Sekunden
	 * @returns {string}        der Zeitstempel z.B. 00:05:29.000
	 * @memberof StationVideoView
	 */
	secondsToTime(sec: number): string {
		const hours: number = Math.floor(sec / (60 * 60));

		const divisor_for_minutes: number = sec % (60 * 60);
		const minutes: number = Math.floor(divisor_for_minutes / 60);

		const divisor_for_seconds: number = divisor_for_minutes % 60;

		const seconds: number = Math.floor(divisor_for_seconds);

		const hoursStr: string = hours < 10 ? '0' + hours.toString() : hours.toString();
		const minutesStr: string = minutes < 10 ? '0' + minutes.toString() : minutes.toString();
		const secondsStr: string = seconds < 10 ? '0' + seconds.toString() : seconds.toString();

		const sec_num: number = parseInt(sec, 10);
		const decimal: number = parseFloat(sec) - sec_num;

		const milsecondsStr: string = decimal > 0 ? decimal.toString().substr(2, 3) : '000';

		return hoursStr + ':' + minutesStr + ':' + secondsStr + '.' + milsecondsStr;
	}

	/**
	 * wandelt Sekunden in eine Zeitstempel um
	 * @param {string} time      Zeitstring 'hh:ii:ss'
	 * @returns {string}
	 * @memberof StationVideoView
	 */
	timeToSeconds(time :string): number {
		let videoLengthInSeconds: number = 0;
		const values: Array<string> = time.split(':');
		for (let i = 0; i < values.length; i++) {
			let val: number = parseInt(values[i], 10);
			switch (i) {
				case 0:
					videoLengthInSeconds += val * 60 * 60;
					break;
				case 1:
					videoLengthInSeconds += val * 60;
					break;
				case 2:
					videoLengthInSeconds += val;
					break;
				default:
					break;
			}
		}

		return videoLengthInSeconds;
	}

	/**
	 * gibt eine uebersetzten Text zurueck
	 * @param  {string} textKey definierter Key
	 * @return {string}         uebersetzter Text
	 */
	getTransElementText(textKey: string): string {
		return i18next.t(textKey);
	}

	/**
	 * Rendert einen Videoplayer
	 * @param {number} playerId 		die PlayerId
	 * @param {string} playerVideo 		der Dateipfad zum Video
	 * @returns {React$Element<any>} 	das Videoplayer Element
	 * @memberof StationVideoView
	 */
	renderVideoPlayer(playerId: number, playerVideo: string): React$Element<any> {
		const { activePlayerState, showPlayerControlsDisabled, mainPlayerCurrentTime, secondaryPlayerCurrentTime } = this.state;

		let startTime: number = 0;

		if (playerId === PLAYER.MAIN && mainPlayerCurrentTime > 0) {
			startTime = mainPlayerCurrentTime;
		} else if (playerId === PLAYER.SECONDARY && secondaryPlayerCurrentTime > 0) {
			startTime = secondaryPlayerCurrentTime;
		}

		const playerCurrentTime: number = activePlayerState.readyState === 4 ? activePlayerState.currentTime : 0;
		const playerDuration: number = activePlayerState.readyState === 4 ? activePlayerState.duration : 0;

		return (
			<Container fluid>
				<Row>
					<Col xs={12} sm={12} md={12} lg={12} className='slider-video-time'>
						<Slider
							value={playerCurrentTime}
							className='sliderVideoTime'
							onChange={this.onChangeVideoSlider}
							trackStyle={{ backgroundColor: '#337ab7' }}
							step={0.01}
							min={0}
							max={playerDuration}
							handleStyle={{
								borderColor: '#337ab7',
								height: 20,
								width: 20,
								marginLeft: -10,
								marginTop: -8,
							}}
						/>
					</Col>
				</Row>
				<Row>
					<Col xs={12} sm={12} md={12} lg={12} className='text-center player-video-data'>
						<Player ref='player' src={playerVideo} startTime={startTime} playsInline>
							<BigPlayButton disabled />
							<ControlBar disabled={showPlayerControlsDisabled}>
								<CurrentTimeDisplay order={4.1} />
								<TimeDivider order={4.2} />
								<PlaybackRateMenuButton rates={[0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8]} order={7.1} />
								<VolumeMenuButton disabled />
							</ControlBar>
						</Player>
					</Col>
				</Row>
			</Container>
		);
	}

	/**
	 * erzeugt ein Popup mit einer Warnmeldung, wenn kein Video vorhanden ist
	 * @return {JSX} JSX Markup
	 * @memberof StationVideoView
	 */
	renderViewAlertMessage(): React$Element<any> {
		// $FlowFixMe
		return (
			<Alert>
				<strong>{getTransObject('video.warn', 'Achtung!', 'span')}</strong> {getTransObject('video.noVideoFile', 'Eine Video-Datei ist nicht vorhanden!', 'span')}
			</Alert>
		);
	}

	/**
	 * Rendert den Button fuer das Zurueckspringen zum Objekt
	 * @return {JSX} JSX Markup
	 * @memberof StationVideoView
	 */
	renderButtonBackToProject(): ?React$Element<any> {
		const { backToProject } = this.props;

		return (
			<ButtonGroup>
				<Button onClick={() => backToProject()}>
					<FontAwesomeIcon className='video-control-icon' icon={faAngleDoubleLeft} />&nbsp;{getImageSmall('folder')}
				</Button>
			</ButtonGroup>
		);
	}

	/**
	 * Rendert den Play oder Pause Button fur das aktuelle Video
	 * @return {JSX} JSX Markup
	 * @memberof StationVideoView
	 */
	renderButtonPlayPause(): ?React$Element<any> {
		const { activePlayerState } = this.state;

		let buttonPlayPauseDisabled: boolean = true;
		let playerPause: boolean = true;

		if (activePlayerState.readyState === 4) {
			buttonPlayPauseDisabled = activePlayerState.currentTime >= activePlayerState.duration;
			playerPause = activePlayerState.paused;
		}

		const buttonPlay = (
			<OverlayTrigger rootClose placement='top' overlay={<Tooltip id='tooltipVideoStart'>{getTransObject('video.start', 'Start', 'span')}</Tooltip>}>
				<Button type='button' className="video-control-button" id='buttonPlayPause' disabled={buttonPlayPauseDisabled} onClick={() => this.setVideoPlay()}>
					{<FontAwesomeIcon className='video-control-icon' icon={faPlay} />}
				</Button>
			</OverlayTrigger>
		);

		const buttonPause = (
			<OverlayTrigger rootClose placement='top' overlay={<Tooltip id='tooltipVideoStop'>{getTransObject('video.stop', 'Stop', 'span')}</Tooltip>}>
				<Button type='button' className="video-control-button" id='buttonPlayPause' disabled={buttonPlayPauseDisabled} onClick={() => this.setVideoStop()}>
					{<FontAwesomeIcon className='video-control-icon' icon={faPause} />}
				</Button>
			</OverlayTrigger>
		);

		return <ButtonGroup>{playerPause ? buttonPlay : buttonPause}</ButtonGroup>;
	}

	/**
	 * Rendert die Button der Geschwindigkeit fuer das aktuelle Video
	 * @return {JSX} JSX Markup
	 * @memberof StationVideoView
	 */
	renderButtonGroupSpeed(): ?React$Element<any> {
		const { activePlayer } = this.state;
		const { mainPlayerPlaybackRate, secondaryPlayerPlaybackRate } = this.state;

		const playerPlaybackRate: number = activePlayer === PLAYER.MAIN ? mainPlayerPlaybackRate : secondaryPlayerPlaybackRate;

		return (
			<ButtonGroup>
				<OverlayTrigger rootClose placement='top' overlay={<Tooltip id='tooltipVideoSlower'>{getTransObject('video.slower', 'Langsamer', 'span')}</Tooltip>}>
					<Button type='button'  className="video-control-button" onClick={() => this.changePlaybackRate(-0.5)} disabled={playerPlaybackRate === VIDEODEFAULTS.PLAYBACKRATEMIN}>
						{<FontAwesomeIcon className='video-control-icon' icon={faBackward} />}
					</Button>
				</OverlayTrigger>
				<Button type='button'>
					{playerPlaybackRate.toFixed(1)}&nbsp;x
				</Button>
				<OverlayTrigger rootClose placement='top' overlay={<Tooltip id='tooltipVideoFaster'>{getTransObject('video.faster', 'Schneller', 'span')}</Tooltip>}>
					<Button type='button' className="video-control-button" onClick={() => this.changePlaybackRate(0.5)} disabled={playerPlaybackRate === VIDEODEFAULTS.PLAYBACKRATEMAX}>
						{<FontAwesomeIcon className='video-control-icon' icon={faForward} />}
					</Button>
				</OverlayTrigger>
			</ButtonGroup>
		);
	}

	/**
	 * Rendert das Popup mit der Einstellung um wieviel Sekunden vor oder zurueck gesprungen werden soll
	 * fuer das aktuelle Video
	 * @return {JSX} JSX Markup
	 * @memberof StationVideoView
	 */
	renderPopoverSliderSteps(): ?React$Element<any> {
		const { activePlayer, mainPlayerStep, secondaryPlayerStep } = this.state;

		const playerStep: number = activePlayer === PLAYER.MAIN ? mainPlayerStep : secondaryPlayerStep;

		return (
			<Popover id='popover-trigger-click-root-close' className='sliderStepTooltip'>
				<Slider
					step={1}
					min={VIDEODEFAULTS.PLAYERSTEPMIN}
					max={VIDEODEFAULTS.PLAYERSTEPMAX}
					defaultValue={playerStep}
					onChange={this.onChangeSliderSteps}
					trackStyle={{ backgroundColor: '#337ab7' }}
					handleStyle={{
						borderColor: '#337ab7',
						height: 20,
						width: 20,
						marginLeft: -10,
						marginTop: -8,
					}}
				/>
			</Popover>
		);
	}

	/**
	 * Rendert die Button zum schrittweise springen im aktuellen Video
	 * @return {JSX} JSX Markup
	 * @memberof StationVideoView
	 */
	renderButtonGroupSteps(): ?React$Element<any> {
		const { activePlayer, activePlayerState, mainPlayerStep, secondaryPlayerStep } = this.state;

		const playerStep: number = activePlayer === PLAYER.MAIN ? mainPlayerStep : secondaryPlayerStep;
		let playerCurrentTime: number = 0;
		let playerDuration: number = 0;

		if (activePlayerState.readyState === 4) {
			playerCurrentTime = activePlayerState.currentTime;
			playerDuration = activePlayerState.duration;
		}

		const buttonFastBackwardDisabled: boolean = !(playerCurrentTime > 0);
		const buttonStepBackwardDisabled: boolean = playerCurrentTime < playerStep;
		const buttonStepForwardDisabled: boolean = playerDuration - playerCurrentTime < playerStep;
		const buttonFastForwardDisabled: boolean = playerCurrentTime >= playerDuration;

		return (
			<ButtonGroup>
				<OverlayTrigger rootClose placement='top' overlay={<Tooltip id='tooltipVideoToStart'>{getTransObject('video.toStart', 'Zum Anfang', 'span')}</Tooltip>}>
					<Button type='button' className="video-control-button" onClick={() => this.setCurrentVideoPosition(0)} disabled={buttonFastBackwardDisabled}>
						{<FontAwesomeIcon className='video-control-icon' icon={faFastBackward} />}
					</Button>
				</OverlayTrigger>
				<OverlayTrigger
					rootClose
					placement='top'
					overlay={
						<Tooltip id='tooltipVideoBack'>
							{playerStep} {getTransObject('video.back', 'sek rückwarts springen', 'span')}
						</Tooltip>
					}
				>
					<Button type='button' className="video-control-button" onClick={() => this.stepCurrentVideoPosition(-playerStep, playerCurrentTime)} disabled={buttonStepBackwardDisabled}>
						{<FontAwesomeIcon className='video-control-icon' icon={faStepBackward} />}
					</Button>
				</OverlayTrigger>
				<OverlayTrigger trigger='click' rootClose placement='bottom' overlay={this.renderPopoverSliderSteps()}>
					<Button type='button' className="video-control-button" id='buttonSliderSteps'>
						{playerStep} {getTransObject('video.seconds', 'sek', 'span')}
					</Button>
				</OverlayTrigger>
				<OverlayTrigger
					rootClose
					placement='top'
					overlay={
						<Tooltip id='tooltipVideoForward'>
							{playerStep} {getTransObject('video.forward', 'sek vorwärts springen', 'span')}
						</Tooltip>
					}
				>
					<Button type='button' className="video-control-button" onClick={() => this.stepCurrentVideoPosition(playerStep, playerCurrentTime)} disabled={buttonStepForwardDisabled}>
						{<FontAwesomeIcon className='video-control-icon' icon={faStepForward} />}
					</Button>
				</OverlayTrigger>
				<OverlayTrigger rootClose placement='top' overlay={<Tooltip id='tooltipVideoToEnd'>{getTransObject('video.toEnd', 'Zum Ende', 'span')}</Tooltip>}>
					<Button type='button' className="video-control-button" onClick={() => this.setCurrentVideoPosition(playerDuration)} disabled={buttonFastForwardDisabled}>
						{<FontAwesomeIcon className='video-control-icon' icon={faFastForward} />}
					</Button>
				</OverlayTrigger>
			</ButtonGroup>
		);
	}

	/**
	 * Rendert den Button fuer die Fullscreen Ansicht des aktuellen Videos
	 * @return {JSX} JSX Markup
	 * @memberof StationVideoView
	 */
	renderButtonGroupFullscreen(): ?React$Element<any> {
		return (
			<ButtonGroup>
				<OverlayTrigger rootClose placement='top' overlay={<Tooltip id='tooltipVideoFullscreen'>{getTransObject('video.fullscreen', 'Vollbild', 'span')}</Tooltip>}>
					<Button type='button' className="video-control-button" onClick={() => this.showFullScreenVideo()}>
						<FontAwesomeIcon className="video-control-icon" icon={faExpandArrowsAlt} />
					</Button>
				</OverlayTrigger>
			</ButtonGroup>
		);
	}

    renderButtonDownloadFile(): ?React$Element<any> {
		return (
			<ButtonGroup>
				<OverlayTrigger rootClose placement='top' overlay={<Tooltip id='tooltipVideoFullscreen'>{getTransObject('video.downloadVideo', 'Download Film', 'span')}</Tooltip>}>
					<Button type='button' className="video-control-button"  onClick={() => this.downloadVideo()}>
						{<AiOutlineCloudDownload style={{fontSize: 24}}/>}
					</Button>
				</OverlayTrigger>
			</ButtonGroup>
		);
	}

	/**
	 * Rendert die Dropdownbox fuer die Auswahl der Videos
	 * Video fuer Hauptuntersuchung und Gegenuntersuchung
	 * @return {JSX} JSX Markup
	 * @memberof StationVideoView
	 */
	renderDropdownMovie(): ?React$Element<any> {
		const { currentObject } = this.props;
		const { activePlayer } = this.state;

		if (currentObject.hasOwnProperty('inspection') && currentObject.inspection.length === 2) {
			const inspectionMain: string = this.getTransElementText('common.mainInspection');
			const inspectionSecondary: string = this.getTransElementText('common.counterInspection');

			return (
				<ButtonGroup className='video-select-buttongroup'>
					<FormGroup controlId='formControlsSelect' className='no-margin-bottom'>
						<FormControl
							inputRef={(ref) => {
								this.selectInspectionVideo = ref;
							}}
							componentClass='select'
							placeholder='select'
							value={activePlayer}
							onChange={(e) => {
								this.handleSelectChange(e);
							}}
						>
							<option key={PLAYER.MAIN} value={PLAYER.MAIN}>
								{inspectionMain}
							</option>
							<option key={PLAYER.SECONDARY} value={PLAYER.SECONDARY}>
								{inspectionSecondary}
							</option>
						</FormControl>
					</FormGroup>
				</ButtonGroup>
			);
		}

		return null;
	}

	/**
	 * Rendert die beiden Badge fuer die aktuell abgespielte Zeit des Videos
	 * und die Gesamtzeit des aktuellen Videos
	 * @return {JSX} JSX Markup
	 * @memberof StationVideoView
	 */
	renderVideoDataBadge(): ?React$Element<any> {
		const { activePlayerState } = this.state;

		const playerCurrentTime: number = activePlayerState.readyState === 4 ? activePlayerState.currentTime : 0;
		const playerDuration: number = activePlayerState.readyState === 4 ? activePlayerState.duration : 0;

		return (
			<ButtonGroup className='video-data-buttongroup'>
				<Badge className='video-data-Badge'>
					{this.secondsToTime(playerCurrentTime)}
				</Badge>
				<Badge className='video-data-Badge'>
					{this.secondsToTime(playerDuration)}
				</Badge>
			</ButtonGroup>
		);
	}

	/**
	 * Rendert die gesamte Button Toolbar zur Bedienung des aktuellen Videos
	 * @return {JSX} JSX Markup
	 * @memberof StationVideoView
	 */
	renderButtonToolbar(): ?React$Element<any> {
		const showButtonIfNotPopOut = this.props.showStationVideoPopoutBtn;
		return (
            <div className="d-flex justify-content-between">
                <ButtonToolbar>
                    {showButtonIfNotPopOut ? this.renderButtonBackToProject() : ''}
                    {this.renderButtonPlayPause()}
                    {this.renderButtonGroupSpeed()}
                    {this.renderButtonGroupSteps()}
                    {this.renderDropdownMovie()}
                    {this.renderVideoDataBadge()}
                </ButtonToolbar>
                <ButtonToolbar>
                    {this.renderButtonGroupFullscreen()}
                    {showButtonIfNotPopOut && <StationVideoPopoutBtn />}
                    {this.renderButtonDownloadFile()}
                </ButtonToolbar>
            </div>
		);
	}

	/**
	 * Rendert das obere Card mit den Button fuer das aktuelle Video
	 * zeigt die aktuelle und die gesamte Videoposition als Zeit an
	 * @return {JSX} JSX Markup
	 * @memberof StationVideoView
	 */
	renderTopCard(): ?React$Element<any> {
		const { showPlayerButtonToolbar } = this.state;

		return (
			<Card>
				<Card.Body>
					<Container fluid>
						<Row className="mb-0">
							<Col sm={12} className='text-left'>
								<Fade in={showPlayerButtonToolbar}>
									<div>{this.renderButtonToolbar()}</div>
								</Fade>
							</Col>
						</Row>
					</Container>
				</Card.Body>
			</Card>
		);
	}

	/**
	 * Rendert das untere Card mit dem Videoplayer
	 * @return {JSX} JSX Markup
	 * @memberof StationVideoView
	 */
	renderBottomCard(): ?React$Element<any> {
		const { currentObject } = this.props;
		const { activePlayer } = this.state;

		this.viewMainPlayer = false;
		this.viewSecondaryPlayer = false;

		let videoPlayer: React$Element<any> = this.renderViewAlertMessage();

		if (currentObject.hasOwnProperty('inspection') && currentObject.inspection.length > 0) {
			const mainInspection: Object = currentObject.inspection[0];
			let mainPlayerFile: string = '';

			if (currentObject.inspection.length === 2) {
				const secondaryInspection: Object = currentObject.inspection[1];
				let secondaryPlayerFile: string = '';

				if (mainInspection.hasOwnProperty('videoRef') && mainInspection.videoRef !== '') {
					this.viewMainPlayer = true;
					mainPlayerFile = `${PATH_VIDEO}/${mainInspection.videoRef}`;
				}
				if (secondaryInspection.hasOwnProperty('videoRef') && secondaryInspection.videoRef !== '') {
					this.viewSecondaryPlayer = true;
					secondaryPlayerFile = `${PATH_VIDEO}/${secondaryInspection.videoRef}`;
				}

				if (activePlayer === PLAYER.MAIN && this.viewMainPlayer) {
					videoPlayer = this.renderVideoPlayer(PLAYER.MAIN, mainPlayerFile);
				} else if (activePlayer === PLAYER.SECONDARY && this.viewSecondaryPlayer) {
					videoPlayer = this.renderVideoPlayer(PLAYER.SECONDARY, secondaryPlayerFile);
				}
			} else {
				if (mainInspection?.videoRef !== '') {
					this.viewMainPlayer = true;
					mainPlayerFile = `${PATH_VIDEO}/${mainInspection.videoRef}`;
				}

				videoPlayer = this.viewMainPlayer ? this.renderVideoPlayer(PLAYER.MAIN, mainPlayerFile) : videoPlayer;
			}
		}

		return (
			<Card className='no-border-shadow'>
				<Card.Body >
					<Container fluid>
						<Row>
							<Col sm={12} className='text-left'>
								{videoPlayer}
							</Col>
						</Row>
					</Container>
				</Card.Body>
			</Card>
		);
	}

	/**
	 * Rendert die Komponente
	 * @return {JSX}
	 */
	render() {
		const { currentObject } = this.props;

		if (currentObject.hasOwnProperty('inspection') && currentObject.inspection.length > 0) {
			return (
				<div>
					{this.renderTopCard()}
					{this.renderBottomCard()}
				</div>
			);
		} else {
			return <span>NO VIDEOS</span>;
		}
	}
}

/**
 * HOC fuer das Verbinden mit Tastaturkuerzeln
 */
const VideoView = withKeyboardCommand(StationVideoView, [
	{
		key: 'ctrl+left',
		func: (child) => child.props.backToProject(),
	},
	{
		key: ['k', 'space'],
		func: (child) => child.keyEventVideoPlayOrStop(),
	},
	{
		key: ['j', 'left'],
		func: (child) => child.keyEventVideoStepBackward(),
	},
	{
		key: ['l', 'right'],
		func: (child) => child.keyEventVideoStepForward(),
	},
	{
		key: 'home',
		func: (child) => child.keyEventVideoStart(),
	},
	{
		key: 'end',
		func: (child) => child.keyEventVideoEnd(),
	},
	{
		key: 'f',
		func: (child) => child.keyEventVideoFullScreen(),
	},
	{
		key: '-',
		func: (child) => child.keyEventPlaybackRateSlow(),
	},
	{
		key: '+',
		func: (child) => child.keyEventPlaybackRateFast(),
	},
]);

/**
 * Verbindet die Klasse mit dem Redux Store
 */
const ReduxStationVideoView = connect(
	(state) => {
		return {
			currentObject: state.currentObject,
			currentStation: state.currentStation,
			seekStation: state.seekStation,
		};
	},
	{
		backToProject,
		addStationVideoViewInstance,
		updateStationVideoViewInstance,
	}
)(VideoView);
export default ReduxStationVideoView;
