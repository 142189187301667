// @flow
import React, { Component } from 'react';
import { Card, Row, Col, Nav, TabContent, TabContainer } from 'react-bootstrap';
import _ from 'lodash';

import ProjectMainView from './ProjectMainView';
import NetPlaneMainView from './NetPlaneMainView';
import ObjectListMainView from './objectlist/ObjectListMainView';
import ObjectMainView from './ObjectMainView';
import InspectionMainView from './InspectionMainView';
import StationMainView from './StationMainView';
import StationVideoView from './StationVideoView';

import { PROJECT_LEVEL_VIEWS, OBJECT_LEVEL_VIEWS } from '../misc/const';

import { getName } from '../misc/utils';
import { isEmpty } from '../misc/validationHelper';
import CanalInterface from '../templates/helper/CanalInterface';
import ViewBuilder from '../builder/ViewBuilder';
import PanoramoMainView from './PanoramoMainView';

type Props = {
	components: Object,
	currentNetPlane: Object,
	currentProject: Object,
	currentObject: Object,
	currentInterface: CanalInterface,
	mainViewTab: string,
	setMainViewTab: (string) => mixed,
};

type State = {
	tabs: Array<ViewBuilder>,
};

/**
 * React Komponente, die die Hauptansicht rendert
 * @type {Component}
 * @constructor
 */
export default class MainView extends Component<Props, State> {
	/**
	 * Creates an instance of MainView.
	 * @param {Props} props
	 * @memberof MainView
	 */
	constructor(props: Props) {
		super(props);

		this.state = {
			tabs: [],
		};
	}

	/**
	 * React Lifecycle Methode
	 * Fuegt die Tabs hinzu
	 *
	 * @memberof MainView
	 */
	componentDidMount() {
		const { tabs } = this.state;
		this.renderProjectView(tabs);
        this.renderNetPlaneView(tabs);
		this.renderObjecListMainView(tabs);
		this.renderObjectMainView(tabs);
		this.renderInspectionMainView(tabs);
		this.renderStationView(tabs);
		this.renderStationVideoView(tabs);
		this.renderPanoramoMainView(tabs);

		this.setState({
			tabs,
		});
	}

	/**
	 * Rendert den Tab fuer das Projekt
	 *
	 * @param {Array<ViewBuilder>} tabs Array, wo der Tab hinzugefuegt wird
	 * @memberof MainView
	 */
	renderProjectView(tabs: Array<ViewBuilder>) {
		tabs.push(
			new ViewBuilder({
				eventKey: PROJECT_LEVEL_VIEWS.MAIN.PROJECT_MAIN_VIEW,
				navTitle: (props) => props.currentProject.name,
				image: 'folder',
				component: <ProjectMainView />,
				mountOnEnter: true,
				unmountOnExit: true,
			})
		);
	}

	/**
	 * Rendert den Tab fuer den Netzplan
	 *
	 * @param {Array<ViewBuilder>} tabs Array, wo der Tab hinzugefuegt wird
	 * @memberof MainView
	 */
	renderNetPlaneView(tabs: Array<ViewBuilder>) {
		tabs.push(
			new ViewBuilder({
				eventKey: PROJECT_LEVEL_VIEWS.MAIN.NET_PLANE_VIEW,
				title: {
					key: 'main.netplane',
					fallback: 'Netzplan',
				},
				image: 'net_plane',
				component: <NetPlaneMainView />,
				mountOnEnter: true,
				displayCondition: (props) => !_.isEmpty(props.currentNetPlane)
			})
		);
	}

	/**
	 * Rendert den Tab fuer die Objektliste
	 *
	 * @param {Array<ViewBuilder>} tabs Array, wo der Tab hinzugefuegt wird
	 * @memberof MainView
	 */
	renderObjecListMainView(tabs: Array<ViewBuilder>) {
		tabs.push(
			new ViewBuilder({
				eventKey: PROJECT_LEVEL_VIEWS.MAIN.OBJECT_LIST_MAIN_VIEW,
				title: {
					key: 'main.objects',
					fallback: 'Objekte',
				},
				image: 'object',
				component: <ObjectListMainView />,
				mountOnEnter: true,
				unmountOnExit: true,
			})
		);
	}

	/**
	 * Rendert den Tab fuer das Objekt
	 *
	 * @param {Array<ViewBuilder>} tabs Array, wo der Tab hinzugefuegt wird
	 * @memberof MainView
	 */
	renderObjectMainView(tabs: Array<ViewBuilder>) {
		tabs.push(
			new ViewBuilder({
				eventKey: OBJECT_LEVEL_VIEWS.MAIN.OBJECT_MAIN_VIEW,
				navTitle: (props) => getName(props.currentObject, props.currentInterface.objectname),
				imageFunc: (props) => props.currentObject.type,
				component: <ObjectMainView />,
				mountOnEnter: true,
				unmountOnExit: true,
			})
		);
	}

	/**
	 * Rendert den Tab fuer die Inspektion
	 *
	 * @param {Array<ViewBuilder>} tabs Array, wo der Tab hinzugefuegt wird
	 * @memberof MainView
	 */
	renderInspectionMainView(tabs: Array<ViewBuilder>) {
		tabs.push(
			new ViewBuilder({
				eventKey: OBJECT_LEVEL_VIEWS.MAIN.INSPECTION_MAIN_VIEW,
				title: {
					key: 'main.inspection',
					fallback: 'Untersuchung',
				},
				image: 'inspection',
				component: <InspectionMainView />,
				mountOnEnter: true,
				unmountOnExit: true,
				displayCondition: (props) => props.currentObject && props.currentObject.inspection && props.currentObject.inspection.length > 0,
			})
		);
	}

	/**
	 * Rendert den Tab fuer die Stationen
	 *
	 * @param {Array<ViewBuilder>} tabs Array, wo der Tab hinzugefuegt wird
	 * @memberof MainView
	 */
	renderStationView(tabs: Array<ViewBuilder>) {
		tabs.push(
			new ViewBuilder({
				eventKey: OBJECT_LEVEL_VIEWS.MAIN.STATION_MAIN_VIEW,
				title: {
					key: 'main.station',
					fallback: 'Stationen',
				},
				image: 'station',
				component: <StationMainView />,
				mountOnEnter: true,
				unmountOnExit: true,
				displayCondition: (props) => props.currentObject?.stations?.length > 0,
			})
		);
	}

	/**
	 * Rendert den Tab fuer die Videos
	 *
	 * @param {Array<ViewBuilder>} tabs Array, wo der Tab hinzugefuegt wird
	 * @memberof MainView
	 */
	renderStationVideoView(tabs: Array<ViewBuilder>) {
		tabs.push(
			new ViewBuilder({
				eventKey: OBJECT_LEVEL_VIEWS.MAIN.STATION_VIDEO_VIEW,
				title: {
					key: 'main.film',
					fallback: 'Film',
				},
				image: 'film',
				component: <StationVideoView showStationVideoPopoutBtn={true} instance='MainView_Component' />,
				unmountOnExit: true,
				mountOnEnter: true,
				displayCondition: (props) => props.currentObject?.inspection?.length > 0 && this.validateVideos(props.currentObject.inspection),
			})
		);
	}

	/**
	 * Rendert den Tab fuer den Panoramo Viewer
	 *
	 * @param {Array<ViewBuilder>} tabs Array, wo der Tab hinzugefuegt wird
	 * @memberof MainView
	 */
	renderPanoramoMainView(tabs: Array<ViewBuilder>) {
		tabs.push(
			new ViewBuilder({
				eventKey: OBJECT_LEVEL_VIEWS.MAIN.PANORAMO_MAIN_VIEW,
				title: {
					key: 'main.film',
					fallback: 'Film',
				},
				image: 'pano',
				component: <PanoramoMainView instance='PanoramoMainView_Component' />,
				unmountOnExit: true,
				mountOnEnter: true,
				displayCondition: (props) => this.validatePanoramo(props),
			})
		);
	}

    /**
     * ist true wenn die Inspektion eine PanoramoRef hat, also ein Panoramo FIlm vorhanden ist
     */
	validatePanoramo = (props: Object): boolean => {
		let result: boolean = false;
		if (props.currentObject?.inspection?.length > 0) {
			props.currentObject.inspection.forEach((inspection: Object) => {
				if (!isEmpty(inspection.panoramoRef)) {
					result = true;
				}
			});
		}
		return result;
	};

    /**
     * ist true, wenn ein inspection ein videoref hat, also ein Video vorhanden ist
     */
	validateVideos = (inspections: Array<Object>) => {
		let result: boolean = false;
		if (inspections) {
			inspections.forEach((inspection: Object) => {
				if (!isEmpty(inspection.videoRef)) {
					result = true;
				}
			});
		}
		return result;
	};

	/**
	 * Rendert die Hauptansicht
	 * @return {JSX} JSX Markup
	 */
	render() {
		const { setMainViewTab } = this.props;
		const { mainViewTab } = this.props;
		const { tabs } = this.state;

		return (
			<Card className='no-border-shadow'>
				<Card.Body>
					<TabContainer activeKey={mainViewTab} id='tabs' onSelect={(key) => setMainViewTab(key)}>
						<Row className='no-margin down'>
							<Col xs={12} sm={12} md={12} lg={12}>
								<Nav>{tabs.map((tab) => tab.getNavItem(this.props))}</Nav>
							</Col>
							<Col xs={12} sm={12} md={12} lg={12}>
								<TabContent>{tabs.map((tab) => tab.getTabPane(this.props))}</TabContent>
							</Col>
						</Row>
					</TabContainer>
				</Card.Body>
			</Card>
		);
	}
}
