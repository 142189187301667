import CardHelper from '../../../form/helper/CardHelper';
import FieldHelper from '../../../form/helper/FieldHelper';
import ComplexTextBoxHelper from '../../../form/helper/ComplexTextBoxHelper';
import CheckboxHelper from '../../../form/helper/CheckboxHelper';
import CardCardHelper from '../../../form/helper/CardCardHelper';

const canalObjectSectionArray = [
    new CardHelper({
        id: 'general',
        title: {
            key: 'common.general',
            fallback: 'Allgemein'
        },
        items: [
            new FieldHelper({
                id: 'sectionName',
                title: {
                    key: 'object.sectionName',
                    fallback: 'Haltungsname'
                },
                accessor: 'Objektbezeichnung'
            }),
            new FieldHelper({
                id: 'constructionYear',
                title: {
                    key: 'object.constructionYear',
                    fallback: 'Baujahr'
                },
                accessor: 'Baujahr'
            }),
            new FieldHelper({
                id: 'sectionLength',
                title: {
                    key: 'object.sectionLength',
                    fallback: 'Haltungslänge'
                },
                accessor: 'Laenge'
            }),
            new FieldHelper({
                id: 'material',
                title: {
                    key: 'object.material',
                    fallback: 'Material'
                },
                accessor: 'Material',
                field: new ComplexTextBoxHelper()
            }),
            new FieldHelper({
                id: 'sewerSystem',
                title: {
                    key: 'isybauxml.object.common.sewerSystem',
                    fallback: 'Kanalsystem'
                },
                accessor: 'Entwaesserungsart',
                field: new ComplexTextBoxHelper()
            }),
            new FieldHelper({
                id: 'status',
                title: {
                    key: 'isybauxml.object.common.status',
                    fallback: 'Status'
                },
                accessor: 'Status',
                field: new ComplexTextBoxHelper()
            }),
            new FieldHelper({
                id: 'comment',
                title: {
                    key: 'common.comment',
                    fallback: 'Kommentar'
                },
                accessor: 'Kommentar',
                columnSize: {
                    md: 12,
                    lg: 12
                },
                size: {
                    md: 2,
                    lg: 2
                }
            })
        ]
    }),
    new CardHelper({
        id: 'position',
        title: {
            key: 'isybauxml.object.common.layer',
            fallback: 'Lage'
        },
        items: [
            new FieldHelper({
                id: 'district',
                title: {
                    key: 'common.district',
                    fallback: 'Ortsteil'
                },
                accessor: 'Ortsteil',
                field: new ComplexTextBoxHelper()
            }),
            new FieldHelper({
                id: 'terrainType',
                title: {
                    key: 'common.terrainType',
                    fallback: 'Lage im Verkehr'
                },
                accessor: 'LageOberflaeche',
                field: new ComplexTextBoxHelper()
            }),
            new FieldHelper({
                id: 'street',
                title: {
                    key: 'common.street',
                    fallback: 'Straße'
                },
                accessor: 'Strassenname',
                field: new ComplexTextBoxHelper()
            }),
            new FieldHelper({
                id: 'floodingArea',
                title: {
                    key: 'object.floodingArea',
                    fallback: 'Überschw.-Gebiet'
                },
                accessor: 'Ueberschwemmungsgebiet',
                field: new CheckboxHelper()
            }),
            new FieldHelper({
                id: 'comment',
                title: {
                    key: 'common.comment',
                    fallback: 'Kommentar'
                },
                accessor: 'KommentarLage',
                columnSize: {
                    md: 12,
                    lg: 12
                },
                size: {
                    md: 2,
                    lg: 2
                }
            }),
        ]
    }),
    new CardHelper({
        id: 'manhole',
        items: [
            new CardCardHelper({
                id: 'manholeCard',
                items: [
                    new CardHelper({
                        id: 'topManhole',
                        title: {
                            key: 'object.topManhole',
                            fallback: 'Oberer Schacht'
                        },
                        items: [
                            new FieldHelper({
                                id: 'topName',
                                title: {
                                    key: 'common.name',
                                    fallback: 'Name'
                                },
                                accessor: 'ObererSchacht.fields.Schachtbezeichner',
                                columnSize: {
                                    lg: 12,
                                    md: 12
                                },
                                size: {
                                    lg: 3,
                                    md: 3,
                                    sm: 2,
                                    xs: 2
                                }
                            }),
                            new FieldHelper({
                                id: 'topInvertLevel',
                                title: {
                                    key: 'object.invertLevel',
                                    fallback: 'Sohlhöhe'
                                },
                                accessor: 'ObererSchacht.fields.Sohlhoehe',
                                columnSize: {
                                    lg: 12,
                                    md: 12
                                },
                                size: {
                                    lg: 3,
                                    md: 3,
                                    sm: 2,
                                    xs: 2
                                }
                            }),
                            new FieldHelper({
                                id: 'topDistancePipe',
                                title: {
                                    key: 'object.distancePipe',
                                    fallback: 'Abstand Rohranfang zur Schachtmitte'
                                },
                                accessor: 'ObererSchacht.fields.DistanceManholeCenter',
                                columnSize: {
                                    lg: 12,
                                    md: 12
                                },
                                size: {
                                    lg: 5,
                                    md: 5,
                                    sm: 5,
                                    xs: 5
                                }
                            })
                        ]
                    }),
                    new CardHelper({
                        id: 'bottomManhole',
                        title: {
                            key: 'object.bottomManhole',
                            fallback: 'Unterer Schacht'
                        },
                        items: [
                            new FieldHelper({
                                id: 'bottomName',
                                title: {
                                    key: 'common.name',
                                    fallback: 'Name'
                                },
                                accessor: 'UntererSchacht.fields.Schachtbezeichner',
                                columnSize: {
                                    lg: 12,
                                    md: 12
                                },
                                size: {
                                    lg: 3,
                                    md: 3,
                                    sm: 2,
                                    xs: 2
                                }
                            }),
                            new FieldHelper({
                                id: 'bottomInvertLevel',
                                title: {
                                    key: 'object.invertLevel',
                                    fallback: 'Sohlhöhe'
                                },
                                accessor: 'UntererSchacht.fields.Sohlhoehe',
                                columnSize: {
                                    lg: 12,
                                    md: 12
                                },
                                size: {
                                    lg: 3,
                                    md: 3,
                                    sm: 2,
                                    xs: 2
                                }
                            }),
                            new FieldHelper({
                                id: 'bottomDistancePipe',
                                title: {
                                    key: 'object.distancePipe',
                                    fallback: 'Abstand Rohranfang zur Schachtmitte'
                                },
                                accessor: 'UntererSchacht.fields.DistanceManholeCenter',
                                columnSize: {
                                    lg: 12,
                                    md: 12
                                },
                                size: {
                                    lg: 5,
                                    md: 5,
                                    sm: 5,
                                    xs: 5
                                }
                            })
                        ]
                    })
                ]
            })
        ]
    }),
    new CardHelper({
        id: 'geometry',
        title: {
            key: 'common.geometry',
            fallback: 'Geometrie'
        },
        items: [
            new FieldHelper({
                id: 'profileType',
                title: {
                    key: 'object.profileType',
                    fallback: 'Profilart'
                },
                accessor: 'Profilart',
                field: new ComplexTextBoxHelper()
            }),
            new FieldHelper({
                id: 'profileHeight',
                title: {
                    key: 'object.profileHeight',
                    fallback: 'Profilhöhe'
                },
                accessor: 'Profilhoehe'
            }),
            new FieldHelper({
                id: 'pipeLength',
                title: {
                    key: 'object.pipeLength',
                    fallback: 'Rohrlänge'
                },
                accessor: 'RohrLaenge'
            }),
            new FieldHelper({
                id: 'profileWidth',
                title: {
                    key: 'object.profileWidth',
                    fallback: 'Profilbreite'
                },
                accessor: 'Profilbreite'
            }),
            new FieldHelper({
                id: 'sdr',
                title: {
                    key: 'isybauxml.object.common.sdr',
                    fallback: 'SDR'
                },
                accessor: 'SDR'
            }),
            new FieldHelper({
                id: 'außendurchmesser',
                title: {
                    key: 'isybauxml.object.common.outerDiameter',
                    fallback: 'Außendurchmesser'
                },
                accessor: 'Aussendurchmesser'
            }),
            new FieldHelper({
                id: 'specialProfile',
                title: {
                    key: 'isybauxml.object.common.specialProfile',
                    fallback: 'Sonderprofil vorhanden'
                },
                accessor: 'SonderprofilVorhanden',
                field: new CheckboxHelper()
            }),
            new FieldHelper({
                id: 'profileId',
                title: {
                    key: 'isybauxml.object.common.profileID',
                    fallback: 'ProfilID'
                },
                accessor: 'ProfilID'
            }),
            new FieldHelper({
                id: 'dimensionOrigin',
                title: {
                    key: 'isybauxml.object.common.dimensionOrigin',
                    fallback: 'Herkunft der Profilmaße'
                },
                accessor: 'HerkunftProfilmasse',
                field: new ComplexTextBoxHelper()
            })
        ]
    }),
    new CardHelper({
        id: 'envparams',
        title: {
            key: 'object.environmentalParameters',
            fallback: 'Umweltparameter'
        },
        items: [
            new FieldHelper({
                id: 'wastewaterType',
                title: {
                    key: 'isybauxml.object.common.wastewaterType',
                    fallback: 'Abwasserart'
                },
                accessor: 'Abwasserart',
                field: new ComplexTextBoxHelper()
            }),
            new FieldHelper({
                id: 'waterConservationArea',
                title: {
                    key: 'object.waterConservationArea',
                    fallback: 'Wasserschutzzone'
                },
                accessor: 'Wasserschutzzone',
                field: new ComplexTextBoxHelper()
            }),
            new FieldHelper({
                id: 'sewageSubstances',
                title: {
                    key: 'isybauxml.object.common.sewageSubstances',
                    fallback: 'AbwasserartWGS'
                },
                accessor: 'AbwasserartWGS',
                field: new ComplexTextBoxHelper()
            }),
            new FieldHelper({
                id: 'soilType',
                title: {
                    key: 'isybauxml.object.common.soilType',
                    fallback: 'Bodenart'
                },
                accessor: 'Bodenart',
                field: new ComplexTextBoxHelper()
            }),
            new FieldHelper({
                id: 'groundwaterDistance',
                title: {
                    key: 'object.groundwaterDistance',
                    fallback: 'Grundwasserabstand'
                },
                accessor: 'GWabstand',
                field: new ComplexTextBoxHelper()
            })
        ]
    }),
    new CardHelper({
        id: 'sanitation',
        onlyWithValues: true,
        title: {
            key: 'isybauxml.object.common.rehabilitation',
            fallback: 'Sanierung'
        },
        items: [
            new FieldHelper({
                id: 'interiorProtection',
                title: {
                    key: 'object.interiorProtection',
                    fallback: 'Innenschutz'
                },
                accessor: 'Innenschutz',
                field: new ComplexTextBoxHelper()
            }),
            new FieldHelper({
                id: 'coating',
                title: {
                    key: 'object.coating',
                    fallback: 'Auskleidung'
                },
                accessor: 'Auskleidung',
                field: new ComplexTextBoxHelper()
            }),
            new FieldHelper({
                id: 'typeCoating',
                title: {
                    key: 'isybauxml.object.common.typeCoating',
                    fallback: 'Art der Auskleidung'
                },
                accessor: 'ArtAuskleidung',
                field: new ComplexTextBoxHelper()
            }),
            new FieldHelper({
                id: 'coatMaterial',
                title: {
                    key: 'isybauxml.object.common.coatMaterial',
                    fallback: 'Auskl. Material'
                },
                accessor: 'MaterialAuskleidung',
                field: new ComplexTextBoxHelper()
            })
        ]
    }),
    new CardHelper({
        id: 'misc',
        title: {
            key: 'common.other',
            fallback: 'Sonstiges'
        },
        items: [
            new FieldHelper({
                id: 'oldSectionName',
                title: {
                    key: 'isybauxml.object.section.other.oldSectionName',
                    fallback: 'Alter Haltungsname'
                },
                accessor: 'AlteObjektbezeichnung'
            }),
            new FieldHelper({
                id: 'strang',
                title: {
                    key: 'isybauxml.object.common.strang',
                    fallback: 'Strang'
                },
                accessor: 'Strang'
            }),
            new FieldHelper({
                id: 'lengthCenterCover',
                title: {
                    key: 'isybauxml.object.section.other.lengthCenterCover',
                    fallback: 'DMP-Länge'
                },
                accessor: 'DMPLaenge'
            }),
            new FieldHelper({
                id: 'pressurizingMethod',
                title: {
                    key: 'isybauxml.object.common.pressurizingMethod',
                    fallback: 'Druckverfahren'
                },
                accessor: 'Druckverfahren',
                field: new ComplexTextBoxHelper()
            }),
            new FieldHelper({
                id: 'sectionFunction',
                title: {
                    key: 'isybauxml.object.section.other.sectionFunction',
                    fallback: 'Funktion der Haltung'
                },
                accessor: 'HaltungsFunktion',
                field: new ComplexTextBoxHelper()
            }),
            new FieldHelper({
                id: 'nominalPositivePressure',
                title: {
                    key: 'isybauxml.object.common.nominalPositivePressure',
                    fallback: 'Nenndruck'
                },
                accessor: 'Nenndruck'
            }),
            new FieldHelper({
                id: 'materialOrigin',
                title: {
                    key: 'isybauxml.object.common.materialOrigin',
                    fallback: 'Herkunft des Materials'
                },
                accessor: 'HerkunftMaterial',
                field: new ComplexTextBoxHelper()
            }),
            new FieldHelper({
                id: 'lisaGUID',
                title: {
                    key: 'isybauxml.object.common.lisaGUID',
                    fallback: 'LISA-GUID'
                },
                accessor: 'LISA-GUID'
            }),
            new FieldHelper({
                id: 'property',
                title: {
                    key: 'isybauxml.object.common.property',
                    fallback: 'Eigentum'
                },
                accessor: 'Eigentum'
            })
        ]
    })
];

export default canalObjectSectionArray;