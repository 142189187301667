// @flow
import React, { Component } from 'react';
import {Card, Form, FormGroup, Col, InputGroup, FormControl, Button, ButtonToolbar} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faLock, faSignInAlt, faTimes } from '@fortawesome/free-solid-svg-icons';

type Props = {
    uuid: string,
    title: string | React$Element<any>,
    newPassword: string,
    keyField: string,
    confirm: string,
    callback: (string, string, string) => mixed,
    button: string | React$Element<any>,
    abortButton: string | React$Element<any>,
    abort: () => mixed
}

type State = {
    ready: boolean
}

/**
 * Komponente fuer das Zuruecksetzen des Passworts
 * @export
 * @class ResetPassword
 * @extends {Component}
 */
export default class ResetPassword extends Component<Props,State> {
    key: ?HTMLInputElement;
    password: ?HTMLInputElement;
    confirmPassword: ?HTMLInputElement;

    /**
     * Creates an instance of ResetPassword.
     * @param {Object} props Die Properties der Klasse
     * @memberof ResetPassword
     */
    constructor(props: Object) {
        super(props);

        this.state = {
            ready: false
        }
    }

    /**
     * React Lifecycle Methode
     * @memberof ResetPassword
     */
    componentDidMount():void {
        // $FlowFixMe
        this.key.focus();
    }

    /**
     * Ruft den Callback auf wenn Absenden betaetigt wird
     * @memberof ResetPassword
     */
    onSend = ():void => {
        const {callback} = this.props;
        // $FlowFixMe
        callback(this.key.value, this.password.value, this.confirmPassword.value);
    }

    /**
     * Ruft den Abbrechen Callback auf wenn das Abbrechen betaetigt wird
     * @memberof ResetPassword
     */
    onAbort = ():void => {
        const {abort} = this.props;
        abort();
    }

    /**
     * Prueft ob die Eingabefelder korrekt gefuellt sind und setzt den Status
     * @memberof ResetPassword
     */
    checkFields = ():void => {
        // $FlowFixMe
        const match = this.password.value.trim() === this.confirmPassword.value.trim();
        // $FlowFixMe
        const notNull = this.password.value.trim() !== '' && this.confirmPassword.value.trim() !== '' && this.key.value.trim() !== '';

        this.setState({
            ready: match && notNull
        });
    }

    /**
     * Workaround fuer Submit Probleme im Firefox
     * @param {Event} e Das Event das ausgeloest wurde
     */
    handleKeyPress = (e: SyntheticEvent<>):void => {
        const {ready} = this.state;
        if (e.key === 'Enter' && ready) {
            e.stopPropagation();
            e.preventDefault();
            this.onSend();
        }
    }

    /**
     * Rendert die Komponente
     * @return {JSX} JSX Markup
     */
    render() {
        const {title, keyField, newPassword, confirm, button, abortButton, uuid} = this.props;
        const {ready} = this.state;

        return (
            <Card onKeyPress={this.handleKeyPress}>
                <Card.Heading>
                    <Card.Title>{title}</Card.Title>
                </Card.Heading>
                <Card.Body >
                    <Form horizontal>
                        <FormGroup>
                            <Col sm={12}>
                                <InputGroup>
                                    <InputGroup.Addon>
                                        <FontAwesomeIcon icon={faCog}></FontAwesomeIcon>
                                    </InputGroup.Addon>
                                    <FormControl
                                        type="text"
                                        placeholder={keyField}
                                        inputRef={ref => { this.key = ref;}}
                                        onChange={this.checkFields}
                                        defaultValue={uuid}
                                    >
                                    </FormControl>
                                </InputGroup>
                            </Col>
                        </FormGroup>
                        <FormGroup>
                            <Col sm={12}>
                                <InputGroup>
                                    <InputGroup.Addon>
                                        <FontAwesomeIcon icon={faLock}></FontAwesomeIcon>
                                    </InputGroup.Addon>
                                    <FormControl
                                        type="password"
                                        placeholder={newPassword}
                                        inputRef={ref => { this.password = ref; }}
                                        onChange={this.checkFields}
                                    >
                                    </FormControl>
                                </InputGroup>
                            </Col>
                        </FormGroup>
                        <FormGroup>
                            <Col sm={12}>
                                <InputGroup>
                                    <InputGroup.Addon>
                                        <FontAwesomeIcon icon={faLock}></FontAwesomeIcon>
                                    </InputGroup.Addon>
                                    <FormControl
                                        type="password"
                                        placeholder={confirm}
                                        inputRef={ref => { this.confirmPassword = ref; }}
                                        onChange={this.checkFields}
                                    >
                                    </FormControl>
                                </InputGroup>
                            </Col>
                        </FormGroup>
                        <FormGroup>
                            <Col sm={12}>
                                <ButtonToolbar>
                                    <Button
                                        onClick={this.onSend}
                                        disabled={!ready}
                                    >
                                        <FontAwesomeIcon icon={faSignInAlt}></FontAwesomeIcon> {button}
                                    </Button>
                                    <Button
                                        onClick={this.onAbort}
                                    >
                                        <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon> {abortButton}
                                    </Button>
                                </ButtonToolbar>
                            </Col>
                        </FormGroup>
                    </Form>
                </Card.Body>
            </Card>
        );
    }
}