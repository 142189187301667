// @flow
// $FlowFixMe
import React, {useState} from 'react';
import {Popover, OverlayTrigger, FormControl, InputGroup, Button} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExchangeAlt } from '@fortawesome/free-solid-svg-icons';

type Props = {
    value?: Object,
    inputSize: number
}

const MODE = {
    TEXT: 'text',
    CODE: 'code'
}

const hasMode = (value: ?Object) => value && value.hasOwnProperty(MODE.TEXT) && value.hasOwnProperty(MODE.CODE);

const getValue = (value: ?Object, mode: string) => {
    if (value && hasMode(value)) {
        return value[mode];
    }
    else {
        return value;
    }
}

/**
 * Erzeugt eine Eingabefeld
 * @param {any} value Wert des Feldes
 * @param {number} inputSize Groesse des Feldes
 */
const ComplexTextBox = ({value, inputSize}:Props) => {
    const [mode, setMode] = useState(MODE.TEXT);

    const val = getValue(value, mode);

    const popover: React$Element<any> = (
        <Popover id="popover">
            <strong>{val}</strong>
        </Popover>
    );

    const changeMode = () => mode === MODE.TEXT ? setMode(MODE.CODE) : setMode(MODE.TEXT)

    return (
        <OverlayTrigger
            rootClose
            trigger="click"
            placement="top"
            overlay={popover}
        >
            {
                hasMode(value) ?
                    <InputGroup className="ComplexTextBox">
                        <FormControl
                            type="text"
                            value={val}
                            readOnly

                        />
                        <Button size="small" onClick={changeMode} >
                            <FontAwesomeIcon icon={faExchangeAlt}></FontAwesomeIcon>
                        </Button>
                    </InputGroup>
                :
                    <FormControl
                        type="text"
                        value={val}
                        readOnly
                    />
            }
        </OverlayTrigger>
    )

}

export default ComplexTextBox;