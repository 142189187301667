// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import general from '../../templates/General';
import { showSideView, objectOpened } from '../../actions/viewActions';
import { originalSelected } from '../../actions/viewActions';

type Props = {
	showSideView: (boolean) => mixed,
	objects: Array<Object>,
	objectOpened: (Object) => mixed,
	currentObject: Object,
	originalSelected: (Object) => mixed,
};

/**
 * React Komponente, die eine Tabelle im SideView rendert
 * @type {Component}
 * @constructor
 */
class ObjectListSideView extends Component<Props> {
	/**
	 * Properties des tr Elements
	 * @param  {object} state    Status der Tabelle
	 * @param  {object} rowInfo  Informationen ueber die Zeile
	 * @param  {object} column
	 * @param  {object} instance Instanz der Tabelle
	 * @return {object}          Properties fuer das tr Element
	 */
	trProps = (state: Object, rowInfo: Object, column: Object, instance: Object): Object => {
		const { currentObject } = this.props;
		const { objectOpened, showSideView } = this.props;
		const { originalSelected } = this.props;

		return {
			onClick: (e) => {
				objectOpened(rowInfo.original);
				originalSelected(rowInfo.original);
				showSideView(false);
			},
			style: {
				background: currentObject && rowInfo.original._id === currentObject._id ? 'rgba(0, 0, 249, 0.6)' : '',
				color: currentObject && rowInfo.original._id === currentObject._id ? 'white' : '',
			},
		};
	};

	/**
	 * Rendert eine Liste der GIS Objekte im SideView
	 * @return {JSX} JSX Markup
	 */
	render() {
		const { objects } = this.props;

		return (
			<ReactTable
				data={objects}
				columns={general.objectlist.columns}
				className="-striped -highlight tableSize"
				showPagination={false}
				getTrProps={this.trProps}
				pageSize={objects.length > 0 ? objects.length : 1}
			/>
		);
	}
}

/**
 * Verbindet die Klasse mit dem Redux Store
 */
const ReduxObjectListSideView = connect(
	(state) => {
		return {
			objects: state.currentProject.objects,
			currentObject: state.currentObject,
		};
	},
	{
		showSideView,
		objectOpened,
		originalSelected,
	}
)(ObjectListSideView);

export default ReduxObjectListSideView;
