import React from 'react';
import { Spinner } from 'react-bootstrap';

import '../../css/fallback.css';

/**
 * Einfache Komponent fuer ein Fallback beim Laden von Uebersetzungen
 */
const FallbackUI = () => {
	return (
		<div>
			<Spinner animation="border" role="status">
				<span className="visually-hidden">Loading...</span>
			</Spinner>
		</div>
	);
};

export default FallbackUI;
