// @flow
import React from 'react';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';

import TouchHandler from './common/TouchHandler';

import 'bootstrap/dist/css/bootstrap.css';
import 'react-table/react-table.css';
import '../css/App.css';
import "react-toastify/dist/ReactToastify.css";

import Navigation from './Navigation';
import MainView from './MainView';
import SideView from './SideView';
import LoadingScreen from './page/LoadingScreen';
import NoProject from './page/NoProject';
import AuthenticatedPage from './page/AuthenticatedPage';
import SideMenu from './page/SideMenu';
import { actions } from '../actions/combindedActions';
import cellRenderer from '../builder/CellRenderer';
import AuthContainer from './auth/AuthContainer';
import LayoutManager from '../layout/LayoutManager';
import LoadingSpinner from '../components/common/LoadingSpinner';

import { withTranslation } from 'react-i18next';

/**
 * React Komponente, die den ganzen Seiteninhalt haelt
 * @type {Component}
 * @constructor
 */
function App ({
	authentication,
	projects,
	currentProject,
	_message,
	resetLogin,
	_login,
	_loginReset,
	_loginPreview,
	showSideView,
	_logout,
	currentObject,
	settings,
	currentInterface,
	sideViewMenu,
	setMainViewTab,
	currentNetPlane,
	components,
	mainViewTab,
	stationVideoViewInstances,
	...props
}) {
	/**
	 * Rendert die Seitenansicht
	 * @return {JSX}
	 */
	const renderSideView = (): React$Element<any> => {
		return (
			<SideView
				components={components}
				currentObject={currentObject}
				currentNetPlane={currentNetPlane}
				currentInterface={currentInterface}
				stationVideoViewInstances={stationVideoViewInstances}
			/>
		);
	}

	/**
	 * Rendert die Hauptansicht
	 * @return {JSX}
	 */
	const renderMainView = (): React$Element<any> => {		
		return (
			<MainView
				components={components}
				currentNetPlane={currentNetPlane}
				currentProject={currentProject}
				currentObject={currentObject}
				currentInterface={currentInterface}
				mainViewTab={mainViewTab}
				setMainViewTab={setMainViewTab}
			/>
		);
	}

	/**
	 * Callback fuer einen Swipe auf einem Touchgeraet zum oeffnen des Seitenmenues
	 *
	 * @param {Object} event Das ausgeloeste Event
	 *
	 * @memberof App
	 */
	const onSwipeRight = (event: Object) => {
		if (event.pointerType === 'touch' || event.srcEvent.pointerType === 'touch') {			
			showSideView(true);
		}
		// Stoppen damit sich auf Tablets nicht das Seitenmenue oeffnet
		event.srcEvent.stopPropagation();
	};

	/**
	 * Rendert die Ansicht fuer angemeldete Benutzer
	 * @param {String} header Der aktuelle Header fuer die Navigation
	 * @return {JSX}
	 */
	const renderAuthenticatedPage = (header: string): React$Element<any> => {	
		const sideView = renderSideView();
		const layout = LayoutManager.getLayout(settings.layout);

		return (
			<TouchHandler onSwipeRight={onSwipeRight}>
				<span>
					<AuthenticatedPage
						navigation={<Navigation header={header} layout={layout} />}
						layout={layout}
						sideView={sideView}
						mainView={renderMainView()}
						sideMenu={<SideMenu show={sideViewMenu} onHide={() => showSideView(false)} title={header} body={sideView}></SideMenu>}
					/>
				</span>
			</TouchHandler>
		);
	}

	/**
	 * Rendert den AuthContainer
	 * @return {JSX}
	 */
	const renderAuthContainer = (): React$Element<any> => {
		return (
			<AuthContainer 
				_loginReset={_loginReset}
				_login={_login}
				_loginPreview={_loginPreview}
				resetLogin={resetLogin}
				_message={_message}
			/>
		);
	}

	/**
	 * Prueft ob ein Projekt gewaehlt ist und liefert dann dessen Schnittstelle zurueck
	 * @return {String} Der Wert der Schnittstelle oder IKAS Evolution WebViewer
	 */
	const setHeader = (): string => {
		let header: string = 'IKAS Evolution WebViewer';

		if (currentProject) {
			header = currentProject.interface !== '' ? currentProject.interface : 'IKAS Evolution WebViewer';
		}

		return header;
	}

    const handleNoProjectsLogout = () => {
        _logout();
	}

		
	cellRenderer.setProps(props);

	let appView: React$Element<any>;

	let header: string = setHeader();
	document.title = header;

	if (projects == null && authentication.token) {
		appView = <LoadingScreen />;
	}
	if (authentication.token && projects && projects.length === 0) {
		appView = <NoProject _logout={handleNoProjectsLogout} />;
	} else if (authentication.token && projects && currentProject) {
		appView = renderAuthenticatedPage(header);
	} else {
		appView = renderAuthContainer();
	}

	return (
		<>
			{appView}
			<ToastContainer></ToastContainer>
			<LoadingSpinner />
		</>
	);
	
}

/**
 * Verbindung von App und Redux zu ReduxApp
 * @type {Component}
 */
const ReduxApp = connect((state) => state, actions)(App);
/**
 * HOC fuer i18n erzeugen
 */
export default withTranslation()(ReduxApp);
