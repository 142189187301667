// @flow
import React from 'react';
import {ToggleButton , ToggleButtonGroup, Row, Col, ButtonToolbar } from 'react-bootstrap';
import { Trans } from 'react-i18next';

import LayoutManager from '../../../layout/LayoutManager';

type Props = {
    layout: string,
    onChange: (value: string) => void,
    help?: boolean,
    helpText?: string | React$Element<any>
}

/**
 * Rendert eine Auswahl der Layouts
 * @param {string} layout Aktuelles Layout
 * @param {Function} onChange Callback fuer Aenderungen
 * @param {boolean} help Ob die Hilfe angezeigt werden soll
 * @param {string | React$Element<any>}  helpText Text der Hilfe
 */
const LayoutSelection = ({layout, onChange}: Props) => {
    return (
        <div className="settings-form-group">
            <Row>
                <Col className="ml-5 mb-3">
                    <span className="fs-5 "><Trans i18nKey="common.layout">Layout</Trans> </span><br/>
                    <span><Trans i18nKey="settings.helpText.layout">...</Trans></span>
                </Col>
            </Row>
            <Row>
                <Col sm={3}>
                    <ButtonToolbar>
                        <ToggleButtonGroup
                            type="radio"
                            name="layout"
                            value={layout}
                        >
                            {
                                LayoutManager.getOptions().map((item, key) => (
                                    <ToggleButton
                                        value={item.value}
                                        key={key}
                                        type="radio"
                                        name="layout"
                                        onClick={()=>onChange(item.value)}
                                        checked={layout === item.value}
                                    >
                                        {item.image}
                                    </ToggleButton>
                                ))
                            }
                        </ToggleButtonGroup>
                    </ButtonToolbar>
                </Col>
            </Row>
        </div>
    );
}

export default LayoutSelection;