// @flow
import React, {Fragment} from 'react';
import {Container, Row, Col} from 'react-bootstrap';

type Props = {
    navigation: React$Element<any>,
    layout: Object,
    sideView: React$Element<any>,
    mainView: React$Element<any>,
    sideMenu: React$Element<any>
}

/**
 * Komponente fuer die Seite der authentifizierten Benutzer
 * @param {React$Element<any>} navigation Die Navigationsleiste
 * @param {Object} layout Die Einstellungen des Layouts
 * @param {React$Element<any>} sideView Die Seitenansicht
 * @param {React$Element<any>} mainView Die Hauptansicht
 * @param {React$Element<any>} sideMenu Das Seitenmenu
 */
const AuthenticatedPage = ({navigation, layout, sideView, mainView, sideMenu}: Props) => {
    let classNameLeft = "no-padding d-lg-block";

    if(layout.left.xs.size === 0) {
        classNameLeft = classNameLeft.concat(' d-xs-none');
    }
    if(layout.left.sm.size === 0) {
        classNameLeft = classNameLeft.concat(' d-sm-none');
    }
    classNameLeft = (layout.left.md.size === 0) ? classNameLeft.concat(' d-md-none') : classNameLeft.concat(' d-md-block');

    // hide right side on layout popout
    const classRight = (layout.id === 'popout') ? "d-none" : "no-padding";

    return (
        <Fragment>
            {navigation}
            <Container style={{
                margin: 0,
                paddingLeft: 0,
                paddingRight: 0,
                width: '100%',
                maxWidth: 'none'

            }}>
                <Row style={{width: '100'}}>
                    <Col
                        xs={{span: layout.left.xs.size}}
                        sm={{span: layout.left.sm.size}}
                        md={{span: layout.left.md.size}}
                        lg={{span: layout.left.lg.size}}
                        className={classNameLeft}
                    >
                        {sideView}
                    </Col>
                    <Col
                        xs={{span: layout.right.xs.size}}
                        sm={{span: layout.right.sm.size}}
                        md={{span: layout.right.md.size}}
                        lg={{span: layout.right.lg.size}}
                        className={classRight}
                    >
                        {mainView}
                    </Col>
                </Row>
            </Container>
            {sideMenu}
        </Fragment>
    );
};

export default AuthenticatedPage;