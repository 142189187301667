// @flow
import React from 'react';
import {Col, Row} from 'react-bootstrap';
import {Trans} from 'react-i18next';
import {ColumnSize, Title} from '../../misc/flowTypes';
import { isEmpty } from '../../misc/validationHelper';

import '../../css/form.css';

type Props = {
    columnSize: ColumnSize,
    title?: Title,
    label: ColumnSize,
    field: ColumnSize,
    children: ?React$Element<any>,
    fieldCssClass?: string
}

/**
 * Erzeugt ein Feld mit Label und Element
 * @param {ColumnSize} columnSize Die groesse des Feldes
 * @param {Title} title Der Wert des Labels
 * @param {ColumnSize} label Die groesse des Labels
 * @param {ColumnSize} field Die groesse des Elements
 * @param {string} fieldCssClass Die CSS Klasse des Feldes
 * @param {React$Element<any>} children Das Element
 */
const FormField = ({columnSize, title, label, field, fieldCssClass, children}: Props) => (
    <Col
        xs={columnSize.xs}
        sm={columnSize.sm}
        md={columnSize.md}
        lg={columnSize.lg}
    >
        <Row>
            {title && !isEmpty(title.key) && !isEmpty(title.fallback) ?
                /** $FlowFixMe */
                <Col
                    xs={label.xs}
                    sm={label.sm}
                    md={label.md}
                    lg={label.md}
                    className="control-label"
                >
                    <span className="label-wrap"><Trans i18nKey={title.key}>{title.fallback}</Trans></span>
                </Col> : null
            }
            <Col
                xs={field.xs}
                sm={field.sm}
                md={field.md}
                lg={field.lg}
                className={fieldCssClass}
            >
                {children}
            </Col>
        </Row>
    </Col>
);

export default FormField;