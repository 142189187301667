// @flow
import React from 'react';
import { connect } from 'react-redux';
import { NavItem } from 'react-bootstrap';

import { objectOpened } from '../../../actions/viewActions';
import { OBJECT_LEVEL_VIEWS } from '../../../misc/const';
import { getImageSmall } from '../../../misc/utils';

type Props = {
	currentObject: Object,
	objectOpened: (Object, string) => mixed,
};

/**
 * Erzeugt einen Eintrag in der Navigation um auf die Inspektionsseite zu gelangen
 * @param {Object} currentObject Das aktuell gewaehlte Objekt
 * @param {Function} objectOpened Redux Action um das Objekt auszuwaehlen
 */
const InspectionBreadcrumbItem = ({ currentObject, objectOpened }: Props) =>
	currentObject != null && currentObject.hasOwnProperty('inspection') && currentObject.inspection.length !== 0 ? (
		<NavItem key="inspection" onClick={() => objectOpened(currentObject, OBJECT_LEVEL_VIEWS.MAIN.INSPECTION_MAIN_VIEW)}>
			{getImageSmall(currentObject.inspectionType)}
			&nbsp;{currentObject.date}
		</NavItem>
	) : null;

/**
 *  Verbindet die Klasse mit dem Redux Store
 */
const ReduxInspectionBreadcrumbItem = connect(
	(state) => {
		return {
			currentObject: state.currentObject,
		};
	},
	{
		objectOpened,
	}
)(InspectionBreadcrumbItem);

export default ReduxInspectionBreadcrumbItem;
