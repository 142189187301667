// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Nav } from 'react-bootstrap';
import Badge from 'react-bootstrap/Badge';
import { getImage } from '../misc/utils';
import { showSideView } from '../actions/viewActions';
import { switchProject, _updateProjectsList } from '../actions/dataActions';
import { translateString } from '../i18n/utils';
import IconButton from '../components/common/IconButton';
import { BsArrowRepeat } from "react-icons/bs";


type Props = {
	switchProject: (Object) => mixed,
	showSideView: (boolean) => mixed,
    _updateProjectsList:() => mixed,
	currentProject: Object,
    projects: Array<Object>,
};

/**
 * React Komponente, die eine Liste mit Projekten fuer die Seitenansicht rendert
 * @type {Component}
 * @constructor
 */
class ProjectSideView extends Component<Props, State> {

	/**
	 * Haendelt den wechsel einens Projekts und triggert die event SWITCH_PROJECT und SHOW_SIDE_VIEW
	 * @param  {object} project Projekt zu dem gewechselt wird
	 * @return {void}
	 */
	handleProjectSwitch = (project: Object): void => {
		const { switchProject, showSideView } = this.props;
		switchProject(project);
		showSideView(false);
	};


	/**
	 * Erstellt ein Navigation fuer die Projekte
	 * @param {Array<Object>} projects Liste von Projekten
	 * @return {JSX} JSX Markup
	 */
	renderProjects(projects: Array<Object>): ?React$Element<any> {
		if (projects === null) {
            return null;
        }

        const { currentProject, _updateProjectsList } = this.props;

        let navItems: Array<React$Element<any>> = [];
        let activeProject: number = 0;

        navItems = projects.map((project: Object, projectIndex: number) => {
            let active = '';
            if (project._id === currentProject._id) {
                activeProject = project._id;
                active = 'active ';
            }

            const badge = (project.newItem) ? <Badge pill bg="primary" className="ms-1">{translateString('common.new')}</Badge>: null;

            return (
                <Nav.Item key={projectIndex}>
                    <Nav.Link className={active} href="#" key={project._id} onClick={() => this.handleProjectSwitch(project)}>
                        {getImage('folder')}&nbsp;
                        {project.name}
                        {badge}
                    </Nav.Link>
                </Nav.Item>
            );
        });

        return (
            <>
                <div className='d-flex justify-content-between' style={{marginBottom: 12}}>
                    <div className='d-flex align-items-center'>{translateString('common.projectlist')}</div>

                    <IconButton
                        text={translateString('common.updateProjectList')}
                        icon=<BsArrowRepeat className='icon-button' />
                        onClick={_updateProjectsList}
                    />
                </div>

                <Nav id="projectSelection" activeKey={activeProject}>
                    {navItems}
                </Nav>
            </>
        );
	}

	/**
	 * Rendert eine Liste mit Projekten fuer die Seitenansicht
	 * @return {JSX} JSX Markup
	 */
	render() {
		const { projects } = this.props;

		return this.renderProjects(projects);
	}
}

/**
 *  Verbindet die Klasse mit dem Redux Store
 */
const ReduxProjectSideView = connect(
	(state) => {
		return {
			currentProject: state.currentProject,
			projects: state.projects,
		};
	},
	{
		switchProject,
		showSideView,
        _updateProjectsList
	}
)(ProjectSideView);
export default ReduxProjectSideView;
