// @flow
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card, Container } from 'react-bootstrap';

import Carousel from './common/ImageCarousel/Carousel';

import TouchHandler from './common/TouchHandler';

type Props = {
	stationSelected: (Object) => mixed,
	currentStation: Object,
	currentObject: Object,
};

type StationIndexType = {
	index: number,
	current: Object,
};

/**
 * React Komponente, die Stationsbilder in einem Carousel anzeigt
 */
export default class StationCarousel extends Component<Props> {
	constructor(props: Object) {
		super(props);

		this.state = {
			currentStation: this.getStationWithIndex(props.currentObject.stations, props.currentStation),
		};
	}

	/**
	 * Liefert die aktuelle ausgewaehlte Station mit dessen Index zurueck
	 *
	 * @param {Array} stations Alle Stationen des Objekts
	 * @param {Object} station Die aktuelle Station falls vorhanden
	 * @return {Object} Der Index und die ausgewaehlte Station
	 */
	getStationWithIndex(stations: Array<Object>, station: Object): StationIndexType {
		let index: number = 0;
		let current: Object = stations[0];

		if (station != null) {
			current = station;

			stations.forEach((s: Object, sIndex: number) => {
				if (s._id === current._id) {
					index = sIndex;
				}
			});
		}

		return index;
	}

	/**
	 * Callback fuer swipe events. Setzt die gewaehlte Station
	 *
	 * @param {Object} event Das ausgeloeste Event
	 * @param {Object} target Die Station die gesetzt werden soll
	 *
	 * @memberof StationCarousel
	 */
	onSwipe = (event: Object, target: Object): void => {
		if (event.pointerType === 'touch' || event.srcEvent.pointerType === 'touch') {
			const { stationSelected } = this.props;
			stationSelected(target);
		}
		// Stoppen damit sich auf Tablets nicht das Seitenmenue oeffnet
		event.srcEvent.stopPropagation();
	};

	/**
	 * Rendert den View fuer das Karussell
	 * @return {JSX} JSX Markup
	 */
	render() {
		const { currentObject } = this.props;

		if (currentObject.hasOwnProperty('stations') && currentObject.stations.length > 0) {
			const stations: Array<Object> = currentObject.stations;

			return (
				<TouchHandler
					onSwipeRight={(e) => this.onSwipe(e, stations[this.state.currentStation - 1])}
					onSwipeLeft={(e) => this.onSwipe(e, stations[this.state.currentStation + 1])}
				>
					<span>
						<Card className="no-border-shadow padding-less">
							<Card.Body >
								<Container fluid>
									<Carousel stations={stations} index={this.state.currentStation} />
								</Container>
							</Card.Body>
						</Card>
					</span>
				</TouchHandler>
			);
		} else {
			return null;
		}
	}
}

StationCarousel.propTypes = {
	stationSelected: PropTypes.func,
	currentObject: PropTypes.object,
	currentStation: PropTypes.object,
};
