// @flow
import cellRenderer from '../builder/CellRenderer';
import {OBJECTLIST_RENDERER_MODE} from '../misc/const';
import ObjectListColumnHelper from './helper/ObjectListColumnHelper';
import ObjectListTabHelper from './helper/ObjectListTabHelper';
import Station from './helper/Station';
import StationView from './helper/StationView';
import FormColumnHelper from '../form/helper/FormColumnHelper';

const name_array = [
    'Objektbezeichnung',
    'Schachtbezeichner',
    'DWA-M150_H_HG001',
    'DWA-M150_L_HG011',
    'DWA-M150_S_KG001'
]

export const general = {
    projectName: "DefaultNameProject",

    objectlist: new ObjectListTabHelper({
        id: 'default',
        title: {
            key: 'common.general',
            fallback: 'Allgemein'
        },
        columns: [
            new ObjectListColumnHelper({
                id: 'default.Typ',
                types: {
                    section: true,
                    lateral: true,
                    manhole: true
                },
                show: true,
                accessor: 'type',
                headerFunc: () => ( cellRenderer.render('imgRenderer', {type: 'icon1'})),
                columnType: OBJECTLIST_RENDERER_MODE.IMG
            }),
            new ObjectListColumnHelper({
                id: 'default.Name',
                title: {
                    key: 'objectlist.name',
                    fallback: 'Name'
                },
                types: {
                    section: true,
                    lateral: true,
                    manhole: true
                },
                show: true,
                accessor: (d: Object) => {
                    let result = '';
    
                    name_array.forEach((name) => {
                        if (d.hasOwnProperty(name)) {
                            result = d[name];
                        }
                    });
    
                    return result;
                }
            }),    
            new ObjectListColumnHelper({
                id: 'default.Datum',
                title: {
                    key: 'objectlist.date',
                    fallback: 'Datum'
                },
                types: {
                    section: true,
                    lateral: true,
                    manhole: true
                },
                show: true,
                accessor: 'date'
            }),    
            new ObjectListColumnHelper({
                id: 'default.InspektionsArt',            
                types: {
                    section: true,
                    lateral: true,
                    manhole: true
                },
                show: true,
                accessor: 'inspectionType',
                headerFunc: () => ( cellRenderer.render('imgRenderer', {type: 'icon2'})),
                columnType: OBJECTLIST_RENDERER_MODE.IMG
            }),
            new ObjectListColumnHelper({
                id: 'default.Analyse',            
                types: {
                    section: true,
                    lateral: true,
                    manhole: true
                },
                show: true,
                accessor: 'Analyse',
                headerFunc: () => ( cellRenderer.render('imgRenderer', {type: 'measurement'})),
                columnType: OBJECTLIST_RENDERER_MODE.IMG
            }) 
        ]
    }),    

    station: new Station({
        section: new StationView({
            STATION_VIEW_NO_PHOTO: [
                new FormColumnHelper({
                    id: 'general.g',
                    title: {
                        key: 'station.g',
                        fallback: 'G'
                    },
                    accessor: 'G',
                    cellFunc: (row: Object) => (cellRenderer.render('tableArrowGegenUntersuchungRenderer',{row})),
                }),
                new FormColumnHelper({
                    id: 'general.foto',
                    title: {
                        key: 'station.photo',
                        fallback: 'Foto'
                    },
                    accessor: 'Foto'
                }),
                new FormColumnHelper({
                    id: 'general.video',
                    title: {
                        key: 'station.video',
                        fallback: 'Video'
                    },
                    accessor: 'Video'
                }),
                new FormColumnHelper({
                    id: 'general.entfernung',
                    title: {
                        key: 'station.distance',
                        fallback: 'Entf.'
                    },
                    accessor: 'Entfernung'
                }),
                new FormColumnHelper({
                    id: 'general.code',
                    title: {
                        key: 'station.code',
                        fallback: 'Code'
                    },
                    accessor: 'Code'
                }),
                new FormColumnHelper({
                    id: 'general.q1',
                    title: {
                        key: 'station.q1',
                        fallback: 'Q1'
                    },
                    accessor: 'Q1'
                }),
                new FormColumnHelper({
                    id: 'general.q2',
                    title: {
                        key: 'station.q2',
                        fallback: 'Q2'
                    },
                    accessor: 'Q2'
                }),
                new FormColumnHelper({
                    id: 'general.qzs',
                    title: {
                        key: 'station.qzs',
                        fallback: 'QZS'
                    },
                    accessor: 'QZS'
                }),
                new FormColumnHelper({
                    id: 'general.position',
                    title: {
                        key: 'station.position',
                        fallback: 'Pos.'
                    },
                    accessor: 'Position'
                }),
                new FormColumnHelper({
                    id: 'general.v',
                    title: {
                        key: 'station.c',
                        fallback: 'V'
                    },
                    accessor: 'V'
                }),
                new FormColumnHelper({
                    id: 'general.s',
                    title: {
                        key: 'station.s',
                        fallback: 'S'
                    },
                    accessor: 'S'
                }),
                new FormColumnHelper({
                    id: 'general.anmerkung',
                    title: {
                        key: 'station.remark',
                        fallback: 'Anmerkung'
                    },
                    accessor: 'Bemerkung'
                })
            ],
            STATION_VIEW_PHOTO: [
                new FormColumnHelper({
                    id: 'general.g',
                    title: {
                        key: 'station.g',
                        fallback: 'G'
                    },
                    accessor: 'G',
                    cellFunc: (row: Object) => (cellRenderer.render('tableArrowGegenUntersuchungRenderer',{row})),
                }),
                new FormColumnHelper({
                    id: 'general.foto',
                    title: {
                        key: 'station.photo',
                        fallback: 'Foto'
                    },
                    accessor: 'Foto',
                    cellFunc: (row: Object) => ( cellRenderer.render('stationImgRenderer', {row}))
                }),
                new FormColumnHelper({
                    id: 'general.video',
                    title: {
                        key: 'station.video',
                        fallback: 'Video'
                    },
                    accessor: 'Video'
                }),
                new FormColumnHelper({
                    id: 'general.entfernung',
                    title: {
                        key: 'station.distance',
                        fallback: 'Entf.'
                    },
                    accessor: 'Entfernung'
                }),
                new FormColumnHelper({
                    id: 'general.code',
                    title: {
                        key: 'station.code',
                        fallback: 'Code'
                    },
                    accessor: 'Code'
                }),
                new FormColumnHelper({
                    id: 'general.q1',
                    title: {
                        key: 'station.q1',
                        fallback: 'Q1'
                    },
                    accessor: 'Q1'
                }),
                new FormColumnHelper({
                    id: 'general.q2',
                    title: {
                        key: 'station.q2',
                        fallback: 'Q2'
                    },
                    accessor: 'Q2'
                }),
                new FormColumnHelper({
                    id: 'general.qzs',
                    title: {
                        key: 'station.qzs',
                        fallback: 'QZS'
                    },
                    accessor: 'QZS'
                }),
                new FormColumnHelper({
                    id: 'general.position',
                    title: {
                        key: 'station.position',
                        fallback: 'Pos.'
                    },
                    accessor: 'Position'
                }),
                new FormColumnHelper({
                    id: 'general.v',
                    title: {
                        key: 'station.c',
                        fallback: 'V'
                    },
                    accessor: 'V'
                }),
                new FormColumnHelper({
                    id: 'general.s',
                    title: {
                        key: 'station.s',
                        fallback: 'S'
                    },
                    accessor: 'S'
                }),
                new FormColumnHelper({
                    id: 'general.anmerkung',
                    title: {
                        key: 'station.remark',
                        fallback: 'Anmerkung'
                    },
                    accessor: 'Bemerkung'
                })
            ]
        }),
        lateral: new StationView({
            STATION_VIEW_NO_PHOTO: [
                new FormColumnHelper({
                    id: 'general.g',
                    title: {
                        key: 'station.g',
                        fallback: 'G'
                    },
                    accessor: 'G',
                    cellFunc: (row: Object) => (cellRenderer.render('tableArrowGegenUntersuchungRenderer',{row})),                
                }),
                new FormColumnHelper({
                    id: 'general.foto',
                    title: {
                        key: 'station.photo',
                        fallback: 'Foto'
                    },
                    accessor: 'Foto'
                }),
                new FormColumnHelper({
                    id: 'general.video',
                    title: {
                        key: 'station.video',
                        fallback: 'Video'
                    },
                    accessor: 'Video'
                }),
                new FormColumnHelper({
                    id: 'general.entfernung',
                    title: {
                        key: 'station.distance',
                        fallback: 'Entf.'
                    },
                    accessor: 'Entfernung'
                }),
                new FormColumnHelper({
                    id: 'general.code',
                    title: {
                        key: 'station.code',
                        fallback: 'Code'
                    },
                    accessor: 'Code'
                }),
                new FormColumnHelper({
                    id: 'general.q1',
                    title: {
                        key: 'station.q1',
                        fallback: 'Q1'
                    },
                    accessor: 'Q1'
                }),
                new FormColumnHelper({
                    id: 'general.q2',
                    title: {
                        key: 'station.q2',
                        fallback: 'Q2'
                    },
                    accessor: 'Q2'
                }),
                new FormColumnHelper({
                    id: 'general.qzs',
                    title: {
                        key: 'station.qzs',
                        fallback: 'QZS'
                    },
                    accessor: 'QZS'
                }),
                new FormColumnHelper({
                    id: 'general.position',
                    title: {
                        key: 'station.position',
                        fallback: 'Pos.'
                    },
                    accessor: 'Position'
                }),
                new FormColumnHelper({
                    id: 'general.v',
                    title: {
                        key: 'station.c',
                        fallback: 'V'
                    },
                    accessor: 'V'
                }),
                new FormColumnHelper({
                    id: 'general.s',
                    title: {
                        key: 'station.s',
                        fallback: 'S'
                    },
                    accessor: 'S'
                }),
                new FormColumnHelper({
                    id: 'general.anmerkung',
                    title: {
                        key: 'station.remark',
                        fallback: 'Anmerkung'
                    },
                    accessor: 'Anmerkung'
                })
            ],
            STATION_VIEW_PHOTO: [
                new FormColumnHelper({
                    id: 'general.g',
                    title: {
                        key: 'station.g',
                        fallback: 'G'
                    },
                    accessor: 'G',
                    cellFunc: (row: Object) => (cellRenderer.render('tableArrowGegenUntersuchungRenderer',{row})),
                }),
                new FormColumnHelper({
                    id: 'general.foto',
                    title: {
                        key: 'station.photo',
                        fallback: 'Foto'
                    },
                    accessor: 'Foto',
                    cellFunc: (row: Object) => ( cellRenderer.render('stationImgRenderer', {row}))
                }),
                new FormColumnHelper({
                    id: 'general.video',
                    title: {
                        key: 'station.video',
                        fallback: 'Video'
                    },
                    accessor: 'Video'
                }),
                new FormColumnHelper({
                    id: 'general.entfernung',
                    title: {
                        key: 'station.distance',
                        fallback: 'Entf.'
                    },
                    accessor: 'Entfernung'
                }),
                new FormColumnHelper({
                    id: 'general.code',
                    title: {
                        key: 'station.code',
                        fallback: 'Code'
                    },
                    accessor: 'Code'
                }),
                new FormColumnHelper({
                    id: 'general.q1',
                    title: {
                        key: 'station.q1',
                        fallback: 'Q1'
                    },
                    accessor: 'Q1'
                }),
                new FormColumnHelper({
                    id: 'general.q2',
                    title: {
                        key: 'station.q2',
                        fallback: 'Q2'
                    },
                    accessor: 'Q2'
                }),
                new FormColumnHelper({
                    id: 'general.qzs',
                    title: {
                        key: 'station.qzs',
                        fallback: 'QZS'
                    },
                    accessor: 'QZS'
                }),
                new FormColumnHelper({
                    id: 'general.position',
                    title: {
                        key: 'station.position',
                        fallback: 'Pos.'
                    },
                    accessor: 'Position'
                }),
                new FormColumnHelper({
                    id: 'general.v',
                    title: {
                        key: 'station.c',
                        fallback: 'V'
                    },
                    accessor: 'V'
                }),
                new FormColumnHelper({
                    id: 'general.s',
                    title: {
                        key: 'station.s',
                        fallback: 'S'
                    },
                    accessor: 'S'
                }),
                new FormColumnHelper({
                    id: 'general.anmerkung',
                    title: {
                        key: 'station.remark',
                        fallback: 'Anmerkung'
                    },
                    accessor: 'Anmerkung'
                })
            ]        
        }),
        manhole: new StationView({
            STATION_VIEW_NO_PHOTO: [                
                new FormColumnHelper({
                    id: 'general.foto',
                    title: {
                        key: 'station.photo',
                        fallback: 'Foto'
                    },
                    accessor: 'Foto'
                }),
                new FormColumnHelper({
                    id: 'general.video',
                    title: {
                        key: 'station.video',
                        fallback: 'Video'
                    },
                    accessor: 'Video'
                }),
                new FormColumnHelper({
                    id: 'general.tiefe',
                    title: {
                        key: 'station.depth',
                        fallback: 'Tiefe'
                    },
                    accessor: 'Tiefe'
                }),
                new FormColumnHelper({
                    id: 'general.code',
                    title: {
                        key: 'station.code',
                        fallback: 'Code'
                    },
                    accessor: 'Code'
                }),
                new FormColumnHelper({
                    id: 'general.q1',
                    title: {
                        key: 'station.q1',
                        fallback: 'Q1'
                    },
                    accessor: 'Q1'
                }),
                new FormColumnHelper({
                    id: 'general.q2',
                    title: {
                        key: 'station.q2',
                        fallback: 'Q2'
                    },
                    accessor: 'Q2'
                }),
                new FormColumnHelper({
                    id: 'general.qzs',
                    title: {
                        key: 'station.qzs',
                        fallback: 'QZS'
                    },
                    accessor: 'QZS'
                }),
                new FormColumnHelper({
                    id: 'general.position',
                    title: {
                        key: 'station.position',
                        fallback: 'Pos.'
                    },
                    accessor: 'Position'
                }),
                new FormColumnHelper({
                    id: 'general.b',
                    title: {
                        key: 'station.b',
                        fallback: 'B'
                    },
                    accessor: 'B'
                }),
                new FormColumnHelper({
                    id: 'general.v',
                    title: {
                        key: 'station.c',
                        fallback: 'V'
                    },
                    accessor: 'V'
                }),
                new FormColumnHelper({
                    id: 'general.s',
                    title: {
                        key: 'station.s',
                        fallback: 'S'
                    },
                    accessor: 'S'
                }),
                new FormColumnHelper({
                    id: 'general.anmerkung',
                    title: {
                        key: 'station.remark',
                        fallback: 'Anmerkung'
                    },
                    accessor: 'Anmerkung'
                })
            ],
            STATION_VIEW_PHOTO: [                
                new FormColumnHelper({
                    id: 'general.foto',
                    title: {
                        key: 'station.photo',
                        fallback: 'Foto'
                    },
                    accessor: 'Foto',
                    cellFunc: (row: Object) => ( cellRenderer.render('stationImgRenderer', {row}))
                }),
                new FormColumnHelper({
                    id: 'general.video',
                    title: {
                        key: 'station.video',
                        fallback: 'Video'
                    },
                    accessor: 'Video'
                }),
                new FormColumnHelper({
                    id: 'general.tiefe',
                    title: {
                        key: 'station.depth',
                        fallback: 'Tiefe'
                    },
                    accessor: 'Tiefe'
                }),
                new FormColumnHelper({
                    id: 'general.code',
                    title: {
                        key: 'station.code',
                        fallback: 'Code'
                    },
                    accessor: 'Code'
                }),
                new FormColumnHelper({
                    id: 'general.q1',
                    title: {
                        key: 'station.q1',
                        fallback: 'Q1'
                    },
                    accessor: 'Q1'
                }),
                new FormColumnHelper({
                    id: 'general.q2',
                    title: {
                        key: 'station.q2',
                        fallback: 'Q2'
                    },
                    accessor: 'Q2'
                }),
                new FormColumnHelper({
                    id: 'general.qzs',
                    title: {
                        key: 'station.qzs',
                        fallback: 'QZS'
                    },
                    accessor: 'QZS'
                }),
                new FormColumnHelper({
                    id: 'general.position',
                    title: {
                        key: 'station.position',
                        fallback: 'Pos.'
                    },
                    accessor: 'Position'
                }),
                new FormColumnHelper({
                    id: 'general.b',
                    title: {
                        key: 'station.b',
                        fallback: 'B'
                    },
                    accessor: 'B'
                }),
                new FormColumnHelper({
                    id: 'general.v',
                    title: {
                        key: 'station.c',
                        fallback: 'V'
                    },
                    accessor: 'V'
                }),
                new FormColumnHelper({
                    id: 'general.s',
                    title: {
                        key: 'station.s',
                        fallback: 'S'
                    },
                    accessor: 'S'
                }),
                new FormColumnHelper({
                    id: 'general.anmerkung',
                    title: {
                        key: 'station.remark',
                        fallback: 'Anmerkung'
                    },
                    accessor: 'Anmerkung'
                })
            ],
            
        })
    }),

    inspectionMain: {
        key: "common.mainInspection",
        value: "Hauptuntersuchung"
    },
    inspectionSecondary: {
        key: "common.counterInspection",
        value: "Gegenuntersuchung"
    },

    stationname: "Code",
    stationphoto: "Foto",
    stationvideo: "Video",
    stationdistance: "Entfernung",
}

export default general;