// @flow
import React from 'react';
import {SortableContainer} from 'react-sortable-hoc';
import ObjectListSortableItem from './ObjectListSortableItem';
import ObjectListTabHelper from '../../templates/helper/ObjectListTabHelper';

type SortableListType = {
    items: Array<Object>,
    objectlist: Array<ObjectListTabHelper>
}

/**
 * HOC fuer SortableContainer
 * Erstellt eine sortierbare Liste
 * @param {array} items Array mit den Items der Liste
 * @param {array} objectlist Array mit den Spalten der aktuellen Schnittstelle
 * @type {SortableContainer}
 */
const ObjectListSortableList = SortableContainer(({items, objectlist}: SortableListType) => {
    return (
        <ul className="list-group">
            {items.map((value: Object, index: number) => {
                let col: ?Object = null;
                objectlist.forEach((list: Object) => {
                    list.columns.forEach((l: Object) => {
                        if (l.id === value.id) {
                            col = l;
                        }
                    })
                });
                return  <ObjectListSortableItem 
                            key={`item-${index}`} 
                            index={index} 
                            value={col}
                            mode={value.mode} 
                        />;
            })}
        </ul>
    );
});

export default ObjectListSortableList
