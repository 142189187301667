// @flow
import React from 'react';

import ProjectBreadcrumbItem from './navigation/breadcrumb/ProjectBreadcrumbItem';
import ObjectBreadcrumbItem from './navigation/breadcrumb/ObjectBreadcrumbItem';
import InspectionBreadcrumbItem from './navigation/breadcrumb/InspectionBreadcrumbItem';
import StationBreadcrumbItem from './navigation/breadcrumb/StationBreadcrumbItem';

/**
 * Klasse fuer die Darstellung einen Breadcrumbs in der Navigation
 *
 * @class ProjectBreadcrumb
 * @extends {Component<Props>}
 */
const ProjectBreadcrumb = () => (
    <>
        <ProjectBreadcrumbItem />
        <ObjectBreadcrumbItem />
        <InspectionBreadcrumbItem />
        <StationBreadcrumbItem />
    </>
);

export default ProjectBreadcrumb;