import IconButton from '../../common/IconButton';
import {translateString} from '../../../i18n/utils';
import { ButtonGroup } from 'react-bootstrap';

import { FaVectorSquare, FaCloud, FaCrosshairs } from "react-icons/fa6";
import { AiOutlineCamera, AiOutlineCloudDownload } from 'react-icons/ai';
import { BsWindow, BsWindowStack, BsWindowSplit } from "react-icons/bs";

export default function PanoButtonGroup({
    initialized,
    toggleCrossHair,
    toggleWrap,
    showWrapPopout,
    showPanoPopout,
    togglePointCloud,
    hasPointCloud,
    downloadScreenshot,
    downloadPanoramoFile,
    isThisPopout,
}) {
    return (
        <>
            <ButtonGroup>
                <IconButton
                    text={translateString('panoramo.showCrossHair')}
                    disabled={!initialized}
                    onClick={toggleCrossHair}
                    icon=<FaCrosshairs className='icon-button' />
                />
            </ButtonGroup>
            <ButtonGroup>
                <IconButton
                    text={translateString('panoramo.showWrap')}
                    disabled={!initialized}
                    onClick={toggleWrap}
                    icon=<BsWindowSplit className='icon-button' />
                />
                <IconButton
                    text={translateString('panoramo.openWrapPopout')}
                    disabled={!initialized}
                    onClick={showWrapPopout}
                    icon=<BsWindow className='icon-button' />
                />
                {!isThisPopout && <IconButton
                    text={translateString('panoramo.openPanoPopout')}
                    disabled={!initialized}
                    onClick={showPanoPopout}
                    icon=<BsWindowStack className='icon-button' />
                />}
                {false && <IconButton
                    text={translateString('panoramo.show3DObjects')}
                    disabled={true}
                    onClick={()=>alert('Anzeige der 3D Objecte noch nicht implementiert')}
                    icon=<FaVectorSquare className='icon-button' />
                />}
            </ButtonGroup>
            {hasPointCloud && <ButtonGroup>
                <IconButton
                    text={translateString('panoramo.showPointCloud')}
                    disabled={!initialized && !hasPointCloud}
                    onClick={togglePointCloud}
                    icon=<FaCloud className='icon-button' />
                />
            </ButtonGroup>}
            <ButtonGroup>
                <IconButton
                    text={translateString('panoramo.downloadScreenshot')}
                    disabled={!initialized}
                    onClick={downloadScreenshot}
                    icon=<AiOutlineCamera className='icon-button' />
                />
                <IconButton
                    text={translateString('panoramo.downloadPanoramoFile')}
                    disabled={!initialized}
                    onClick={downloadPanoramoFile}
                    icon=<AiOutlineCloudDownload className='icon-button' />
                />
            </ButtonGroup>
        </>
    );
}