// @flow
import React, {Component, Fragment} from 'react';
// $FlowFixMe
import {Button, Modal, Container, Col, Tab, Tabs, Row, Card} from 'react-bootstrap';
import cloneDeep from 'lodash.clonedeep';
import {arrayMove} from 'react-sortable-hoc';
import {Trans} from 'react-i18next';

import general from '../../templates/General';
import ObjectListSortableList from './ObjectListSortableList';
import {renderTransTitle, getTransObject} from '../../misc/utils';
import ObjectListTabHelper from '../../templates/helper/ObjectListTabHelper';
import ObjectListSettingObject from '../../templates/helper/ObjectListSettingObject';

import '../../css/objectlist.css';
import CanalInterface from '../../templates/helper/CanalInterface';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWrench, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';

type Props = {
    update: (Array<Object>) => mixed,
    settings: Object,
    currentProject: Object,
    currentInterface: CanalInterface,
}

type State = {
    show: boolean,
    settingCols: Array<ObjectListSettingObject>,
    selection: Array<Object>,
    tabs: Array<ObjectListTabHelper>
}

type SortType = {
    oldIndex: number,
    newIndex: number
}

/**
 * Klasse, die eine Spaltenauswahl fuer die Objektliste erstellt
 * @type {Object}
 * @constructor
 */
export default class ObjectListSetting extends Component<Props, State> {
    /**
     * Konstruiert das Objekt und setzt den Anfangsstatus
     * @param  {object} props Properties
     */
    constructor(props: Object) {
        super(props);
        this.state = {
            show: false,
            settingCols: [],
            selection: [],
            tabs: []
        }
    }

    /**
     * React Lifecycle Methode, die gerufen wird wenn Properties aktualisiert werden
     */
    componentDidUpdate(prevProps: Object, prevState: Object):void {
        const {currentProject} = this.props;

        if (prevProps.currentProject.interface !== currentProject.interface) {
            this.updateColumnState();
        }
    }

    /**
     * Aktualisiert die ausgewaehlten Spalten
     * @return {void}
     */
    updateColumnState():void {
        const {settings, currentProject, currentInterface} = this.props;

        const _interfaceSettings: Array<Object> = settings.objectlist[currentProject.interface];
        const settingCols: Array<ObjectListSettingObject> = cloneDeep(_interfaceSettings);

        const tabs: Array<ObjectListTabHelper> = [cloneDeep(general.objectlist), ...cloneDeep(currentInterface.objectlist)];

        const selection: Array<Object> = [];
        settingCols.forEach(col => {
            if (col.show) {
                selection.push(col);
            }
        });

        this.setState({
            settingCols,
            tabs,
            selection
        });
    }

    /**
     * Oeffnet das Fenster
     * @return {void}
     */
    open = ():void => {
        this.updateColumnState();
        this.setState({ show: true });
    }

    /**
     * Schliesst das Fenster
     * @return {void}
     */
    close = ():void => {
        this.setState({ show: false });
    }

    /**
     * Schliesst das Fenster und speichert die Aenderungen
     * @return {void}
     */
    save = ():void => {
        this.translateToSettings();
        this.close();
    }

    /**
     * Transformiert die gewaehlten Spalten in Benutzereinstellungen und gibt sie an die Objektliste
     * @return {void}
     */
    translateToSettings():void {
        const {selection, settingCols} = this.state;
        const {update} = this.props;
        const list: Array<ObjectListSettingObject> = [];
        //Selektierte Spalten hinzufuehren
        selection.forEach(select => {
            list.push(new ObjectListSettingObject(select));
        });

        //Alle anderen Spalten hinzufuegen
        settingCols.forEach(col => {
            let contains: boolean = false;
            list.forEach(entry => {
                if (entry.id === col.id && entry.mode === col.mode) {
                    contains = true;
                }
            });

            if (!contains) {
                list.push(new ObjectListSettingObject(col));
            }
        });
        update(list);
    }

    /**
     * Aktualisiert die Auswahl
     * @param {Object} col Spalte, die veraendert wurde
     * @param {string} mode Modus der Spalte
     * @memberof ObjectListSetting
     * @return {void}
     */
    updateSelection(col: Object, mode: string) {
        const {selection} = this.state;
        const _selection = cloneDeep(selection);
        let contains: boolean = false;
        let cIndex: number = 0;
        _selection.forEach((select, index) => {
            if (col.id === select.id && select.mode === mode) {
                contains = true;
                cIndex = index;
            }
        });

        if (col[mode] && !contains) {
            _selection.push({
                id: col.id,
                show: col[mode],
                mode
            });
        }
        else if (!col[mode] && contains) {
            _selection.splice(cIndex, 1);
        }

        this.setState({selection : _selection});
    }

    /**
     * Setzt den uebergebenen Wert auf das Gegenteil der aktuellen Selektierung.
     * @param {object} col Spalte, die geaendert werden soll
     * @param {string} mode Modus der Spalte
     */
    setSelected = (col: Object, mode: string):void => {
        col[mode] = !col[mode];
        this.updateSelection(col, mode);

        const {settingCols} = this.state;
        settingCols.forEach(c => {
            if (c.id === col.id && c.mode === mode) {
                c.show = col[mode];
            }
        });

        this.setState({settingCols});
    }

    /**
     * Funktion, die als Callback fuer die Sortierung verwendet wird. Sortiert die Auswahl neu
     * @param  {number} oldIndex Alter Index des Items
     * @param  {number} newIndex Neuer Index des Items
     * @return {void}
     */
    onSortEnd = ({oldIndex, newIndex} : SortType):void => {
        const {selection} = this.state;
        this.setState({
            selection: arrayMove(selection, oldIndex, newIndex),
        });
    };


    /**
     * Rendert den TabView
     * @return {JSX} Der TabView als JSX
     */
    renderTabView():React$Element<any> {
        const {tabs, settingCols} = this.state;
        return (
            <div>
                <Tabs defaultActiveKey={0} id="oblist-tabs">
                    {tabs.map((tab: Object, index: number) => {
                        return tab.build(index, this.setSelected, settingCols);
                    })}

                    <Tab
                        eventKey={1001}
                        key={1001}
                        title={renderTransTitle("K", "common.K")}
                        disabled
                        tabClassName="active settings-fake-tab settings-fake-tab-offset highlight-settings-type"
                    ></Tab>

                    <Tab
                        eventKey={1002}
                        key={1002}
                        title={renderTransTitle("T", "common.T")}
                        disabled
                        tabClassName="active settings-fake-tab highlight-settings-type"
                    >

                    </Tab>
                </Tabs>
                <span><b>T</b>: {getTransObject('common.T_Tooltipp', 'T Langtext', 'span')}, <b>K</b>: {getTransObject('common.K_Tooltipp', 'K Langtext', 'span')} </span>
            </div>
        );
    }

    /**
     * Rendert die Auswahl
     * @return {JSX} Die sortierbare Liste als JSX
     */
    renderSelection():React$Element<any> {
        const {selection, tabs} = this.state;
        return <ObjectListSortableList
                    items={selection}
                    objectlist={tabs}
                    onSortEnd={this.onSortEnd}
                />;
    }

    /**
     * Rendert das Button Card
     * @return {JSX} Das ButtonCard als JSX
     */
    renderButtonCard():React$Element<any> {
        const {selection} = this.state;

        return (
            <Card className="buttonCard">
                <Card.Body >
                    <Button onClick={this.save} disabled={selection.length === 0}>
                        <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>&nbsp;
                        <Trans i18nKey="common.save" parent="span">Speichern</Trans>
                    </Button>
                    <Button onClick={this.close}>
                        <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>&nbsp;
                        <Trans i18nKey="common.abort" parent="span">Abbrechen</Trans>
                    </Button>
                </Card.Body>
            </Card>
        );
    }

    /**
     * Rendert das Modal Fenster
     * @return {JSX} Das Modal Fenster als JSX
     */
    renderModal():React$Element<any> {
        const {show} = this.state;

        return (
            <Modal
                show={show}
                onHide={this.close}
                size="xl"
                dialogClassName="modal-90w"
                id="settingsmodal"
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <Trans i18nKey="objectlistselector.selection">Spaltenauswahl</Trans>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container fluid>
                        <Row>
                            {this.renderButtonCard()}
                        </Row>
                        <Row>
                            <Col
                                md={7}
                                lg={6}
                                className="settings-row"
                            >
                                {this.renderTabView()}
                            </Col>
                            <Col
                                md={5}
                                sm={12}
                                lg={6}
                            >
                                <Tabs defaultActiveKey={100} id="odlist-sort">
                                    <Tab
                                        eventKey={100}
                                        key={100}
                                        title={renderTransTitle("Auswahl/Sortieren", "objectlistselector.selectsort")}
                                    >
                                        {this.renderSelection()}
                                    </Tab>
                                </Tabs>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>
        );
    }

    /**
     * Rendert die Einstellungsansicht in einen Button
     * @return {JSX} Die Ansicht als JSX
     */
    render () {
        const {show} = this.state;

        return (
            <Fragment>
                <Button className="buttonObjectListSetting" onClick={this.open}>
                    <FontAwesomeIcon icon={faWrench}></FontAwesomeIcon>
                </Button>
                {show ? this.renderModal() : null}
            </Fragment>
        );
    }
}