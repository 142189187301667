// @flow
import React from 'react';
import {Tab, Row} from 'react-bootstrap';
import {Trans} from 'react-i18next';
import FormHelper from "./FormHelper";
import ValidationArray from './ValidationArray';
import type {ValueElement} from './FormHelper';
import FieldHelper from "./FieldHelper";
import {Title} from '../../misc/flowTypes';
import { isEmpty } from '../../misc/validationHelper';

type TabOptions = {
    id: string,
    title: Title,
    items?: Array<FieldHelper>,
    eventKey: string
}

type TabDefaults = {
    id: string,
    title: Title,
    items: Array<FieldHelper>,
    eventKey: string
}

const def: TabDefaults = {
    id: '',
    title: {
        key: '',
        fallback: ''
    },
    items: [],
    eventKey: ''
}

/**
 * Hilfklasse fuer Formtemplates, um einen Tab zu erzeugen
 *
 * @export
 * @class TabHelper
 * @extends {FormHelper}
 */
export default class TabHelper extends FormHelper {
    _options: TabDefaults;

    /**
     * Creates an instance of TabHelper.
     * @param {TabOptions} options Die Einstellungen der Klasse
     * @memberof TabHelper
     */
    constructor(options: TabOptions) {
        super();

        const title = {...def.title, ...options.title};

        this._options = {...def, ...options, ...{title}};
    }

    get id():string {
        return this._options.id;
    }

    get title():Title {
        return this._options.title;
    }

    get items():Array<FieldHelper> {
        return this._options.items;
    }

    get eventKey():string {
        return this._options.eventKey;
    }

    /**
     * Prueft ob die Einstellungen der Klasse korrekt sind
     *
     * @returns {ValidationArray}
     * @memberof TabHelper
     */
    validate():ValidationArray {
        const messages = new ValidationArray(this.id);

        if (isEmpty(this.id)) {
            messages.error.push('Id ist nicht gesetzt!');
        }

        if (isEmpty(this.eventKey)) {
            messages.error.push('EventKey ist nicht gesetzt!');
        }

        if (isEmpty(this.title)) {
            messages.error.push('Titel ist nicht gesetzt!');
        }

        if (this.items.length === 0) {
            messages.warn.push('Dieser TabView hat keine Felder!');
        }

        return messages;
    }

    /**
     * Baut den Tab
     *
     * @param {Object} object Das Kanalobjekt
     * @returns {?ValueElement}
     * @memberof TabHelper
     */
    build(object: Object):?ValueElement {
        const children = this.items.map(item => {
            const child = item.build(object);
            if (child) {
                return child.element;
            }
            return null;
        });

        return {
            valueSet: true,
            element:
                <Tab
                    eventKey={this.eventKey}
                    title={
                        <Trans i18nKey={this.title.key} parent="span">{this.title.fallback}</Trans>
                    }
                    key={this.eventKey}
                >
                    <Row>
                        {children}
                    </Row>
                </Tab>
        };
    }
}