// @flow
// import { combineReducers } from 'redux';
import i18next from 'i18next';
import { toast } from 'react-toastify';

import { DEFAULT_STATE } from '../misc/const';
import { DISPATCH_MESSAGE } from '../actions/utils/actionTypes';

import loginReducer from './loginReducer';
import dataReducer from './dataReducer';
import viewStateReducer from './viewStateReducer';
import { TOASTIFY_SEVERITY } from '../misc/const';
import { Action } from '../misc/flowTypes';

/**
 * Erzeugt eine Nachricht, die dem Benutzer angezeigt wird
 * @param  {String} text     Text der Nachricht
 * @param  {String} severity Schwere der Nachricht
 * @param  {Object} position Position der Nachricht
 */
function message(text: string, severity: ?string, position: string = toast.POSITION.TOP_CENTER) {
	const messageText: string = i18next.t(text);

	switch (severity) {
		case TOASTIFY_SEVERITY.ERROR:
			toast.error(messageText, {
				position: position,
				autoClose: 5000,
			});
			break;
		case TOASTIFY_SEVERITY.INFO:
			toast.info(messageText, {
				position: position,
				autoClose: 2000,
			});
			break;
		case TOASTIFY_SEVERITY.SUCCESS:
			toast.success(messageText, {
				position: position,
				autoClose: 2000,
			});
			break;
		default:
			toast.info(messageText, {
				position: position,
				autoClose: 2000,
			});
			break;
	}
}

// Combine Reducers
// const appReducers = combineReducers({
// 	loginReducer,
// 	dataReducer,
// 	viewStateReducer,
// });

/**
 * Funktion, die vom Redux aufgerufen wird, wenn actions dispatched werden
 * @param  {object} [state=DEFAULT_STATE] Aktueller Status
 * @param  {object} action                Inhalt der Action mit den Werten
 * @return {object}                       Neuer Status mit dem alten gemerged
 */
// eslint-disable-next-line import/no-anonymous-default-export
export default function (state: Object = DEFAULT_STATE, action: Action): Object {
	if (action.type === DISPATCH_MESSAGE) {
		message(action.text, action.severity, action.position);
	}

	//login
	state = loginReducer(state, action);
	//data
	state = dataReducer(state, action);
	//view
	state = viewStateReducer(state, action);

	return state;
	// return appReducers;
}
