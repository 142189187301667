// @flow
import React from 'react';
import {Container, Image} from 'react-bootstrap';
import {Trans} from 'react-i18next';

import logo from '../../images/ibak.png';
import loader from '../../images/ajax-loader.gif';

/**
 * Rendert einen Ladebildschirm
 */
const LoadingScreen = () => (
    <Container fluid>
        <Image
            src={logo}
            className="logo"
            responsive
        />
        <h1 className="text-center">IKAS Evolution WebViewer</h1>
        <h3 className="text-center">
            <Trans i18nKey="start.loading">Laden</Trans>
        </h3>
        <Image
            src={loader}
            className="loader"
            responsive
        />
    </Container>
);

export default LoadingScreen;