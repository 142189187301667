// @flow
import Layout from './Layout';
import popout from '../images/layout/popout.png';

/**
 * Klasse fuer ein Layout das die Ansicht auf 0 zu 12 aufteilt
 *
 * @export
 * @class ZeroToTwolfLayout
 * @extends {Layout}
 */
export default class TwelveToZeroLayout extends Layout {
    /**
     * Creates an instance of TwelveToZeroLayout.
     * @memberof TwelveToZeroLayout
     */
    constructor() {
        super('popout', popout); // Bild

        this.left.xs.hidden = false;
        this.left.sm.hidden = false;
        this.left.md.hidden = false;
        this.left.lg.hidden = false;

        this.right.xs.hidden = true;
        this.right.sm.hidden = true;
        this.right.md.hidden = true;
        this.right.lg.hidden = true;

        this.right.md.size = 0;
        this.right.lg.size = 0;
        this.right.xs.size = 0;
        this.right.sm.size = 0;
    }
}