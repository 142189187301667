// @flow
import React from 'react';
import {Card, Row} from 'react-bootstrap';
import {Title} from '../../misc/flowTypes';
import {Trans} from 'react-i18next';
import {isEmpty} from '../../misc/validationHelper';

type Props = {
    children: Array<?React$Element<any>>,
    title?: Title
}

/**
 * Erzeugt ein Card
 * @param {Array<?React$Element<any>} children Die Kindelemente des Cards
 * @param {Title} title Der Titel des Cards
 */
const FormCard = ({children, title}: Props) => {
    if (title && !isEmpty(title.key) && !isEmpty(title.fallback)) {
        return (
            <Card>
                <Card.Header>
                    <Trans i18nKey={title.key}>{title.fallback}</Trans>
                </Card.Header>

                <Card.Body >
                    <Row>
                        {children}
                    </Row>
                </Card.Body>
            </Card>
        )
    }
    else {
        let className = 'body-no-padding test';
        // workaround, damit die Anschlusspunkte nebeneinander angezeigt werden
        // evtl. muss die Komponente CardCardHelper aufgeteilt werden für Tabs und nebeneinanderer angeordnete Cards
        if( ['manholeCard', 'connectionpointCard'].includes(children[0].key)) {
            className = className.concat(' flex-direction-row');
        } else if (children[0].key === 'manholeTabs') {
            // Manhole enthält Tabs
            className = className.concat(' manholeTabs');
        }
        return (
            <Card>
                <Card.Body className={className}>
                    {children}
                </Card.Body>
            </Card>
        )
    }
}

export default FormCard;