// @flow
import React, { Component } from 'react';
import {Card, Form, FormGroup, Col, InputGroup, FormControl, Button, ButtonToolbar} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faSignInAlt, faTimes } from '@fortawesome/free-solid-svg-icons';

type Props = {
    title: string | React$Element<any>,
    password: string,
    confirm: string,
    callback: (string, string) => void,
    button: string | React$Element<any>,
    abortButton: string | React$Element<any>,
    abort: () => mixed,
    intro: React$Element<any> | string
}

type State = {
    ready: boolean
}

/**
 * Komponente fuer das Freischalten von Benutzern
 *
 * @export
 * @class UserUnlock
 * @extends {Component<Props, State>}
 */
export default class UserUnlock extends Component<Props, State> {
    password: ?HTMLInputElement;
    confirmPassword: ?HTMLInputElement;

    /**
     * Creates an instance of UserUnlock.
     * @param {Object} props Die Properties der Klasse
     * @memberof UserUnlock
     */
    constructor(props: Object) {
        super(props);

        this.state = {
            ready: false
        };
    }

    /**
     * React Lifecylce Methode
     *
     * @memberof UserUnlock
     */
    componentDidMount():void {
        // $FlowFixMe
        this.password.focus();
    }

    /**
     * Ruft den Callback auf wenn Absenden betatiegt wird
     *
     * @memberof UserUnlock
     */
    onSend = ():void => {
        const {callback} = this.props;
        // $FlowFixMe
        callback(this.password.value, this.confirmPassword.value);
    }

    /**
     * Ruft den Abbrechen Callback auf wenn das Abbrechen betaetigt wird
     *
     * @memberof UserUnlock
     */
    onAbort = ():void => {
        const {abort} = this.props;
        abort();
    }

    /**
     * Prueft ob die Eingabefelder korrekt gefuellt sind und setzt den Status
     *
     * @memberof UserUnlock
     */
    checkFields = ():void => {
        // $FlowFixMe
        const match = this.password.value.trim() === this.confirmPassword.value.trim();
        // $FlowFixMe
        const notNull = this.password.value.trim() !== '' && this.confirmPassword.value.trim() !== '';

        this.setState({
            ready: match && notNull
        });
    }

    /**
     * Workaround fuer Submit Probleme im Firefox
     * @param {Event} e Das Event das ausgeloest wurde
     */
    handleKeyPress = (e: SyntheticEvent<>):void => {
        const {ready} = this.state;
        if (e.key === 'Enter' && ready) {
            e.stopPropagation();
            e.preventDefault();
            this.onSend();
        }
    }

    /**
     * Rendert die Komponente
     *
     * @returns
     * @memberof UserUnlock
     */
    render() {
        const {title, password, confirm, button, abortButton, intro} = this.props;
        const {ready} = this.state;

        return (
            <Card onKeyPress={this.handleKeyPress}>
                <Card.Heading>
                    <Card.Title>{title}</Card.Title>
                </Card.Heading>
                <Card.Body >
                    <Form horizontal>
                        {intro}
                        <FormGroup>
                            <Col sm={12}>
                                <InputGroup>
                                    <InputGroup.Addon>
                                        <FontAwesomeIcon icon={faLock}></FontAwesomeIcon>
                                    </InputGroup.Addon>
                                    <FormControl
                                        type="password"
                                        placeholder={password}
                                        inputRef={ref => {this.password = ref;}}
                                        onChange={this.checkFields}
                                    />
                                </InputGroup>
                            </Col>
                        </FormGroup>
                        <FormGroup>
                            <Col sm={12}>
                                <InputGroup>
                                    <InputGroup.Addon>
                                        <FontAwesomeIcon icon={faLock}></FontAwesomeIcon>
                                    </InputGroup.Addon>
                                    <FormControl
                                        type="password"
                                        placeholder={confirm}
                                        inputRef={ref => {this.confirmPassword = ref;}}
                                        onChange={this.checkFields}
                                    />
                                </InputGroup>
                            </Col>
                        </FormGroup>
                        <FormGroup>
                            <Col sm={12}>
                                <ButtonToolbar>
                                    <Button
                                        onClick={this.onSend}
                                        disabled={!ready}
                                    >
                                        <FontAwesomeIcon icon={faSignInAlt}></FontAwesomeIcon> {button}
                                    </Button>
                                    <Button
                                        onClick={this.onAbort}
                                    >
                                        <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon> {abortButton}
                                    </Button>
                                </ButtonToolbar>
                            </Col>
                        </FormGroup>
                    </Form>
                </Card.Body>
            </Card>
        );
    }
}