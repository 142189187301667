// @flow
import {isBoolean} from '../misc/validationHelper';

/**
 * Hilfsklasse fuer das Seitenmenu des Layouts
 *
 * @export
 * @class SideMenuHelper
 */
export default class SideMenuHelper {
    _xs: boolean = false;
    _sm: boolean = false;
    _md: boolean = false;
    _lg: boolean = false;
    
    get xs():boolean {
        return this._xs;
    }

    get sm():boolean {
        return this._sm;
    }

    get md():boolean {
        return this._md;
    }

    get lg():boolean {
        return this._lg;
    }

    set xs(xs: boolean) {
        if (isBoolean(xs)) {
            this._xs = xs;
        }
    }

    set sm(sm: boolean) {
        if (isBoolean(sm)) {
            this._sm = sm;
        }
    }

    set md(md: boolean) {
        if (isBoolean(md)) {
            this._md = md;
        }
    }

    set lg(lg: boolean) {
        if (isBoolean(lg)) {
            this._lg = lg;
        }
    }
}