// @flow
import React from 'react';
import {Form} from 'react-bootstrap';
import LayoutSelection from './form/LayoutSelection';
// import { LANG } from '../../misc/const';
// import SelectWithLabel from './form/SelectWithLabel';
// import { Trans } from 'react-i18next';

type Props = {
    onChange: (string, any) => void,
    settings: Object,
    help: boolean
}

// const LANGUAGE_OPTIONS = LANG.map((l) => <option key={l.key} value={l.key}>{l.name}</option>);

/**
 * Tab fuer Allgemeine Einstellungen auf den Einstellungen
 * @param {Function} onChange Callback fuer Aenderungen
 * @param {Object} settings Aktuelle Einstellungen
 * @param {boolean} help Ob die Text angezeigt werden soll
 */
const GeneralTab = ({onChange, settings}: Props) => (
    <Form>
        {/* <SelectWithLabel
            value={settings.lang}
            onChange={(value: string) => onChange('lang', value)}
            label={<Trans i18nKey="common.language">Sprache</Trans>}
            options={LANGUAGE_OPTIONS}
            help={help}
            helpText={<Trans i18nKey="settings.helpText.lang">...</Trans>}
        /> */}
        <LayoutSelection
            layout={settings.layout}
            onChange={(value: string) => onChange('layout', value)}
        />
    </Form>
);

export default GeneralTab;