// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Card, Button } from 'react-bootstrap';

import FormBuilder from '../form/FormBuilder';
import { getImageSmall } from '../misc/utils';
import CanalInterface from '../templates/helper/CanalInterface';
import FormHelper from '../form/helper/FormHelper';
import withKeyboardCommand from './hoc/withKeyboardCommands';

import { backToProject } from '../actions/dataActions';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleLeft } from '@fortawesome/free-solid-svg-icons';

type Props = {
	currentObject: Object,
	currentInterface: CanalInterface,
	backToProject: () => mixed,
};

/**
 * React Komponente, die eine Form fuer eine Objekt rendert
 * @type {Component}
 * @constructor
 */
class ObjectMainView extends Component<Props> {
	formBuilder: FormBuilder = new FormBuilder();

	/**
	 * Rendert die Form fuer das Objekt
	 * @return {JSX} JSX Markup
	 */
	render() {
		const { currentInterface, currentObject } = this.props;
		const { backToProject } = this.props;
		// $FlowFixMe
		const template: Array<FormHelper> = currentInterface.object[currentObject.type].data;
		const form: React$Element<any> = this.formBuilder.build(currentObject, template);

		return (
            <>
                <Form>
                    <Card>
                        <Card.Body>
                            <Button onClick={() => backToProject()}>
                                <FontAwesomeIcon icon={faAngleDoubleLeft}></FontAwesomeIcon>&nbsp;
                                {getImageSmall('folder')}
                            </Button>
                        </Card.Body>
                    </Card>
                </Form>
                <Form id="objectview">
                    {form}
                </Form>
            </>
		);
	}
}

/**
 *  HOC fuer das Verbinden mit Tastaturkuerzeln
 */
const ObjectView = withKeyboardCommand(ObjectMainView, [
	{
		key: 'ctrl+left',
		func: (child) => child.props.backToProject(),
	},
]);

/**
 *  Verbindet die Klasse mit dem Redux Store
 */
const ReduxObjectMainView = connect(
	(state) => {
		return {
			currentObject: state.currentObject,
			currentInterface: state.currentInterface,
		};
	},
	{ backToProject }
)(ObjectView);
export default ReduxObjectMainView;
