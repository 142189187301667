// @flow
import Layout from './Layout';
import threeToNine from '../images/layout/threetonine.png';

/**
 * Klasse fuer ein Layout das die Ansicht auf 3 zu 9 aufteilt
 *
 * @export
 * @class ThreeToNineLayout
 * @extends {Layout}
 */
export default class ThreeToNineLayout extends Layout {
    /**
     * Creates an instance of ThreeToNineLayout.
     * @memberof ThreeToNineLayout
     */
    constructor() {
        super('threeToNine', threeToNine);

        this.sideMenuHidden.md = true;
        this.sideMenuHidden.lg = true;
        this.left.xs.hidden = true;
        this.left.sm.hidden = true;
        this.left.md.size = 3;
        this.left.lg.size = 3;
        this.right.xs.size = 12;
        this.right.sm.size = 12;
    }
}