// @flow
import {isBoolean, checkRange} from '../misc/validationHelper';

const MIN_RANGE: number = 0;
const MAX_RANGE: number = 12;

/**
 * Hilfklasse fuer eine Art von Spalten des Layouts
 *
 * @export
 * @class ColumnTypeHelper
 */
export default class ColumnTypeHelper {
    _hidden: boolean = false;
    _size: number = 0;
    _sizeCallback: (size: number) => void;
    
    /**
     * Creates an instance of ColumnTypeHelper.
     * @param {(size: number) => void} sizeCallback Callback fuer Aenderungen an der Groesse
     * @memberof ColumnTypeHelper
     */
    constructor(sizeCallback: (size: number) => void) {
        this._sizeCallback = sizeCallback;
    }

    get hidden():boolean {
        return this._hidden;
    }

    set hidden(hidden: boolean) {
        if (isBoolean(hidden)) {
            this._hidden = hidden;
        }
    }

    get size():number {
        return this._size;
    }

    set size(size: number) {
        if (checkRange(MIN_RANGE, MAX_RANGE, size)) {
            this._size = size;
            this._sizeCallback(size);
        }
    }
}