// @flow
import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { translateComp } from '../../../i18n/utils';

import general from '../../../templates/General';

export default function InfoPanel({ current }) {
	return (
		<Row>
			<Card className="no-border-shadow">
				<Card.Body >
					<Col lg={4} md={4} sm={4}>
						<strong>{translateComp('station.photoname', 'Fotoname')}:</strong>
						&nbsp;
						{current[general.stationphoto]}
					</Col>
					<Col lg={4} md={4} sm={4}>
						<strong>{translateComp('station.distance', 'Entfernung')}:</strong>
						&nbsp;
						{current[general.stationdistance]}
					</Col>
					<Col lg={4} md={4} sm={4}>
						<strong>{translateComp('station.code', 'Kürzel')}:</strong>
						&nbsp;
						{current[general.stationname]}
					</Col>
				</Card.Body>
			</Card>
		</Row>
	);
}
