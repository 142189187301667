// @flow
import React from 'react';
import {Title} from "../misc/flowTypes";
import { Nav, TabPane } from "react-bootstrap";
import { Trans } from 'react-i18next';
import { getImage } from '../misc/utils';

type ViewBuilderOptions = {
    eventKey: string,
    navTitle?: (Object) => string,
    title?: Title,
    image?: string,
    imageFunc?: (Object) => string,
    component: React$Element<any>,
    onEntered?: () => mixed,
    mountOnEnter?: boolean,
    unmountOnExit?: boolean,
    displayCondition?: (Object) => boolean
}


/**
 * Hilfsklasse fuer Tabs
 *
 * @export
 * @class ViewBuilder
 */
export default class ViewBuilder {
    eventKey: string;
    navTitle: ?(Object) => string;
    title: ?Title;
    image: ?string;
    component: React$Element<any>;
    onEntered: () => mixed;
    mountOnEnter: boolean;
    unmountOnExit: boolean;
    displayCondition: (Object) => boolean;
    imageFunc: ?(Object) => string

    /**
     * Creates an instance of ViewBuilder.
     * @param {ViewBuilderOptions} options Optionen fuer den Tab
     * @memberof ViewBuilder
     */
    constructor(options: ViewBuilderOptions) {
        this.eventKey = options.eventKey;
        this.navTitle = options.navTitle;
        this.title = options.title;
        this.image = options.image;
        this.imageFunc = options.imageFunc;
        this.component = options.component;
        this.onEntered = options.onEntered ? options.onEntered : () => {};
        this.mountOnEnter = options.mountOnEnter ? true : false;
        this.unmountOnExit = options.unmountOnExit ? true : false;
        this.displayCondition = options.displayCondition ? options.displayCondition : (props: Object) => true;
    }

    /**
     * Gibt zurueck ob der Tab dargestellt werden soll
     *
     * @param {Object} props Properties von der Komponente, die diesen Tab anzeigen moechte
     * @returns {boolean}
     * @memberof ViewBuilder
     */
    shouldDisplay(props: Object):boolean {
        const components = props.components;
        if (components != null && components.hasOwnProperty(this.eventKey)) {
            let condition = this.displayCondition(props);
            return components[this.eventKey] && condition;
        }
        return false;
    }

    /**
     * Erzeugt den Wert fuer den Titel des Tabs
     *
     * @param {Object} props Properties von der Komponente, die diesen Tab anzeigen moechte
     * @returns {React$Element<any>}
     * @memberof ViewBuilder
     */
    buildTitle(props: Object):React$Element<any> {
        let titleValue: React$Element<any> = <span></span>;

        if (this.navTitle) {
            titleValue = <span>{this.navTitle(props)}</span>;
        }

        if (this.title) {
            titleValue = <Trans i18nKey={this.title.key} parent='span'>{this.title.fallback}</Trans>;
        }

        if (this.image) {
            titleValue = (
                <span>
                    {getImage(this.image, 'correct-img-size')}&nbsp;
                    {titleValue}
                </span>
            );
        }

        if (this.imageFunc) {
            titleValue = (
                <span>
                    {getImage(this.imageFunc(props), 'correct-img-size')}&nbsp;
                    {titleValue}
                </span>
            )
        }

        return titleValue;
    }

    /**
     * Gibt das Navigationsitem des Tabs zurueck
     *
     * @param {Object} props Properties von der Komponente, die diesen Tab anzeigen moechte
     * @returns {?React$Element<any>}
     * @memberof ViewBuilder
     */
    getNavItem(props: Object):?React$Element<any> {
        if (this.shouldDisplay(props) ) {
            return (
                <Nav.Item key={this.eventKey}>
                    <Nav.Link eventKey={this.eventKey}>
                        {this.buildTitle(props)}
                    </Nav.Link>
                </Nav.Item>
            );
        }
        return null;
    }

    /**
     * Gibt den Inhalt des Tabs zureuck
     *
     * @param {Object} props Properties von der Komponente, die diesen Tab anzeigen moechte
     * @returns {?React$Element<any>}
     * @memberof ViewBuilder
     */
    getTabPane(props: Object):?React$Element<any> {
        if (this.shouldDisplay(props)) {
            return (
                <TabPane
                    key={this.eventKey}
                    eventKey={this.eventKey}
                    unmountOnExit={this.unmountOnExit}
                    mountOnEnter={this.mountOnEnter}
                    onEntered={this.onEntered}
                >
                    {this.component}
                </TabPane>
            );
        }
        return null;
    }
}