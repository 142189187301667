// @flow
import React from 'react';
import {Tabs} from 'react-bootstrap';

type Props = {
    id: string,
    defaultActiveKey: string,
    children: Array<?React$Element<any>>
}

/**
 * Erzeugt einen View fuer Tabs
 * @param {string} id Id des Views
 * @param {string} defaultActiveKey Name des Tabkeys, welcher standardmaessig ausgewaehlt ist
 * @param {Array<?React$Element<any>} children Die Tabs des Views
 */
const TabView = ({id, defaultActiveKey, children}: Props) => (
    <Tabs
        id={id}
        defaultActiveKey={defaultActiveKey}
    >
        {children}
    </Tabs>
);

export default TabView;