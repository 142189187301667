// @flow
import FormHelper from "../../form/helper/FormHelper";

/**
 * Hilfsklasse fuer ein Kanalobjekt in einer Schnittstelle
 *
 * @export
 * @class CanalObject
 */
export default class CanalObject {
    data: Array<FormHelper>

    /**
     * Creates an instance of CanalObject.
     * @param {Array<FormHelper>} data Die Felder des Objekts
     * @memberof CanalObject
     */
    constructor(data: Array<FormHelper>) {
        this.data = data;
    }
}