// @flow
import React, { Component } from 'react';
import { Card, TabContainer, Row, Col, Nav, TabContent } from 'react-bootstrap';

import ProjectSideView from './ProjectSideView';
import ObjectListSideView from './objectlist/ObjectListSideView';
import StationSideView from './StationSideView';
import NetPlaneView from './NetPlaneSideView';
import { PROJECT_LEVEL_VIEWS, OBJECT_LEVEL_VIEWS } from '../misc/const';
import ViewBuilder from '../builder/ViewBuilder';
import InspectionMainView from './InspectionMainView';
import ObjectMainView from './ObjectMainView';
import { getName } from '../misc/utils';
import { connect } from 'react-redux';
import { _updateSettings } from '../actions/dataActions';
import _ from 'lodash';

type Props = {
	components: Object,
	currentObject: Object,
	currentNetPlane: Object,
	settings: Object,
	_updateSettings: (Object, string, ?boolean, ?boolean) => mixed,
};

type State = {
	tabs: Array<ViewBuilder>,
	viewKey: string,
	settings: Object,
	currentObject: Object,
};

/**
 * React Komponente, die die Seitenansicht rendert
 * @type {Component}
 * @constructor
 */
class SideView extends Component<Props, State> {
	/**
	 * Creates an instance of SideView.
	 * @param {Props} props
	 * @memberof SideView
	 */
	constructor(props: Props) {
		super(props);

		this.state = {
			tabs: [],
			viewKey: '',
		};
	}

	/**
	 * React Lifecycle Methode
	 * Fuegt die Tabs hinzu
	 *
	 * @memberof SideView
	 */
	componentDidMount() {
		this.renderSideView();
	}
	/**
	 * React Lifecycle Methode
	 * Fuegt die Tabs hinzu
	 *
	 * @memberof SideView
	 */
	renderSideView() {
		const { tabs } = this.state;
		this.renderProjectView(tabs);
		this.renderObjectList(tabs);
		this.renderObjectSideView(tabs);
		this.renderInspectionView(tabs);
		this.renderStationView(tabs);
        if( Object.keys(this.props.currentNetPlane).length !== 0 ) {
		    this.renderNetPlaneView(tabs);
        }
		this.setState({
			tabs,
		});
	}

	/**
	 * Rendert den Tab fuer das Projekt
	 *
	 * @param {Array<ViewBuilder>} tabs Array, wo der Tab hinzugefuegt wird
	 * @memberof SideView
	 */
	renderProjectView(tabs: Array<ViewBuilder>) {
		tabs.push(
			new ViewBuilder({
				eventKey: PROJECT_LEVEL_VIEWS.SIDE.PROJECT_SIDE_VIEW,
				title: {
					key: 'common.projects',
					fallback: 'Projekte',
				},
				image: 'folder',
				component: <ProjectSideView />,
				mountOnEnter: true,
				unmountOnExit: true,
			})
		);
	}

	/**
	 * Rendert den Tab fuer die Objektliste
	 *
	 * @param {Array<ViewBuilder>} tabs Array, wo der Tab hinzugefuegt wird
	 * @memberof SideView
	 */
	renderObjectList(tabs: Array<ViewBuilder>) {
		//const showObjectList = (props) => props.currentObject && props.currentObject.stations && props.currentObject.stations.length > 0;
		tabs.push(
			new ViewBuilder({
				eventKey: OBJECT_LEVEL_VIEWS.SIDE.OBJECT_LIST_SIDE_VIEW,
				title: {
					key: 'main.objects',
					fallback: 'Objekte',
				},
				image: 'object',
				component: <ObjectListSideView />,
				mountOnEnter: true,
				unmountOnExit: true,
				//            displayCondition: showObjectList
			})
		);
	}

	/**
	 * Rendert den Tab fuer die Stationsliste
	 *
	 * @param {Array<ViewBuilder>} tabs Array, wo der Tab hinzugefuegt wird
	 * @memberof SideView
	 */
	renderStationView(tabs: Array<ViewBuilder>) {
		tabs.push(
			new ViewBuilder({
				eventKey: OBJECT_LEVEL_VIEWS.SIDE.STATION_SIDE_VIEW,
				title: {
					key: 'main.station',
					fallback: 'Stationen',
				},
				image: 'station',
				component: <StationSideView />,
				mountOnEnter: true,
				unmountOnExit: true,
				displayCondition: (props) => props.currentObject && props.currentObject.stations && props.currentObject.stations.length > 0,
			})
		);
	}

	/**
	 * Rendert den Tab fuer die Inspektion
	 *
	 * @param {Array<ViewBuilder>} tabs Array, wo der Tab hinzugefuegt wird
	 * @memberof MainView
	 */
	renderInspectionView(tabs: Array<ViewBuilder>) {
		tabs.push(
			new ViewBuilder({
				eventKey: OBJECT_LEVEL_VIEWS.SIDE.INSPECTION_SIDE_VIEW,
				title: {
					key: 'main.inspection',
					fallback: 'Untersuchung',
				},
				image: 'inspection',
				component: <InspectionMainView />,
				mountOnEnter: true,
				unmountOnExit: true,
				displayCondition: (props) => this.validateInspection(props),
			})
		);
	}

	validateInspection = (props: Object): boolean => {
		let result = false;

		if (props.currentObject && props.currentObject.inspection && props.currentObject.inspection.length > 0) {
			if (props.stationVideoViewInstances && props.stationVideoViewInstances.length > 1) result = true;
		}

		return result;
	};

	/**
	 * Rendert den Tab fuer das Objekt
	 *
	 * @param {Array<ViewBuilder>} tabs Array, wo der Tab hinzugefuegt wird
	 * @memberof MainView
	 */
	renderObjectSideView(tabs: Array<ViewBuilder>) {
		tabs.push(
			new ViewBuilder({
				eventKey: OBJECT_LEVEL_VIEWS.SIDE.OBJECT_SIDE_VIEW,
				navTitle: (props) => getName(props.currentObject, props.currentInterface.objectname),
				imageFunc: (props) => props.currentObject.type,
				component: <ObjectMainView />,
				mountOnEnter: true,
				unmountOnExit: true,
				displayCondition: (props) => this.validateObjectSideView(props),
			})
		);
	}

	validateObjectSideView = (props: Object): boolean => {
		let result = false;

		if (props.currentObject && props.currentObject.inspection && props.currentObject.inspection.length > 0) {
			if (props.stationVideoViewInstances && props.stationVideoViewInstances.length > 1) result = true;
		}

		return result;
	};

	/**
	 * Rendert den Tab fuer den Netzplan
	 *
	 * @param {Array<ViewBuilder>} tabs Array, wo der Tab hinzugefuegt wird
	 * @memberof SideView
	 */
	renderNetPlaneView(tabs: Array<ViewBuilder>) {
		tabs.push(
			new ViewBuilder({
				eventKey: OBJECT_LEVEL_VIEWS.SIDE.NETPLANE_SIDE_VIEW,
				title: {
					key: 'main.netplane',
					fallback: 'Netzplan',
				},
				image: 'net_plane',
				component: <NetPlaneView />,
				mountOnEnter: true,
				displayCondition: (props) => !_.isEmpty(props.currentNetPlane),
			})
		);
	}

	updateKey = (key: string) => {
		this.setState({ viewKey: key });
	};

	/**
	 * Rendert die Seitenansicht
	 *
	 * @return {JSX} JSX Markup
	 * @memberof SideView
	 */
	render() {
		const { tabs, viewKey } = this.state;
		let tabsToDisplay: number = 0;
		let firstTab: ViewBuilder;
		let key: string = viewKey;
		let tabVisible: boolean = false;

		tabs.forEach((tab) => {
			if (tab.shouldDisplay(this.props)) {
				tabsToDisplay++;
				if (!firstTab) {
					firstTab = tab;
				}

				if (viewKey === tab.eventKey) {
					tabVisible = true;
				}
			}
		});

		if (key === '' && firstTab) {
			key = firstTab.eventKey;
		}

		if (!tabVisible && firstTab) {
			key = firstTab.eventKey;
			//this.updateKey(key);
		}

		return (
			<Card className='sideview-container no-border-shadow'>
				<Card.Body>
					{tabsToDisplay === 1 && firstTab ? firstTab.component : null}
					{tabsToDisplay > 1 && firstTab ? (
						<TabContainer id='sideTabs' activeKey={key} onSelect={(key) => this.updateKey(key)}>
							<Row>
								<Col xs={12} sm={12} md={12} lg={12}>
									<Nav>{tabs.map((tab) => tab.getNavItem(this.props))}</Nav>
								</Col>
								<Col xs={12} sm={12} md={12} lg={12}>
									<TabContent>{tabs.map((tab) => tab.getTabPane(this.props))}</TabContent>
								</Col>
							</Row>
						</TabContainer>
					) : null}
				</Card.Body>
			</Card>
		);
	}
}

/**
 *  Verbindet die Klasse mit dem Redux Store
 */
const SideViewConnect = connect(
	(state, ownProps) => {
		return {
			settings: state.settings,
			currentObject: state.currentObject,
		};
	},
	{
		_updateSettings,
	}
)(SideView);

export default SideViewConnect;
