// @flow
import React from 'react';
import { connect } from 'react-redux';
// $FlowFixMe
import { Navbar, Nav } from 'react-bootstrap';

import ProjectBreadcrumb from './ProjectBreadcrumb';
import NavMenuButton from './page/NavMenuButton';
import SettingsNavigationItem from './navigation/SettingsNavigationItem';
import LogoutNavigationItem from './navigation/LogoutNavigationItem';
import { showSideView } from '../actions/viewActions';
import { BsList } from "react-icons/bs";

type Props = {
	authenticated: boolean,
	showSideView: (boolean) => mixed,
	header: string,
	layout: Object,
};

/**
 * React Komponente, die eine Navigationsleiste am Seitenanfang rendert
 * @type {Component}
 * @constructor
 */
const Navigation = ({ authenticated, layout, header, showSideView }: Props) => {
    return (
		<Navbar fixed="top" expand="xl">
            <NavMenuButton onClick={() => showSideView(true)} layout={layout.sideMenuHidden} />

            <Navbar.Brand className="navbar-brand d-sm-block d-xs-none ms-3 py-3">{header}</Navbar.Brand>

            <Navbar.Toggle className='navbar-btn' children={<BsList />} />
			<Navbar.Collapse className="navbar-collapse height-100p">
                <Nav>
                    <Nav.Link className='navbar-breadcrump'>
                        <ProjectBreadcrumb />
                    </Nav.Link>
                </Nav>
                <Nav className="navbar-settings">
                    <Nav.Link>
                        <SettingsNavigationItem />
                    </Nav.Link>

                    <Nav.Link>
                        <LogoutNavigationItem />
                    </Nav.Link>
                </Nav>
			</Navbar.Collapse>
		</Navbar>
	);
};

const ReduxNavigation = connect(
	(state, ownProps) => {
		return {
			authenticated: state.authentication.token != null && state.authentication.token !== '',
			header: ownProps.header,
			layout: ownProps.layout,
		};
	},
	{
		showSideView,
	}
)(Navigation);

export default ReduxNavigation;
