// @flow
import React, { Component } from 'react';
import {Card, Form, FormGroup, Col, InputGroup, FormControl, Button, ButtonToolbar} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faSignInAlt, faTimes } from '@fortawesome/free-solid-svg-icons';

type Props = {
    username: string,
    title: string | React$Element<any>,
    callback: (string) => mixed,
    button: string | React$Element<any>,
    abortButton: string | React$Element<any>,
    abort: () => mixed
}

type State = {
    ready: boolean
}

/**
 * Komponente fuer das Anfragen eines neuen Passworts
 *
 * @export
 * @class RequestPassword
 * @extends {Component}
 */
export default class RequestPassword extends Component<Props,State> {
    username: ?HTMLInputElement;

    /**
     * Creates an instance of RequestPassword.
     * @param {Object} props Die Properties des Klasse
     * @memberof RequestPassword
     */
    constructor(props: Object) {
        super(props);

        this.state = {
            ready: false
        }
    }

    /**
     * React Lifecycle Methode
     * @memberof RequestPassword
     */
    componentDidMount():void {
        // $FlowFixMe
        this.username.focus();
    }

    /**
     * Ruft den Callback auf wenn das Absenden betaetigt wird
     * @memberof RequestPassword
     */
    onSend = ():void => {
        const {callback} = this.props;
        // $FlowFixMe
        callback(this.username.value);
    }

    /**
     * Ruft den Abbrechen Callback auf wenn das Abbrechen betaetigt wird
     * @memberof RequestPassword
     */
    onAbort = ():void => {
        const {abort} = this.props;
        abort();
    }

    /**
     * Prueft ob das Eingabefeld korrekt gefuellt ist und setzt den Status
     * @memberof RequestPassword
     */
    checkField = ():void => {
        // $FlowFixMe
        const ready = this.username.value.trim() !== '';
        this.setState({
            ready
        });
    }

    /**
     * Workaround fuer Submit Probleme im Firefox
     * @param {Event} e Das Event das ausgeloest wurde
     */
    handleKeyPress = (e: SyntheticEvent<>):void => {
        const {ready} = this.state;
        if (e.key === 'Enter' && ready) {
            e.stopPropagation();
            e.preventDefault();
            this.onSend();
        }
    }


    /**
     * Rendert die Komponente
     * @return {JSX} JSX Markup
     */
    render() {
        const {title, username, button, abortButton} = this.props;
        const {ready} = this.state;

        return (
            <Card onKeyPress={this.handleKeyPress}>
                <Card.Heading>
                    <Card.Title>{title}</Card.Title>
                </Card.Heading>
                <Card.Body >
                    <Form horizontal>
                        <FormGroup>
                            <Col sm={12}>
                                <InputGroup>
                                    <InputGroup.Addon>
                                        <FontAwesomeIcon icon={faUser}></FontAwesomeIcon>
                                    </InputGroup.Addon>
                                    <FormControl
                                        type="email"
                                        placeholder={username}
                                        inputRef={ref => { this.username = ref; }}
                                        onChange={this.checkField}
                                    >
                                    </FormControl>
                                </InputGroup>
                            </Col>
                        </FormGroup>
                        <FormGroup>
                            <Col sm={12}>
                                <ButtonToolbar>
                                    <Button
                                        onClick={this.onSend}
                                        disabled={!ready}
                                    >
                                        <FontAwesomeIcon icon={faSignInAlt}></FontAwesomeIcon> {button}
                                    </Button>
                                    <Button
                                        onClick={this.onAbort}
                                    >
                                        <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon> {abortButton}
                                    </Button>
                                </ButtonToolbar>
                            </Col>
                        </FormGroup>
                    </Form>
                </Card.Body>
            </Card>
        )
    }
}