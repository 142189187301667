// @flow
import React from 'react';
import {isEmpty} from '../../misc/validationHelper';
import ValidationArray from './ValidationArray';
import {Title} from '../../misc/flowTypes';
import FormCard from '../components/FormCard';
import FormHelper from './FormHelper';
import type {ValueElement} from './FormHelper';

type CardDefaults = {
    id: string,
    title?: Title,
    items: Array<FormHelper>,
    onlyWithValues: boolean
}

type CardOptions = {
    id: string,
    title?: Title,
    items?: Array<FormHelper>,
    onlyWithValues?: boolean
}

const def: CardDefaults = {
    id: '',
    title: {
        key: '',
        fallback: ''
    },
    items: [],
    onlyWithValues: false
};

/**
 * Hilfsklasse fuer Formtemplates, um ein Card zu erzeugen
 *
 * @export
 * @class CardHelper
 * @extends {FormHelper}
 */
export default class CardHelper extends FormHelper {
    _options: CardDefaults;

    /**
     * Creates an instance of CardHelper.
     * @param {CardOptions} options Die Einstellungen des Cards
     * @memberof CardHelper
     */
    constructor(options: CardOptions) {
        super();

        const title = {...def.title, ...options.title};

        this._options = {...def, ...options, ...{title}};
    }

    get id():string {
        return this._options.id;
    }

    get title():Title {
        return this._options.title;
    }

    get items():Array<any> {
        return this._options.items;
    }

    get onlyWithValues():boolean {
        return this._options.onlyWithValues;
    }

    /**
     * Prueft ob die Einstellungen der Klasse korrekt sind
     *
     * @returns {ValidationArray}
     * @memberof CardHelper
     */
    validate():ValidationArray {
        const messages = new ValidationArray(this.id);

        if (isEmpty(this.id)) {
            messages.error.push('Id ist nicht gesetzt!');
        }

        if (this.items.length === 0) {
            messages.warn.push('Dieses Card hat keine Items!');
        }

        return messages;
    }

    /**
     * Baut das Card
     *
     * @param {Object} object Das Kanalobjekt
     * @returns {?ValueElement}
     * @memberof CardHelper
     */
    build(object: Object):?ValueElement {
        let oneValue = false;
        const children = this.items.map(item => {
            const child = item.build(object);
            if (!oneValue && child.valueSet) {
                oneValue = child.valueSet;
            }
            return child.element;
        });

        if (!this.onlyWithValues || (this.onlyWithValues && oneValue)) {
            return {
                valueSet: oneValue,
                element:
                    <FormCard
                        title={this.title}
                        key={this.id}
                    >
                        {children}
                    </FormCard>
            }
        }
    }
}