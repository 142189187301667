import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import rootReducer from './reducer/reducer';

// import requestMddlwr from './middleware/requestMddlwr';

/**
 * Setzt welche Actions geloggt werden sollen
 * @param {*} getState
 * @param {*} action
 * @returns {bool}
 */
const confLogger = (getState, action) => {
	// if (action.type) return action.type !== 'persist/REHYDRATE' && action.type !== 'persist/PERSIST';
	return false;
};

/**
 * Erstellt einen benutzerdefinierten Logger
 */
const logger = createLogger({
	predicate: (getState, action) => confLogger(getState, action),
});

/**
 * Fast alle Middlewares zusammen
 */
const middlewares = [thunk, logger /*, requestMddlwr */];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

/**
 * Erweiterungen des Store (z.B. Middleware)
 */
const enhancer = composeEnhancers(applyMiddleware(...middlewares));

/**
 * Erstellt den Store aus den Reducern und den Erweiterungen
 */
const store = createStore(rootReducer, enhancer);

/**
 * Gibt den Store und den persistierten Store zurueck
 */
export default store;
