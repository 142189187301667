/* eslint-disable import/prefer-default-export */
import * as loginActions from './loginActions';
import * as dataActions from './dataActions';
import * as viewActions from './viewActions';

export const actions = {
	...loginActions,
	...dataActions,
	...viewActions,
};
