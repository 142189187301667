// @flow

type Options = {
    id: string,
    show: boolean,
    mode: string,
    width: string
}

/**
 * Hilfsobjekt fuer die Einstellung von Spalten in der Objektliste
 *
 * @export
 * @class ObjectListSettingObject
 */
export default class ObjectListSettingObject {
    id: string;
    show: boolean;
    mode: string;
    width: string;

    /**
     * Creates an instance of ObjectListSettingObject.
     * @param {Options} col Spalte aus dem die Einstellungen erzeugt werden
     * @memberof ObjectListSettingObject
     */
    constructor(col: Options) {
        this.id = col.id;
        this.show = col.show;
        this.mode = col.mode;
        if (col.width) {
            this.width = col.width;
        }        
    }
}