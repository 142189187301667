// @flow
import ValidationArray from './ValidationArray';

export type ValueElement = {
    valueSet: boolean,
    element: ?React$Element<any>
}

/**
 * Template fuer Hilfsklassen für das Formtemplate
 *
 * @export
 * @class FormHelper
 */
export default class FormHelper {
    /**
     * Baut ein Element
     *
     * @param {Object} object Das Kanalobjekt
     * @returns {?ValueElement}
     * @memberof FormHelper
     */
    build(object: Object):?ValueElement {
        return {
            valueSet: false, 
            element: null
        };
    }

    /**
     * Prueft ob die Einstellungen der Klasse korrekt sind
     *
     * @returns {ValidationArray}
     * @memberof FormHelper
     */
    validate():ValidationArray {        
        return new ValidationArray('');
    }
};