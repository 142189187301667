// @flow

/**
 * Prueft ob der gegebene Wert ein Boolean ist
 *
 * @export
 * @param {boolean} value Wert, der geprueft wird
 * @returns {boolean}
 */
export function isBoolean(value: boolean):boolean {
    return typeof(value) === 'boolean';
}

/**
 * Prueft ob sich der Wert in dem Bereich befindet
 *
 * @export
 * @param {number} min Minimal erlaubter Wert
 * @param {number} max Maximal erlaubter Wert
 * @param {number} value Wert, der geprueft wird
 * @returns {boolean}
 */
export function checkRange(min: number, max: number, value: number):boolean {
    return !isNaN(value) && value >= min && value <= max;
}

/**
 * Prueft ob der uebergebene String leer ist
 *
 * @export
 * @param {string} value
 * @returns
 */
export function isEmpty(value?: string) {
    if (value) {
        if (typeof value.trim === 'function') {
            value = value.trim();
        }
        return value === '';
    }
    return true;
}