import { Container, Row, Col } from 'react-bootstrap';
import React, { useRef, useEffect } from 'react';

export default function PanoWrap({
    panoViewer,
    objectType
}) {
    const wrapTargetContainer = useRef(null);
    const wrapOverviewTargetContainer = useRef(null);

    useEffect(() => {
        const open = async () =>  {
            panoViewer.setRenderTargets({
                wrapDetailDiv: wrapTargetContainer.current,
                wrapOverviewDiv: wrapOverviewTargetContainer.current
            });
            await panoViewer.openWrap();
        }
        if(panoViewer) {
            open();
        }

        return () => {
            if(panoViewer) {
                panoViewer.disposeWrap();
            }
        }
    }, [panoViewer]);

    const styleWrap = { height: '90vH', width: '100%', border: '1px solid black' };
    const styleOverview = { height: '90vH', width: '100%', border: '1px solid black', marginBottom: 8 };

    const rowStyle = {display: 'flex'};
    const colSpan = {wrap: 10, overview: 2}

    if (objectType !== 'manhole') {
        rowStyle.flexDirection = 'column-reverse';
        colSpan.wrap = 12;
        colSpan.overview = 12;
        styleWrap.height = '55vh';
        styleOverview.height = '35vh';
    }

    return (
        <Row style={rowStyle}>
            <Col xs={colSpan.wrap} >
                <Container fluid>
                    <WrapRenderTarget innerRef={wrapTargetContainer} style={styleWrap} />
                </Container>
            </Col>
            <Col xs={colSpan.overview}>
                <Container fluid>
                    <WrapOverviewRenderTarget innerRef={wrapOverviewTargetContainer} style={styleOverview}/>
                </Container>
            </Col>
        </Row>
    );
}

const WrapRenderTarget = ({innerRef, style}) => {
    return <div id="panoWrapTargetContainer" ref={innerRef} style={style}></div>
};

const WrapOverviewRenderTarget = ({innerRef, style}) => {
    return <div id="panoWrapOverviewTargetContainer" ref={innerRef} style={style}></div>
};