// @flow
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Container, Col, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import 'url-search-params-polyfill';

import { TOASTIFY_SEVERITY } from '../../misc/const';
import { PATH_FORGOT_PASSWORD, PATH_RESET_PASSWORD, PATH_UNLOCK, PATH_PREVIEW } from '../../backend/paths';

import { post, get } from '../../backend/API';
import Login from './Login';
import RequestPassword from './RequestPassword';
import ResetPassword from './ResetPassword';
import { dispatchIf } from '../../misc/utils';
import * as TYPES from '../../misc/types';
import { MessageType } from '../../misc/flowTypes';
import UserUnlock from './UserUnlock';
import { BAD_REQUEST, INTERNAL_SERVER_ERROR, NOT_FOUND } from '../../misc/httpCodes';

import { translateString, translateComp } from '../../i18n/utils';

type Props = {
	resetLogin: boolean,
	_message: (MessageType) => mixed,
	_loginReset: () => mixed,
	_login: (string, string) => mixed,
	_loginPreview: (Object) => mixed,
};

type State = {
	view: string,
	uuid: string,
	login: boolean,
	forname: string,
	surname: string,
	mail: string,
};

/**
 * Klasse fuer einen Container, der die Authentifizierung regelt
 *
 * @export
 * @class AuthContainer
 * @extends {Component}
 */
export default class AuthContainer extends Component<Props, State> {
	/**
	 * Creates an instance of AuthContainer.
	 * @param {Object} props Die Properties der Klasse
	 * @memberof AuthContainer
	 */
	constructor(props: Object) {
		super(props);

		const url: URLSearchParams = new URLSearchParams(window.location.search);

		const target: string = url.get('target');
		const uuid: string = url.get('uuid');
		let view: string = this.views.LOGIN;

		if (target === this.views.RESET) {
			view = this.views.RESET;
		}

		if (target === this.views.NEWUSER) {
			view = this.views.NEWUSER;
		}

		if (target === this.views.PREVIEW) {
			view = this.views.PREVIEW;
		}

		this.state = {
			view,
			login: false,
			uuid,
			forname: '',
			surname: '',
			mail: '',
		};
	}

	views: Object = {
		LOGIN: 'login',
		REQUEST: 'request',
		RESET: 'reset',
		NEWUSER: 'newuser',
		PREVIEW: 'preview',
	};

	/**
	 * React Lifecycle Methode
	 * @param {Object} nextProps Die naechsten Properties
	 * @memberof AuthContainer
	 */
	UNSAFE_componentWillReceiveProps(nextProps: Object): void {
		const { _loginReset } = this.props;

		if (nextProps.resetLogin) {
			this.setState({
				login: false,
			});
			_loginReset();
		}
	}

	/**
	 * React Lifecylce Methode
	 *
	 * @memberof AuthContainer
	 */
	componentDidMount(): void {
		const { uuid, view } = this.state;

		if (uuid && view === this.views.NEWUSER) {
			this.requestUnlock();
		}
		if (uuid && view === this.views.PREVIEW) {
			this.showPreview();
		}
	}

	/**
	 * Fuehrt einen Anmeldeversuch durch
	 * @param  {String} username Der Benutzername
	 * @param  {String} password Das Passwort
	 */
	login = (username: string, password: string): void => {
		this.props._login(username, password);
		this.setState({ login: true });
	};

	/**
	 * Fuehrt einen Anmeldeversuch als Vorschau fuer den Dienstleister durch
	 */
	showPreview = (): void => {
		const { _message } = this.props;
		const { uuid } = this.state;

		post(PATH_PREVIEW, {
			uuid: uuid,
		})
			.then((result: Object) => {
				if (result.data.success) {
					this.props._loginPreview(result.data);
					this.setState({ login: true });
				}
			})
			.catch((error: Object) => {
				_message({
					text: 'messages.loginFailed',
					severity: TOASTIFY_SEVERITY.ERROR,
					position: toast.POSITION.TOP_CENTER,
				});
			});

		const url: Object = new URL(window.location);
		window.history.pushState({}, document.title, `${url.protocol}//${url.host}`);
	};

	/**
	 * Fuehrt das Stellen einer Anfrage zum Zuruecksetzen des Passworts durch
	 * @param {String} mail Der Benutzername
	 * @memberof AuthContainer
	 */
	requestPassword = (mail: string): void => {
		const { _message } = this.props;
		const payload: Object = {
			mail,
		};

		post(PATH_FORGOT_PASSWORD, payload)
			.then((result: Object) => {
				if (result.data.success) {
					_message({
						text: 'messages.keySend',
						severity: TOASTIFY_SEVERITY.SUCCESS,
						position: toast.POSITION.TOP_CENTER,
					});
					this.setState({
						view: this.views.RESET,
					});
				} else {
					_message({
						text: 'messages.keyNotSend',
						severity: TOASTIFY_SEVERITY.WARNING,
						position: toast.POSITION.TOP_CENTER,
					});
				}
			})
			.catch((error: Object) => {
				_message({
					text: 'messages.unknown',
					severity: TOASTIFY_SEVERITY.ERROR,
					position: toast.POSITION.TOP_CENTER,
				});
			});
	};

	/**
	 * Fuehrt das Zuruecksetzen des Passworts durch
	 * @param {String} key Der Key aus der E-Mail
	 * @param {String} firstPassword Das neue Passwort
	 * @param {String} secondPassword Die Bestaetigung des Passworts
	 * @memberof AuthContainer
	 */
	resetPassword = (key: string, firstPassword: string, secondPassword: string): void => {
		const { _message } = this.props;
		const payload = {
			key,
			firstPassword,
			secondPassword,
		};

		post(PATH_RESET_PASSWORD, payload)
			.then((result: Object) => {
				if (result.data.success) {
					_message({
						text: 'messages.passwordChanged',
						severity: TOASTIFY_SEVERITY.SUCCESS,
						position: toast.POSITION.TOP_CENTER,
					});
					this.setState({
						view: this.views.LOGIN,
					});

					const url: Object = new URL(window.location);
					window.history.pushState({}, document.title, `${url.protocol}//${url.host}`);
				}
			})
			.catch((error: Object) => {
				dispatchIf(error, BAD_REQUEST, TYPES.VALIDATION_FAILED, _message, {
					text: 'messages.validFailed',
					severity: TOASTIFY_SEVERITY.ERROR,
					position: toast.POSITION.TOP_CENTER,
				});
				dispatchIf(error, BAD_REQUEST, TYPES.USER_INCORRECT, _message, {
					text: 'messages.keyNotSend',
					severity: TOASTIFY_SEVERITY.ERROR,
					position: toast.POSITION.TOP_CENTER,
				});
				dispatchIf(error, BAD_REQUEST, TYPES.KEY_INCORRECT, _message, {
					text: 'messages.keyFalse',
					severity: TOASTIFY_SEVERITY.ERROR,
					position: toast.POSITION.TOP_CENTER,
				});
				dispatchIf(error, INTERNAL_SERVER_ERROR, TYPES.UNKNOWN, _message, {
					text: 'messages.unknown',
					severity: TOASTIFY_SEVERITY.ERROR,
					position: toast.POSITION.TOP_CENTER,
				});
			});
	};

	/**
	 * Holt die Information fuer die Freischaltung von der API
	 *
	 * @memberof AuthContainer
	 */
	requestUnlock = (): void => {
		const { _message } = this.props;
		const { uuid } = this.state;

		get(`${PATH_UNLOCK}/${uuid}`)
			.then((result: Object) => {
				if (result.data.success) {
					this.setState({
						forname: result.data.forname,
						surname: result.data.surname,
						mail: result.data.mail,
					});
				}
			})
			.catch((error: Object) => {
				dispatchIf(error, NOT_FOUND, TYPES.KEY_INCORRECT, _message, {
					text: 'messages.keyFalse',
					severity: TOASTIFY_SEVERITY.ERROR,
					position: toast.POSITION.TOP_CENTER,
				});
				dispatchIf(error, BAD_REQUEST, TYPES.NO_KEY_GIVEN, _message, {
					text: 'messages.keyFalse',
					severity: TOASTIFY_SEVERITY.ERROR,
					position: toast.POSITION.TOP_CENTER,
				});
				dispatchIf(error, NOT_FOUND, TYPES.USER_NOT_FOUND, _message, {
					text: 'messages.keyNotSend',
					severity: TOASTIFY_SEVERITY.ERROR,
					position: toast.POSITION.TOP_CENTER,
				});
				dispatchIf(error, INTERNAL_SERVER_ERROR, TYPES.UNKNOWN, _message, {
					text: 'messages.unknown',
					severity: TOASTIFY_SEVERITY.ERROR,
					position: toast.POSITION.TOP_CENTER,
				});
			});
	};

	/**
	 * Versucht den Account freizuschalten
	 *
	 * @param {string} firstPassword Das Passwort
	 * @param {string} secondPassword Das Passwort zum Vergleich
	 * @memberof AuthContainer
	 *
	 */
	unlockAccount = (firstPassword: string, secondPassword: string): void => {
		const { _message } = this.props;
		const { uuid } = this.state;
		const payload = {
			firstPassword,
			secondPassword,
		};

		post(`${PATH_UNLOCK}/${uuid}`, payload)
			.then((result: Object) => {
				if (result.data.success) {
					_message({
						text: 'messages.userUnlocked',
						severity: TOASTIFY_SEVERITY.SUCCESS,
						position: toast.POSITION.TOP_CENTER,
					});
					this.setState({
						view: this.views.LOGIN,
					});

					const url: Object = new URL(window.location);
					window.history.pushState({}, document.title, `${url.protocol}//${url.host}`);
					this.login(this.state.mail, firstPassword);
				}
			})
			.catch((error: Object) => {
				dispatchIf(error, BAD_REQUEST, TYPES.VALIDATION_FAILED, _message, {
					text: 'messages.validFailed',
					severity: TOASTIFY_SEVERITY.ERROR,
					position: toast.POSITION.TOP_CENTER,
				});
				dispatchIf(error, NOT_FOUND, TYPES.KEY_INCORRECT, _message, {
					text: 'messages.keyFalse',
					severity: TOASTIFY_SEVERITY.ERROR,
					position: toast.POSITION.TOP_CENTER,
				});
				dispatchIf(error, BAD_REQUEST, TYPES.NO_KEY_GIVEN, _message, {
					text: 'messages.keyFalse',
					severity: TOASTIFY_SEVERITY.ERROR,
					position: toast.POSITION.TOP_CENTER,
				});
				dispatchIf(error, NOT_FOUND, TYPES.USER_NOT_FOUND, _message, {
					text: 'messages.keyNotSend',
					severity: TOASTIFY_SEVERITY.ERROR,
					position: toast.POSITION.TOP_CENTER,
				});
				dispatchIf(error, INTERNAL_SERVER_ERROR, TYPES.UNKNOWN, _message, {
					text: 'messages.unknown',
					severity: TOASTIFY_SEVERITY.ERROR,
					position: toast.POSITION.TOP_CENTER,
				});
			});
	};

	/**
	 * Setzt den View auf REQUESt
	 * @memberof AuthContainer
	 */
	request = (): void => {
		this.setState({
			view: this.views.REQUEST,
			login: false,
		});
	};

	/**
	 * Setzt den View auf LOGIN
	 * @memberof AuthContainer
	 */
	abort = (): void => {
		this.setState({
			view: this.views.LOGIN,
			login: false,
		});
	};

	/**
	 * Rendert die Anmeldemaske
	 * @return {JSX} Die Anmeldemaske
	 */
	renderLogin(): React$Element<any> {
		const { login } = this.state;
		const username: string = translateString('common.mail');
		const password: string = translateString('common.password');

		return (
			<Login
				title={translateComp('common.login', 'Anmelden')}
				username={username}
				password={password}
				button={translateComp('common.login', 'Anmelden')}
				passwordButton={translateComp('common.forgotPassword', 'Passwort vergessen')}
				callback={this.login}
				passwordCallback={this.request}
				loader={login}
			></Login>
		);
	}

	/**
	 * Rendert die Maske fuer das Anfragen eines Passwort zuruecksetzens
	 * @memberof AuthContainer
	 * @return {JSX}
	 */
	renderRequestPassword(): React$Element<any> {
		const username: string = translateString('common.mail');

		return (
			<RequestPassword
				title={translateComp('common.forgotPassword', 'Passwort vergessen')}
				username={username}
				button={translateComp('common.send', 'Absenden')}
				abort={this.abort}
				abortButton={translateComp('common.abort', 'Abbrechen')}
				callback={this.requestPassword}
			></RequestPassword>
		);
	}

	renderResetPassword(): React$Element<any> {
		const password: string = translateString('common.password');
		const confirmPassword: string = translateString('common.confirmPassword');
		const keyField: string = translateString('common.key');
		const { uuid } = this.state;

		return (
			<ResetPassword
				title={translateComp('common.resetPassword', 'Passwort zurücksetzen')}
				newPassword={password}
				confirm={confirmPassword}
				keyField={keyField}
				button={translateComp('common.send', 'Absenden')}
				abort={this.abort}
				abortButton={translateComp('common.abort', 'Abbrechen')}
				callback={this.resetPassword}
				uuid={uuid}
			></ResetPassword>
		);
	}

	/**
	 * Rendert die Maske fuer die Freischaltung eines Benutzers
	 *
	 * @returns {React$Element<any>}
	 * @memberof AuthContainer
	 */
	renderNewUser(): React$Element<any> {
		const { forname, surname, mail } = this.state;

		const password: string = translateString('common.password');
		const confirmPassword: string = translateString('common.confirmPassword');
		const intro = (
			<Fragment>
				<p>
					{translateString('common.unlockUserIntroHead', {
						forname,
						surname,
					})}
				</p>
				<p>
					{translateString('common.unlockUserIntro', {
						mail,
					})}
				</p>
			</Fragment>
		);

		return (
			<UserUnlock
				title={translateComp('common.unlockUser', 'Benutzer freischalten')}
				password={password}
				confirm={confirmPassword}
				button={translateComp('common.send', 'Absenden')}
				abort={this.abort}
				abortButton={translateComp('common.abort', 'Abbrechen')}
				callback={this.unlockAccount}
				intro={intro}
			></UserUnlock>
		);
	}

	/**
	 * Rendert den Container
	 * @memberof AuthContainer
	 * @return {JSX}
	 */
	render() {
		const { view } = this.state;

		let display = null;

        //only renderLogin() is active
		switch (view) {
			case this.views.LOGIN:
				display = this.renderLogin();
				break;
			case this.views.REQUEST:
				display = this.renderRequestPassword();
				break;
			case this.views.RESET:
				display = this.renderResetPassword();
				break;
			case this.views.NEWUSER:
				display = this.renderNewUser();
				break;
			default:
				display = this.renderLogin();
				break;
		}

		return (
			<Container fluid>
				<Row>
					<Col sm={{span: 8, offset: 2}} md={{span: 6, offset: 3}} lg={{span: 4, offset: 4}} className="login">
						{display}
					</Col>
				</Row>
			</Container>
		);
	}
}

AuthContainer.propTypes = {
	_loginReset: PropTypes.func,
	_login: PropTypes.func,
	_message: PropTypes.func,
	_loginPreview: PropTypes.func,
	resetLogin: PropTypes.bool,
};
