// @flow
import React from 'react';
import {Title} from "../../misc/flowTypes";
import CellRenderer from '../../builder/CellRenderer';

import '../../css/form.css';

type FormColumnOptions = {
    id: string,
    title: Title,
    accessor: string,
    renderer?: string,
    cellFunc?: (Object) => mixed
}

/**
 * Hilfsklasse fuer eine Spalte einer Tabelle auf dem Formular
 *
 * @export
 * @class FormColumn
 */
export default class FormColumnHelper {
    id: string;
    title: Title;
    accessor: string;
    renderer: ?string;
    cellFunc: (Object) => mixed;

    /**
     * Creates an instance of FormColumn.
     * @param {FormColumnOptions} options 
     * @memberof FormColumn
     */
    constructor(options: FormColumnOptions) {
        this.id = options.id;
        this.title = options.title;
        this.accessor = options.accessor;
        this.renderer = options.renderer;
        if (options.cellFunc) {
            this.cellFunc = options.cellFunc;
        }
    }

    Header = () => (
        CellRenderer.render(
            'transColumnRenderer',
            {
                key: this.title.key,
                fallback: this.title.fallback
            }
        )
    )

    Cell = (row: Object) => {
        if (this.cellFunc) {
            return this.cellFunc(row);
        }
        else if (this.renderer) {
            return CellRenderer.render(
                this.renderer,
                {
                    type: row.original[this.accessor]
                }                
            );
        }
        else {
            return <div>{row.value}</div>;
        }
    }
}