// @flow
import React from 'react';
import ReactTable from 'react-table';
import {Trans} from 'react-i18next';
import FormColumnHelper from '../helper/FormColumnHelper';


type Props = {
    columns: Array<FormColumnHelper>,
    rows: Array<Object>
}

/**
 * Erzeugt eine Tabelle
 * @param {Array<Column>} columns Spalten der Tabelle
 * @param {Array<Object>} rows Zeilen der Tabelle
 */
const Table = ({columns, rows}: Props) => (
    <ReactTable 
        columns={columns}
        data={rows}
        className="-striped -highlight"
        showPagination={false}
        defaultPageSize={rows.length}
        noDataText={
            <Trans i18nKey="common.noDataText">keine Daten vorhanden</Trans>
        }
    />
);

export default Table;