import ObjectListColumnHelper from '../../helper/ObjectListColumnHelper';
import {OBJECTLIST_RENDERER_MODE} from '../../../misc/const';
import {returnIfHasProperty, returnIfHasPropertyDeepCheck} from '../../../misc/utils';

const objectListTabHelperObjectMasterData = {
    id: 'general',
    title: {
        key: 'common.masterData',
        fallback: 'Stammdaten'
    },
    sort: true,
    columns: [
        new ObjectListColumnHelper({
            id: "general.Abdeckplatte",
            title: {
                key: 'isybauxml.object.manhole.layout.coverPlate',
                fallback: 'Abdeckplatte'
            },
            types: {
                manhole: true
            },
            accessor: "Abdeckplatte",                    
            columnType: OBJECTLIST_RENDERER_MODE.CHECKBOX
        }),
        new ObjectListColumnHelper({
            id: 'general.Abdeckungsklasse',
            title: {
                key: 'isybauxml.object.manhole.covering.classificationCover',
                fallback: 'Abdeckungsklasse'
            },
            types: {
                manhole: true
            },
            accessor: 'Abdeckungsklasse',
            duoField: true
        }),
        new ObjectListColumnHelper({
            id: 'general.Abwasserart',
            title: {
                key: 'isybauxml.object.common.wastewaterType',
                fallback: 'Abwasserart'
            },
            types: {
                section: true,
                lateral: true,
                manhole: true
            },
            accessor: 'Abwasserart',
            duoField: true
        }),
        new ObjectListColumnHelper({
            id: 'general.AbwasserartWGS',
            title: {
                key: 'isybauxml.object.common.sewageSubstances',
                fallback: 'AbwasserartWGS'
            },
            types: {
                section: true,
                lateral: true,
                manhole: true
            },
            accessor: 'AbwasserartWGS',
            duoField: true
        }),
        new ObjectListColumnHelper({
            id: 'general.AlteObjektbezeichnung',
            title: {
                key: 'common.oldName',
                fallback: 'Alte Objektbezeichnung'
            },
            types: {
                section: true,
                lateral: true,
                manhole: true
            },
            accessor: 'AlteObjektbezeichnung'
        }),
        new ObjectListColumnHelper({
            id: 'general.AnschlussImHauptkanal',
            title: {
                key: 'object.connectionmaincanal',
                fallback: 'Anschluss im Hauptkanal'
            },
            types: {                        
                lateral: true
            },
            accessor: 'Objektbezeichner'
        }),
        new ObjectListColumnHelper({
            id: 'general.AnzahlAnschluesse',
            title: {
                key: 'isybauxml.object.manhole.other.numberConnections',
                fallback: 'Anzahl Anschlüsse'
            },
            types: {
                manhole: true
            },
            accessor: 'AnzahlAnschluesse'
        }),
        new ObjectListColumnHelper({
            id: 'general.AnzahlAuflageringe',
            title: {
                key: 'isybauxml.object.manhole.covering.numberRings',
                fallback: 'Anzahl Auflageringe'
            },
            types: {
                manhole: true
            },
            accessor: 'AnzahlAuflageringe'
        }),
        new ObjectListColumnHelper({
            id: 'general.AnzahlDeckel',
            title: {
                key: 'isybauxml.object.manhole.other.numberCovers',
                fallback: 'Anzahl Deckel'
            },
            types: {
                manhole: true
            },
            accessor: 'AnzahlDeckel'
        }),
        new ObjectListColumnHelper({
            id: 'general.ArtAuskleidung"',
            title: {
                key: 'isybauxml.object.common.typeCoating',
                fallback: 'Art Auskleidung'
            },
            types: {
                section: true,
                lateral: true,
                manhole: true
            },
            accessor: 'ArtAuskleidung',
            duoField: true
        }),
        new ObjectListColumnHelper({
            id: 'general.ArtEinstieghilfe',
            title: {
                key: 'isybauxml.object.manhole.other.typeClimbingAid',
                fallback: 'Art Einstieghilfe'
            },
            types: {
                manhole: true
            },
            accessor: 'ArtEinstieghilfe',
            duoField: true,                    
            columnType: OBJECTLIST_RENDERER_MODE.CHECKBOX
        }),
        new ObjectListColumnHelper({
            id: 'general.Auskleidung',
            title: {
                key: 'object.coating',
                fallback: 'Auskleidung'
            },
            types: {
                section: true,
                lateral: true
            },
            accessor: 'Auskleidung',
            duoField: true
        }),
        new ObjectListColumnHelper({
            id: 'general.Baujahr',
            title: {
                key: 'object.constructionYear',
                fallback: 'Baujahr'
            },
            types: {
                section: true,
                lateral: true,
                manhole: true
            },
            accessor: 'Baujahr'
        }),
        new ObjectListColumnHelper({
            id: 'general.Bodenart',
            title: {
                key: 'isybauxml.object.common.soilType',
                fallback: 'Bodenart'
            },
            types: {
                section: true,
                lateral: true,
                manhole: true
            },
            accessor: 'Bodenart',
            duoField: true
        }),
        new ObjectListColumnHelper({
            id: 'general.BreiteAufbau',
            title: {
                key: 'isybauxml.object.manhole.layout.layoutWidth',
                fallback: 'Aufbaubreite'
            },
            types: {
                manhole: true
            },
            accessor: 'BreiteAufbau'
        }),
        new ObjectListColumnHelper({
            id: 'general.BreiteDeckel',
            title: {
                key: 'object.coverWidth',
                fallback: 'Deckelbreite'
            },
            types: {
                manhole: true
            },
            accessor: 'BreiteDeckel'
        }),
        new ObjectListColumnHelper({
            id: 'general.BreiteUnten',
            title: {
                key: 'isybauxml.object.manhole.chamber.chamberWidth',
                fallback: 'Untere Breite'
            },
            types: {
                manhole: true
            },
            accessor: 'BreiteUnten'
        }),
        new ObjectListColumnHelper({
            id: 'general.BreiteUnterteil',
            title: {
                key: 'isybauxml.object.manhole.bottomPart.bottomPartWidth',
                fallback: 'Unterteilbreite'
            },
            types: {
                manhole: true
            },
            accessor: 'BreiteUnterteil'
        }),
        new ObjectListColumnHelper({
            id: 'general.Deckelform',
            title: {
                key: 'object.coverShape',
                fallback: 'Deckelform'
            },
            types: {
                manhole: true
            },
            accessor: 'Deckelform',
            duoField: true
        }),
        new ObjectListColumnHelper({
            id: 'general.Deckelhöhe',
            title: {
                key: 'object.coverHeight',
                fallback: 'Deckelhöhe'
            },
            types: {
                manhole: true
            },
            accessor: 'ISYBAU-XML_S_DeckelHoehe'
        }),
        new ObjectListColumnHelper({
            id: 'general.Deckeltyp',
            title: {
                key: 'isybauxml.object.manhole.covering.coverType',
                fallback: 'Deckeltyp'
            },
            types: {
                manhole: true
            },
            accessor: 'Deckeltyp',
            duoField: true
        }),
        new ObjectListColumnHelper({
            id: 'general.DMPLaenge',
            title: {
                key: 'isybauxml.object.section.other.lengthCenterCover',
                fallback: 'DMP-Länge'
            },
            types: {
                section: true
            },
            accessor: 'DMPLaenge'
        }),
        new ObjectListColumnHelper({
            id: 'general.Druckverfahren',
            title: {
                key: 'isybauxml.object.common.pressurizingMethod',
                fallback: 'Druckverfahren'
            },
            types: {
                section: true,
                lateral: true
            },
            accessor: 'Druckverfahren',
            duoField: true
        }),
        new ObjectListColumnHelper({
            id: 'general.Einstieghilfe',
            title: {
                key: 'isybauxml.object.manhole.other.entranceAid',
                fallback: 'Einstieghilfe'
            },
            types: {
                manhole: true
            },
            accessor: 'Einstieghilfe',                    
            columnType: OBJECTLIST_RENDERER_MODE.CHECKBOX
        }),
        new ObjectListColumnHelper({
            id: 'general.Entwaesserungsart',
            title: {
                key: 'isybauxml.object.common.sewerSystem',
                fallback: 'Kanalsystem'
            },
            types: {
                section: true,
                lateral: true,
                manhole: true
            },
            accessor: 'Entwaesserungsart',
            duoField: true
        }),
        new ObjectListColumnHelper({
            id: 'general.FormAufbau',
            title: {
                key: 'isybauxml.object.manhole.layout.layoutForm',
                fallback: 'Aufbauform'
            },
            types: {
                manhole: true
            },
            accessor: 'Aufbauform',
            duoField: true
        }),
        new ObjectListColumnHelper({
            id: 'general.Funktion',
            title: {
                key: 'common.function',
                fallback: 'Funktion'
            },
            types: {
                section: true,
                lateral: true,
                manhole: true
            },
            accessor: (d: Object) => returnIfHasProperty(d, ['HaltungsFunktion', 'LeitungsFunktion', 'Schachtfunktion']),
            duoField: true
        }),
        new ObjectListColumnHelper({
            id: 'general.Gerinneform',
            title: {
                key: 'object.channelShape',
                fallback: 'Gerinneform'
            },
            types: {
                manhole: true
            },
            accessor: 'Gerinneform',
            duoField: true
        }),
        new ObjectListColumnHelper({
            id: 'general.Grundwasserabstand',
            title: {
                key: 'object.groundwaterDistance',
                fallback: 'Grundwasserabstand'
            },
            types: {
                section: true,
                lateral: true,
                manhole: true
            },
            accessor: 'GWabstand',
            duoField: true
        }),
        new ObjectListColumnHelper({
            id: 'general.HerkunftMaterial',
            title: {
                key: 'isybauxml.object.common.materialOrigin',
                fallback: 'Herkunft des Materials'
            },
            types: {
                section: true,
                lateral: true
            },
            accessor: 'HerkunftMaterial',
            duoField: true
        }),
        new ObjectListColumnHelper({
            id: 'general.HerkunftProfilmasse',
            title: {
                key: 'isybauxml.object.common.dimensionOrigin',
                fallback: 'Herkunft der Profilmaße'
            },
            types: {
                section: true,
                lateral: true
            },
            accessor: 'HerkunftProfilmasse',
            duoField: true
        }),
        new ObjectListColumnHelper({
            id: 'general.HoeheAufbau',
            title: {
                key: 'isybauxml.object.manhole.layout.layoutHeight',
                fallback: 'Aufbauhöhe'
            },
            types: {
                manhole: true
            },
            accessor: 'HoeheAufbau'
        }),
        new ObjectListColumnHelper({
            id: 'general.HoeheAuflageringe',
            title: {
                key: 'isybauxml.object.manhole.covering.ringsHeight',
                fallback: 'Höhe der Auflageringe'
            },
            types: {
                manhole: true
            },
            accessor: 'HoeheAuflageringe'
        }),
        new ObjectListColumnHelper({
            id: 'general.HoeheUnten',
            title: {
                key: 'isybauxml.object.manhole.chamber.chamberHeight',
                fallback: 'Untere Höhe'
            },
            types: {
                manhole: true
            },
            accessor: 'HoeheUnten'
        }),
        new ObjectListColumnHelper({
            id: 'general.HoeheUnterteil',
            title: {
                key: 'isybauxml.object.manhole.bottomPart.bottomPartHeight',
                fallback: 'Unterteilhöhe'
            },
            types: {
                manhole: true
            },
            accessor: 'HoeheUnterteil'
        }),
        new ObjectListColumnHelper({
            id: 'general.Innenschutz',
            title: {
                key: 'object.interiorProtection',
                fallback: 'Innenschutz'
            },
            types: {
                section: true,
                lateral: true,
                manhole: true
            },
            accessor: 'Innenschutz',
            duoField: true
        }),
        new ObjectListColumnHelper({
            id: 'general.Kommentar',
            title: {
                key: 'common.comment',
                fallback: 'Kommentar'
            },
            types: {
                section: true,
                lateral: true,
                manhole: true
            },
            accessor: 'Kommentar'
        }),
        new ObjectListColumnHelper({
            id: 'general.KommentarLage',
            title: {
                key: 'isybauxml.object.common.layerComment',
                fallback: 'Kommentar Lage'
            },
            types: {
                section: true,
                lateral: true,
                manhole: true
            },
            accessor: 'KommentarLage'
        }),
        new ObjectListColumnHelper({
            id: 'general.KonusSchachtaufbau',
            title: {
                key: 'isybauxml.object.manhole.layout.layoutTaper',
                fallback: 'Konus Schachtaufbau'
            },
            types: {
                manhole: true
            },
            accessor: 'Konus Schachtaufbau',                    
            columnType: OBJECTLIST_RENDERER_MODE.CHECKBOX
        }),
        new ObjectListColumnHelper({
            id: 'general.KonusUntererSchachtzone',
            title: {
                key: 'isybauxml.object.manhole.chamber.chamberTaper',
                fallback: 'Konus unterer Schachtzone'
            },
            types: {
                manhole: true
            },
            accessor: 'Konus unterer Schachtzone',
            columnType: OBJECTLIST_RENDERER_MODE.CHECKBOX
        }),
        new ObjectListColumnHelper({
            id: 'general.LageOberflaeche',
            title: {
                key: 'common.terrainType',
                fallback: 'Lage im Verkehr'
            },
            types: {
                section: true,
                lateral: true,
                manhole: true
            },
            accessor: 'LageOberflaeche',
            duoField: true
        }),
        new ObjectListColumnHelper({
            id: 'general.Laenge',
            title: {
                key: 'common.length',
                fallback: 'Länge'
            },
            types: {
                section: true,
                lateral: true
            },
            accessor: 'Laenge'
        }),
        new ObjectListColumnHelper({
            id: 'general.LaengeDeckel',
            title: {
                key: 'object.coverLength',
                fallback: 'Deckellänge'
            },
            types: {
                manhole: true
            },
            accessor: 'LaengeDeckel'
        }),
        new ObjectListColumnHelper({
            id: 'general.LaengeUnten',
            title: {
                key: 'isybauxml.object.manhole.chamber.chamberLength',
                fallback: 'Länge unten'
            },
            types: {
                manhole: true
            },
            accessor: 'LaengeUnten'
        }),
        new ObjectListColumnHelper({
            id: 'general.LaengeUnterteil',
            title: {
                key: 'isybauxml.object.manhole.bottomPart.bottomPartLength',
                fallback: 'Unterteillänge'
            },
            types: {
                manhole: true
            },
            accessor: 'LaengeUnterteil'
        }),
        new ObjectListColumnHelper({
            id: 'general.LaengeAufbau',
            title: {
                key: 'isybauxml.object.manhole.layout.layoutLength',
                fallback: 'Aufbaulänge'
            },
            types: {
                manhole: true
            },
            accessor: 'LaengeAufbau'
        }),
        new ObjectListColumnHelper({
            id: 'general.LISA-GUID',
            title: {
                key: 'isybauxml.object.common.lisaGUID',
                fallback: 'LISA-GUID'
            },
            types: {
                section: true,
                lateral: true,
                manhole: true
            },
            accessor: 'LISA-GUID'
        }),
        new ObjectListColumnHelper({
            id: 'general.Material',
            title: {
                key: 'object.material',
                fallback: 'Material'
            },
            types: {
                section: true,
                lateral: true,
                manhole: true
            },
            accessor: 'Material',
            duoField: true
        }),
        new ObjectListColumnHelper({
            id: 'general.MaterialAbdeckung',
            title: {
                key: 'object.coveringMaterial',
                fallback: 'Abdeckungsmaterial'
            },
            types: {
                manhole: true
            },
            accessor: 'MaterialAbdeckung',
            duoField: true
        }),
        new ObjectListColumnHelper({
            id: 'general.MaterialAufbau',
            title: {
                key: 'isybauxml.object.manhole.layout.layoutMaterial',
                fallback: 'Aufbaumaterial'
            },
            types: {
                manhole: true
            },
            accessor: 'MaterialAufbau',
            duoField: true
        }),
        new ObjectListColumnHelper({
            id: 'general.MaterialAuskleidung',
            title: {
                key: 'isybauxml.object.common.coatMaterial',
                fallback: 'Auskl. Material'
            },
            types: {
                section: true,
                lateral: true
            },
            accessor: 'MaterialAuskleidung',
            duoField: true
        }),
        new ObjectListColumnHelper({
            id: 'general.MaterialGerinne',
            title: {
                key: 'object.invertMaterial',
                fallback: 'Gerinnematerial'
            },
            types: {
                manhole: true
            },
            accessor: 'MaterialGerinne',
            duoField: true
        }),
        new ObjectListColumnHelper({
            id: 'general.MaterialSteighilfen',
            title: {
                key: 'isybauxml.object.manhole.other.climbingAidMaterial',
                fallback: 'Material der Steighilfen'
            },
            types: {
                manhole: true
            },
            accessor: 'MaterialSteighilfen',
            duoField: true
        }),
        new ObjectListColumnHelper({
            id: 'general.MaterialUnten',
            title: {
                key: 'isybauxml.object.manhole.chamber.chamberMaterial',
                fallback: 'Unteres Material'
            },
            types: {
                manhole: true
            },
            accessor: 'MaterialUnten',
            duoField: true
        }),
        new ObjectListColumnHelper({
            id: 'general.MaterialUnterteil',
            title: {
                key: 'isybauxml.object.manhole.bottomPart.bottomPartMaterial',
                fallback: 'Material des Unterteils'
            },
            types: {
                manhole: true
            },
            accessor: 'MaterialUnterteil',
            duoField: true
        }),
        new ObjectListColumnHelper({
            id: 'general.Nenndruck',
            title: {
                key: 'isybauxml.object.common.nominalPositivePressure',
                fallback: 'Nenndruck'
            },
            types: {
                section: true,
                lateral: true
            },
            accessor: 'Nenndruck'
        }),
        new ObjectListColumnHelper({
            id: 'general.OberePKBezeichnung',
            title: {
                key: 'object.upperPkName',
                fallback: 'Obere PK-Bezeichnung'
            },
            types: {
                lateral: true
            },
            accessor: 'ObererAnschlusspunkt.NameAnschlusspunkt'
        }),
        new ObjectListColumnHelper({
            id: 'general.ObereSohlhoehe',
            title: {
                key: 'object.upperInvertLevel',
                fallback: 'Obere Sohlhöhe'
            },
            types: {
                section: true,
                lateral: true
            },
            accessor: (d: Object) => returnIfHasPropertyDeepCheck(d, 
                [
                    [
                        'ObererSchacht',
                        'fields',
                        'Sohlhoehe'
                    ],
                    [
                        'ObererAnschlusspunkt',
                        'fields',
                        'Sohlhoehe'
                    ]
                ]
            ),                    
            columnType: OBJECTLIST_RENDERER_MODE.CHECKBOX
        }),
        new ObjectListColumnHelper({
            id: 'general.OberesBezugsobjekt',
            title: {
                key: 'object.upperObject',
                fallback: 'Oberes Bezugsobjekt'
            },
            types: {
                section: true,
                lateral: true
            },
            accessor: (d: Object) => returnIfHasPropertyDeepCheck(d, 
                [
                    [
                        'ObererSchacht',
                        'fields',
                        'Schachtbezeichner'
                    ], 
                    [
                        'ObererAnschlusspunkt',
                        'fields',
                        'Objektbezeichner'
                    ]
                ]
            ),                    
            columnType: OBJECTLIST_RENDERER_MODE.CHECKBOX
        }),
        new ObjectListColumnHelper({
            id: 'general.Ortsteil',
            title: {
                key: 'common.district',
                fallback: 'Ortsteil'
            },
            types: {
                section: true,
                lateral: true,
                manhole: true
            },
            accessor: 'Ortsteil',
            duoField: true
        }),
        new ObjectListColumnHelper({
            id: 'general.Podest',
            title: {
                key: 'isybauxml.object.manhole.chamber.landing',
                fallback: 'Podest'
            },
            types: {
                manhole: true
            },
            accessor: 'Podest',
            columnType: OBJECTLIST_RENDERER_MODE.CHECKBOX
        }),
        new ObjectListColumnHelper({
            id: 'general.Profilart',
            title: {
                key: 'object.profileType',
                fallback: 'Profilart'
            },
            types: {
                section: true,
                lateral: true
            },
            accessor: 'Profilart',
            duoField: true
        }),
        new ObjectListColumnHelper({
            id: 'general.Profilbreite',
            title: {
                key: 'object.profileWidth',
                fallback: 'Profilbreite'
            },
            types: {
                section: true,
                lateral: true
            },
            accessor: 'Profilbreite'
        }),
        new ObjectListColumnHelper({
            id: 'general.Profilhoehe',
            title: {
                key: 'object.profileHeight',
                fallback: 'Profilhöhe'
            },
            types: {
                section: true,
                lateral: true
            },
            accessor: 'Profilhoehe'
        }),
        new ObjectListColumnHelper({
            id: 'general.ProfilID',
            title: {
                key: 'isybauxml.object.common.profileID',
                fallback: 'ProfilID'
            },
            types: {
                section: true,
                lateral: true
            },
            accessor: 'ProfilID'
        }),
        new ObjectListColumnHelper({
            id: 'general.Punktkennung_Oben',
            title: {
                key: 'object.upperPointCode',
                fallback: 'Obere Punktkennung'
            },
            types: {
                lateral: true
            },
            accessor: 'ObererAnschlusspunkt.Punktkennung_Oben',
            duoField: true
        }),
        new ObjectListColumnHelper({
            id: 'general.Punktkennung_Unten',
            title: {
                key: 'object.lowerPointCode',
                fallback: 'Untere Punktkennung'
            },
            types: {
                lateral: true
            },
            accessor: 'UntererAnschlusspunkt.Punktkennung_Unten',
            duoField: true
        }),
        new ObjectListColumnHelper({
            id: 'general.RohrLaenge',
            title: {
                key: 'object.pipeLength',
                fallback: 'Rohrlänge'
            },
            types: {
                section: true
            },
            accessor: 'RohrLaenge'
        }),
        new ObjectListColumnHelper({
            id: 'general.Schachtsohlhoehe',
            title: {
                key: 'object.manholeInvertLevel',
                fallback: 'Schachtsohlhöhe'
            },
            types: {
                manhole: true
            },
            accessor: 'ISYBAU-XML_M_Sohlhoehe'
        }),
        new ObjectListColumnHelper({
            id: 'general.Schachttiefe',
            title: {
                key: 'object.manholeDepth',
                fallback: 'Schachttiefe'
            },
            types: {
                manhole: true
            },
            accessor: 'Schachttiefe'
        }),
        new ObjectListColumnHelper({
            id: 'general.Schmutzfaenger',
            title: {
                key: 'isybauxml.object.manhole.covering.dirtTrap',
                fallback: 'Schmutzfänger'
            },
            types: {
                manhole: true
            },
            accessor: 'Schmutzfaenger',                    
            columnType: OBJECTLIST_RENDERER_MODE.CHECKBOX
        }),
        new ObjectListColumnHelper({
            id: 'general.SonderprofilVorhanden',
            title: {
                key: 'isybauxml.object.common.specialProfile',
                fallback: 'Sonderprofilvorhanden'
            },
            types: {
                section: true,
                lateral: true
            },
            accessor: 'Sonderprofilvorhanden',
            columnType: OBJECTLIST_RENDERER_MODE.CHECKBOX
        }),
        new ObjectListColumnHelper({
            id: 'general.Status',
            title: {
                key: 'isybauxml.object.common.status',
                fallback: 'Status'
            },
            types: {
                section: true,
                lateral: true,
                manhole: true
            },
            accessor: 'Status',
            duoField: true
        }),
        new ObjectListColumnHelper({
            id: '"general.Strang',
            title: {
                key: 'isybauxml.object.common.strang',
                fallback: 'Strang'
            },
            types: {
                section: true,
                lateral: true,
                manhole: true
            },
            accessor: 'Strang'
        }),
        new ObjectListColumnHelper({
            id: 'general.Strassenname',
            title: {
                key: 'common.street',
                fallback: 'Straßenname'
            },
            types: {
                section: true,
                lateral: true,
                manhole: true
            },
            accessor: 'Strassenname',
            duoField: true
        }),
        new ObjectListColumnHelper({
            id: 'general.Uebergabeschacht',
            title: {
                key: 'isybauxml.object.manhole.other.transferManhole',
                fallback: 'Übergabeschacht'
            },
            types: {
                manhole: true
            },
            accessor: 'Uebergabeschacht',
            columnType: OBJECTLIST_RENDERER_MODE.CHECKBOX
        }),
        new ObjectListColumnHelper({
            id: 'general.Uebergangsplatte',
            title: {
                key: 'isybauxml.object.manhole.chamber.reducingSlab',
                fallback: 'Übergangsplatte'
            },
            types: {
                manhole: true
            },
            accessor: 'Uebergangsplatte',
            columnType: OBJECTLIST_RENDERER_MODE.CHECKBOX
        }),
        new ObjectListColumnHelper({
            id: 'general.Ueberschwemmungsgebiet',
            title: {
                key: 'object.floodingArea',
                fallback: 'Überschwemmungsgebiet'
            },
            types: {
                section: true,
                lateral: true,
                manhole: true
            },
            accessor: 'Ueberschwemmungsgebiet',
            columnType: OBJECTLIST_RENDERER_MODE.CHECKBOX
        }),
        new ObjectListColumnHelper({
            id: 'general.UnterePKBezeichnung',
            title: {
                key: 'object.lowerPkName',
                fallback: 'Untere PK-Bezeichnung'
            },
            types: {
                lateral: true
            },
            accessor: 'UntererAnschlusspunkt.NameAnschlusspunkt'
        }),
        new ObjectListColumnHelper({
            id: 'general.UntereSchachtzoneform',
            title: {
                key: 'isybauxml.object.manhole.chamber.chamberForm',
                fallback: 'Untere Schachtzonenform'
            },
            types: {
                manhole: true
            },
            accessor: 'UntereSchachtzoneform',
            duoField: true
        }),
        new ObjectListColumnHelper({
            id: 'general.UntereSohlhoehe',
            title: {
                key: 'object.lowerInvertLevel',
                fallback: 'Untere Sohlhöhe'
            },
            types: {
                section: true,
                lateral: true
            },
            accessor: (d: Object) => returnIfHasPropertyDeepCheck(d, 
                [
                    [
                        'UntererAnschlusspunkt',
                        'fields',
                        'Sohlhoehe'
                    ], 
                    [
                        'UntererSchacht',
                        'fields',
                        'Sohlhoehe'
                    ]
                ]
            ),                    
            columnType: OBJECTLIST_RENDERER_MODE.CHECKBOX
        }),
        new ObjectListColumnHelper({
            id: 'general.UnteresBezugsobjekt',
            title: {
                key: 'object.lowerObject',
                fallback: 'Unteres Bezugsobjekt'
            },
            types: {
                section: true,
                lateral: true
            },
            accessor: (d: Object) => returnIfHasPropertyDeepCheck(d, 
                [
                    [
                        'UntererSchacht',
                        'fields',
                        'Schachtbezeichner'
                    ], 
                    [
                        'UntererAnschlusspunkt',
                        'fields',
                        'Objektbezeichner'
                    ]
                ]
            ),                    
            columnType: OBJECTLIST_RENDERER_MODE.CHECKBOX
        }),
        new ObjectListColumnHelper({
            id: 'general.Unterteilform',
            title: {
                key: 'isybauxml.object.manhole.bottomPart.bottomPartForm',
                fallback: 'Unterteilform'
            },
            types: {
                manhole: true
            },
            accessor: 'Unterteilform',
            duoField: true
        }),
        new ObjectListColumnHelper({
            id: 'general.Wasserschutzzone',
            title: {
                key: 'object.waterConservationArea',
                fallback: 'Wasserschutzzone'
            },
            types: {
                section: true,
                lateral: true,
                manhole: true
            },
            accessor: 'Wasserschutzzone',
            duoField: true
        })
    ]
};

export default objectListTabHelperObjectMasterData;
