// @flow
import React from 'react';
import { connect } from 'react-redux';
import { NavItem } from 'react-bootstrap';

import { objectOpened } from '../../../actions/viewActions';
import { OBJECT_LEVEL_VIEWS } from '../../../misc/const';
import { getImageSmall, getName } from '../../../misc/utils';
import general from '../../../templates/General';

type Props = {
	currentStation: Object,
	currentObject: Object,
	objectOpened: (Object, string) => mixed,
};

/**
 * Erzeugt einen Eintrag in der Navigation um auf die Station zu gelangen
 * @param {Object} currentObject Das aktuell gewaehlte Objekt
 * @param {Object} currentStation Die aktuell gewaehlte Station
 * @param {Function} objectOpened Redux Action um das Objekt auszuwaehlen
 */
const StationBreadcrumbItem = ({ currentObject, currentStation, objectOpened }: Props) =>
	currentObject && currentStation ? (
		<NavItem key="station" onClick={() => objectOpened(currentObject, OBJECT_LEVEL_VIEWS.MAIN.STATION_MAIN_VIEW)}>
			{getImageSmall('station')}
			&nbsp;{getName(currentStation, general.stationname)}
		</NavItem>
	) : null;

/**
 *  Verbindet die Klasse mit dem Redux Store
 */
const ReduxStationBreadcrumbItem = connect(
	(state) => {
		return {
			currentStation: state.currentStation,
			currentObject: state.currentObject,
		};
	},
	{
		objectOpened,
	}
)(StationBreadcrumbItem);

export default ReduxStationBreadcrumbItem;
