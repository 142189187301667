// @flow
/**
 * Typen fuer Http Antworten
 * @type {Number}
 */
export const UNKNOWN: number = 0;
export const USER_NOT_FOUND: number = 1;
export const USER_NOT_ACTIVE: number = 2;
export const WRONG_PASSWORD: number = 3;
export const NO_USER_OR_PASSWORD: number = 4;
export const NO_USERNAME: number = 5;
export const USER_INCORRECT: number = 6;
export const KEY_INCORRECT: number = 7;
export const TOKEN_INVALID: number = 8;
export const VALIDATION_FAILED: number = 9;
export const TOKEN_EXPIRED_RES: number = 10;
export const DUPLICATE_KEY: number = 11;
export const TOKEN_NOT_FOUND: number = 12;
export const SETUP_NEEDED_RES: number = 13;
export const SETUP_KEY_NOT_VALID: number = 14;
export const SETUP_MISSING_FIELDS: number = 15;
export const USER_ID_NOT_GIVEN: number = 16;
export const ACCESS_FORBIDDEN: number = 17;
export const NO_PASSWORD: number = 18;
export const PASSWORD_NO_MATCH: number = 19;
export const CONTRACTOR_NOT_FOUND: number = 20;
export const CONTRACTOR_ID_NOT_GIVEN: number = 21;
export const NO_KEY_GIVEN: number = 35;
export const LICENSE_INVALID: number = 45;
