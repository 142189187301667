// @flow
import React from 'react';
import {Form} from 'react-bootstrap';
import {translateString} from '../../i18n/utils';

import '../../css/form.css';

type Props = {
    checked: boolean
}

/**
 * Erzeugt eine Checkbox in Form eines Buttons
 * @param {boolean} checked Ob die Checkbox gechecked ist
 */
const FormCheckbox = ({checked}:Props) => {
    let text = '';
    let styleClasses = '';

    if (checked.text === 'Ja') {
        text = translateString('common.yes', 'Ja');
    } else if (checked.text === 'Nein') {
        text = translateString('common.no', 'Nein');
    }

    return (
        <Form.Control
            type='text'
            value={text}
            readOnly
            className={styleClasses}
        />
    );
};

export default FormCheckbox;