// @flow
import React, {Fragment} from 'react';
import {Button, ListGroupItem} from 'react-bootstrap';
import {Title} from '../../misc/flowTypes';
import CellRenderer from '../../builder/CellRenderer';
import {OBJECTLIST_RENDERER_MODE, OBJECTLIST_COLUMN_MODE} from '../../misc/const';
import { isEmpty } from '../../misc/validationHelper';
import { getImageSmall } from '../../misc/utils';

import '../../css/objectlist.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';

type ColumnType = {
    section?: boolean,
    lateral?: boolean,
    manhole?: boolean
}

type ObjectListColumnOptions = {
    id: string,
    types: ColumnType,
    title?: Title,
    show?: boolean,
    accessor: string | (Object) => mixed,
    duoField?: boolean,
    columnType?: string,
    headerFunc?: () => mixed
}

/**
 * Hilfklasse fuer Spalten der Objektliste in einer Schnittstelle
 *
 * @export
 * @class ObjectListColumnHelper
 */
export default class ObjectListColumnHelper {    
    id: string;
    _id: string;
    types: ColumnType;
    title: Title;
    show: boolean;
    accessor: string | (Object) => mixed;
    duoField: boolean;
    _columnType: string = OBJECTLIST_RENDERER_MODE.TEXT;
    default: boolean;
    text: boolean;
    code: boolean;
    mode: string = 'default';
    width: string;

    /**
     * Creates an instance of ObjectListColumnHelper.
     * @param {ObjectListColumnOptions} options Die Optionen der Spalte
     * @memberof ObjectListColumnHelper
     */
    constructor(options: ObjectListColumnOptions) {
        this.id = options.id;
        this.types = options.types;
        if (options.title) {
            this.title = options.title;
        }
        if (options.show) {
            this.show = options.show;
        }        
        this.accessor = options.accessor;
        if (options.duoField) {
            this.duoField = options.duoField;
        }        
        this.columnType = options.columnType;

        if (options.headerFunc) {
            // $FlowFixMe
            this.headerFunc = options.headerFunc;
        }
    }

    /**
     * Gibt die gesetzten Typen als React Image Elemente zurueck
     *
     * @returns {Array<React$Element<any>>}
     * @memberof ObjectListColumnHelper
     */
    getTypesAsImage():Array<?React$Element<any>> {
        let type : Array<?React$Element<any>> = [];

        if (this.types.section) {
            type.push(getImageSmall('section', 0));
        }

        if (this.types.lateral) {
            type.push(getImageSmall('lateral', 1));
        }

        if (this.types.manhole) {
            type.push(getImageSmall('manhole', 2));
        }
        
        return type;
    }

    set columnType(type?: string) {
        if (type && !isEmpty(type)) {
            this._columnType = type;
        }
    }

    get columnType():string {
        return this._columnType;
    }

    /**
     * Funktion fuer den Kopf der Spalte
     *
     * @returns {React$Element<any>}
     * @memberof ObjectListColumnHelper
     */
    headerFunc():React$Element<any> {
        return CellRenderer.render(
            'transColumnRenderer',
            {
                key: this.title.key,
                fallback: this.title.fallback,
                parent: 'span'
            }
        )
    }

    /**
     * Callback fuer React Table fuer den Kopf der Spalte
     *
     * @memberof ObjectListColumnHelper
     */
    Header = () => (
        this.headerFunc()
    );

    /**
     * Callback fuer React Table fuer den Inhalt der Spalte
     *
     * @memberof ObjectListColumnHelper
     */
    Cell = (row: Object) => (
        CellRenderer.render(
            'objectListColumnRenderer',
            {
                row,
                mode: this.columnType
            }
        )
    );
    
    /**
     * Baut ein Listeneintrag fuer die Konfiguration
     *
     * @param {(Object, string) => mixed} onClick Klick auf Auswahl
     * @param {number} index Index des Eintrags
     * @returns {React$Element<any>}
     * @memberof ObjectListColumnHelper
     */
    build(onClick: (Object, string) => mixed, index: number):React$Element<any> {
        const remove = <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>;
        const ok = <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>;

        let button = 
            <Button 
                className="single-settings-btn" 
                onClick={() => onClick(this, OBJECTLIST_COLUMN_MODE.DEFAULT)} 
            >
                {this.default ? ok : remove}
            </Button>;

        if (this.duoField) {
            button = 
            <Fragment>
                <Button
                    className="duo-settings-btn"
                    onClick={() => onClick(this, OBJECTLIST_COLUMN_MODE.CODE)}
                >
                    {this.code ? ok : remove}
                </Button>
                <Button
                    className="duo-settings-btn"
                    onClick={() => onClick(this, OBJECTLIST_COLUMN_MODE.TEXT)}
                >
                    {this.text ? ok : remove}
                </Button>
            </Fragment>;
        }

        const header = this.Header();
        const type = 
            <span className="objectlist-right">
                {this.getTypesAsImage()}
            </span>;
    

        return (
            <ListGroupItem key={index} className="larger-list">
                {header}
                {button}
                {type}
            </ListGroupItem>
        );
    }

}