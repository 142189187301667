// @flow
import React from 'react';
import {Popover, OverlayTrigger, Form} from 'react-bootstrap';

type Props = {
    value?: any,
    inputSize: number
}

/**
 * Erzeugt eine Eingabefeld
 * @param {any} value Wert des Feldes
 * @param {number} inputSize Groesse des Feldes
 */
const TextBox = ({value, inputSize}:Props) => {
    const popover: React$Element<any> = (
        <Popover id="popover">
            <strong>{value}</strong>
        </Popover>
    );

    return (
        <OverlayTrigger
            rootClose
            trigger="click"
            placement="top"
            overlay={popover}
        >


            <Form.Control
                type="text"
                value={value}
                readOnly
            />
        </OverlayTrigger>
    )

}

export default TextBox;