import CardHelper from '../../../form/helper/CardHelper';
import FieldHelper from '../../../form/helper/FieldHelper';
import ComplexTextBoxHelper from '../../../form/helper/ComplexTextBoxHelper';
import CheckboxHelper from '../../../form/helper/CheckboxHelper';
import TabCardHelper from '../../../form/helper/TabCardHelper';
import TabHelper from '../../../form/helper/TabHelper';
import TableHelper from '../../../form/helper/TableHelper';
import FormColumnHelper from '../../../form/helper/FormColumnHelper';

const canalObjectManholeArray = [
    new CardHelper({
        id: 'general',
        title: {
            key: 'common.general',
            fallback: 'Allgemein'
        },
        items: [
            new FieldHelper({
                id: 'manholeName',
                title: {
                    key: 'object.manholeName',
                    fallback: 'Schachtname'
                },
                accessor: 'Schachtbezeichner'
            }),
            new FieldHelper({
                id: 'constructionYear',
                title: {
                    key: 'object.constructionYear',
                    fallback: 'Baujahr'
                },
                accessor: 'Baujahr'
            }),
            new FieldHelper({
                id: 'manholeDepth',
                title: {
                    key: 'object.manholeDepth',
                    fallback: 'Schachttiefe'
                },
                accessor: 'Schachttiefe'
            }),
            new FieldHelper({
                id: 'status',
                title: {
                    key: 'isybauxml.object.common.status',
                    fallback: 'Status'
                },
                accessor: 'Status',
                field: new ComplexTextBoxHelper()
            }),
            new FieldHelper({
                id: 'sewerSystem',
                title: {
                    key: 'isybauxml.object.common.sewerSystem',
                    fallback: 'Kanalsystem'
                },
                accessor: 'Entwaesserungsart',
                field: new ComplexTextBoxHelper()
            }),
            new FieldHelper({
                id: 'comment',
                title: {
                    key: 'common.comment',
                    fallback: 'Kommentar'
                },
                accessor: 'Kommentar'
            })
        ]
    }),
    new CardHelper({
        id: 'position',
        title: {
            key: 'isybauxml.object.common.layer',
            fallback: 'Lage'
        },
        items: [
            new FieldHelper({
                id: 'district',
                title: {
                    key: 'common.district',
                    fallback: 'Ortsteil'
                },
                accessor: 'Ortsteil',
                field: new ComplexTextBoxHelper()
            }),
            new FieldHelper({
                id: 'terrainType',
                title: {
                    key: 'common.terrainType',
                    fallback: 'Lage im Verkehr'
                },
                accessor: 'LageOberflaeche',
                field: new ComplexTextBoxHelper()
            }),
            new FieldHelper({
                id: 'street',
                title: {
                    key: 'common.street',
                    fallback: 'Straße'
                },
                accessor: 'Strassenname',
                field: new ComplexTextBoxHelper()
            }),
            new FieldHelper({
                id: 'floodingArea',
                title: {
                    key: 'object.floodingArea',
                    fallback: 'Überschwemmungsgebiet'
                },
                accessor: 'Ueberschwemmungsgebiet',
                field: new CheckboxHelper()
            }),
            new FieldHelper({
                id: 'posComment',
                title: {
                    key: 'common.comment',
                    fallback: 'Kommentar'
                },
                accessor: 'KommentarLage',
                columnSize: {
                    lg: 12,
                    md: 12
                },
                size: {
                    lg: 2,
                    md: 2
                }
            })
        ]
    }),
    new CardHelper({
        id: 'geodata',
        title: {
            key: 'object.geodata',
            fallback: 'Geodaten'
        },
        items: [
            new FieldHelper({
                id: 'xCoordinateCover',
                title: {
                    key: 'object.xCoordinateCover',
                    fallback: 'Rechtswert Deckel'
                },
                accessor: 'ISYBAU-XML_S_DeckelRechts'
            }),
            new FieldHelper({
                id: 'xCoordinate',
                title: {
                    key: 'object.xCoordinate',
                    fallback: 'Rechtswert'
                },
                accessor: 'ISYBAU-XML_M_Rechtswert'
            }),
            new FieldHelper({
                id: 'yCoordinateCover',
                title: {
                    key: 'object.yCoordinateCover',
                    fallback: 'Hochwert Deckel'
                },
                accessor: 'ISYBAU-XML_S_DeckelHoch'
            }),
            new FieldHelper({
                id: 'yCoordinate',
                title: {
                    key: 'object.yCoordinate',
                    fallback: 'Hochwert'
                },
                accessor: 'ISYBAU-XML_M_Hochwert'
            }),
            new FieldHelper({
                id: 'coverHeight',
                title: {
                    key: 'object.coverHeight',
                    fallback: 'Deckelhöhe'
                },
                accessor: 'ISYBAU-XML_S_DeckelHoehe'
            }),
            new FieldHelper({
                id: 'invertLevel',
                title: {
                    key: 'object.invertLevel',
                    fallback: 'Sohlhöhe'
                },
                accessor: 'Sohlhoehe'
            })
        ]
    }),
    new CardHelper({
        id: 'tabs',
        items: [
            new TabCardHelper({
                id: 'manholeTabs',
                defaultActiveKey: 'covering',
                items: [
                    new TabHelper({
                        id: 'covering',
                        title: {
                            key: 'object.covering',
                            fallback: 'Abdeckung'
                        },
                        eventKey: 'covering',
                        items: [
                            new FieldHelper({
                                id: 'coverType',
                                title: {
                                    key: 'isybauxml.object.manhole.covering.coverType',
                                    fallback: 'Deckeltyp'
                                },
                                accessor: 'Deckeltyp',
                                field: new ComplexTextBoxHelper()
                            }),
                            new FieldHelper({
                                id: 'classificationCover',
                                title: {
                                    key: 'isybauxml.object.manhole.covering.classificationCover',
                                    fallback: 'Abdeckungsklasse'
                                },
                                accessor: 'Abdeckungsklasse',
                                field: new ComplexTextBoxHelper()
                            }),
                            new FieldHelper({
                                id: 'coverShape',
                                title: {
                                    key: 'object.coverShape',
                                    fallback: 'Deckelform'
                                },
                                accessor: 'Deckelform'
                            }),
                            new FieldHelper({
                                id: 'dirtTrap',
                                title: {
                                    key: 'isybauxml.object.manhole.covering.dirtTrap',
                                    fallback: 'Schmutzfänger'
                                },
                                accessor: 'Schmutzfaenger',
                                field: new CheckboxHelper()
                            }),
                            new FieldHelper({
                                id: 'ringsHeight',
                                title: {
                                    key: 'isybauxml.object.manhole.covering.ringsHeight',
                                    fallback: 'Höhe der Auflageringe'
                                },
                                accessor: 'HoeheAuflageringe'
                            }),
                            new FieldHelper({
                                id: 'numberRings',
                                title: {
                                    key: 'isybauxml.object.manhole.covering.numberRings',
                                    fallback: 'Anzahl der Auflageringe'
                                },
                                accessor: 'AnzahlAuflageringe'
                            }),
                            new FieldHelper({
                                id: 'coverLength',
                                title: {
                                    key: 'object.coverLength',
                                    fallback: 'Deckellänge'
                                },
                                accessor: 'LaengeDeckel'
                            }),
                            new FieldHelper({
                                id: 'material',
                                title: {
                                    key: 'object.material',
                                    fallback: 'Material'
                                },
                                accessor: 'MaterialAbdeckung',
                                field: new ComplexTextBoxHelper()
                            }),
                            new FieldHelper({
                                id: 'coverWidth',
                                title: {
                                    key: 'object.coverWidth',
                                    fallback: 'Deckelbreite'
                                },
                                accessor: 'BreiteDeckel'
                            }),
                            new FieldHelper({
                                id: 'lisaGUID',
                                title: {
                                    key: 'isybauxml.object.common.lisaGUID',
                                    fallback: 'LISA-GUID'
                                },
                                accessor: 'LISA-GUID'
                            }),
                        ]
                    }),
                    new TabHelper({
                        id: 'header',
                        title: {
                            key: 'isybauxml.object.manhole.layout.header',
                            fallback: 'Aufbau'
                        },
                        eventKey: 'header',
                        items: [
                            new FieldHelper({
                                id: 'form',
                                title: {
                                    key: 'common.form',
                                    fallback: 'Form'
                                },
                                accessor: 'Aufbauform',
                                field: new ComplexTextBoxHelper()
                            }),
                            new FieldHelper({
                                id: 'coverPlate',
                                title: {
                                    key: 'isybauxml.object.manhole.layout.coverPlate',
                                    fallback: 'Abdeckplatte'
                                },
                                accessor: 'Abdeckplatte',
                                field: new CheckboxHelper()
                            }),
                            new FieldHelper({
                                id: 'length',
                                title: {
                                    key: 'common.length',
                                    fallback: 'Länge'
                                },
                                accessor: 'LaengeAufbau'
                            }),
                            new FieldHelper({
                                id: 'taper',
                                title: {
                                    key: 'isybauxml.object.common.taper',
                                    fallback: 'Konus'
                                },
                                accessor: 'Konus Schachtaufbau',
                                field: new CheckboxHelper()
                            }),
                            new FieldHelper({
                                id: 'width',
                                title: {
                                    key: 'common.width',
                                    fallback: 'Breite'
                                },
                                accessor: 'BreiteAufbau'
                            }),
                            new FieldHelper({
                                id: 'material',
                                title: {
                                    key: 'object.material',
                                    fallback: 'Material'
                                },
                                accessor: 'MaterialAufbau',
                                field: new ComplexTextBoxHelper()
                            }),
                            new FieldHelper({
                                id: 'height',
                                title: {
                                    key: 'common.height',
                                    fallback: 'Höhe'
                                },
                                accessor: 'HoeheAufbau'
                            })
                        ]
                    }),
                    new TabHelper({
                        id: 'chamberHeader',
                        title: {
                            key: 'isybauxml.object.manhole.chamber.header',
                            fallback: 'Untere Schachtzone'
                        },
                        eventKey: 'chamberHeader',
                        items: [
                            new FieldHelper({
                                id: 'form',
                                title: {
                                    key: 'common.form',
                                    fallback: 'Form'
                                },
                                accessor: 'UntereSchachtzoneform',
                                field: new ComplexTextBoxHelper()
                            }),
                            new FieldHelper({
                                id: 'reducingSlab',
                                title: {
                                    key: 'isybauxml.object.manhole.chamber.reducingSlab',
                                    fallback: 'Übergangsplatte'
                                },
                                accessor: 'Uebergangsplatte',
                                field: new CheckboxHelper()
                            }),
                            new FieldHelper({
                                id: 'length',
                                title: {
                                    key: 'common.length',
                                    fallback: 'Länge'
                                },
                                accessor: 'LaengeUnten'
                            }),
                            new FieldHelper({
                                id: 'taper',
                                title: {
                                    key: 'isybauxml.object.common.taper',
                                    fallback: 'Konus'
                                },
                                accessor: 'Konus unterer Schachtzone',
                                field: new CheckboxHelper()
                            }),
                            new FieldHelper({
                                id: 'width',
                                title: {
                                    key: 'common.width',
                                    fallback: 'Breite'
                                },
                                accessor: 'BreiteUnten'
                            }),
                            new FieldHelper({
                                id: 'landing',
                                title: {
                                    key: 'isybauxml.object.manhole.chamber.landing',
                                    fallback: 'Podest'
                                },
                                accessor: 'Podest',
                                field: new CheckboxHelper()
                            }),
                            new FieldHelper({
                                id: 'height',
                                title: {
                                    key: 'common.height',
                                    fallback: 'Höhe'
                                },
                                accessor: 'HoeheUnten'
                            }),
                            new FieldHelper({
                                id: 'material',
                                title: {
                                    key: 'object.material',
                                    fallback: 'Material'
                                },
                                accessor: 'MaterialUnten',
                                field: new ComplexTextBoxHelper()
                            })
                        ]
                    }),
                    new TabHelper({
                        id: 'bottomPart',
                        title: {
                            key: 'object.bottomPart',
                            fallback: 'Unterteil'
                        },
                        eventKey: 'bottomPart',
                        items: [
                            new FieldHelper({
                                id: 'form',
                                title: {
                                    key: 'common.form',
                                    fallback: 'Form'
                                },
                                accessor: 'Unterteilform',
                                field: new ComplexTextBoxHelper()
                            }),
                            new FieldHelper({
                                id: 'channelShape',
                                title: {
                                    key: 'object.channelShape',
                                    fallback: 'Gerinneform'
                                },
                                accessor: 'Gerinneform',
                                field: new ComplexTextBoxHelper()
                            }),
                            new FieldHelper({
                                id: 'length',
                                title: {
                                    key: 'common.length',
                                    fallback: 'Länge'
                                },
                                accessor: 'LaengeUnterteil'
                            }),
                            new FieldHelper({
                                id: 'invertMaterial',
                                title: {
                                    key: 'object.invertMaterial',
                                    fallback: 'Gerinnematerial'
                                },
                                accessor: 'MaterialGerinne',
                                field: new ComplexTextBoxHelper()
                            }),
                            new FieldHelper({
                                id: 'width',
                                title: {
                                    key: 'common.width',
                                    fallback: 'Breite'
                                },
                                accessor: 'BreiteUnterteil'
                            }),
                            new FieldHelper({
                                id: 'material',
                                title: {
                                    key: 'object.material',
                                    fallback: 'Material'
                                },
                                accessor: 'MaterialUnterteil',
                                field: new ComplexTextBoxHelper()
                            }),
                            new FieldHelper({
                                id: 'height',
                                title: {
                                    key: 'common.height',
                                    fallback: 'Höhe'
                                },
                                accessor: 'HoeheUnterteil'
                            })
                        ]
                    })
                ]
            })
        ]
    }),
    new CardHelper({
        id: 'connected',
        title: {
            key: 'object.connectedObjects',
            fallback: 'Angeschlossene Objekte'
        },
        items: [
            new TableHelper({
                id: 'connectedObjects',
                accessor: 'connectedObjects',
                items: [
                    new FormColumnHelper({
                        id: 'typ',
                        title: {
                            key: 'object.typ',
                            fallback: 'Typ'
                        },
                        accessor: 'type',
                        renderer: 'imgRenderer'
                    }),
                    new FormColumnHelper({
                        id: 'name',
                        title: {
                            key: 'common.name',
                            fallback: 'Name'
                        },
                        accessor: 'name'
                    }),
                    new FormColumnHelper({
                        id: 'direction',
                        title: {
                            key: 'object.direction',
                            fallback: 'Richtung'
                        },
                        accessor: 'direction',
                        renderer: 'formTableIconDirectionRenderer'
                    }),
                    new FormColumnHelper({
                        id: 'baseAltitude',
                        title: {
                            key: 'object.baseAltitude',
                            fallback: 'Sohlhöhe [mNN]'
                        },
                        accessor: 'Sohlhoehe'
                    }),
                    new FormColumnHelper({
                        id: 'profilWidthTable',
                        title: {
                            key: 'object.profileWidthTable',
                            fallback: 'Profilbreite [mm]'
                        },
                        accessor: 'Profilbreite'
                    }),
                    new FormColumnHelper({
                        id: 'profileHeightTable',
                        title: {
                            key: 'object.profileHeightTable',
                            fallback: 'Profilhöhe [mm]'
                        },
                        accessor: 'Profilhoehe'
                    })
                ]
            })
        ]
    }),
    new CardHelper({
        id: 'envparams',
        title: {
            key: 'object.environmentalParameters',
            fallback: 'Umweltparameter'
        },
        items: [
            new FieldHelper({
                id: 'wastewaterType',
                title: {
                    key: 'isybauxml.object.common.wastewaterType',
                    fallback: 'Abwasserart'
                },
                accessor: 'Abwasserart',
                field: new ComplexTextBoxHelper()
            }),
            new FieldHelper({
                id: 'waterConservationArea',
                title: {
                    key: 'object.waterConservationArea',
                    fallback: 'Wasserschutzzone'
                },
                accessor: 'Wasserschutzzone',
                field: new ComplexTextBoxHelper()
            }),
            new FieldHelper({
                id: 'sewageSubstances',
                title: {
                    key: 'isybauxml.object.common.sewageSubstances',
                    fallback: 'AbwasserartWGS'
                },
                accessor: 'AbwasserartWGS',
                field: new ComplexTextBoxHelper()
            }),
            new FieldHelper({
                id: 'groundwaterDistance',
                title: {
                    key: 'object.groundwaterDistance',
                    fallback: 'Grundwasserabstand'
                },
                accessor: 'GWabstand',
                field: new ComplexTextBoxHelper()
            }),
            new FieldHelper({
                id: 'soilType',
                title: {
                    key: 'isybauxml.object.common.soilType',
                    fallback: 'Bodenart'
                },
                accessor: 'Bodenart',
                field: new ComplexTextBoxHelper()
            })
        ]
    }),
    new CardHelper({
        id: 'sanitation',
        onlyWithValues: true,
        title: {
            key: 'isybauxml.object.common.rehabilitation',
            fallback: 'Sanierung'
        },
        items: [
            new FieldHelper({
                id: 'interiorProtection',
                title: {
                    key: 'object.interiorProtection',
                    fallback: 'Innenschutz'
                },
                accessor: 'Innenschutz',
                field: new ComplexTextBoxHelper()
            }),
            new FieldHelper({
                id: 'typeCoating',
                title: {
                    key: 'isybauxml.object.common.typeCoating',
                    fallback: 'Art der Auskleidung'
                },
                accessor: 'ArtAuskleidung',
                field: new ComplexTextBoxHelper()
            })
        ]
    }),
    new CardHelper({
        id: 'misc',
        title: {
            key: 'common.other',
            fallback: 'Sonstiges'
        },
        items: [
            new FieldHelper({
                id: 'oldManholeName',
                title: {
                    key: 'isybauxml.object.manhole.other.oldManholeName',
                    fallback: 'Alter Schachtname'
                },
                accessor: 'AlteObjektbezeichnung'
            }),
            new FieldHelper({
                id: 'entranceAid',
                title: {
                    key: 'isybauxml.object.manhole.other.entranceAid',
                    fallback: 'Einstieghilfe'
                },
                accessor: 'Einstieghilfe',
                field: new CheckboxHelper()
            }),
            new FieldHelper({
                id: 'lisaGUID',
                title: {
                    key: 'isybauxml.object.common.lisaGUID',
                    fallback: 'LISA-GUID'
                },
                accessor: 'LISA-GUID'
            }),
            new FieldHelper({
                id: 'typeClimbingAid',
                title: {
                    key: 'isybauxml.object.manhole.other.typeClimbingAid',
                    fallback: 'Art der Einstieghilfe'
                },
                accessor: 'ArtEinstieghilfe',
                field: new ComplexTextBoxHelper()
            }),
            new FieldHelper({
                id: 'functionManhole',
                title: {
                    key: 'isybauxml.object.manhole.other.functionManhole',
                    fallback: 'Funktion des Schachts'
                },
                accessor: 'Schachtfunktion',
                field: new ComplexTextBoxHelper()
            }),
            new FieldHelper({
                id: 'climbingAidMaterial',
                title: {
                    key: 'isybauxml.object.manhole.other.climbingAidMaterial',
                    fallback: 'Material der Steighilfen'
                },
                accessor: 'MaterialSteighilfen',
                field: new ComplexTextBoxHelper()
            }),
            new FieldHelper({
                id: 'numberCovers',
                title: {
                    key: 'isybauxml.object.manhole.other.numberCovers',
                    fallback: 'Anzahl der Deckel'
                },
                accessor: 'AnzahlDeckel'
            }),
            new FieldHelper({
                id: 'transferManhole',
                title: {
                    key: 'isybauxml.object.manhole.other.transferManhole',
                    fallback: 'Übergabeschacht'
                },
                accessor: 'Uebergabeschacht',
                field: new CheckboxHelper()
            }),
            new FieldHelper({
                id: 'numberConnections',
                title: {
                    key: 'isybauxml.object.manhole.other.numberConnections',
                    fallback: 'Anzahl der Anschlüsse'
                },
                accessor: 'AnzahlAnschluesse'
            }),
            new FieldHelper({
                id: 'strang',
                title: {
                    key: 'isybauxml.object.common.strang',
                    fallback: 'Strang'
                },
                accessor: 'Strang'
            }),
            new FieldHelper({
                id: 'property',
                title: {
                    key: 'isybauxml.object.common.property',
                    fallback: 'Eigentum'
                },
                accessor: 'Eigentum'
            })
        ]
    })
];

export default canalObjectManholeArray;