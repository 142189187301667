// @flow
import {findInspectionValue, returnIfHasProperty, returnIfHasPropertyDeepCheck} from '../../misc/utils';
import {OBJECTLIST_RENDERER_MODE} from '../../misc/const';
import CardHelper from '../../form/helper/CardHelper';
import FieldHelper from '../../form/helper/FieldHelper';
import CheckboxHelper from '../../form/helper/CheckboxHelper';
import CardCardHelper from '../../form/helper/CardCardHelper';
import TabCardHelper from '../../form/helper/TabCardHelper';
import TabHelper from '../../form/helper/TabHelper';
import TableHelper from '../../form/helper/TableHelper';
import ObjectListTabHelper from '../helper/ObjectListTabHelper';
import ObjectListColumnHelper from '../helper/ObjectListColumnHelper';
import FormColumnHelper from '../../form/helper/FormColumnHelper';
import CanalInterface from '../helper/CanalInterface';
import CanalObjects from '../helper/CanalObjects';
import CanalObject from '../helper/CanalObject';
import ComplexTextBoxHelper from '../../form/helper/ComplexTextBoxHelper';

/**
 * Template Beschreibung fuer DWA-M150
 * @type {Object}
 */
// eslint-disable-next-line import/no-anonymous-default-export
export default new CanalInterface({
    version: "2.0",
    name: 'DWA-M150',

    project: [
        new CardHelper({
            id: 'general',
            title: {
                key: 'common.general',
                fallback: 'Allgemein',
            },
            items: [
                new FieldHelper({
                    id: 'projectname',
                    title: {
                        key: 'common.projectname',
                        fallback: 'Projektname'
                    },
                    accessor: 'DefaultNameProject'
                }),
                new FieldHelper({
                    id: 'orderrule',
                    title: {
                        key: 'common.orderrule',
                        fallback: 'Auftragsregel'
                    },
                    accessor: 'FriendlyNameRessource'
                })
            ]
        })
    ],

    objectlist: [
        new ObjectListTabHelper({
            id: 'general',
            title: {
                key: 'common.masterData',
                fallback: 'Stammdaten'
            },
            sort: true,
            columns: [
                new ObjectListColumnHelper({
                    id: 'general.AlternativeBezeichnung',
                    title: {
                        key: 'dwa_m_150.objectlist.altName',
                        fallback: 'Alternative Bezeichnung'
                    },
                    types: {
                        section: true,
                        lateral: true,
                        manhole: true
                    },
                    accessor: (d: Object) => returnIfHasProperty(d, ['DWA-M150_S_KG002', 'DWA-M150_L_HG002', 'DWA-M150_H_HG002'])
                }),
                new ObjectListColumnHelper({
                    id: 'general.AnschlussImHauptkanal',
                    title: {
                        key: 'object.connectionmaincanal',
                        fallback: 'Anschluss im Hauptkanal'
                    },
                    types: {
                        lateral: true
                    },
                    accessor: 'DWA-M150_G_Objektbezeichner'
                }),
                new ObjectListColumnHelper({
                    id: 'general.AnzahlSteigeisen',
                    title: {
                        key: 'dwa_m_150.manhole.countClimbingAid',
                        fallback: 'Anzahl Steigeisen'
                    },
                    types: {
                        manhole: true
                    },
                    accessor: 'DWA-M150_S_KG324'
                }),
                new ObjectListColumnHelper({
                    id: 'general.Baudatum',
                    title: {
                        key: 'dwa_m_150.common.buildDate',
                        fallback: 'Baudatum'
                    },
                    types: {
                        section: true,
                        manhole: true
                    },
                    accessor: (d: Object) => returnIfHasProperty(d, ['DWA-M150_H_HG921_SES', 'DWA-M150_S_KG921_SES'])
                }),
                new ObjectListColumnHelper({
                    id: 'general.Baujahr',
                    title: {
                        key: 'object.constructionYear',
                        fallback: 'Baujahr'
                    },
                    types: {
                        section: true,
                        lateral: true,
                        manhole: true
                    },
                    accessor: (d: Object) => returnIfHasProperty(d, ['DWA-M150_H_HG303', 'DWA-M150_L_HG303', 'DWA-M150_S_KG303'])
                }),
                new ObjectListColumnHelper({
                    id: 'general.Bauwerksart',
                    title: {
                        key: 'dwa_m_150.manhole.buildingType',
                        fallback: 'Bauwerksart'
                    },
                    types: {
                        manhole: true
                    },
                    accessor: 'DWA-M150_S_KG306',
                    duoField: true
                }),
                new ObjectListColumnHelper({
                    id: 'general.Bemerkung',
                    title: {
                        key: 'dwa_m_150.objectlist.remarkGeneral',
                        fallback: 'Bemerkung (Allgemein)'
                    },
                    types: {
                        section: true,
                        lateral: true,
                        manhole: true
                    },
                    accessor: (d: Object) => returnIfHasProperty(d, ['DWA-M150_H_HG999', 'DWA-M150_L_HG999', 'DWA-M150_S_KG999'])
                }),
                new ObjectListColumnHelper({
                    id: 'general.Bermematerial',
                    title: {
                        key: 'dwa_m_150.manhole.bermeMaterial',
                        fallback: 'Bermematerial'
                    },
                    types: {
                        manhole: true
                    },
                    accessor: 'DWA-M150_S_KG320'
                }),
                new ObjectListColumnHelper({
                    id: 'general.Bezugsobjekt',
                    title: {
                        key: 'dwa_m_150.objectlist.object',
                        fallback: 'Bezugsobjekt'
                    },
                    types: {
                        lateral: true
                    },
                    accessor: 'a'
                }),
                new ObjectListColumnHelper({
                    id: 'general.Bodengruppe',
                    title: {
                        key: 'dwa_m_150.common.terrainGroup',
                        fallback: 'Bodengruppe'
                    },
                    types: {
                        section: true,
                        lateral: true,
                        manhole: true
                    },
                    accessor: (d: Object) => returnIfHasProperty(d, ['DWA-M150_H_HG409', 'DWA-M150_L_HG409', 'DWA-M150_S_KG409']),
                    duoField: true
                }),
                new ObjectListColumnHelper({
                    id: 'general.DeckelVerschraubt',
                    title: {
                        key: 'dwa_m_150.manhole.coverScrewed',
                        fallback: 'Deckel verschraubt'
                    },
                    types: {
                        manhole: true
                    },
                    accessor: 'DWA-M150_S_KG315',
                    columnType: OBJECTLIST_RENDERER_MODE.CHECKBOX
                }),
                new ObjectListColumnHelper({
                    id: 'general.Deckelbreite',
                    title: {
                        key: 'object.coverWidth',
                        fallback: 'Deckelbreite'
                    },
                    types: {
                        manhole: true
                    },
                    accessor: 'DWA-M150_S_KG313'
                }),
                new ObjectListColumnHelper({
                    id: 'general.Deckelform',
                    title: {
                        key: 'object.coverShape',
                        fallback: 'Deckelform'
                    },
                    types: {
                        manhole: true
                    },
                    accessor: 'DWA-M150_S_KG310',
                    duoField: true
                }),
                new ObjectListColumnHelper({
                    id: 'general.Deckelhoehe',
                    title: {
                        key: 'object.coverHeight',
                        fallback: 'Deckelhöhe'
                    },
                    types: {
                        manhole: true
                    },
                    accessor: 'DWA-M150_S_KG204'
                }),
                new ObjectListColumnHelper({
                    id: 'general.Deckelklasse',
                    title: {
                        key: 'dwa_m_150.manhole.coverClass',
                        fallback: 'Deckelklasse'
                    },
                    types: {
                        manhole: true
                    },
                    accessor: 'DWA-M150_S_KG312',
                    duoField: true
                }),
                new ObjectListColumnHelper({
                    id: 'general.Deckellaenge',
                    title: {
                        key: 'object.coverLength',
                        fallback: 'Deckellänge'
                    },
                    types: {
                        manhole: true
                    },
                    accessor: 'DWA-M150_S_KG314'
                }),
                new ObjectListColumnHelper({
                    id: 'general.Deckelmaterial',
                    title: {
                        key: 'dwa_m_150.objectlist.coverMaterial',
                        fallback: 'Deckelmaterial'
                    },
                    types: {
                        manhole: true
                    },
                    accessor: 'DWA-M150_S_KG311',
                    duoField: true
                }),
                new ObjectListColumnHelper({
                    id: 'general.Dokument',
                    title: {
                        key: 'dwa_m_150.objectlist.documentGeneral',
                        fallback: 'Dokument (Allgemein)'
                    },
                    types: {
                        section: true,
                        lateral: true,
                        manhole: true
                    },
                    accessor: (d: Object) => returnIfHasProperty(d, ['DWA-M150_H_HG998', 'DWA-M150_L_HG998', 'DWA-M150_S_KG998'])
                }),
                new ObjectListColumnHelper({
                    id: 'general.Eigentum',
                    title: {
                        key: 'dwa_m_150.common.property',
                        fallback: 'Eigentum'
                    },
                    types: {
                        section: true,
                        lateral: true,
                        manhole: true
                    },
                    accessor: (d: Object) => returnIfHasProperty(d, ['DWA-M150_H_HG402', 'DWA-M150_L_HG402', 'DWA-M150_S_KG402']),
                    duoField: true
                }),
                new ObjectListColumnHelper({
                    id: 'general.Einstauhaeufigkeit',
                    title: {
                        key: 'dwa_m_150.common.jamFrequency',
                        fallback: 'Einstauhäufigkeit'
                    },
                    types: {
                        section: true,
                        lateral: true,
                        manhole: true
                    },
                    accessor: (d: Object) => returnIfHasProperty(d, ['DWA-M150_H_HG408', 'DWA-M150_L_HG408', 'DWA-M150_S_KG408']),
                    duoField: true
                }),
                new ObjectListColumnHelper({
                    id: 'general.Einzugsgebietschluessel',
                    title: {
                        key: 'dwa_m_150.common.hinterlandKey',
                        fallback: 'Einzugsgebietschlüssel'
                    },
                    types: {
                        section: true,
                        lateral: true,
                        manhole: true
                    },
                    accessor: (d: Object) => returnIfHasProperty(d, ['DWA-M150_H_HG107', 'DWA-M150_L_HG107', 'DWA-M150_S_KG107']),
                    duoField: true
                }),
                new ObjectListColumnHelper({
                    id: 'general.Funktionszustand',
                    title: {
                        key: 'dwa_m_150.common.function',
                        fallback: 'Funktionszustand'
                    },
                    types: {
                        section: true,
                        lateral: true,
                        manhole: true
                    },
                    accessor: (d: Object) => returnIfHasProperty(d, ['DWA-M150_H_HG401', 'DWA-M150_L_HG401', 'DWA-M150_S_KG401']),
                    duoField: true
                }),
                new ObjectListColumnHelper({
                    id: 'general.Gebietsschluessel',
                    title: {
                        key: 'dwa_m_150.common.locationKey',
                        fallback: 'Gebietsschlüssel'
                    },
                    types: {
                        section: true,
                        lateral: true,
                        manhole: true
                    },
                    accessor: (d: Object) => returnIfHasProperty(d, ['DWA-M150_H_HG106', 'DWA-M150_L_HG106', 'DWA-M150_S_KG106']),
                    duoField: true
                }),
                new ObjectListColumnHelper({
                    id: 'general.Gemeindeschluessel',
                    title: {
                        key: 'dwa_m_150.common.communityKey',
                        fallback: 'Gemeindeschlüssel'
                    },
                    types: {
                        section: true,
                        lateral: true,
                        manhole: true
                    },
                    accessor: (d: Object) => returnIfHasProperty(d, ['DWA-M150_H_HG105', 'DWA-M150_L_HG105', 'DWA-M150_S_KG105']),
                    duoField: true
                }),
                new ObjectListColumnHelper({
                    id: 'general.Gerinnebreite',
                    title: {
                        key: 'dwa_m_150.manhole.channelWidth',
                        fallback: 'Gerinnebreite'
                    },
                    types: {
                        manhole: true
                    },
                    accessor: 'DWA-M150_S_KG318'
                }),
                new ObjectListColumnHelper({
                    id: 'general.Gerinneform',
                    title: {
                        key: 'object.channelShape',
                        fallback: 'Gerinneform'
                    },
                    types: {
                        manhole: true
                    },
                    accessor: 'DWA-M150_S_KG316',
                    duoField: true
                }),
                new ObjectListColumnHelper({
                    id: 'general.Gerinnelaenge',
                    title: {
                        key: 'dwa_m_150.manhole.channelLength',
                        fallback: 'Gerinnelänge'
                    },
                    types: {
                        manhole: true
                    },
                    accessor: 'DWA-M150_S_KG319'
                }),
                new ObjectListColumnHelper({
                    id: 'general.Gerinnematerial',
                    title: {
                        key: 'object.invertMaterial',
                        fallback: 'Gerinnematerial'
                    },
                    types: {
                        manhole: true
                    },
                    accessor: 'DWA-M150_S_KG317',
                    duoField: true
                }),
                new ObjectListColumnHelper({
                    id: 'general.Grundwasserabstand',
                    title: {
                        key: 'object.groundwaterDistance',
                        fallback: 'Grundwasserabstand'
                    },
                    types: {
                        section: true,
                        lateral: true,
                        manhole: true
                    },
                    accessor: (d: Object) => returnIfHasProperty(d, ['DWA-M150_H_HG405', 'DWA-M150_L_HG405', 'DWA-M150_S_KG405']),
                    duoField: true
                }),
                new ObjectListColumnHelper({
                    id: 'general.Innenschutz',
                    title: {
                        key: 'object.interiorProtection',
                        fallback: 'Innenschutz'
                    },
                    types: {
                        manhole: true
                    },
                    accessor: 'DWA-M150_S_KG321',
                    duoField: true
                }),
                new ObjectListColumnHelper({
                    id: 'general.Innenschutzmaterial',
                    title: {
                        key: 'dwa_m_150.manhole.interiorProtectionMaterial',
                        fallback: 'Innenschutzmaterial'
                    },
                    types: {
                        manhole: true
                    },
                    accessor: 'DWA-M150_S_KG322',
                    duoField: true
                }),
                new ObjectListColumnHelper({
                    id: 'general.Kanalart',
                    title: {
                        key: 'dwa_m_150.common.sewerType',
                        fallback: 'Kanalart'
                    },
                    types: {
                        section: true,
                        lateral: true,
                        manhole: true
                    },
                    accessor: (d: Object) => returnIfHasProperty(d, ['DWA-M150_H_HG301', 'DWA-M150_L_HG301', 'DWA-M150_S_KG301']),
                    duoField: true
                }),
                new ObjectListColumnHelper({
                    id: 'general.Kanalnutzung',
                    title: {
                        key: 'dwa_m_150.common.sewerUsage',
                        fallback: 'Kanalnutzung'
                    },
                    types: {
                        section: true,
                        lateral: true,
                        manhole: true
                    },
                    accessor: (d: Object) => returnIfHasProperty(d, ['DWA-M150_H_HG302', 'DWA-M150_L_HG302', 'DWA-M150_S_KG302']),
                    duoField: true
                }),
                new ObjectListColumnHelper({
                    id: 'general.Kantenart',
                    title: {
                        key: 'dwa_m_150.objectlist.edgeType',
                        fallback: 'Kantenart'
                    },
                    types: {
                        section: true,
                        lateral: true
                    },
                    accessor: (d: Object) => returnIfHasProperty(d, ['DWA-M150_H_HG313', 'DWA-M150_L_HG313']),
                    duoField: true
                }),
                new ObjectListColumnHelper({
                    id: 'general.Kantengefaelle',
                    title: {
                        key: 'dwa_m_150.objectlist.edgeFall',
                        fallback: 'Kantengefälle'
                    },
                    types: {
                        section: true,
                        lateral: true
                    },
                    accessor: (d: Object) => returnIfHasProperty(d, ['DWA-M150_H_HG311', 'DWA-M150_L_HG311'])
                }),
                new ObjectListColumnHelper({
                    id: 'general.Kantenlaenge',
                    title: {
                        key: 'dwa_m_150.objectlist.edgeLength',
                        fallback: 'Kantenlänge'
                    },
                    types: {
                        section: true,
                        lateral: true
                    },
                    accessor: (d: Object) => returnIfHasProperty(d, ['DWA-M150_H_HG310', 'DWA-M150_L_HG310'])
                }),
                new ObjectListColumnHelper({
                    id: 'general.Knotenart',
                    title: {
                        key: 'dwa_m_150.objectlist.nodeType',
                        fallback: 'Knotenart'
                    },
                    types: {
                        manhole: true
                    },
                    accessor: 'DWA-M150_S_KG305',
                    duoField: true
                }),
                new ObjectListColumnHelper({
                    id: 'general.LageImVerkehrsraum',
                    title: {
                        key: 'common.terrainType',
                        fallback: 'Lage im Verkehrsraum'
                    },
                    types: {
                        section: true,
                        lateral: true,
                        manhole: true
                    },
                    accessor: (d: Object) => returnIfHasProperty(d, ['DWA-M150_H_HG404', 'DWA-M150_L_HG404', 'DWA-M150_S_KG404']),
                    duoField: true
                }),
                new ObjectListColumnHelper({
                    id: 'general.Lagerungsart',
                    title: {
                        key: 'dwa_m_150.common.stockType',
                        fallback: 'Lagerungsart'
                    },
                    types: {
                        section: true,
                        lateral: true
                    },
                    accessor: (d: Object) => returnIfHasProperty(d, ['DWA-M150_H_HG411', 'DWA-M150_L_HG411']),
                    duoField: true
                }),
                new ObjectListColumnHelper({
                    id: 'general.Materialart',
                    title: {
                        key: 'dwa_m_150.objectlist.materialType',
                        fallback: 'Materialart'
                    },
                    types: {
                        section: true,
                        lateral: true,
                        manhole: true
                    },
                    accessor: (d: Object) => returnIfHasProperty(d, ['DWA-M150_H_HG304', 'DWA-M150_L_HG304', 'DWA-M150_S_KG304']),
                    duoField: true
                }),
                new ObjectListColumnHelper({
                    id: 'general.Messtechnik',
                    title: {
                        key: 'dwa_m_150.manhole.measurmentTechnic',
                        fallback: 'Messtechnik'
                    },
                    types: {
                        manhole: true
                    },
                    accessor: 'DWA-M150_S_KG326',
                    duoField: true
                }),
                new ObjectListColumnHelper({
                    id: 'general.MittlereTiefe',
                    title: {
                        key: 'dwa_m_150.common.middleDepth',
                        fallback: 'Mittlere Tiefe'
                    },
                    types: {
                        section: true,
                        lateral: true
                    },
                    accessor: (d: Object) => returnIfHasProperty(d, ['DWA-M150_H_HG312', 'DWA-M150_L_HG312'])
                }),
                new ObjectListColumnHelper({
                    id: 'general.NummerKlaeranlage',
                    title: {
                        key: 'dwa_m_150.common.defecatorNumber',
                        fallback: 'Nummer der Kläranlage'
                    },
                    types: {
                        section: true,
                        lateral: true,
                        manhole: true
                    },
                    accessor: (d: Object) => returnIfHasProperty(d, ['DWA-M150_H_HG108', 'DWA-M150_L_HG108', 'DWA-M150_S_KG108']),
                    duoField: true
                }),
                new ObjectListColumnHelper({
                    id: 'general.OberePKBezeichnung',
                    title: {
                        key: 'object.upperPkName',
                        fallback: 'Obere PK-Bezeichnung'
                    },
                    types: {
                        lateral: true
                    },
                    accessor: 'ObererAnschlusspunkt.DWA-M150_G_Objektbezeichner'
                }),
                new ObjectListColumnHelper({
                    id: 'general.OberePunktkennung',
                    title: {
                        key: 'object.upperPointCode',
                        fallback: 'Obere Punktkennung'
                    },
                    types: {
                        lateral: true
                    },
                    accessor: 'ObererAnschlusspunkt.DWA-M150_C_HG010',
                    duoField: true
                }),
                new ObjectListColumnHelper({
                    id: 'general.ObereSohlhoehe',
                    title: {
                        key: 'object.upperInvertLevel',
                        fallback: 'Obere Sohlhöhe'
                    },
                    types: {
                        section: true,
                        lateral: true
                    },
                    accessor: (d: Object) => returnIfHasPropertyDeepCheck(d,
                        [
                            [
                                'ObererSchacht',
                                'DWA-M150_C_Sohlhoehe'
                            ],
                            [
                                'ObererAnschlusspunkt',
                                'DWA-M150_C_Sohlhoehe'
                            ]
                        ]
                    )
                }),
                new ObjectListColumnHelper({
                    id: 'general.OberesBezugsobjekt',
                    title: {
                        key: 'object.upperObject',
                        fallback: 'Oberes Bezugsobjekt'
                    },
                    types: {
                        section: true,
                        lateral: true
                    },
                    accessor: (d: Object) => returnIfHasPropertyDeepCheck(d,
                        [
                            [
                                'ObererSchacht',
                                'DWA-M150_S_KG001'
                            ],
                            [
                                'ObererAnschlusspunkt',
                                'DWA-M150_G_Objektbezeichner'
                            ]
                        ]
                    )
                }),
                new ObjectListColumnHelper({
                    id: 'general.Ortsteilname',
                    title: {
                        key: 'dwa_m_150.objectlist.districtName',
                        fallback: 'Ortsteilname'
                    },
                    types: {
                        section: true,
                        lateral: true,
                        manhole: true
                    },
                    accessor: (d: Object) => returnIfHasProperty(d, ['DWA-M150_H_HG104', 'DWA-M150_L_HG104', 'DWA-M150_S_KG104']),
                    duoField: true
                }),
                new ObjectListColumnHelper({
                    id: 'general.Profilart',
                    title: {
                        key: 'object.profileType',
                        fallback: 'Profilart'
                    },
                    types: {
                        section: true,
                        lateral: true
                    },
                    accessor: (d: Object) => returnIfHasProperty(d, ['DWA-M150_H_HG305', 'DWA-M150_L_HG305']),
                    duoField: true
                }),
                new ObjectListColumnHelper({
                    id: 'general.Profilauskleidung',
                    title: {
                        key: 'dwa_m_150.common.profileCoating',
                        fallback: 'Profilauskleidung'
                    },
                    types: {
                        section: true,
                        lateral: true
                    },
                    accessor: (d: Object) => returnIfHasProperty(d, ['DWA-M150_H_HG308', 'DWA-M150_L_HG308']),
                    duoField: true
                }),
                new ObjectListColumnHelper({
                    id: 'general.ProfilauskleidungSelbsttragend',
                    title: {
                        key: 'dwa_m_150.common.profileCoatingSelf',
                        fallback: 'Profilauskleidung selbsttragend'
                    },
                    types: {
                        section: true,
                        lateral: true
                    },
                    accessor: (d: Object) => returnIfHasProperty(d, ['DWA-M150_H_HG316', 'DWA-M150_L_HG316'])
                }),
                new ObjectListColumnHelper({
                    id: 'general.Profilauskleidungmaterial',
                    title: {
                        key: 'dwa_m_150.common.profileCoatingMaterial',
                        fallback: 'Material Profilauskleidung'
                    },
                    types: {
                        section: true,
                        lateral: true
                    },
                    accessor: (d: Object) => returnIfHasProperty(d, ['DWA-M150_H_HG309', 'DWA-M150_L_HG309']),
                    duoField: true
                }),
                new ObjectListColumnHelper({
                    id: 'general.Profilbreite',
                    title: {
                        key: 'object.profileWidth',
                        fallback: 'Profilbreite'
                    },
                    types: {
                        section: true,
                        lateral: true
                    },
                    accessor: (d: Object) => returnIfHasProperty(d, ['DWA-M150_H_HG306', 'DWA-M150_L_HG306'])
                }),
                new ObjectListColumnHelper({
                    id: 'general.Profilhoehe',
                    title: {
                        key: 'object.profileHeight',
                        fallback: 'Profilhöhe'
                    },
                    types: {
                        section: true,
                        lateral: true
                    },
                    accessor: (d: Object) => returnIfHasProperty(d, ['DWA-M150_H_HG307', 'DWA-M150_L_HG307'])
                }),
                new ObjectListColumnHelper({
                    id: 'general.Rohrlaenge',
                    title: {
                        key: 'object.pipeLength',
                        fallback: 'Rohrlänge'
                    },
                    types: {
                        section: true,
                        lateral: true
                    },
                    accessor: (d: Object) => returnIfHasProperty(d, ['DWA-M150_H_HG314', 'DWA-M150_L_HG314'])
                }),
                new ObjectListColumnHelper({
                    id: 'general.RohrlaengeAnfangWeite',
                    title: {
                        key: 'dwa_m_150.objectlist.pipeLength',
                        fallback: 'Rohrlänge (von Rohranfang bis Rohrweite)'
                    },
                    types: {
                        section: true,
                        lateral: true
                    },
                    accessor: (d: Object) => returnIfHasProperty(d, ['DWA-M150_H_HG911_SES', 'DWA-M150_L_HG911_SES'])
                }),
                new ObjectListColumnHelper({
                    id: 'general.Schachtart',
                    title: {
                        key: 'dwa_m_150.manhole.manholeType',
                        fallback: 'Schachtart'
                    },
                    types: {
                        manhole: true
                    },
                    accessor: 'DWA-M150_S_KG920_SES',
                    duoField: true
                }),
                new ObjectListColumnHelper({
                    id: 'general.Schachtbreite',
                    title: {
                        key: 'dwa_m_150.manhole.manholeWidth',
                        fallback: 'Schachtbreite'
                    },
                    types: {
                        manhole: true
                    },
                    accessor: 'DWA-M150_S_KG309'
                }),
                new ObjectListColumnHelper({
                    id: 'general.Schachtform',
                    title: {
                        key: 'dwa_m_150.manhole.manholeForm',
                        fallback: 'Schachtform'
                    },
                    types: {
                        manhole: true
                    },
                    accessor: 'DWA-M150_S_KG307',
                    duoField: true
                }),
                new ObjectListColumnHelper({
                    id: 'general.Schachtlaenge',
                    title: {
                        key: 'dwa_m_150.manhole.manholeLength',
                        fallback: 'Schachtlänge'
                    },
                    types: {
                        manhole: true
                    },
                    accessor: 'DWA-M150_S_KG308'
                }),
                new ObjectListColumnHelper({
                    id: 'general.Schachtsohlhoehe',
                    title: {
                        key: 'object.manholeInvertLevel',
                        fallback: 'Schachtsohlhöhe'
                    },
                    types: {
                        manhole: true
                    },
                    accessor: 'DWA-M150_M_Sohlhoehe'
                }),
                new ObjectListColumnHelper({
                    id: 'general.Schachttiefe',
                    title: {
                        key: 'object.manholeDepth',
                        fallback: 'Schachttiefe'
                    },
                    types: {
                        manhole: true
                    },
                    accessor: 'DWA-M150_S_KG211'
                }),
                new ObjectListColumnHelper({
                    id: 'general.StatusDaten',
                    title: {
                        key: 'dwa_m_150.common.stateData',
                        fallback: 'Status Daten'
                    },
                    types: {
                        section: true,
                        lateral: true,
                        manhole: true
                    },
                    accessor: (d: Object) => returnIfHasProperty(d, ['DWA-M150_H_HG407', 'DWA-M150_L_HG407', 'DWA-M150_S_KG407']),
                    duoField: true
                }),
                new ObjectListColumnHelper({
                    id: 'general.StatusKoordinaten',
                    title: {
                        key: 'dwa_m_150.lateral.stateCoords',
                        fallback: 'Status Koordinaten'
                    },
                    types: {
                        lateral: true,
                        manhole: true
                    },
                    accessor: (d: Object) => returnIfHasProperty(d, ['DWA-M150_L_HG924_SES', 'DWA-M150_S_KG924_SES']),
                    duoField: true
                }),
                new ObjectListColumnHelper({
                    id: 'general.StatusProfilangaben',
                    title: {
                        key: 'dwa_m_150.common.stateProfile',
                        fallback: 'Status Profilangaben'
                    },
                    types: {
                        section: true,
                        lateral: true
                    },
                    accessor: (d: Object) => returnIfHasProperty(d, ['DWA-M150_H_HG315', 'DWA-M150_L_HG315']),
                    duoField: true
                }),
                new ObjectListColumnHelper({
                    id: 'general.Steighilfe',
                    title: {
                        key: 'dwa_m_150.manhole.climbingAid',
                        fallback: 'Steighilfe'
                    },
                    types: {
                        manhole: true
                    },
                    accessor: 'DWA-M150_S_KG323',
                    duoField: true
                }),
                new ObjectListColumnHelper({
                    id: 'general.Steighilfenwerkstoff',
                    title: {
                        key: 'dwa_m_150.manhole.climbingAidMaterial',
                        fallback: 'Steighilfenwerkstoff'
                    },
                    types: {
                        manhole: true
                    },
                    accessor: 'DWA-M150_S_KG325',
                    duoField: true
                }),
                new ObjectListColumnHelper({
                    id: 'general.Strassenname',
                    title: {
                        key: 'common.street',
                        fallback: 'Straße'
                    },
                    types: {
                        section: true,
                        lateral: true,
                        manhole: true
                    },
                    accessor: (d: Object) => returnIfHasProperty(d, ['DWA-M150_H_HG102', 'DWA-M150_L_HG102', 'DWA-M150_S_KG102']),
                    duoField: true
                }),
                new ObjectListColumnHelper({
                    id: 'general.Ueberschwemmungsgebiet',
                    title: {
                        key: 'object.floodingArea',
                        fallback: 'Überschwemmungsgebiet'
                    },
                    types: {
                        section: true,
                        lateral: true,
                        manhole: true
                    },
                    accessor: (d: Object) => returnIfHasProperty(d, ['DWA-M150_H_HG406', 'DWA-M150_L_HG406', 'DWA-M150_S_KG406']),
                    columnType: OBJECTLIST_RENDERER_MODE.CHECKBOX
                }),
                new ObjectListColumnHelper({
                    id: 'general.UnterePKBezeichnung',
                    title: {
                        key: 'object.lowerPkName',
                        fallback: 'Untere PK-Bezeichnung'
                    },
                    types: {
                        lateral: true
                    },
                    accessor: 'UntererAnschlusspunkt.DWA-M150_G_Objektbezeichner'
                }),
                new ObjectListColumnHelper({
                    id: 'general.UnterePunktkennung',
                    title: {
                        key: 'object.lowerPointCode',
                        fallback: 'Untere Punktkennung'
                    },
                    types: {
                        lateral: true
                    },
                    accessor: 'UntererAnschlusspunkt.DWA-M150_C_HG010',
                    duoField: true
                }),
                new ObjectListColumnHelper({
                    id: 'general.UntereSohlhoehe',
                    title: {
                        key: 'object.lowerInvertLevel',
                        fallback: 'Untere Sohlhöhe'
                    },
                    types: {
                        section: true,
                        lateral: true
                    },
                    accessor: (d: Object) => returnIfHasPropertyDeepCheck(d,
                        [
                            [
                                'UntererAnschlusspunkt',
                                'DWA-M150_C_Sohlhoehe'
                            ],
                            [
                                'UntererSchacht',
                                'DWA-M150_C_Sohlhoehe'
                            ]
                        ]
                    )
                }),
                new ObjectListColumnHelper({
                    id: 'general.UnteresBezugsobjekt',
                    title: {
                        key: 'object.lowerObject',
                        fallback: 'Unteres Bezugsobjekt'
                    },
                    types: {
                        section: true,
                        lateral: true
                    },
                    accessor: (d: Object) => returnIfHasPropertyDeepCheck(d,
                        [
                            [
                                'UntererAnschlusspunkt',
                                'DWA-M150_G_Objektbezeichner'
                            ],
                            [
                                'UntererSchacht',
                                'DWA-M150_S_KG001'
                            ]
                        ]
                    )
                }),
                new ObjectListColumnHelper({
                    id: 'general.Wanddicke',
                    title: {
                        key: 'dwa_m_150.common.wallThickness',
                        fallback: 'Wanddicke'
                    },
                    types: {
                        section: true,
                        lateral: true
                    },
                    accessor: (d: Object) => returnIfHasProperty(d, ['DWA-M150_H_HG410', 'DWA-M150_L_HG410'])
                }),
                new ObjectListColumnHelper({
                    id: 'general.Wasserschutzzone',
                    title: {
                        key: 'object.waterConservationArea',
                        fallback: 'Wasserschutzzone'
                    },
                    types: {
                        section: true,
                        lateral: true,
                        manhole: true
                    },
                    accessor: (d: Object) => returnIfHasProperty(d, ['DWA-M150_H_HG403', 'DWA-M150_L_HG403', 'DWA-M150_S_KG403']),
                    duoField: true
                })
            ]
        }),
        new ObjectListTabHelper({
            id: 'inspection',
            title: {
                key: 'main.inspection',
                fallback: 'Untersuchung'
            },
            sort: true,
            columns: [
                new ObjectListColumnHelper({
                    id: 'inspection.Auftraggeber',
                    title: {
                        key: 'dwa_m_150.inspection.client',
                        fallback: 'Auftraggeber'
                    },
                    types: {
                        section: true,
                        lateral: true,
                        manhole: true
                    },
                    accessor: (d: Object) => findInspectionValue("DWA-M150_H_HI001.DWA-M150_L_HI001.DWA-M150_S_KI001", d)
                }),
                new ObjectListColumnHelper({
                    id: 'inspection.Bauleitung',
                    title: {
                        key: 'dwa_m_150.inspection.constructionManagement',
                        fallback: 'Bauleitung'
                    },
                    types: {
                        section: true,
                        lateral: true,
                        manhole: true
                    },
                    accessor: (d: Object) => findInspectionValue("DWA-M150_H_HI113.DWA-M150_L_HI113.DWA-M150_S_KI113", d)
                }),
                new ObjectListColumnHelper({
                    id: 'inspection.Bearbeitungshinweis',
                    title: {
                        key: 'dwa_m_150.inspection.editHint',
                        fallback: 'Bearbeitungshinweis'
                    },
                    types: {
                        section: true,
                        lateral: true,
                        manhole: true
                    },
                    accessor: (d: Object) => findInspectionValue("DWA-M150_H_HI008.DWA-M150_L_HI008.DWA-M150_S_KI008", d)
                }),
                new ObjectListColumnHelper({
                    id: 'inspection.Bearbeitungsstatus',
                    title: {
                        key: 'dwa_m_150.inspection.editState',
                        fallback: 'Bearbeitungsstatus'
                    },
                    types: {
                        section: true,
                        lateral: true,
                        manhole: true
                    },
                    accessor: (d: Object) => findInspectionValue("DWA-M150_H_HI007.DWA-M150_L_HI007.DWA-M150_S_KI007", d)
                }),
                new ObjectListColumnHelper({
                    id: 'inspection.Bemerkung',
                    title: {
                        key: 'dwa_m_150.objectlist.inspectionRemark',
                        fallback: 'Bemerkung (Untersuchung)'
                    },
                    types: {
                        section: true,
                        lateral: true,
                        manhole: true
                    },
                    accessor: (d: Object) => findInspectionValue("DWA-M150_H_HI999.DWA-M150_L_HI999.DWA-M150_S_KI999", d)
                }),
                new ObjectListColumnHelper({
                    id: 'inspection.Betriebssicher',
                    title: {
                        key: 'dwa_m_150.inspection.workSave',
                        fallback: 'Betriebssicher'
                    },
                    types: {
                        manhole: true
                    },
                    accessor: (d: Object) => findInspectionValue("DWA-M150_S_KI119", d),
                    columnType: OBJECTLIST_RENDERER_MODE.CHECKBOX
                }),
                new ObjectListColumnHelper({
                    id: 'inspection.BewPunkteBetriebssicherheit',
                    title: {
                        key: 'dwa_m_149_3.evalPointsOpReliabilityLong',
                        fallback: 'Bew.punkte Betriebssicherheit (DWA-Klass.)'
                    },
                    types: {
                        section: true,
                        lateral: true,
                        manhole: true
                    },
                    accessor: (d: Object) => findInspectionValue("EvaluationPointsEvalOperation", d)
                }),
                new ObjectListColumnHelper({
                    id: 'inspection.BewPunkteDichtheit',
                    title: {
                        key: 'dwa_m_149_3.evalPointTightnessLong',
                        fallback: 'Bew.punkte Dichtheit (DWA-Klass.)'
                    },
                    types: {
                        section: true,
                        lateral: true,
                        manhole: true
                    },
                    accessor: (d: Object) => findInspectionValue("EvaluationPointsEvalTightness", d)
                }),
                new ObjectListColumnHelper({
                    id: 'inspection.BewPunkteStandsicherheit',
                    title: {
                        key: 'dwa_m_149_3.evalPointStabilityLong',
                        fallback: 'Bew.punkte Standsicherheit (DWA-Klass.)'
                    },
                    types: {
                        section: true,
                        lateral: true,
                        manhole: true
                    },
                    accessor: (d: Object) => findInspectionValue("EvaluationPointsEvalStatic", d)
                }),
                new ObjectListColumnHelper({
                    id: 'inspection.Bewertungsdatum',
                    title: {
                        key: 'common.evaluationTime',
                        fallback: 'Bewertungsdatum'
                    },
                    types: {
                        section: true,
                        lateral: true,
                        manhole: true
                    },
                    accessor: (d: Object) => findInspectionValue("EvaluationTime", d)
                }),
                new ObjectListColumnHelper({
                    id: 'inspection.BezugspunktAmUmfang',
                    title: {
                        key: 'dwa_m_150.inspection.pointAtDimension',
                        fallback: 'Bezugspunkt am Umfang'
                    },
                    types: {
                        manhole: true
                    },
                    accessor: (d: Object) => findInspectionValue("DWA-M150_S_KI102", d),
                    duoField: true
                }),
                new ObjectListColumnHelper({
                    id: 'inspection.BezugspunktStart',
                    title: {
                        key: 'dwa_m_150.inspection.pointStart',
                        fallback: 'Bezugspunkt Start'
                    },
                    types: {
                        section: true,
                        lateral: true
                    },
                    accessor: (d: Object) => findInspectionValue("DWA-M150_H_HI102.DWA-M150_L_HI102", d),
                    duoField: true
                }),
                new ObjectListColumnHelper({
                    id: 'inspection.DateinameDigitalesVideo',
                    title: {
                        key: 'dwa_m_150.inspection.fileNameDigital',
                        fallback: 'Dateiname digitales Video'
                    },
                    types: {
                        manhole: true
                    },
                    accessor: (d: Object) => findInspectionValue("DWA-M150_S_KI116", d)
                }),
                new ObjectListColumnHelper({
                    id: 'inspection.Dokument',
                    title: {
                        key: 'dwa_m_150.objectlist.inspectionDocument',
                        fallback: 'Dokument (Untersuchung)'
                    },
                    types: {
                        section: true,
                        lateral: true,
                        manhole: true
                    },
                    accessor: (d: Object) => findInspectionValue("DWA-M150_H_HI998.DWA-M150_L_HI998.DWA-M150_S_KI998", d)
                }),
                new ObjectListColumnHelper({
                    id: 'inspection.Kamerasystem',
                    title: {
                        key: 'dwa_m_150.inspection.cameraSystem',
                        fallback: 'Kamerasystem'
                    },
                    types: {
                        section: true,
                        lateral: true,
                        manhole: true
                    },
                    accessor: (d: Object) => findInspectionValue("DWA-M150_H_HI006.DWA-M150_L_HI006.DWA-M150_S_KI006", d)
                }),
                new ObjectListColumnHelper({
                    id: 'inspection.EndgueltigeObjektanzahl',
                    title: {
                        key: 'arbeitshilfenAbwasser.finalObjectNumberLong',
                        fallback: 'Endgültige Objektanzahl (A.A.-Klass.)'
                    },
                    types: {
                        section: true,
                        lateral: true,
                        manhole: true
                    },
                    accessor: (d: Object) => findInspectionValue("EvaluationNumberFinal", d)
                }),
                new ObjectListColumnHelper({
                    id: 'inspection.Fahrzeug',
                    title: {
                        key: 'dwa_m_150.inspection.car',
                        fallback: 'Fahrzeug'
                    },
                    types: {
                        section: true,
                        lateral: true,
                        manhole: true
                    },
                    accessor: (d: Object) => findInspectionValue("DWA-M150_H_HI902.DWA-M150_L_HI902.DWA-M150_S_KI902", d)
                }),
                new ObjectListColumnHelper({
                    id: 'inspection.Firma',
                    title: {
                        key: 'dwa_m_150.inspection.company',
                        fallback: 'Firma'
                    },
                    types: {
                        section: true,
                        lateral: true,
                        manhole: true
                    },
                    accessor: (d: Object) => findInspectionValue("DWA-M150_H_HI111.DWA-M150_L_HI111.DWA-M150_S_KI111", d)
                }),
                new ObjectListColumnHelper({
                    id: 'inspection.Fotoanzahl',
                    title: {
                        key: 'common.pictureCount',
                        fallback: 'Fotoanzahl'
                    },
                    types: {
                        section: true,
                        lateral: true,
                        manhole: true
                    },
                    accessor: (d: Object) => findInspectionValue("Fotoanzahl", d)
                }),
                new ObjectListColumnHelper({
                    id: 'inspection.Fotospeichermedium',
                    title: {
                        key: 'inspection.photoStorageMedium',
                        fallback: 'Fotospeichermedium'
                    },
                    types: {
                        section: true,
                        lateral: true,
                        manhole: true
                    },
                    accessor: (d: Object) => findInspectionValue("DWA-M150_H_HI117.DWA-M150_L_HI117.DWA-M150_S_KI117", d),
                    duoField: true
                }),
                new ObjectListColumnHelper({
                    id: 'inspection.Inspekteur',
                    title: {
                        key: 'inspection.inspector',
                        fallback: 'Inspekteur'
                    },
                    types: {
                        section: true,
                        lateral: true,
                        manhole: true
                    },
                    accessor: (d: Object) => findInspectionValue("DWA-M150_H_HI112.DWA-M150_L_HI112.DWA-M150_S_KI112", d)
                }),
                new ObjectListColumnHelper({
                    id: 'inspection.Inspektionsart',
                    title: {
                        key: 'inspection.typeInspection',
                        fallback: 'Inspektionsart'
                    },
                    types: {
                        section: true,
                        lateral: true,
                        manhole: true
                    },
                    accessor: (d: Object) => findInspectionValue("DWA-M150_H_HI103.DWA-M150_L_HI103.DWA-M150_S_KI103", d),
                    duoField: true
                }),
                new ObjectListColumnHelper({
                    id: 'inspection.Inspektionsdatum',
                    title: {
                        key: 'inspection.inspectionDate',
                        fallback: 'Inspektionsdatum'
                    },
                    types: {
                        section: true,
                        lateral: true,
                        manhole: true
                    },
                    accessor: (d: Object) => findInspectionValue("Inspektionsbeginn", d)
                }),
                new ObjectListColumnHelper({
                    id: 'inspection.Inspektionsgrund',
                    title: {
                        key: 'dwa_m_150.inspection.inspectionReason',
                        fallback: 'Inspektionsgrund'
                    },
                    types: {
                        section: true,
                        lateral: true,
                        manhole: true
                    },
                    accessor: (d: Object) => findInspectionValue("DWA-M150_H_HI004.DWA-M150_L_HI004.DWA-M150_S_KI004", d),
                    duoField: true
                }),
                new ObjectListColumnHelper({
                    id: 'inspection.Inspektionslänge',
                    title: {
                        key: 'inspection.inspectionLength',
                        fallback: 'Inspektionslänge'
                    },
                    types: {
                        section: true,
                        lateral: true,
                        manhole: true
                    },
                    accessor: (d: Object) => findInspectionValue("Inspektionslaenge", d)
                }),
                new ObjectListColumnHelper({
                    id: 'inspection.Inspektionsnummer',
                    title: {
                        key: 'dwa_m_150.objectlist.inspectionNumber',
                        fallback: 'Inspektionsnummer'
                    },
                    types: {
                        lateral: true
                    },
                    accessor: (d: Object) => findInspectionValue("DWA-M150_L_HI003_BASYS", d)
                }),
                new ObjectListColumnHelper({
                    id: 'inspection.Inspektionsrichtung',
                    title: {
                        key: 'inspection.inspectionDirection',
                        fallback: 'Inspektionsrichtung'
                    },
                    types: {
                        section: true,
                        lateral: true
                    },
                    accessor: (d: Object) => findInspectionValue("Inspektionsrichtung", d),
                    duoField: true
                }),
                new ObjectListColumnHelper({
                    id: 'inspection.Inspektionszeit',
                    title: {
                        key: 'inspection.inspectionTime',
                        fallback: 'Inspektionszeit'
                    },
                    types: {
                        section: true,
                        lateral: true,
                        manhole: true
                    },
                    accessor: (d: Object) => findInspectionValue("Inspektionszeit", d)
                }),
                new ObjectListColumnHelper({
                    id: 'inspection.KonusRichtig',
                    title: {
                        key: 'dwa_m_150.inspection.taperCorrect',
                        fallback: 'Konus richtig'
                    },
                    types: {
                        manhole: true
                    },
                    accessor: (d: Object) => findInspectionValue("DWA-M150_S_KI120", d)
                }),
                new ObjectListColumnHelper({
                    id: 'inspection.NameDesSpeichermediums',
                    title: {
                        key: 'dwa_m_150.inspection.nameStorage',
                        fallback: 'Name des Speichermediums'
                    },
                    types: {
                        lateral: true
                    },
                    accessor: (d: Object) => findInspectionValue("DWA-M150_S_KI115", d)
                }),
                new ObjectListColumnHelper({
                    id: 'inspection.Objektklasse',
                    title: {
                        key: 'arbeitshilfenAbwasser.objectClass',
                        fallback: 'Objektklasse'
                    },
                    types: {
                        section: true,
                        lateral: true,
                        manhole: true
                    },
                    accessor: (d: Object) => findInspectionValue("Class", d)
                }),
                new ObjectListColumnHelper({
                    id: 'inspection.Projektnummer',
                    title: {
                        key: 'dwa_m_150.inspection.nameStorage',
                        fallback: 'Projektnummer'
                    },
                    types: {
                        section: true,
                        lateral: true,
                        manhole: true
                    },
                    accessor: (d: Object) => findInspectionValue("DWA-M150_H_HI002.DWA-M150_L_HI002.DWA-M150_S_KI002", d)
                }),
                new ObjectListColumnHelper({
                    id: 'inspection.Reinigung',
                    title: {
                        key: 'inspection.cleaning',
                        fallback: 'Reinigung'
                    },
                    types: {
                        section: true,
                        lateral: true,
                        manhole: true
                    },
                    accessor: (d: Object) => findInspectionValue("DWA-M150_H_HI107.DWA-M150_L_HI107.DWA-M150_S_KI107", d)
                }),
                new ObjectListColumnHelper({
                    id: 'inspection.SanBedarfszahl',
                    title: {
                        key: 'dwa_m_149_3.rehabilitationNumberLong',
                        fallback: 'Sanierungsbedarfzahl (DWA-Klass.)'
                    },
                    types: {
                        section: true,
                        lateral: true,
                        manhole: true
                    },
                    accessor: (d: Object) => findInspectionValue("EvaluationNumberFinal", d)
                }),
                new ObjectListColumnHelper({
                    id: 'inspection.SanPrio',
                    title: {
                        key: 'din1986_30.rehabilitationPriorityLong',
                        fallback: 'Sanierungspriorität (DIN 1986-30)'
                    },
                    types: {
                        section: true,
                        lateral: true,
                        manhole: true
                    },
                    accessor: (d: Object) => findInspectionValue("Class", d)
                }),
                new ObjectListColumnHelper({
                    id: 'inspection.Temperatur',
                    title: {
                        key: 'inspection.temperature',
                        fallback: 'Temperatur'
                    },
                    types: {
                        section: true,
                        lateral: true,
                        manhole: true
                    },
                    accessor: (d: Object) => findInspectionValue("DWA-M150_H_HI108.DWA-M150_L_HI108.DWA-M150_S_KI108", d)
                }),
                new ObjectListColumnHelper({
                    id: 'inspection.Umgebungsluft',
                    title: {
                        key: 'dwa_m_150.inspection.envAir',
                        fallback: 'Umgebungsluft'
                    },
                    types: {
                        manhole: true
                    },
                    accessor: (d: Object) => findInspectionValue("DWA-M150_S_KI121", d)
                }),
                new ObjectListColumnHelper({
                    id: 'inspection.VertikalerBezugspunkt',
                    title: {
                        key: 'inspection.verticalPoint',
                        fallback: 'Vertikaler Bezugspunkt'
                    },
                    types: {
                        manhole: true
                    },
                    accessor: (d: Object) => findInspectionValue("DWA-M150_S_KI101", d),
                    duoField: true
                }),
                new ObjectListColumnHelper({
                    id: 'inspection.Videospeichermedium',
                    title: {
                        key: 'inspection.videoStorageMedium',
                        fallback: 'Videospeichermedium'
                    },
                    types: {
                        section: true,
                        lateral: true,
                        manhole: true
                    },
                    accessor: (d: Object) => findInspectionValue("DWA-M150_H_HI114.DWA-M150_L_HI114.DWA-M150_S_KI114", d),
                    duoField: true
                }),
                new ObjectListColumnHelper({
                    id: 'inspection.Vorflutsicherung',
                    title: {
                        key: 'dwa_m_150.inspection.floadSafety',
                        fallback: 'Vorflutsicherung'
                    },
                    types: {
                        section: true,
                        lateral: true,
                        manhole: true
                    },
                    accessor: (d: Object) => findInspectionValue("DWA-M150_H_HI109.DWA-M150_L_HI109.DWA-M150_S_KI109", d),
                    duoField: true
                }),
                new ObjectListColumnHelper({
                    id: 'inspection.Wasserspiegel',
                    title: {
                        key: 'dwa_m_150.inspection.waterSurface',
                        fallback: 'Wasserspiegel'
                    },
                    types: {
                        section: true,
                        lateral: true,
                        manhole: true
                    },
                    accessor: (d: Object) => findInspectionValue("DWA-M150_H_HI110.DWA-M150_L_HI110.DWA-M150_S_KI110", d)
                }),
                new ObjectListColumnHelper({
                    id: 'inspection.Wetter',
                    title: {
                        key: 'inspection.weather',
                        fallback: 'Wetter'
                    },
                    types: {
                        section: true,
                        lateral: true,
                        manhole: true
                    },
                    accessor: (d: Object) => findInspectionValue("DWA-M150_H_HI106.DWA-M150_L_HI106.DWA-M150_S_KI106", d),
                    duoField: true
                })
            ]
        })
    ],

    objectname: "DWA-M150_H_HG001.DWA-M150_L_HG011.DWA-M150_S_KG001",

    object: new CanalObjects({
        section: new CanalObject([
            new CardHelper({
                id: 'general',
                title: {
                    key: 'common.general',
                    fallback: 'Allgemein'
                },
                items: [
                    new FieldHelper({
                        id: 'sectionName',
                        title: {
                            key: 'object.sectionName',
                            fallback: 'Haltungsname'
                        },
                        accessor: 'DWA-M150_H_HG001'
                    }),
                    new FieldHelper({
                        id: 'constructionYear',
                        title: {
                            key: 'object.constructionYear',
                            fallback: 'Baujahr'
                        },
                        accessor: 'DWA-M150_H_HG303'
                    }),
                    new FieldHelper({
                        id: 'sectionLength',
                        title: {
                            key: 'object.sectionLength',
                            fallback: 'Haltungslänge'
                        },
                        accessor: 'DWA-M150_H_HG310'
                    }),
                    new FieldHelper({
                        id: 'material',
                        title: {
                            key: 'object.material',
                            fallback: 'Material'
                        },
                        accessor: 'DWA-M150_H_HG304',
                        field: new ComplexTextBoxHelper()
                    }),
                    new FieldHelper({
                        id: 'sewerType',
                        title: {
                            key: 'dwa_m_150.common.sewerType',
                            fallback: 'Kanalart'
                        },
                        accessor: 'DWA-M150_H_HG301',
                        field: new ComplexTextBoxHelper()
                    }),
                    new FieldHelper({
                        id: 'sewerUsage',
                        title: {
                            key: 'dwa_m_150.common.sewerUsage',
                            fallback: 'Kanalnutzung'
                        },
                        accessor: 'DWA-M150_H_HG302',
                        field: new ComplexTextBoxHelper()
                    }),
                    new FieldHelper({
                        id: 'remark',
                        title: {
                            key: 'common.remark',
                            fallback: 'Bemerkung'
                        },
                        accessor: 'DWA-M150_H_HG999'
                    }),
                    new FieldHelper({
                        id: 'document',
                        title: {
                            key: 'dwa_m_150.common.document',
                            fallback: 'Dokument'
                        },
                        accessor: 'DWA-M150_H_HG998'
                    })
                ]
            }),
            new CardHelper({
                id: 'manhole',
                items: [
                    new CardCardHelper({
                        id: 'manholeCard',
                        items: [
                            new CardHelper({
                                id: 'topManhole',
                                title: {
                                    key: 'object.topManhole',
                                    fallback: 'Oberer Schacht'
                                },
                                items: [
                                    new FieldHelper({
                                        id: 'name',
                                        title: {
                                            key: 'common.name',
                                            fallback: 'Name'
                                        },
                                        accessor: 'ObererSchacht.DWA-M150_H_KG001',
                                        columnSize: {
                                            lg: 12,
                                            md: 12
                                        },
                                        size: {
                                            lg: 3,
                                            md: 3,
                                            sm: 2,
                                            xs: 2
                                        }
                                    }),
                                    new FieldHelper({
                                        id: 'invertLevel',
                                        title: {
                                            key: 'object.invertLevel',
                                            fallback: 'Sohlhöhe'
                                        },
                                        accessor: 'ObererSchacht.DWA-M150_C_Sohlhoehe',
                                        columnSize: {
                                            lg: 12,
                                            md: 12
                                        },
                                        size: {
                                            lg: 3,
                                            md: 3,
                                            sm: 2,
                                            xs: 2
                                        }
                                    }),
                                    new FieldHelper({
                                        id: 'distancePipe',
                                        title: {
                                            key: 'object.distancePipe',
                                            fallback: 'Abstand Rohranfang zur Schachtmitte'
                                        },
                                        accessor: 'ObererSchacht.DistanceManholeCenter',
                                        columnSize: {
                                            lg: 12,
                                            md: 12
                                        },
                                        size: {
                                            lg: 5,
                                            md: 5,
                                            sm: 5,
                                            xs: 5
                                        }
                                    }),
                                ]
                            }),
                            new CardHelper({
                                id: 'bottomManhole',
                                title: {
                                    key: 'object.bottomManhole',
                                    fallback: 'Unterer Schacht'
                                },
                                items: [
                                    new FieldHelper({
                                        id: 'name',
                                        title: {
                                            key: 'common.name',
                                            fallback: 'Name'
                                        },
                                        accessor: 'UntererSchacht.DWA-M150_H_KG001',
                                        columnSize: {
                                            lg: 12,
                                            md: 12
                                        },
                                        size: {
                                            lg: 3,
                                            md: 3,
                                            sm: 2,
                                            xs: 2
                                        }
                                    }),
                                    new FieldHelper({
                                        id: 'invertLevel',
                                        title: {
                                            key: 'object.invertLevel',
                                            fallback: 'Sohlhöhe'
                                        },
                                        accessor: 'UntererSchacht.DWA-M150_C_Sohlhoehe',
                                        columnSize: {
                                            lg: 12,
                                            md: 12
                                        },
                                        size: {
                                            lg: 3,
                                            md: 3,
                                            sm: 2,
                                            xs: 2
                                        }
                                    }),
                                    new FieldHelper({
                                        id: 'distancePipe',
                                        title: {
                                            key: 'object.distancePipe',
                                            fallback: 'Abstand Rohranfang zur Schachtmitte'
                                        },
                                        accessor: 'UntererSchacht.DistanceManholeCenter',
                                        columnSize: {
                                            lg: 12,
                                            md: 12
                                        },
                                        size: {
                                            lg: 5,
                                            md: 5,
                                            sm: 5,
                                            xs: 5
                                        }
                                    }),
                                ]
                            })
                        ]
                    })
                ]
            }),
            new CardHelper({
                id: 'pipe',
                items: [
                    new CardCardHelper({
                        id: 'pipeCard',
                        items: [
                            new CardHelper({
                                id: 'pipeInvertTop',
                                title: {
                                    key: 'dwa_m_150.common.pipeInvertTop',
                                    fallback: 'Rohrsohle oben'
                                },
                                items: [
                                    new FieldHelper({
                                        id: 'xCoordinate',
                                        title: {
                                            key: 'object.xCoordinate',
                                            fallback: 'Rechtswert'
                                        },
                                        accessor: 'RohrsohleOben.DWA-M150_M_Rechtswert',
                                        columnSize: {
                                            lg: 12,
                                            md: 12
                                        },
                                        size: {
                                            lg: 3,
                                            md: 3,
                                            sm: 2,
                                            xs: 2
                                        }
                                    }),
                                    new FieldHelper({
                                        id: 'yCoordinate',
                                        title: {
                                            key: 'object.yCoordinate',
                                            fallback: 'Hochwert'
                                        },
                                        accessor: 'RohrsohleOben.DWA-M150_M_Hochwert',
                                        columnSize: {
                                            lg: 12,
                                            md: 12
                                        },
                                        size: {
                                            lg: 3,
                                            md: 3,
                                            sm: 2,
                                            xs: 2
                                        }
                                    }),
                                    new FieldHelper({
                                        id: 'positionPrecision',
                                        title: {
                                            key: 'dwa_m_150.common.positionPrecision',
                                            fallback: 'Lagegenauigkeitsstufe'
                                        },
                                        accessor: 'RohrsohleOben.DWA-M150_H_HG203',
                                        columnSize: {
                                            lg: 12,
                                            md: 12
                                        },
                                        size: {
                                            lg: 3,
                                            md: 3,
                                            sm: 2,
                                            xs: 2
                                        }
                                    }),
                                    new FieldHelper({
                                        id: 'heightPrecision',
                                        title: {
                                            key: 'dwa_m_150.common.heightPrecision',
                                            fallback: 'Höhengenauigkeitsstufe'
                                        },
                                        accessor: 'RohrsohleOben.DWA-M150_H_HG205',
                                        columnSize: {
                                            lg: 12,
                                            md: 12
                                        },
                                        size: {
                                            lg: 3,
                                            md: 3,
                                            sm: 2,
                                            xs: 2
                                        }
                                    }),
                                ]
                            }),
                            new CardHelper({
                                id: 'pipeInvertBottom',
                                title: {
                                    key: 'dwa_m_150.common.pipeInvertBottom',
                                    fallback: 'Rohrsohle unten'
                                },
                                items: [
                                    new FieldHelper({
                                        id: 'xCoordinate',
                                        title: {
                                            key: 'object.xCoordinate',
                                            fallback: 'Rechtswert'
                                        },
                                        accessor: 'RohrsohleUnten.DWA-M150_M_Rechtswert',
                                        columnSize: {
                                            lg: 12,
                                            md: 12
                                        },
                                        size: {
                                            lg: 3,
                                            md: 3,
                                            sm: 2,
                                            xs: 2
                                        }
                                    }),
                                    new FieldHelper({
                                        id: 'yCoordinate',
                                        title: {
                                            key: 'object.yCoordinate',
                                            fallback: 'Hochwert'
                                        },
                                        accessor: 'RohrsohleUnten.DWA-M150_M_Hochwert',
                                        columnSize: {
                                            lg: 12,
                                            md: 12
                                        },
                                        size: {
                                            lg: 3,
                                            md: 3,
                                            sm: 2,
                                            xs: 2
                                        }
                                    }),
                                    new FieldHelper({
                                        id: 'positionPrecision',
                                        title: {
                                            key: 'dwa_m_150.common.positionPrecision',
                                            fallback: 'Lagegenauigkeitsstufe'
                                        },
                                        accessor: 'RohrsohleUnten.DWA-M150_H_HG208',
                                        columnSize: {
                                            lg: 12,
                                            md: 12
                                        },
                                        size: {
                                            lg: 3,
                                            md: 3,
                                            sm: 2,
                                            xs: 2
                                        }
                                    }),
                                    new FieldHelper({
                                        id: 'heightPrecision',
                                        title: {
                                            key: 'dwa_m_150.common.heightPrecision',
                                            fallback: 'Höhengenauigkeitsstufe'
                                        },
                                        accessor: 'RohrsohleUnten.DWA-M150_H_HG210',
                                        columnSize: {
                                            lg: 12,
                                            md: 12
                                        },
                                        size: {
                                            lg: 3,
                                            md: 3,
                                            sm: 2,
                                            xs: 2
                                        }
                                    }),
                                ]
                            })
                        ]
                    })
                ]
            }),
            new CardHelper({
                id: 'dimesion',
                title: {
                    key: 'dwa_m_150.common.dimension',
                    fallback: 'Maße'
                },
                items: [
                    new FieldHelper({
                        id: 'profileType',
                        title: {
                            key: 'object.profileType',
                            fallback: 'Profilart'
                        },
                        accessor: 'DWA-M150_H_HG305',
                        field: new ComplexTextBoxHelper()
                    }),
                    new FieldHelper({
                        id: 'stateProfile',
                        title: {
                            key: 'dwa_m_150.common.stateProfile',
                            fallback: 'Status Profilangaben'
                        },
                        accessor: 'DWA-M150_H_HG315',
                        field: new ComplexTextBoxHelper()
                    }),
                    new FieldHelper({
                        id: 'profileHeight',
                        title: {
                            key: 'object.profileHeight',
                            fallback: 'Profilhöhe'
                        },
                        accessor: 'DWA-M150_H_HG307'
                    }),
                    new FieldHelper({
                        id: 'profileWidth',
                        title: {
                            key: 'object.profileWidth',
                            fallback: 'Profilbreite'
                        },
                        accessor: 'DWA-M150_H_HG306'
                    }),
                    new FieldHelper({
                        id: 'pipeLength',
                        title: {
                            key: 'object.pipeLength',
                            fallback: 'Rohrlänge'
                        },
                        accessor: 'DWA-M150_H_HG314'
                    }),
                    new FieldHelper({
                        id: 'middleDepth',
                        title: {
                            key: 'dwa_m_150.common.middleDepth',
                            fallback: 'Mittlere Tiefe'
                        },
                        accessor: 'DWA-M150_H_HG312'
                    }),
                    new FieldHelper({
                        id: 'sectionDip',
                        title: {
                            key: 'dwa_m_150.common.sectionDip',
                            fallback: 'Haltungsgefälle'
                        },
                        accessor: 'DWA-M150_H_HG311'
                    }),
                    new FieldHelper({
                        id: 'wallThickness',
                        title: {
                            key: 'dwa_m_150.common.wallThickness',
                            fallback: 'Wanddicke'
                        },
                        accessor: 'DWA-M150_H_HG410'
                    })
                ]
            }),
            new CardHelper({
                id: 'location',
                title: {
                    key: 'common.position',
                    fallback: 'Standort'
                },
                items: [
                    new FieldHelper({
                        id: 'street',
                        title: {
                            key: 'common.street',
                            fallback: 'Straße'
                        },
                        accessor: 'DWA-M150_H_HG102',
                        field: new ComplexTextBoxHelper()
                    }),
                    new FieldHelper({
                        id: 'number',
                        title: {
                            key: 'common.number',
                            fallback: 'Nr.'
                        },
                        accessor: 'DWA-M150_H_HG997'
                    }),
                    new FieldHelper({
                        id: 'communityKey',
                        title: {
                            key: 'dwa_m_150.common.communityKey',
                            fallback: 'Gemeindeschlüssel'
                        },
                        accessor: 'DWA-M150_H_HG105',
                        field: new ComplexTextBoxHelper()
                    }),
                    new FieldHelper({
                        id: 'district',
                        title: {
                            key: 'common.district',
                            fallback: 'Ortsteil'
                        },
                        accessor: 'DWA-M150_H_HG104',
                        field: new ComplexTextBoxHelper()
                    }),
                    new FieldHelper({
                        id: 'locationKey',
                        title: {
                            key: 'dwa_m_150.common.locationKey',
                            fallback: 'Gebietsschlüssel'
                        },
                        accessor: 'DWA-M150_H_HG106',
                        field: new ComplexTextBoxHelper()
                    }),
                    new FieldHelper({
                        id: 'terrainType',
                        title: {
                            key: 'common.terrainType',
                            fallback: 'Lage im Verkehrsraum'
                        },
                        accessor: 'DWA-M150_H_HG404',
                        field: new ComplexTextBoxHelper()
                    }),
                    new FieldHelper({
                        id: 'hinterlandKey',
                        title: {
                            key: 'dwa_m_150.common.hinterlandKey',
                            fallback: 'Einzugsgebietschlüssel'
                        },
                        accessor: 'DWA-M150_H_HG107',
                        field: new ComplexTextBoxHelper()
                    }),
                    new FieldHelper({
                        id: 'defecatorNumber',
                        title: {
                            key: 'dwa_m_150.common.defecatorNumber',
                            fallback: 'Nummer der Kläranlange'
                        },
                        accessor: 'DWA-M150_H_HG108',
                        field: new ComplexTextBoxHelper()
                    })
                ]
            }),
            new CardHelper({
                id: 'environmentalParameters',
                title: {
                    key: 'object.environmentalParameters',
                    fallback: 'Umweltparameter'
                },
                items: [
                    new FieldHelper({
                        id: 'waterConservationArea',
                        title: {
                            key: 'object.waterConservationArea',
                            fallback: 'Wasserschutzzone'
                        },
                        accessor: 'DWA-M150_H_HG403',
                        field: new ComplexTextBoxHelper()
                    }),
                    new FieldHelper({
                        id: 'jamFrequency',
                        title: {
                            key: 'dwa_m_150.common.jamFrequency',
                            fallback: 'Einstauhäufigkeit'
                        },
                        accessor: 'DWA-M150_H_HG408',
                        field: new ComplexTextBoxHelper()
                    }),
                    new FieldHelper({
                        id: 'groundwaterDistance',
                        title: {
                            key: 'object.groundwaterDistance',
                            fallback: 'Grundwasserabstand'
                        },
                        accessor: 'DWA-M150_H_HG405',
                        field: new ComplexTextBoxHelper()
                    }),
                    new FieldHelper({
                        id: 'terrainGroup',
                        title: {
                            key: 'dwa_m_150.common.terrainGroup',
                            fallback: 'Bodengruppe'
                        },
                        accessor: 'DWA-M150_H_HG409',
                        field: new ComplexTextBoxHelper()
                    }),
                    new FieldHelper({
                        id: 'floodingArea',
                        title: {
                            key: 'object.floodingArea',
                            fallback: 'Überschwemmungsgebiet'
                        },
                        accessor: 'DWA-M150_H_HG406',
                        field: new CheckboxHelper()
                    }),
                    new FieldHelper({
                        id: 'stockType',
                        title: {
                            key: 'dwa_m_150.common.stockType',
                            fallback: 'Lagerungsart'
                        },
                        accessor: 'DWA-M150_H_HG411',
                        field: new ComplexTextBoxHelper()
                    })
                ]
            }),
            new CardHelper({
                id: 'coating',
                title: {
                    key: 'object.coating',
                    fallback: 'Auskleidung'
                },
                items: [
                    new FieldHelper({
                        id: 'profileCoating',
                        title: {
                            key: 'dwa_m_150.common.profileCoating',
                            fallback: 'Profilauskleidung'
                        },
                        accessor: 'DWA-M150_H_HG308',
                        field: new ComplexTextBoxHelper()
                    }),
                    new FieldHelper({
                        id: 'profileCoatingMaterial',
                        title: {
                            key: 'dwa_m_150.common.profileCoatingMaterial',
                            fallback: 'Material Profilauskleidung'
                        },
                        accessor: 'DWA-M150_H_HG309',
                        field: new ComplexTextBoxHelper()
                    }),
                    new FieldHelper({
                        id: 'profileCoatingSelf',
                        title: {
                            key: 'dwa_m_150.common.profileCoatingSelf',
                            fallback: 'Profilauskleidung selbsttragend'
                        },
                        accessor: 'DWA-M150_H_HG316',
                        field: new CheckboxHelper()
                    })
                ]
            }),
            new CardHelper({
                id: 'misc',
                title: {
                    key: 'common.other',
                    fallback: 'Sonstiges'
                },
                items: [
                    new FieldHelper({
                        id: 'altSectionName',
                        title: {
                            key: 'dwa_m_150.section.altSectionName',
                            fallback: 'Alternative Haltungsbezeichnung'
                        },
                        accessor: 'DWA-M150_H_HG002'
                    }),
                    new FieldHelper({
                        id: 'property',
                        title: {
                            key: 'dwa_m_150.common.property',
                            fallback: 'Eigentum'
                        },
                        accessor: 'DWA-M150_H_HG402',
                        field: new ComplexTextBoxHelper()
                    }),
                    new FieldHelper({
                        id: 'sectionType',
                        title: {
                            key: 'dwa_m_150.section.sectionType',
                            fallback: 'Haltungsart'
                        },
                        accessor: 'DWA-M150_H_HG313',
                        field: new ComplexTextBoxHelper()
                    }),
                    new FieldHelper({
                        id: 'stateData',
                        title: {
                            key: 'dwa_m_150.common.stateData',
                            fallback: 'Status Daten'
                        },
                        accessor: 'DWA-M150_H_HG407',
                        field: new ComplexTextBoxHelper()
                    }),
                    new FieldHelper({
                        id: 'function',
                        title: {
                            key: 'dwa_m_150.common.function',
                            fallback: 'Funktionszustand'
                        },
                        accessor: 'DWA-M150_H_HG401',
                        field: new ComplexTextBoxHelper()
                    })
                ]
            }),
            new CardHelper({
                id: 'ext',
                title: {
                    key: 'common.extension',
                    fallback: 'Erweiterungen'
                },
                items: [
                    new FieldHelper({
                        id: 'pipeLength',
                        title: {
                            key: 'object.pipeLength',
                            fallback: 'Rohrlänge'
                        },
                        accessor: 'DWA-M150_H_HG911_SES'
                    }),
                    new FieldHelper({
                        id: 'buildDate',
                        title: {
                            key: 'dwa_m_150.common.buildDate',
                            fallback: 'Baudatum'
                        },
                        accessor: 'DWA-M150_H_HG921_SES'
                    })
                ]
            })
        ]),
        lateral: new CanalObject([
            new CardHelper({
                id: 'general',
                title: {
                    key: 'common.general',
                    fallback: 'Allgemein'
                },
                items: [
                    new FieldHelper({
                        id: 'lateralName',
                        title: {
                            key: 'object.lateralName',
                            fallback: 'Leitungsname'
                        },
                        accessor: 'DWA-M150_L_HG011'
                    }),
                    new FieldHelper({
                        id: 'constructionYear',
                        title: {
                            key: 'object.constructionYear',
                            fallback: 'Baujahr'
                        },
                        accessor: 'DWA-M150_L_HG303'
                    }),
                    new FieldHelper({
                        id: 'lateralLength',
                        title: {
                            key: 'object.lateralLength',
                            fallback: 'Leitungslänge'
                        },
                        accessor: 'DWA-M150_L_HG310'
                    }),
                    new FieldHelper({
                        id: 'material',
                        title: {
                            key: 'object.material',
                            fallback: 'Material'
                        },
                        accessor: 'DWA-M150_L_HG304',
                        field: new ComplexTextBoxHelper()
                    }),
                    new FieldHelper({
                        id: 'sewerType',
                        title: {
                            key: 'dwa_m_150.common.sewerType',
                            fallback: 'Kanalart'
                        },
                        accessor: 'DWA-M150_L_HG301',
                        field: new ComplexTextBoxHelper()
                    }),
                    new FieldHelper({
                        id: 'sewerUsage',
                        title: {
                            key: 'dwa_m_150.common.sewerUsage',
                            fallback: 'Kanalnutzung'
                        },
                        accessor: 'DWA-M150_L_HG302',
                        field: new ComplexTextBoxHelper()
                    }),
                    new FieldHelper({
                        id: 'remark',
                        title: {
                            key: 'common.remark',
                            fallback: 'Bemerkung'
                        },
                        accessor: 'DWA-M150_L_HG999'
                    }),
                    new FieldHelper({
                        id: 'document',
                        title: {
                            key: 'dwa_m_150.common.document',
                            fallback: 'Dokument'
                        },
                        accessor: 'DWA-M150_L_HG998'
                    })
                ]
            }),
            new CardHelper({
                id: 'connectionpoint',
                items: [
                    new CardCardHelper({
                        id: 'connectionpointCard',
                        items: [
                            new CardHelper({
                                id: 'upperConnectionPoint',
                                title: {
                                    key: 'object.upperConnectionPoint',
                                    fallback: 'Oberer Anschlusspunkt'
                                },
                                items: [
                                    new FieldHelper({
                                        id: 'name',
                                        title: {
                                            key: 'common.name',
                                            fallback: 'Name'
                                        },
                                        accessor: 'ObererAnschlusspunkt.DWA-M150_G_Objektbezeichner',
                                        columnSize: {
                                            lg: 12,
                                            md: 12
                                        },
                                        size: {
                                            lg: 3,
                                            md: 3
                                        }
                                    }),
                                    new FieldHelper({
                                        id: 'pointCode',
                                        title: {
                                            key: 'object.pointCode',
                                            fallback: 'Punktkennung'
                                        },
                                        accessor: 'ObererAnschlusspunkt.DWA-M150_C_HG010',
                                        columnSize: {
                                            lg: 5,
                                            md: 5,
                                            sm: 6,
                                            xs: 6
                                        },
                                        size: {
                                            lg: 7,
                                            md: 7,
                                            sm: 8,
                                            xs: 8
                                        },
                                        fieldCssClass: 'correct-padding',
                                        field: new ComplexTextBoxHelper()
                                    }),
                                    new FieldHelper({
                                        id: 'nameConnectionPoint',
                                        accessor: 'ObererAnschlusspunkt.DWA-M150_C_HG005',
                                        columnSize: {
                                            lg: 7,
                                            md: 7,
                                            sm: 6,
                                            xs: 6
                                        },
                                        size: {
                                            lg: 0,
                                            md: 0,
                                            sm: 0,
                                            xs: 0
                                        }
                                    }),
                                    new FieldHelper({
                                        id: 'invertLevel',
                                        title: {
                                            key: 'object.invertLevel',
                                            fallback: 'Sohlhöhe'
                                        },
                                        accessor: 'ObererAnschlusspunkt.DWA-M150_C_Sohlhoehe',
                                        columnSize: {
                                            lg: 12,
                                            md: 12
                                        },
                                        size: {
                                            lg: 3,
                                            md: 3
                                        }
                                    }),
                                    new FieldHelper({
                                        id: 'distancePipe',
                                        title: {
                                            key: 'object.distancePipe',
                                            fallback: 'Abstand Rohranfang zur Schachtmitte'
                                        },
                                        accessor: 'ObererAnschlusspunkt.DistanceManholeCenter',
                                        columnSize: {
                                            lg: 12,
                                            md: 12
                                        },
                                        size: {
                                            lg: 5,
                                            md: 5
                                        }
                                    })
                                ]
                            }),
                            new CardHelper({
                                id: 'lowerConnectionPoint',
                                title: {
                                    key: 'object.lowerConnectionPoint',
                                    fallback: 'Unterer Anschlusspunkt'
                                },
                                items: [
                                    new FieldHelper({
                                        id: 'name',
                                        title: {
                                            key: 'common.name',
                                            fallback: 'Name'
                                        },
                                        accessor: 'UntererAnschlusspunkt.DWA-M150_G_Objektbezeichner',
                                        columnSize: {
                                            lg: 12,
                                            md: 12
                                        },
                                        size: {
                                            lg: 3,
                                            md: 3
                                        }
                                    }),
                                    new FieldHelper({
                                        id: 'pointCode',
                                        title: {
                                            key: 'object.pointCode',
                                            fallback: 'Punktkennung'
                                        },
                                        accessor: 'UntererAnschlusspunkt.DWA-M150_C_HG010',
                                        columnSize: {
                                            lg: 5,
                                            md: 5,
                                            sm: 6,
                                            xs: 6
                                        },
                                        size: {
                                            lg: 7,
                                            md: 7,
                                            sm: 8,
                                            xs: 8
                                        },
                                        fieldCssClass: 'correct-padding',
                                        field: new ComplexTextBoxHelper()
                                    }),
                                    new FieldHelper({
                                        id: 'nameConnectionPoint',
                                        accessor: 'UntererAnschlusspunkt.DWA-M150_C_HG005',
                                        columnSize: {
                                            lg: 7,
                                            md: 7,
                                            sm: 6,
                                            xs: 6
                                        },
                                        size: {
                                            lg: 0,
                                            md: 0,
                                            sm: 0,
                                            xs: 0
                                        }
                                    }),
                                    new FieldHelper({
                                        id: 'invertLevel',
                                        title: {
                                            key: 'object.invertLevel',
                                            fallback: 'Sohlhöhe'
                                        },
                                        accessor: 'UntererAnschlusspunkt.DWA-M150_C_Sohlhoehe',
                                        columnSize: {
                                            lg: 12,
                                            md: 12
                                        },
                                        size: {
                                            lg: 3,
                                            md: 3
                                        }
                                    }),
                                    new FieldHelper({
                                        id: 'distancePipe',
                                        title: {
                                            key: 'object.distancePipe',
                                            fallback: 'Abstand Rohranfang zur Schachtmitte'
                                        },
                                        accessor: 'UntererAnschlusspunkt.DistanceManholeCenter',
                                        columnSize: {
                                            lg: 12,
                                            md: 12
                                        },
                                        size: {
                                            lg: 5,
                                            md: 5
                                        }
                                    })
                                ]
                            })
                        ]
                    })
                ]
            }),
            new CardHelper({
                id: 'connectionmaincanal',
                title: {
                    key: 'object.connectionmaincanal',
                    fallback: 'Anschluss im Hauptkanal'
                },
                items: [
                    new FieldHelper({
                        id: 'name',
                        title: {
                            key: 'common.name',
                            fallback: 'Name'
                        },
                        accessor: 'DWA-M-150_G_Objektbezeichner',
                        columnSize: {
                            lg: 12,
                            md: 12
                        },
                        size: {
                            lg: 2,
                            md: 2
                        }
                    })
                ]
            }),
            new CardHelper({
                id: 'pipe',
                items: [
                    new CardCardHelper({
                        id: 'pipeCard',
                        items: [
                            new CardHelper({
                                id: 'pipeInvertTop',
                                title: {
                                    key: 'dwa_m_150.common.pipeInvertTop',
                                    fallback: 'Rohrsohle oben'
                                },
                                items: [
                                    new FieldHelper({
                                        id: 'xCoordinate',
                                        title: {
                                            key: 'object.xCoordinate',
                                            fallback: 'Rechtswert'
                                        },
                                        accessor: 'RohrsohleOben.DWA-M150_M_Rechtswert',
                                        columnSize: {
                                            lg: 12,
                                            md: 12
                                        },
                                        size: {
                                            lg: 3,
                                            md: 3,
                                            sm: 2,
                                            xs: 2
                                        }
                                    }),
                                    new FieldHelper({
                                        id: 'yCoordinate',
                                        title: {
                                            key: 'object.yCoordinate',
                                            fallback: 'Hochwert'
                                        },
                                        accessor: 'RohrsohleOben.DWA-M150_M_Hochwert',
                                        columnSize: {
                                            lg: 12,
                                            md: 12
                                        },
                                        size: {
                                            lg: 3,
                                            md: 3,
                                            sm: 2,
                                            xs: 2
                                        }
                                    }),
                                    new FieldHelper({
                                        id: 'posistionPrecision',
                                        title: {
                                            key: 'dwa_m_150.common.positionPrecision',
                                            fallback: 'Lagegenauigkeitsstufe'
                                        },
                                        accessor: 'RohrsohleOben.DWA-M150_L_HG203',
                                        columnSize: {
                                            lg: 12,
                                            md: 12
                                        },
                                        size: {
                                            lg: 3,
                                            md: 3,
                                            sm: 2,
                                            xs: 2
                                        }
                                    }),
                                    new FieldHelper({
                                        id: 'heightPrecision',
                                        title: {
                                            key: 'dwa_m_150.common.heightPrecision',
                                            fallback: 'Höhengenauigkeitsstufe'
                                        },
                                        accessor: 'RohrsohleOben.DWA-M150_L_HG205',
                                        columnSize: {
                                            lg: 12,
                                            md: 12
                                        },
                                        size: {
                                            lg: 3,
                                            md: 3,
                                            sm: 2,
                                            xs: 2
                                        }
                                    })
                                ]
                            }),
                            new CardHelper({
                                id: 'pipeInvertBottom',
                                title: {
                                    key: 'dwa_m_150.common.pipeInvertBottom',
                                    fallback: 'Rohrsohle unten'
                                },
                                items: [
                                    new FieldHelper({
                                        id: 'xCoordinate',
                                        title: {
                                            key: 'object.xCoordinate',
                                            fallback: 'Rechtswert'
                                        },
                                        accessor: 'RohrsohleUnten.DWA-M150_M_Rechtswert',
                                        columnSize: {
                                            lg: 12,
                                            md: 12
                                        },
                                        size: {
                                            lg: 3,
                                            md: 3,
                                            sm: 2,
                                            xs: 2
                                        }
                                    }),
                                    new FieldHelper({
                                        id: 'yCoordinate',
                                        title: {
                                            key: 'object.yCoordinate',
                                            fallback: 'Hochwert'
                                        },
                                        accessor: 'RohrsohleUnten.DWA-M150_M_Hochwert',
                                        columnSize: {
                                            lg: 12,
                                            md: 12
                                        },
                                        size: {
                                            lg: 3,
                                            md: 3,
                                            sm: 2,
                                            xs: 2
                                        }
                                    }),
                                    new FieldHelper({
                                        id: 'posistionPrecision',
                                        title: {
                                            key: 'dwa_m_150.common.positionPrecision',
                                            fallback: 'Lagegenauigkeitsstufe'
                                        },
                                        accessor: 'RohrsohleUnten.DWA-M150_L_HG203',
                                        columnSize: {
                                            lg: 12,
                                            md: 12
                                        },
                                        size: {
                                            lg: 3,
                                            md: 3,
                                            sm: 2,
                                            xs: 2
                                        }
                                    }),
                                    new FieldHelper({
                                        id: 'heightPrecision',
                                        title: {
                                            key: 'dwa_m_150.common.heightPrecision',
                                            fallback: 'Höhengenauigkeitsstufe'
                                        },
                                        accessor: 'RohrsohleUnten.DWA-M150_L_HG205',
                                        columnSize: {
                                            lg: 12,
                                            md: 12
                                        },
                                        size: {
                                            lg: 3,
                                            md: 3,
                                            sm: 2,
                                            xs: 2
                                        }
                                    })
                                ]
                            })
                        ]
                    })
                ]
            }),
            new CardHelper({
                id: 'location',
                title: {
                    key: 'dwa_m_150.common.dimension',
                    fallback: 'Maße'
                },
                items: [
                    new FieldHelper({
                        id: 'profileType',
                        title: {
                            key: 'object.profileType',
                            fallback: 'Profilart'
                        },
                        accessor: 'DWA-M150_L_HG305',
                        field: new ComplexTextBoxHelper()
                    }),
                    new FieldHelper({
                        id: 'stateProfile',
                        title: {
                            key: 'dwa_m_150.common.stateProfile',
                            fallback: 'Status Profilangaben'
                        },
                        accessor: 'DWA-M150_L_HG315',
                        field: new ComplexTextBoxHelper()
                    }),
                    new FieldHelper({
                        id: 'profileHeight',
                        title: {
                            key: 'object.profileHeight',
                            fallback: 'Profilhöhe'
                        },
                        accessor: 'DWA-M150_L_HG307'
                    }),
                    new FieldHelper({
                        id: 'profileWidth',
                        title: {
                            key: 'object.profileWidth',
                            fallback: 'Profilbreite'
                        },
                        accessor: 'DWA-M150_L_HG306'
                    }),
                    new FieldHelper({
                        id: 'pipeLength',
                        title: {
                            key: 'object.pipeLength',
                            fallback: 'Rohrlänge'
                        },
                        accessor: 'DWA-M150_L_HG314'
                    }),
                    new FieldHelper({
                        id: 'middleDepth',
                        title: {
                            key: 'dwa_m_150.common.middleDepth',
                            fallback: 'Mittlere Tiefe'
                        },
                        accessor: 'DWA-M150_L_HG312'
                    }),
                    new FieldHelper({
                        id: 'sectionDip',
                        title: {
                            key: 'dwa_m_150.common.sectionDip',
                            fallback: 'Haltungsgefälle'
                        },
                        accessor: 'DWA-M150_L_HG311'
                    }),
                    new FieldHelper({
                        id: 'wallThickness',
                        title: {
                            key: 'dwa_m_150.common.wallThickness',
                            fallback: 'Wanddicke'
                        },
                        accessor: 'DWA-M150_L_HG410'
                    })
                ]
            }),
            new CardHelper({
                id: 'dimension',
                title: {
                    key: 'common.position',
                    fallback: 'Standort'
                },
                items: [
                    new FieldHelper({
                        id: 'street',
                        title: {
                            key: 'common.street',
                            fallback: 'Straße'
                        },
                        accessor: 'DWA-M150_L_HG102',
                        field: new ComplexTextBoxHelper()
                    }),
                    new FieldHelper({
                        id: 'number',
                        title: {
                            key: 'common.number',
                            fallback: 'Nr.'
                        },
                        accessor: 'DWA-M150_L_HG997'
                    }),
                    new FieldHelper({
                        id: 'locationKey',
                        title: {
                            key: 'dwa_m_150.common.locationKey',
                            fallback: 'Gebietsschlüssel'
                        },
                        accessor: 'DWA-M150_L_HG106',
                        field: new ComplexTextBoxHelper()
                    }),
                    new FieldHelper({
                        id: 'district',
                        title: {
                            key: 'common.district',
                            fallback: 'Ortsteil'
                        },
                        accessor: 'DWA-M150_L_HG104',
                        field: new ComplexTextBoxHelper()
                    }),
                    new FieldHelper({
                        id: 'communityKey',
                        title: {
                            key: 'dwa_m_150.common.communityKey',
                            fallback: 'Gemeindeschlüssel'
                        },
                        accessor: 'DWA-M150_L_HG105',
                        field: new ComplexTextBoxHelper()
                    }),
                    new FieldHelper({
                        id: 'terrainType',
                        title: {
                            key: 'common.terrainType',
                            fallback: 'Lage im Verkehrsraum'
                        },
                        accessor: 'DWA-M150_L_HG404',
                        field: new ComplexTextBoxHelper()
                    }),
                    new FieldHelper({
                        id: 'hinterlandKey',
                        title: {
                            key: 'dwa_m_150.common.hinterlandKey',
                            fallback: 'Einzugsgebietschlüssel'
                        },
                        accessor: 'DWA-M150_L_HG107',
                        field: new ComplexTextBoxHelper()
                    }),
                    new FieldHelper({
                        id: 'defecatorNumber',
                        title: {
                            key: 'dwa_m_150.common.defecatorNumber',
                            fallback: 'Nummer der Kläranlage'
                        },
                        accessor: 'DWA-M150_L_HG108',
                        field: new ComplexTextBoxHelper()
                    })
                ]
            }),
            new CardHelper({
                id: 'environmentalParameters',
                title: {
                    key: 'object.environmentalParameters',
                    fallback: 'Umweltparameter'
                },
                items: [
                    new FieldHelper({
                        id: 'waterConservationArea',
                        title: {
                            key: 'object.waterConservationArea',
                            fallback: 'Wasserschutzzone'
                        },
                        accessor: 'DWA-M150_L_HG403',
                        field: new ComplexTextBoxHelper()
                    }),
                    new FieldHelper({
                        id: 'jamFrequency',
                        title: {
                            key: 'dwa_m_150.common.jamFrequency',
                            fallback: 'Einstauhäufigkeit'
                        },
                        accessor: 'DWA-M150_L_HG408',
                        field: new ComplexTextBoxHelper()
                    }),
                    new FieldHelper({
                        id: 'groundwaterDistance',
                        title: {
                            key: 'object.groundwaterDistance',
                            fallback: 'Grundwasserabstand'
                        },
                        accessor: 'DWA-M150_L_HG405',
                        field: new ComplexTextBoxHelper()
                    }),
                    new FieldHelper({
                        id: 'terrainGroup',
                        title: {
                            key: 'dwa_m_150.common.terrainGroup',
                            fallback: 'Bodengruppe'
                        },
                        accessor: 'DWA-M150_L_HG409',
                        field: new ComplexTextBoxHelper()
                    }),
                    new FieldHelper({
                        id: 'floodingArea',
                        title: {
                            key: 'object.floodingArea',
                            fallback: 'Überschwemmungsgebiet'
                        },
                        accessor: 'DWA-M150_L_HG406',
                        field: new CheckboxHelper()
                    }),
                    new FieldHelper({
                        id: 'stockType',
                        title: {
                            key: 'dwa_m_150.common.stockType',
                            fallback: 'Lagerungsart'
                        },
                        accessor: 'DWA-M150_L_HG411',
                        field: new ComplexTextBoxHelper()
                    })
                ]
            }),
            new CardHelper({
                id: 'coating',
                title: {
                    key: 'object.coating',
                    fallback: 'Auskleidung'
                },
                items: [
                    new FieldHelper({
                        id: 'profileCoating',
                        title: {
                            key: 'dwa_m_150.common.profileCoating',
                            fallback: 'Profilauskleidung'
                        },
                        accessor: 'DWA-M150_L_HG308',
                        field: new ComplexTextBoxHelper()
                    }),
                    new FieldHelper({
                        id: 'profileCoatingMaterial',
                        title: {
                            key: 'dwa_m_150.common.profileCoatingMaterial',
                            fallback: 'Material Profilauskleidung'
                        },
                        accessor: 'DWA-M150_L_HG309',
                        field: new ComplexTextBoxHelper()
                    }),
                    new FieldHelper({
                        id: 'profileCoatingSelf',
                        title: {
                            key: 'dwa_m_150.common.profileCoatingSelf',
                            fallback: 'Profilauskleidung selbsttragend'
                        },
                        accessor: 'DWA-M150_L_HG316',
                        field: new CheckboxHelper()
                    })
                ]
            }),
            new CardHelper({
                id: 'misc',
                title: {
                    key: 'common.other',
                    fallback: 'Sonstiges'
                },
                items: [
                    new FieldHelper({
                        id: 'altLateralName',
                        title: {
                            key: 'dwa_m_150.lateral.altLateralName',
                            fallback: 'Alternative Leitungsbezeichung'
                        },
                        accessor: 'DWA-M150_L_HG002'
                    }),
                    new FieldHelper({
                        id: 'property',
                        title: {
                            key: 'dwa_m_150.common.property',
                            fallback: 'Eigentum'
                        },
                        accessor: 'DWA-M150_L_HG402',
                        field: new ComplexTextBoxHelper()
                    }),
                    new FieldHelper({
                        id: 'lateralType',
                        title: {
                            key: 'dwa_m_150.lateral.lateralType',
                            fallback: 'Leitungsart'
                        },
                        accessor: 'DWA-M150_L_HG313',
                        field: new ComplexTextBoxHelper()
                    }),
                    new FieldHelper({
                        id: 'stateData',
                        title: {
                            key: 'dwa_m_150.common.stateData',
                            fallback: 'Status Daten'
                        },
                        accessor: 'DWA-M150_L_HG407',
                        field: new ComplexTextBoxHelper()
                    }),
                    new FieldHelper({
                        id: 'function',
                        title: {
                            key: 'dwa_m_150.common.function',
                            fallback: 'Funktionszustand'
                        },
                        accessor: 'DWA-M150_L_HG401',
                        field: new ComplexTextBoxHelper()
                    })
                ]
            }),
            new CardHelper({
                id: 'ext',
                title: {
                    key: 'common.extension',
                    fallback: 'Erweiterungen'
                },
                items: [
                    new CardCardHelper({
                        id: 'extensionCard',
                        items: [
                            new CardHelper({
                                id: 'lowerConnectionPoint',
                                title: {
                                    key: 'object.lowerConnectionPoint',
                                    fallback: 'Unterer Anschlusspunkt'
                                },
                                items: [
                                    new FieldHelper({
                                        id: 'occupancyType',
                                        title: {
                                            key: 'dwa_m_150.lateral.occupancyType',
                                            fallback: 'Belegungsart'
                                        },
                                        accessor: 'DWA-M150_C_KG922_SES',
                                        columnSize: {
                                            lg: 12,
                                            md: 12
                                        },
                                        size: {
                                            lg: 3,
                                            md: 3,
                                            sm: 2,
                                            xs: 2
                                        }
                                    }),
                                    new FieldHelper({
                                        id: 'design',
                                        title: {
                                            key: 'dwa_m_150.lateral.design',
                                            fallback: 'Ausführung'
                                        },
                                        accessor: 'DWA-M-150_C_KG923_SES',
                                        columnSize: {
                                            lg: 12,
                                            md: 12
                                        },
                                        size: {
                                            lg: 3,
                                            md: 3,
                                            sm: 2,
                                            xs: 2
                                        }
                                    }),
                                    new FieldHelper({
                                        id: 'presentationType',
                                        title: {
                                            key: 'dwa_m_150.lateral.presentationType',
                                            fallback: 'Präsentationsart'
                                        },
                                        accessor: 'DWA-M150_C_KG925_SES',
                                        columnSize: {
                                            lg: 12,
                                            md: 12
                                        },
                                        size: {
                                            lg: 3,
                                            md: 3,
                                            sm: 2,
                                            xs: 2
                                        }
                                    })
                                ]
                            }),
                            new CardHelper({
                                id: 'extFields',
                                items: [
                                    new FieldHelper({
                                        id: 'pipeLength',
                                        title: {
                                            key: 'object.pipeLength',
                                            fallback: 'Rohrlänge'
                                        },
                                        accessor: 'DWA-M150_L_HG911_SES',
                                        columnSize: {
                                            lg: 12,
                                            md: 12
                                        },
                                        size: {
                                            lg: 3,
                                            md: 3,
                                            sm: 2,
                                            xs: 2
                                        }
                                    }),
                                    new FieldHelper({
                                        id: 'stateCoords',
                                        title: {
                                            key: 'dwa_m_150.lateral.stateCoords',
                                            fallback: 'Status Koordinaten'
                                        },
                                        accessor: 'DWA-M150_L_HG924_SES',
                                        columnSize: {
                                            lg: 12,
                                            md: 12
                                        },
                                        size: {
                                            lg: 3,
                                            md: 3,
                                            sm: 2,
                                            xs: 2
                                        },
                                        field: new ComplexTextBoxHelper()
                                    })
                                ]
                            })
                        ]
                    })
                ]
            })
        ]),
        manhole: new CanalObject([
            new CardHelper({
                id: 'general',
                title: {
                    key: 'common.general',
                    fallback: 'Allgemein'
                },
                items: [
                    new FieldHelper({
                        id: 'manholeName',
                        title: {
                            key: 'object.manholeName',
                            fallback: 'Schachtname'
                        },
                        accessor: 'DWA-M150_S_KG001'
                    }),
                    new FieldHelper({
                        id: 'constructionYear',
                        title: {
                            key: 'object.constructionYear',
                            fallback: 'Baujahr'
                        },
                        accessor: 'DWA-M150_S_KG303'
                    }),
                    new FieldHelper({
                        id: 'manholeDepth',
                        title: {
                            key: 'object.manholeDepth',
                            fallback: 'Schachttiefe'
                        },
                        accessor: 'DWA-M150_S_KG211'
                    }),
                    new FieldHelper({
                        id: 'material',
                        title: {
                            key: 'object.material',
                            fallback: 'Material'
                        },
                        accessor: 'DWA-M150_S_KG304',
                        field: new ComplexTextBoxHelper()
                    }),
                    new FieldHelper({
                        id: 'sewerType',
                        title: {
                            key: 'dwa_m_150.common.sewerType',
                            fallback: 'Kanalart'
                        },
                        accessor: 'DWA-M150_S_KG301',
                        field: new ComplexTextBoxHelper()
                    }),
                    new FieldHelper({
                        id: 'sewerUsage',
                        title: {
                            key: 'dwa_m_150.common.sewerUsage',
                            fallback: 'Kanalnutzung'
                        },
                        accessor: 'DWA-M150_S_KG302',
                        field: new ComplexTextBoxHelper()
                    }),
                    new FieldHelper({
                        id: 'remark',
                        title: {
                            key: 'common.remark',
                            fallback: 'Bemerkung'
                        },
                        accessor: 'DWA-M150_S_KG999'
                    }),
                    new FieldHelper({
                        id: 'document',
                        title: {
                            key: 'dwa_m_150.common.document',
                            fallback: 'Dokument'
                        },
                        accessor: 'DWA-M150_S_KG998'
                    })
                ]
            }),
            new CardHelper({
                id: 'geodata',
                title: {
                    key: 'object.geodata',
                    fallback: 'Geodaten'
                },
                items: [
                    new FieldHelper({
                        id: 'xCoordinateCover',
                        title: {
                            key: 'object.xCoordinateCover',
                            fallback: 'Rechtswert Deckel'
                        },
                        accessor: 'DWA-M150_S_KG201'
                    }),
                    new FieldHelper({
                        id: 'invertXCoord',
                        title: {
                            key: 'dwa_m_150.manhole.invertXCoord',
                            fallback: 'Rechtswert Sohle'
                        },
                        accessor: 'DWA-M150_M_Rechtswert'
                    }),
                    new FieldHelper({
                        id: 'yCoordinateCover',
                        title: {
                            key: 'object.yCoordinateCover',
                            fallback: 'Hochwert Deckel'
                        },
                        accessor: 'DWA-M150_S_KG202'
                    }),
                    new FieldHelper({
                        id: 'invertYCoord',
                        title: {
                            key: 'dwa_m_150.manhole.invertYCoord',
                            fallback: 'Hochwert Sohle'
                        },
                        accessor: 'DWA-M150_M_Hochwert'
                    }),
                    new FieldHelper({
                        id: 'coverHeight',
                        title: {
                            key: 'object.coverHeight',
                            fallback: 'Deckelhöhe'
                        },
                        accessor: 'DWA-M150_S_KG204'
                    }),
                    new FieldHelper({
                        id: 'invertLevel',
                        title: {
                            key: 'object.invertLevel',
                            fallback: 'Sohlhöhe'
                        },
                        accessor: 'DWA-M150_M_Sohlhoehe'
                    }),
                    new FieldHelper({
                        id: 'posPrecisionCover',
                        title: {
                            key: 'dwa_m_150.manhole.posPrecisionCover',
                            fallback: 'Lagegenauigkeit Deckel'
                        },
                        accessor: 'DWA-M150_S_KG203'
                    }),
                    new FieldHelper({
                        id: 'posPrecisionInvert',
                        title: {
                            key: 'dwa_m_150.manhole.posPrecisionInvert',
                            fallback: 'Lagegenauigkeit Sohle'
                        },
                        accessor: 'DWA-M150_S_KG208'
                    }),
                    new FieldHelper({
                        id: 'heightPrecisionCover',
                        title: {
                            key: 'dwa_m_150.manhole.heightPrecisionCover',
                            fallback: 'Höhengenauigkeit Deckel'
                        },
                        accessor: 'DWA-M150_S_KG205'
                    }),
                    new FieldHelper({
                        id: 'heightPrecisionInvert',
                        title: {
                            key: 'dwa_m_150.manhole.heightPrecisionInvert',
                            fallback: 'Höhengenauigkeit Sohle'
                        },
                        accessor: 'DWA-M150_S_KG210'
                    })
                ]
            }),
            new CardHelper({
                id: 'tabs',
                items: [
                    new TabCardHelper({
                        id: 'manholeTabs',
                        defaultActiveKey: 'formDimension',
                        items: [
                            new TabHelper({
                                id: 'formDimension',
                                title: {
                                    key: 'dwa_m_150.manhole.formDimension',
                                    fallback: 'Form und Maße'
                                },
                                eventKey: 'formDimension',
                                items: [
                                    new FieldHelper({
                                        id: 'manholeForm',
                                        title: {
                                            key: 'dwa_m_150.manhole.manholeForm',
                                            fallback: 'Schachtform'
                                        },
                                        accessor: 'DWA-M150_S_KG307',
                                        columnSize: {
                                            lg: 12,
                                            md: 12
                                        },
                                        size: {
                                            lg: 2,
                                            md: 2
                                        },
                                        field: new ComplexTextBoxHelper()
                                    }),
                                    new FieldHelper({
                                        id: 'manholeLength',
                                        title: {
                                            key: 'dwa_m_150.manhole.manholeLength',
                                            fallback: 'Schachtlänge'
                                        },
                                        accessor: 'DWA-M150_S_KG308',
                                        columnSize: {
                                            lg: 12,
                                            md: 12
                                        },
                                        size: {
                                            lg: 2,
                                            md: 2
                                        }
                                    }),
                                    new FieldHelper({
                                        id: 'manholeWidth',
                                        title: {
                                            key: 'dwa_m_150.manhole.manholeWidth',
                                            fallback: 'Schachtbreite'
                                        },
                                        accessor: 'DWA-M150_S_KG309',
                                        columnSize: {
                                            lg: 12,
                                            md: 12
                                        },
                                        size: {
                                            lg: 2,
                                            md: 2
                                        }
                                    })
                                ]
                            }),
                            new TabHelper({
                                id: 'covering',
                                title: {
                                    key: 'object.covering',
                                    fallback: 'Abdeckung'
                                },
                                eventKey: 'covering',
                                items: [
                                    new FieldHelper({
                                        id: 'coverShape',
                                        title: {
                                            key: 'object.coverShape',
                                            fallback: 'Deckelform'
                                        },
                                        accessor: 'DWA-M150_S_KG310',
                                        field: new ComplexTextBoxHelper()
                                    }),
                                    new FieldHelper({
                                        id: 'coverClass',
                                        title: {
                                            key: 'dwa_m_150.manhole.coverClass',
                                            fallback: 'Deckelklasse'
                                        },
                                        accessor: 'DWA-M150_S_KG312',
                                        field: new ComplexTextBoxHelper()
                                    }),
                                    new FieldHelper({
                                        id: 'coverLength',
                                        title: {
                                            key: 'object.coverLength',
                                            fallback: 'Deckellänge'
                                        },
                                        accessor: 'DWA-M150_S_KG314'
                                    }),
                                    new FieldHelper({
                                        id: 'coverWidth',
                                        title: {
                                            key: 'object.coverWidth',
                                            fallback: 'Deckelbreite'
                                        },
                                        accessor: 'DWA-M150_S_KG313'
                                    }),
                                    new FieldHelper({
                                        id: 'coveringMaterial',
                                        title: {
                                            key: 'object.coveringMaterial',
                                            fallback: 'Deckelmaterial'
                                        },
                                        accessor: 'DWA-M150_S_KG311',
                                        field: new ComplexTextBoxHelper()
                                    }),
                                    new FieldHelper({
                                        id: 'coverScrewed',
                                        title: {
                                            key: 'dwa_m_150.manhole.coverScrewed',
                                            fallback: 'Deckel verschraubt'
                                        },
                                        accessor: 'DWA-M150_S_KG315',
                                        field: new CheckboxHelper()
                                    })
                                ]
                            }),
                            new TabHelper({
                                id: 'climbingAids',
                                title: {
                                    key: 'dwa_m_150.manhole.climbingAids',
                                    fallback: 'Steighilfen'
                                },
                                eventKey: 'climbingAids',
                                items: [
                                    new FieldHelper({
                                        id: 'climbingAid',
                                        title: {
                                            key: 'dwa_m_150.manhole.climbingAid',
                                            fallback: 'Steighilfe'
                                        },
                                        accessor: 'DWA-M150_S_KG323',
                                        columnSize: {
                                            lg: 12,
                                            md: 12
                                        },
                                        size: {
                                            lg: 2,
                                            md: 2
                                        },
                                        field: new ComplexTextBoxHelper()
                                    }),
                                    new FieldHelper({
                                        id: 'countClimbingAid',
                                        title: {
                                            key: 'dwa_m_150.manhole.countClimbingAid',
                                            fallback: 'Anzahl Steigeisen'
                                        },
                                        accessor: 'DWA-M150_S_KG324',
                                        columnSize: {
                                            lg: 12,
                                            md: 12
                                        },
                                        size: {
                                            lg: 2,
                                            md: 2
                                        }
                                    }),
                                    new FieldHelper({
                                        id: 'climbingAidMaterial',
                                        title: {
                                            key: 'dwa_m_150.manhole.climbingAidMaterial',
                                            fallback: 'Steighilfenwerkstoff'
                                        },
                                        accessor: 'DWA-M150_S_KG325',
                                        columnSize: {
                                            lg: 12,
                                            md: 12
                                        },
                                        size: {
                                            lg: 2,
                                            md: 2
                                        },
                                        field: new ComplexTextBoxHelper()
                                    })
                                ]
                            }),
                            new TabHelper({
                                id: 'bottomPart',
                                title: {
                                    key: 'object.bottomPart',
                                    fallback: 'Unterteil'
                                },
                                eventKey: 'bottomPart',
                                items: [
                                    new FieldHelper({
                                        id: 'channelShape',
                                        title: {
                                            key: 'object.channelShape',
                                            fallback: 'Gerinneform'
                                        },
                                        accessor: 'DWA-M150_S_KG316',
                                        columnSize: {
                                            lg: 12,
                                            md: 12
                                        },
                                        size: {
                                            lg: 2,
                                            md: 2
                                        },
                                        field: new ComplexTextBoxHelper()
                                    }),
                                    new FieldHelper({
                                        id: 'channelLength',
                                        title: {
                                            key: 'dwa_m_150.manhole.channelLength',
                                            fallback: 'Gerinnelänge'
                                        },
                                        accessor: 'DWA-M150_S_KG319',
                                        columnSize: {
                                            lg: 12,
                                            md: 12
                                        },
                                        size: {
                                            lg: 2,
                                            md: 2
                                        }
                                    }),
                                    new FieldHelper({
                                        id: 'channelWidth',
                                        title: {
                                            key: 'dwa_m_150.manhole.channelWidth',
                                            fallback: 'Gerinnebreite'
                                        },
                                        accessor: 'DWA-M150_S_KG318',
                                        columnSize: {
                                            lg: 12,
                                            md: 12
                                        },
                                        size: {
                                            lg: 2,
                                            md: 2
                                        }
                                    }),
                                    new FieldHelper({
                                        id: 'invertMaterial',
                                        title: {
                                            key: 'object.invertMaterial',
                                            fallback: 'Gerinnematerial'
                                        },
                                        accessor: 'DWA-M150_S_KG317',
                                        columnSize: {
                                            lg: 12,
                                            md: 12
                                        },
                                        size: {
                                            lg: 2,
                                            md: 2
                                        },
                                        field: new ComplexTextBoxHelper()
                                    }),
                                    new FieldHelper({
                                        id: 'bermeMaterial',
                                        title: {
                                            key: 'dwa_m_150.manhole.bermeMaterial',
                                            fallback: 'Bermematerial'
                                        },
                                        accessor: 'DWA-M150_S_KG320',
                                        columnSize: {
                                            lg: 12,
                                            md: 12
                                        },
                                        size: {
                                            lg: 2,
                                            md: 2
                                        }
                                    })
                                ]
                            })
                        ]
                    })
                ]
            }),
            new CardHelper({
                id: 'connected',
                title: {
                    key: 'object.connectedObjects',
                    fallback: 'Angeschlossene Objekte'
                },
                items: [
                    new TableHelper({
                        id: 'connectedObjects',
                        accessor: 'connectedObjects',
                        items: [
                            new FormColumnHelper({
                                id: 'typ',
                                title: {
                                    key: 'object.typ',
                                    fallback: 'Typ'
                                },
                                accessor: 'type',
                                renderer: 'imgRenderer'
                            }),
                            new FormColumnHelper({
                                id: 'name',
                                title: {
                                    key: 'common.name',
                                    fallback: 'Name'
                                },
                                accessor: 'name'
                            }),
                            new FormColumnHelper({
                                id: 'direction',
                                title: {
                                    key: 'object.direction',
                                    fallback: 'Richtung'
                                },
                                accessor: 'direction',
                                renderer: 'formTableIconDirectionRenderer'
                            }),
                            new FormColumnHelper({
                                id: 'baseAltitude',
                                title: {
                                    key: 'object.baseAltitude',
                                    fallback: 'Sohlhöhe [mNN]'
                                },
                                accessor: 'Sohlhoehe'
                            }),
                            new FormColumnHelper({
                                id: 'profileWidthTable',
                                title: {
                                    key: 'object.profileWidthTable',
                                    fallback: 'Profilbreite [mm]'
                                },
                                accessor: 'Profilbreite'
                            }),
                            new FormColumnHelper({
                                id: 'profileHeightTable',
                                title: {
                                    key: 'object.profileHeightTable',
                                    fallback: 'Profilhöhe [mm]'
                                },
                                accessor: 'Profilhoehe'
                            })
                        ]
                    })
                ]
            }),
            new CardHelper({
                id: 'location',
                title: {
                    key: 'common.position',
                    fallback: 'Standort'
                },
                items: [
                    new FieldHelper({
                        id: 'street',
                        title: {
                            key: 'common.street',
                            fallback: 'Straße'
                        },
                        accessor: 'DWA-M150_S_KG102',
                        field: new ComplexTextBoxHelper()
                    }),
                    new FieldHelper({
                        id: 'number',
                        title: {
                            key: 'common.number',
                            fallback: 'Nr.'
                        },
                        accessor: 'DWA-M150_S_KG997'
                    }),
                    new FieldHelper({
                        id: 'locationKey',
                        title: {
                            key: 'dwa_m_150.common.locationKey',
                            fallback: 'Gebietsschlüssel'
                        },
                        accessor: 'DWA-M150_S_KG106',
                        field: new ComplexTextBoxHelper()
                    }),
                    new FieldHelper({
                        id: 'district',
                        title: {
                            key: 'common.district',
                            fallback: 'Ortsteil'
                        },
                        accessor: 'DWA-M150_S_KG104',
                        field: new ComplexTextBoxHelper()
                    }),
                    new FieldHelper({
                        id: 'communityKey',
                        title: {
                            key: 'dwa_m_150.common.communityKey',
                            fallback: 'Gemeindeschlüssel'
                        },
                        accessor: 'DWA-M150_S_KG105',
                        field: new ComplexTextBoxHelper()
                    }),
                    new FieldHelper({
                        id: 'terrainType',
                        title: {
                            key: 'common.terrainType',
                            fallback: 'Lage im Verkehrsraum'
                        },
                        accessor: 'DWA-M150_S_KG404',
                        field: new ComplexTextBoxHelper()
                    }),
                    new FieldHelper({
                        id: 'hinterlandKey',
                        title: {
                            key: 'dwa_m_150.common.hinterlandKey',
                            fallback: 'Einzugsgebietschlüssel'
                        },
                        accessor: 'DWA-M150_S_KG107',
                        field: new ComplexTextBoxHelper()
                    }),
                    new FieldHelper({
                        id: 'defecatorNumber',
                        title: {
                            key: 'dwa_m_150.common.defecatorNumber',
                            fallback: 'Nummer der Kläranlage'
                        },
                        accessor: 'DWA-M150_S_KG108',
                        field: new ComplexTextBoxHelper()
                    })
                ]
            }),
            new CardHelper({
                id: 'environmentalParameters',
                title: {
                    key: 'object.environmentalParameters',
                    fallback: 'Umweltparameter'
                },
                items: [
                    new FieldHelper({
                        id: 'waterConservationArea',
                        title: {
                            key: 'object.waterConservationArea',
                            fallback: 'Wasserschutzzone'
                        },
                        accessor: 'DWA-M150_S_KG403',
                        field: new ComplexTextBoxHelper()
                    }),
                    new FieldHelper({
                        id: 'jamFrequency',
                        title: {
                            key: 'dwa_m_150.common.jamFrequency',
                            fallback: 'Einstauhäufigkeit'
                        },
                        accessor: 'DWA-M150_S_KG408',
                        field: new ComplexTextBoxHelper()
                    }),
                    new FieldHelper({
                        id: 'groundwaterDistance',
                        title: {
                            key: 'object.groundwaterDistance',
                            fallback: 'Grundwasserabstand'
                        },
                        accessor: 'DWA-M150_S_KG405',
                        field: new ComplexTextBoxHelper()
                    }),
                    new FieldHelper({
                        id: 'terrainGroup',
                        title: {
                            key: 'dwa_m_150.common.terrainGroup',
                            fallback: 'Bodengruppe'
                        },
                        accessor: 'DWA-M150_S_KG409',
                        field: new ComplexTextBoxHelper()
                    }),
                    new FieldHelper({
                        id: 'floodingArea',
                        title: {
                            key: 'object.floodingArea',
                            fallback: 'Überschwemmungsgebiet'
                        },
                        accessor: 'DWA-M150_S_KG406',
                        field: new CheckboxHelper()
                    })
                ]
            }),
            new CardHelper({
                id: 'innerprotection',
                title: {
                    key: 'object.interiorProtection',
                    fallback: 'Innenschutz'
                },
                items: [
                    new FieldHelper({
                        id: 'interiorProtection',
                        title: {
                            key: 'object.interiorProtection',
                            fallback: 'Innenschutz'
                        },
                        accessor: 'DWA-M150_S_KG321',
                        field: new ComplexTextBoxHelper()
                    }),
                    new FieldHelper({
                        id: 'interiorProtectionMaterial',
                        title: {
                            key: 'dwa_m_150.manhole.interiorProtectionMaterial',
                            fallback: 'Innenschutzmaterial'
                        },
                        accessor: 'DWA-M150_S_KG322',
                        field: new ComplexTextBoxHelper()
                    })
                ]
            }),
            new CardHelper({
                id: 'misc',
                title: {
                    key: 'common.other',
                    fallback: 'Sonstiges'
                },
                items: [
                    new FieldHelper({
                        id: 'altManholeName',
                        title: {
                            key: 'dwa_m_150.manhole.altManholeName',
                            fallback: 'Alternative Schachtbezeichnung'
                        },
                        accessor: 'DWA-M150_S_KG002'
                    }),
                    new FieldHelper({
                        id: 'property',
                        title: {
                            key: 'dwa_m_150.common.property',
                            fallback: 'Eigentum'
                        },
                        accessor: 'DWA-M150_S_KG402',
                        field: new ComplexTextBoxHelper()
                    }),
                    new FieldHelper({
                        id: 'function',
                        title: {
                            key: 'dwa_m_150.common.function',
                            fallback: 'Funktionszustand'
                        },
                        accessor: 'DWA-M150_S_KG401',
                        field: new ComplexTextBoxHelper()
                    }),
                    new FieldHelper({
                        id: 'stateData',
                        title: {
                            key: 'dwa_m_150.common.stateData',
                            fallback: 'Status Daten'
                        },
                        accessor: 'DWA-M150_S_KG407',
                        field: new ComplexTextBoxHelper()
                    }),
                    new FieldHelper({
                        id: 'manholeType',
                        title: {
                            key: 'dwa_m_150.manhole.manholeType',
                            fallback: 'Schachtart'
                        },
                        accessor: 'DWA-M150_S_KG305',
                        field: new ComplexTextBoxHelper()
                    }),
                    new FieldHelper({
                        id: 'measurmentTechnic',
                        title: {
                            key: 'dwa_m_150.manhole.measurmentTechnic',
                            fallback: 'Messtechnik'
                        },
                        accessor: 'DWA-M150_S_KG326',
                        field: new ComplexTextBoxHelper()
                    }),
                    new FieldHelper({
                        id: 'buildingType',
                        title: {
                            key: 'dwa_m_150.manhole.buildingType',
                            fallback: 'Bauwerksart'
                        },
                        accessor: 'DWA-M150_S_KG306',
                        field: new ComplexTextBoxHelper()
                    })
                ]
            }),
            new CardHelper({
                id: 'ext',
                title: {
                    key: 'common.extension',
                    fallback: 'Erweiterungen'
                },
                items: [
                    new FieldHelper({
                        id: 'stateCoords',
                        title: {
                            key: 'dwa_m_150.lateral.stateCoords',
                            fallback: 'Status Koordinaten'
                        },
                        accessor: 'DWA-M150_S_KG924_SES',
                        field: new ComplexTextBoxHelper()
                    }),
                    new FieldHelper({
                        id: 'buildDate',
                        title: {
                            key: 'dwa_m_150.common.buildDate',
                            fallback: 'Baudatum'
                        },
                        accessor: 'DWA-M150_S_KG921_SES'
                    }),
                    new FieldHelper({
                        id: 'buildingTypeConnect',
                        title: {
                            key: 'dwa_m_150.manhole.buildingTypeConnect',
                            fallback: 'Bauwerksart (Anschlussschacht)'
                        },
                        accessor: 'DWA-M150_S_KG920_SES',
                        field: new ComplexTextBoxHelper()
                    })
                ]
            })
        ])
    }),

    inspectionname: "",

    inspection: new CanalObjects({
        section: new CanalObject([
            new CardHelper({
                id: 'inspection',
                title: {
                    key: 'inspection.inspection',
                    fallback: 'Inspektion'
                },
                items: [
                    new FieldHelper({
                        id: 'inspectionDirection',
                        title: {
                            key: 'inspection.inspectionDirection',
                            fallback: 'Inspektionsrichtung'
                        },
                        accessor: 'Inspektionsrichtung',
                        field: new ComplexTextBoxHelper()
                    }),
                    new FieldHelper({
                        id: 'inspectionDate',
                        title: {
                            key: 'inspection.inspectionDate',
                            fallback: 'Inspektionsdatum'
                        },
                        accessor: 'Inspektionsbeginn'
                    }),
                    new FieldHelper({
                        id: 'typeInspection',
                        title: {
                            key: 'inspection.typeInspection',
                            fallback: 'Inspektionsart'
                        },
                        accessor: 'DWA-M150_H_HI103',
                        field: new ComplexTextBoxHelper()
                    }),
                    new FieldHelper({
                        id: 'inspectionLength',
                        title: {
                            key: 'inspection.inspectionLength',
                            fallback: 'Inspektionslänge'
                        },
                        accessor: 'Inspektionslaenge'
                    }),
                    new FieldHelper({
                        id: 'videoStorageMedium',
                        title: {
                            key: 'inspection.videoStorageMedium',
                            fallback: 'Videospeichermedium'
                        },
                        accessor: 'DWA-M150_H_HI114',
                        field: new ComplexTextBoxHelper()
                    }),
                    new FieldHelper({
                        id: 'nameStorage',
                        title: {
                            key: 'dwa_m_150.inspection.nameStorage',
                            fallback: 'Name des Speichermediums'
                        },
                        accessor: 'DWA-M150_M_HI115'
                    }),
                    new FieldHelper({
                        id: 'photoStorageMedium',
                        title: {
                            key: 'inspection.photoStorageMedium',
                            fallback: 'Fotospeichermedium'
                        },
                        accessor: 'DWA-M150_H_HI117',
                        field: new ComplexTextBoxHelper()
                    })
                ]
            }),
            new CardHelper({
                id: 'point',
                title: {
                    key: 'inspection.longitudinalPoint',
                    fallback: 'Bezugspunkt in Längsrichtung'
                },
                items: [
                    new FieldHelper({
                        id: 'pointStart',
                        title: {
                            key: 'dwa_m_150.inspection.pointStart',
                            fallback: 'Bezugspunkt Start'
                        },
                        accessor: 'DWA-M150_H_HI102',
                        columnSize: {
                            lg: 12,
                            md: 12
                        },
                        size: {
                            lg: 2,
                            md: 2
                        },
                        field: new ComplexTextBoxHelper()
                    }),
                    new FieldHelper({
                        id: 'distanceTopManhole',
                        title: {
                            key: 'inspection.distanceTopManhole',
                            fallback: 'Abstand oberer Schacht'
                        },
                        accessor: 'IS_CenterPointRefValueTop'
                    }),
                    new FieldHelper({
                        id: 'distanceBottomManhole',
                        title: {
                            key: 'inspection.distanceBottomManhole',
                            fallback: 'Abstand unterer Schacht'
                        },
                        accessor: 'IS_CenterPointRefValueBottom'
                    })
                ]
            }),
            new CardHelper({
                id: 'general',
                title: {
                    key: 'common.general',
                    fallback: 'Allgemein'
                },
                items: [
                    new FieldHelper({
                        id: 'client',
                        title: {
                            key: 'dwa_m_150.inspection.client',
                            fallback: 'Auftraggeber'
                        },
                        accessor: 'DWA-M150_H_HI001'
                    }),
                    new FieldHelper({
                        id: 'projectNumber',
                        title: {
                            key: 'dwa_m_150.inspection.projectNumber',
                            fallback: 'Projektnummer'
                        },
                        accessor: 'DWA-M150_H_HI002'
                    }),
                    new FieldHelper({
                        id: 'company',
                        title: {
                            key: 'dwa_m_150.inspection.company',
                            fallback: 'Firma'
                        },
                        accessor: 'DWA-M150_H_HI111'
                    }),
                    new FieldHelper({
                        id: 'constructionManagement',
                        title: {
                            key: 'dwa_m_150.inspection.constructionManagement',
                            fallback: 'Bauleitung'
                        },
                        accessor: 'DWA-M150_H_HI113'
                    }),
                    new FieldHelper({
                        id: 'inspector',
                        title: {
                            key: 'inspection.inspector',
                            fallback: 'Inspekteur'
                        },
                        accessor: 'DWA-M150_H_HI112'
                    }),
                    new FieldHelper({
                        id: 'weather',
                        title: {
                            key: 'inspection.weather',
                            fallback: 'Wetter'
                        },
                        accessor: 'DWA-M150_H_HI106',
                        field: new ComplexTextBoxHelper()
                    }),
                    new FieldHelper({
                        id: 'cleaning',
                        title: {
                            key: 'inspection.cleaning',
                            fallback: 'Reinigung'
                        },
                        accessor: 'DWA-M150_H_HI107',
                        field: new CheckboxHelper()
                    }),
                    new FieldHelper({
                        id: 'temperature',
                        title: {
                            key: 'inspection.temperature',
                            fallback: 'Temperatur'
                        },
                        accessor: 'DWA-M150_H_HI108'
                    }),
                    new FieldHelper({
                        id: 'floadSafety',
                        title: {
                            key: 'dwa_m_150.inspection.floadSafety',
                            fallback: 'Vorflutsicherung'
                        },
                        accessor: 'DWA-M150_H_HI109',
                        field: new ComplexTextBoxHelper()
                    }),
                    new FieldHelper({
                        id: 'waterSurface',
                        title: {
                            key: 'dwa_m_150.inspection.waterSurface',
                            fallback: 'Wasserspiegel'
                        },
                        accessor: 'DWA-M150_H_HI110'
                    }),
                    new FieldHelper({
                        id: 'inspectionReason',
                        title: {
                            key: 'dwa_m_150.inspection.inspectionReason',
                            fallback: 'Inspektionsgrund'
                        },
                        accessor: 'DWA-M150_H_HI004',
                        field: new ComplexTextBoxHelper()
                    }),
                    new FieldHelper({
                        id: 'editHint',
                        title: {
                            key: 'dwa_m_150.inspection.editHint',
                            fallback: 'Bearbeitungshinweis'
                        },
                        accessor: 'DWA-M150_H_HI008'
                    }),
                    new FieldHelper({
                        id: 'editState',
                        title: {
                            key: 'dwa_m_150.inspection.editState',
                            fallback: 'Bearbeitungsstatus'
                        },
                        accessor: 'DWA-M150_H_HI007'
                    }),
                    new FieldHelper({
                        id: 'cameraSystem',
                        title: {
                            key: 'dwa_m_150.inspection.cameraSystem',
                            fallback: 'Kamerasystem'
                        },
                        accessor: 'DWA-M150_H_HI006'
                    }),
                    new FieldHelper({
                        id: 'inspectionOrderNr',
                        title: {
                            key: 'dwa_m_150.inspection.inspectionOrderNr',
                            fallback: 'Inspektionsauftragsnr'
                        },
                        accessor: 'DWA-M150_H_HI003_BASYS'
                    }),
                    new FieldHelper({
                        id: 'car',
                        title: {
                            key: 'dwa_m_150.inspection.car',
                            fallback: 'Fahrzeug'
                        },
                        accessor: 'DWA-M150_H_HI902'
                    }),
                    new FieldHelper({
                        id: 'remark',
                        title: {
                            key: 'common.remark',
                            fallback: 'Bemerkung'
                        },
                        accessor: 'DWA-M150_H_HI999'
                    }),
                    new FieldHelper({
                        id: 'document',
                        title: {
                            key: 'dwa_m_150.common.document',
                            fallback: 'Dokument'
                        },
                        accessor: 'DWA-M150_H_HI998'
                    })
                ]
            })
        ]),
        lateral: new CanalObject([
            new CardHelper({
                id: 'inspection',
                title: {
                    key: 'inspection.inspection',
                    fallback: 'Inspektion'
                },
                items: [
                    new FieldHelper({
                        id: 'inspectionDirection',
                        title: {
                            key: 'inspection.inspectionDirection',
                            fallback: 'Inspektionsrichtung'
                        },
                        accessor: 'Inspektionsrichtung',
                        field: new ComplexTextBoxHelper()
                    }),
                    new FieldHelper({
                        id: 'inspectionDate',
                        title: {
                            key: 'inspection.inspectionDate',
                            fallback: 'Inspektionsdatum'
                        },
                        accessor: 'Inspektionsbeginn'
                    }),
                    new FieldHelper({
                        id: 'typeInspection',
                        title: {
                            key: 'inspection.typeInspection',
                            fallback: 'Inspektionsart'
                        },
                        accessor: 'DWA-M150_L_HI103',
                        field: new ComplexTextBoxHelper()
                    }),
                    new FieldHelper({
                        id: 'inspectionLength',
                        title: {
                            key: 'inspection.inspectionLength',
                            fallback: 'Inspektionslänge'
                        },
                        accessor: 'Inspektionslaenge'
                    }),
                    new FieldHelper({
                        id: 'videoStorageMedium',
                        title: {
                            key: 'inspection.videoStorageMedium',
                            fallback: 'Videospeichermedium'
                        },
                        accessor: 'DWA-M150_L_HI114',
                        field: new ComplexTextBoxHelper()
                    }),
                    new FieldHelper({
                        id: 'nameStorage',
                        title: {
                            key: 'dwa_m_150.inspection.nameStorage',
                            fallback: 'Name des Speichermediums'
                        },
                        accessor: 'DWA-M150_M_HI115'
                    }),
                    new FieldHelper({
                        id: 'photoStorageMedium',
                        title: {
                            key: 'inspection.photoStorageMedium',
                            fallback: 'Fotospeichermedium'
                        },
                        accessor: 'DWA-M150_L_HI117',
                        field: new ComplexTextBoxHelper()
                    })
                ]
            }),
            new CardHelper({
                id: 'point',
                title: {
                    key: 'inspection.longitudinalPoint',
                    fallback: 'Bezugspunkt in Längsrichtung'
                },
                items: [
                    new FieldHelper({
                        id: 'pointStart',
                        title: {
                            key: 'dwa_m_150.inspection.pointStart',
                            fallback: 'Bezugspunkt Start'
                        },
                        accessor: 'DWA-M150_L_HI102',
                        columnSize: {
                            lg: 12,
                            md: 12
                        },
                        size: {
                            lg: 2,
                            md: 2
                        },
                        field: new ComplexTextBoxHelper()
                    }),
                    new FieldHelper({
                        id: 'clearanceAbove',
                        title: {
                            key: 'inspection.clearanceAbove',
                            fallback: 'Abstand oben'
                        },
                        accessor: 'IL_CenterPointRefValueTop'
                    }),
                    new FieldHelper({
                        id: 'clearanceBelow',
                        title: {
                            key: 'inspection.clearanceBelow',
                            fallback: 'Abstand unten'
                        },
                        accessor: 'IL_CenterPointRefValueBottom'
                    })
                ]
            }),
            new CardHelper({
                id: 'general',
                title: {
                    key: 'common.general',
                    fallback: 'Allgemein'
                },
                items: [
                    new FieldHelper({
                        id: 'client',
                        title: {
                            key: 'dwa_m_150.inspection.client',
                            fallback: 'Auftraggeber'
                        },
                        accessor: 'DWA-M150_L_HI001'
                    }),
                    new FieldHelper({
                        id: 'projectNumber',
                        title: {
                            key: 'dwa_m_150.inspection.projectNumber',
                            fallback: 'Projektnummer'
                        },
                        accessor: 'DWA-M150_L_HI002'
                    }),
                    new FieldHelper({
                        id: 'company',
                        title: {
                            key: 'dwa_m_150.inspection.company',
                            fallback: 'Firma'
                        },
                        accessor: 'DWA-M150_L_HI111'
                    }),
                    new FieldHelper({
                        id: 'constructionManagement',
                        title: {
                            key: 'dwa_m_150.inspection.constructionManagement',
                            fallback: 'Bauleitung'
                        },
                        accessor: 'DWA-M150_L_HI113'
                    }),
                    new FieldHelper({
                        id: 'inspector',
                        title: {
                            key: 'inspection.inspector',
                            fallback: 'Inspekteur'
                        },
                        accessor: 'DWA-M150_L_HI112'
                    }),
                    new FieldHelper({
                        id: 'weather',
                        title: {
                            key: 'inspection.weather',
                            fallback: 'Wetter'
                        },
                        accessor: 'DWA-M150_L_HI106',
                        field: new ComplexTextBoxHelper()
                    }),
                    new FieldHelper({
                        id: 'cleaning',
                        title: {
                            key: 'inspection.cleaning',
                            fallback: 'Reinigung'
                        },
                        accessor: 'DWA-M150_L_HI107',
                        field: new CheckboxHelper()
                    }),
                    new FieldHelper({
                        id: 'temperature',
                        title: {
                            key: 'inspection.temperature',
                            fallback: 'Temperatur'
                        },
                        accessor: 'DWA-M150_L_HI108'
                    }),
                    new FieldHelper({
                        id: 'floadSafety',
                        title: {
                            key: 'dwa_m_150.inspection.floadSafety',
                            fallback: 'Vorflutsicherung'
                        },
                        accessor: 'DWA-M150_L_HI109',
                        field: new ComplexTextBoxHelper()
                    }),
                    new FieldHelper({
                        id: 'waterSurface',
                        title: {
                            key: 'dwa_m_150.inspection.waterSurface',
                            fallback: 'Wasserspiegel'
                        },
                        accessor: 'DWA-M150_L_HI110'
                    }),
                    new FieldHelper({
                        id: 'inspectionReason',
                        title: {
                            key: 'dwa_m_150.inspection.inspectionReason',
                            fallback: 'Inspektionsgrund'
                        },
                        accessor: 'DWA-M150_L_HI004',
                        field: new ComplexTextBoxHelper()
                    }),
                    new FieldHelper({
                        id: 'editHint',
                        title: {
                            key: 'dwa_m_150.inspection.editHint',
                            fallback: 'Bearbeitungshinweis'
                        },
                        accessor: 'DWA-M150_L_HI008'
                    }),
                    new FieldHelper({
                        id: 'editState',
                        title: {
                            key: 'dwa_m_150.inspection.editState',
                            fallback: 'Bearbeitungsstatus'
                        },
                        accessor: 'DWA-M150_L_HI007'
                    }),
                    new FieldHelper({
                        id: 'cameraSystem',
                        title: {
                            key: 'dwa_m_150.inspection.cameraSystem',
                            fallback: 'Kamerasystem'
                        },
                        accessor: 'DWA-M150_L_HI006'
                    }),
                    new FieldHelper({
                        id: 'inspectionOrderNr',
                        title: {
                            key: 'dwa_m_150.inspection.inspectionOrderNr',
                            fallback: 'Inspektionsauftragsnr'
                        },
                        accessor: 'DWA-M150_L_HI003_BASYS'
                    }),
                    new FieldHelper({
                        id: 'car',
                        title: {
                            key: 'dwa_m_150.inspection.car',
                            fallback: 'Fahrzeug'
                        },
                        accessor: 'DWA-M150_L_HI902'
                    }),
                    new FieldHelper({
                        id: 'remark',
                        title: {
                            key: 'common.remark',
                            fallback: 'Bemerkung'
                        },
                        accessor: 'DWA-M150_L_HI999'
                    }),
                    new FieldHelper({
                        id: 'document',
                        title: {
                            key: 'dwa_m_150.common.document',
                            fallback: 'Dokument'
                        },
                        accessor: 'DWA-M150_L_HI998'
                    })
                ]
            })
        ]),
        manhole: new CanalObject([
            new CardHelper({
                id: 'inspection',
                title: {
                    key: 'inspection.inspection',
                    fallback: 'Inspektion'
                },
                items: [
                    new FieldHelper({
                        id: 'verticalPoint',
                        title: {
                            key: 'inspection.verticalPoint',
                            fallback: 'Vertikaler Bezugspunkt'
                        },
                        accessor: 'DWA-M150_S_KI101',
                        field: new ComplexTextBoxHelper()
                    }),
                    new FieldHelper({
                        id: 'inspectionDate',
                        title: {
                            key: 'inspection.inspectionDate',
                            fallback: 'Inspektionsdatum'
                        },
                        accessor: 'Inspektionsbeginn'
                    }),
                    new FieldHelper({
                        id: 'pointAtDimension',
                        title: {
                            key: 'dwa_m_150.inspection.pointAtDimension',
                            fallback: 'Bezugspunkt am Umfang'
                        },
                        accessor: 'DWA-M150_S_KI102',
                        field: new ComplexTextBoxHelper()
                    }),
                    new FieldHelper({
                        id: 'inspectionLength',
                        title: {
                            key: 'inspection.inspectionLength',
                            fallback: 'Inspektionslänge'
                        },
                        accessor: 'Inspektionslaenge'
                    }),
                    new FieldHelper({
                        id: 'workSave',
                        title: {
                            key: 'dwa_m_150.inspection.workSave',
                            fallback: 'Betriebssicher'
                        },
                        accessor: 'DWA-M150_S_KI119',
                        field: new CheckboxHelper()
                    }),
                    new FieldHelper({
                        id: 'videoStorageMedium',
                        title: {
                            key: 'inspection.videoStorageMedium',
                            fallback: 'Videospeichermedium'
                        },
                        accessor: 'DWA-M150_S_KI114',
                        field: new ComplexTextBoxHelper()
                    }),
                    new FieldHelper({
                        id: 'taperCorrect',
                        title: {
                            key: 'dwa_m_150.inspection.taperCorrect',
                            fallback: 'Konus richtig'
                        },
                        accessor: 'DWA-M150_S_KI120',
                        field: new CheckboxHelper()
                    }),
                    new FieldHelper({
                        id: 'nameStorage',
                        title: {
                            key: 'dwa_m_150.inspection.nameStorage',
                            fallback: 'Name des Speichermediums'
                        },
                        accessor: 'DWA-M150_S_KI115'
                    }),
                    new FieldHelper({
                        id: 'envAir',
                        title: {
                            key: 'dwa_m_150.inspection.envAir',
                            fallback: 'Umgebungsluft'
                        },
                        accessor: 'DWA-M150_S_KI121'
                    }),
                    new FieldHelper({
                        id: 'photoStorageMedium',
                        title: {
                            key: 'inspection.photoStorageMedium',
                            fallback: 'Fotospeichermedium'
                        },
                        accessor: 'DWA-M150_S_KI117',
                        field: new ComplexTextBoxHelper()
                    }),
                    new FieldHelper({
                        id: 'typeInspection',
                        title: {
                            key: 'inspection.typeInspection',
                            fallback: 'Inspektionsart'
                        },
                        accessor: 'DWA-M150_S_KI103',
                        field: new ComplexTextBoxHelper()
                    }),
                    new FieldHelper({
                        id: 'fileNameDigital',
                        title: {
                            key: 'dwa_m_150.inspection.fileNameDigital',
                            fallback: 'Dateiname digitales Video'
                        },
                        accessor: 'DWA-M150_S_KI116'
                    })
                ]
            }),
            new CardHelper({
                id: 'general',
                title: {
                    key: 'common.general',
                    fallback: 'Allgemein'
                },
                items: [
                    new FieldHelper({
                        id: 'client',
                        title: {
                            key: 'dwa_m_150.inspection.client',
                            fallback: 'Auftraggeber'
                        },
                        accessor: 'DWA-M150_S_KI001'
                    }),
                    new FieldHelper({
                        id: 'projectNumber',
                        title: {
                            key: 'dwa_m_150.inspection.projectNumber',
                            fallback: 'Projektnummer'
                        },
                        accessor: 'DWA-M150_S_KI002'
                    }),
                    new FieldHelper({
                        id: 'company',
                        title: {
                            key: 'dwa_m_150.inspection.company',
                            fallback: 'Firma'
                        },
                        accessor: 'DWA-M150_S_KI111'
                    }),
                    new FieldHelper({
                        id: 'constructionManagement',
                        title: {
                            key: 'dwa_m_150.inspection.constructionManagement',
                            fallback: 'Bauleitung'
                        },
                        accessor: 'DWA-M150_S_KI113'
                    }),
                    new FieldHelper({
                        id: 'inspector',
                        title: {
                            key: 'inspection.inspector',
                            fallback: 'Inspekteur'
                        },
                        accessor: 'DWA-M150_S_KI112'
                    }),
                    new FieldHelper({
                        id: 'weather',
                        title: {
                            key: 'inspection.weather',
                            fallback: 'Wetter'
                        },
                        accessor: 'DWA-M150_S_KI106',
                        field: new ComplexTextBoxHelper()
                    }),
                    new FieldHelper({
                        id: 'cleaning',
                        title: {
                            key: 'inspection.cleaning',
                            fallback: 'Reinigung'
                        },
                        accessor: 'DWA-M150_S_KI107',
                        field: new CheckboxHelper()
                    }),
                    new FieldHelper({
                        id: 'temperature',
                        title: {
                            key: 'inspection.temperature',
                            fallback: 'Temperatur'
                        },
                        accessor: 'DWA-M150_S_KI108'
                    }),
                    new FieldHelper({
                        id: 'floadSafety',
                        title: {
                            key: 'dwa_m_150.inspection.floadSafety',
                            fallback: 'Vorflutsicherung'
                        },
                        accessor: 'DWA-M150_S_KI109',
                        field: new ComplexTextBoxHelper()
                    }),
                    new FieldHelper({
                        id: 'waterSurface',
                        title: {
                            key: 'dwa_m_150.inspection.waterSurface',
                            fallback: 'Wasserspiegel'
                        },
                        accessor: 'DWA-M150_S_KI110'
                    }),
                    new FieldHelper({
                        id: 'inspectionReason',
                        title: {
                            key: 'dwa_m_150.inspection.inspectionReason',
                            fallback: 'Inspektionsgrund'
                        },
                        accessor: 'DWA-M150_S_KI004',
                        field: new ComplexTextBoxHelper()
                    }),
                    new FieldHelper({
                        id: 'editHint',
                        title: {
                            key: 'dwa_m_150.inspection.editHint',
                            fallback: 'Bearbeitungshinweis'
                        },
                        accessor: 'DWA-M150_S_KI008'
                    }),
                    new FieldHelper({
                        id: 'editState',
                        title: {
                            key: 'dwa_m_150.inspection.editState',
                            fallback: 'Bearbeitungsstatus'
                        },
                        accessor: 'DWA-M150_S_KI007'
                    }),
                    new FieldHelper({
                        id: 'cameraSystem',
                        title: {
                            key: 'dwa_m_150.inspection.cameraSystem',
                            fallback: 'Kamerasystem'
                        },
                        accessor: 'DWA-M150_S_KI006'
                    }),
                    new FieldHelper({
                        id: 'inspectionOrderNr',
                        title: {
                            key: 'dwa_m_150.inspection.inspectionOrderNr',
                            fallback: 'Inspektionsauftragsnr'
                        },
                        accessor: 'DWA-M150_S_HI003_BASYS'
                    }),
                    new FieldHelper({
                        id: 'car',
                        title: {
                            key: 'dwa_m_150.inspection.car',
                            fallback: 'Fahrzeug'
                        },
                        accessor: 'DWA-M150_S_KI902'
                    }),
                    new FieldHelper({
                        id: 'document',
                        title: {
                            key: 'dwa_m_150.common.document',
                            fallback: 'Dokument'
                        },
                        accessor: 'DWA-M150_S_KI998',
                        columnSize: {
                            lg: 12,
                            md: 12
                        },
                        size: {
                            lg: 2,
                            md: 2
                        }
                    }),
                    new FieldHelper({
                        id: 'remark',
                        title: {
                            key: 'common.remark',
                            fallback: 'Bemerkung'
                        },
                        accessor: 'DWA-M150_S_KI999',
                        columnSize: {
                            lg: 12,
                            md: 12
                        },
                        size: {
                            lg: 2,
                            md: 2
                        }
                    })
                ]
            })
        ])
    }),

    station: [
        new FormColumnHelper({
            id: 'dwam150.kd',
            title: {
                key: 'station.kd',
                fallback: 'KD'
            },
            accessor: 'KD'
        }),
        new FormColumnHelper({
            id: 'dwam150.ks',
            title: {
                key: 'station.ks',
                fallback: 'KS'
            },
            accessor: 'KS'
        }),
        new FormColumnHelper({
            id: 'dwam150.kb',
            title: {
                key: 'station.kb',
                fallback: 'KB'
            },
            accessor: 'KB'
        })
    ]
});
