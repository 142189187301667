// @flow
import React from 'react';
import {Button} from 'react-bootstrap';
import { GoSidebarCollapse } from "react-icons/go";

type Layout = {
    xs: boolean,
    sm: boolean,
    md: boolean,
    lg: boolean
}

type Props = {
    onClick: () => mixed,
    layout: Layout
}

/**
 * Hilfsfunktion fuer das Setzen der CSS Klassen des Buttons
 *
 * @param {Layout} layout Die Einstellungen des Layouts fuer den Button
 * @returns {string}
 */
function LayoutHelper(layout: Layout):string {
    let result = '';
    if (layout.xs) {
        result += 'd-xs-none ';
    }

    if (layout.sm) {
        result += 'd-sm-none ';
    }

    if (layout.md) {
        result += 'd-md-none ';
    }

    if (layout.lg) {
        result += 'd-lg-none ';
    }

    return result;
}

/**
 * Komponente fuer einen Navigationsbutton
 * @param {() => mixed} onClick Funktion bei Klick des Buttons
 * @param {Object} layout Die Layoutinformationen fuer den Button
 */
const NavMenuButton = ({onClick, layout, id}: Props) => {
    let css = 'navbar-btn ';
    css += LayoutHelper(layout);
    return (
        <Button
            type='button'
            id={id}
            className={css}
            onClick={onClick}
        >
            <GoSidebarCollapse />
        </Button>
    );
};

export default NavMenuButton;