// @flow
import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Container, Row, Col } from 'react-bootstrap';
import '../css/panoramo.css';

import PopoutWindowPortal from './PopoutWindowPortal';
import PanoButtonGroup from './panoviewer/components/PanoButtonGroup';
import BackToProjectButton from './panoviewer/components/BackToProjectButton';
import { sendErrorMessage } from '../actions/loginActions';

import { addPanoViewInstance, updatePanoViewInstance, removePanoViewInstance } from '../actions/viewActions';
import { requestPanoFileURL, _updateSettings, backToProject } from '../actions/dataActions';

import { PanoramoViewerNG } from '@panoramoviewer-ng/viewer';
import PanoWrap from './panoviewer/PanoWrap';
import Pointcloud from './panoviewer/Pointcloud';
import { translateString } from '../i18n/utils';

function PanoramoMainView ({ isThisPopout = false, instance }) {
    const KORREKTURWERT = 200;
    const dispatch = useDispatch();

    // get data from state
    // Gegenuntersuchung kann so nicht angezeigt werden, weil immer nur inspection[0] abgefragt wird und nicht inspection[1](Gegenuntersuchung)
	const panoFileUrl = useSelector(state => state.currentObject.inspection[0].panoFileUrl);
    const inspectionId = useSelector(state => state.currentObject.inspection[0]._id);
    const objectType = useSelector(state => state.currentObject.type);
    const currentStation = useSelector(state => state.currentStation);
    const settings = useSelector(state => state.settings);
    const originSettings = useSelector(state => state.originSettings);

    // is panoramoviewer initialized
	const [initialized, setInitialized] = useState(false);
	const [crosshair, setCrosshair] = useState(false);
    const [showPointCloud, setShowPointCloud] = useState(false);
    const [hasPointCloud, setHasPointCloud] = useState(false);

    // controls whether the wrap is shown
    const [showWrap, setShowWrap] = useState(false);
    // controls whether the wrap is opened in popout
    const [showWrapPopout, setShowWrapPopout] = useState(false);
    // controls whether the panoramo perspective is shown in popout
    const [showPanoPopout, setShowPanoPopout] = useState(false);

    let panoViewer = useRef(null);
    const targetContainer = useRef(null);
    const wrapTargetContainer = useRef(null);
    const wrapOverviewTargetContainer = useRef(null);

    // handle different instances of the component
    useEffect(()=> {
        dispatch(addPanoViewInstance(instance));
        return () => {
            dispatch(removePanoViewInstance(instance));
        }
    }, [dispatch, instance]);

    // get the url to pano file in storage if unknown
    useEffect(() => {
        if(!panoFileUrl) {
            dispatch(requestPanoFileURL(inspectionId))
        }
    }, [panoFileUrl, dispatch, inspectionId]);

    // error handling
    useEffect(() => {
        const errorHandler = (error) => {
            console.log("an error occured", error);
        };

        const unhandledRejectionHandler = (event) => {
            if(event.reason.includes("Failed to get ips")){
                const message = translateString('messages.panoramoNotFound');
                dispatch(sendErrorMessage(message));
            } else {
                console.log("unhandledRejectionHandler", event.reason);
            }
            event.preventDefault();
        };

        window.addEventListener('error', errorHandler);
        window.addEventListener('unhandledrejection', unhandledRejectionHandler);

        return () => {
          window.removeEventListener('error', errorHandler);
          window.removeEventListener('unhandledrejection', unhandledRejectionHandler);
        };
      }, [dispatch]);

    // initialize panoramo viewer
    useEffect(() => {
        async function openScan() {
            if(!panoViewer.current) {
                panoViewer.current = new PanoramoViewerNG({
                    perspectiveDiv: targetContainer.current,
                });
                const url = new URL(panoFileUrl);
                await panoViewer.current.selectScan(url);
                await panoViewer.current.openPanoView();

                setHasPointCloud(panoViewer.current.hasPointCloud());
                panoViewer.current.showPositionOverlay();

                if(showWrap) {
                    panoViewer.current.setRenderTargets({
                        wrapDetailDiv: wrapTargetContainer.current,
                        wrapOverviewDiv: wrapOverviewTargetContainer.current
                    });
                    await panoViewer.current.openWrap();
                }

                setInitialized(true);
            }
        }
        if (panoFileUrl) {
            openScan();
        }

        return () => {
            if(panoViewer.current) {
                panoViewer.current.disposeWrap();
                panoViewer.current.dispose();
                panoViewer.current = null;
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setInitialized, panoFileUrl, targetContainer, panoViewer, setHasPointCloud]);

    // react on Station switch
    useEffect(() => {
        if(initialized && currentStation && panoViewer.current) {
            const distanceInMillimeter = currentStation?.Entfernung.replace(",", ".");
            const distanceInMeter = distanceInMillimeter * 1000 + KORREKTURWERT;
            const degreeArray = [ 90, 60, 30, 0, 330, 300, 270, 240, 210, 180, 150, 120 ];
            // position is specified in hours -> translate to degree
            let position = currentStation?.Position.trim().replaceAll(" ","").split('-').filter(a => a !== '')
                .map(a => a.trim()).map( e => degreeArray[parseInt(e)]);
            panoViewer.current.setPosition(distanceInMeter, position[0], 90)
        }
    }, [currentStation, initialized, panoViewer]);

    // react on change of "showWrap", display/hide wrap
    useEffect(() => {
        if(!panoViewer.current){
            return;
        }
        const dispose = async() => await panoViewer.current.disposeWrap();
        const openWrap = async() => {
            panoViewer.current.setRenderTargets({
                wrapDetailDiv: wrapTargetContainer.current,
                wrapOverviewDiv: wrapOverviewTargetContainer.current
            });
            await panoViewer.current.openWrap();
        }
        if(showWrap) {
            openWrap();
        } else {
            dispose();
        }
    },[showWrap])

    // show/hide point cloud
    const togglePointCloud = () => setShowPointCloud(!showPointCloud);

    //show/hide panoramo wrap
	const toggleWrap = () => {
        setShowWrap(!showWrap);
    };

    // open panoramo perspetive in popout
    const openPanoPopout = () => {
        dispatch(updatePanoViewInstance('PanoramoPopout_Component'));
        dispatch(
            _updateSettings({ ...settings, layout: 'popout' }, false, true),
        );
        setShowPanoPopout(true);
    }

    // is called when panoramo perspective popout was closed
    const closePanoPopout = () => {
        dispatch(removePanoViewInstance('PanoramoPopout_Component'));
        dispatch(
            _updateSettings({ ...settings, layout: originSettings.layout }, false, true)
        );
        setShowPanoPopout(false);
    };

    // open wrap in popout
    const openWrapPopout = () => {
        setShowWrapPopout(true);
        setShowWrap(false);
    }

    const closeWrapPopout = () => setShowWrapPopout(false);

    // callback functions for the panoramoviewer
    const toggleCrossHairCallback = () => {(!crosshair) ? panoViewer.current.showNavigationLines() : panoViewer.current.hideNavigationLines(); setCrosshair(!crosshair); }

    const download = data => {
        const link = document.createElement('a');
        link.href = data;
        link.setAttribute('download', 'PanoramoScreenshot');
        document.body.appendChild(link);
        link.click();
    }

    const downloadScreenshot = async () => {
        const data = await panoViewer.current.getScreenshot(1900, 1020, 'image/png');
        download(data);
    }

    const downloadPanoramoFile = () => {
        if(!panoFileUrl) {
            return;
        }
        download(panoFileUrl);
    }

    // change height of perspective depending on if the wrap is displayedt
    const colSpan = {wrap: 10, overview: 2}
    let colHeight = '100%';
    if (objectType !== 'manhole') {
        colSpan.wrap = 12;
        colSpan.overview = 12;
        colHeight = '50%';
    }

    return (
        <>
            <Card>
                <Card.Body>
                    <Container fluid>
                        <Row>
                            <Col>
                                <BackToProjectButton active={!isThisPopout} onClick={() => dispatch(backToProject())} />

                                <PanoButtonGroup
                                    initialized={initialized}
                                    toggleCrossHair={toggleCrossHairCallback}
                                    toggleWrap={toggleWrap}
                                    showWrapPopout={() => openWrapPopout()}
                                    showPanoPopout={openPanoPopout}
                                    hasPointCloud={hasPointCloud}
                                    togglePointCloud={togglePointCloud}
                                    downloadScreenshot={downloadScreenshot}
                                    downloadPanoramoFile={downloadPanoramoFile}
                                    isThisPopout={isThisPopout}
                                />
                            </Col>
                        </Row>
                    </Container>
                </Card.Body>
            </Card>

            <Card id="panoramoCard" className={isThisPopout ? 'popout' : ''}>
                <Card.Body className="p-0 h-100">
                    <Container fluid className="h-100">
                        <Row style={{height: showWrap ? '50%' :'100%'}}>
                            <Col xs={12} className="p-0 h-100">
                                <Container fluid className="h-100">
                                    <PanoviewerRenderTarget innerRef={targetContainer} />
                                </Container>
                            </Col>
                        </Row>

                        {showWrap && <Row className={(objectType === 'manhole' ? 'wrapContainer manhole' : 'wrapContainer')}>
                            <Col xs={colSpan.wrap} className="p-0" style={{height: colHeight}}>
                                <WrapRenderTarget innerRef={wrapTargetContainer} />
                            </Col>

                            <Col xs={colSpan.overview} className="p-0" style={{height: colHeight}}>
                                <WrapOverviewRenderTarget innerRef={wrapOverviewTargetContainer} />
                            </Col>
                        </Row>}
                    </Container>
                </Card.Body>
            </Card>

            {panoViewer.current && showWrapPopout && <PopoutWindowPortal closeWindowPortal={closeWrapPopout} title='Panoramo'>
                    <PanoWrap
                        panoViewer={panoViewer.current}
                        objectType={objectType}
                    />
                </PopoutWindowPortal>}

            {showPanoPopout && <PopoutWindowPortal closeWindowPortal={closePanoPopout} title='Panoramo'>
                <PanoramoMainView instance='PanoramoPopout_Component' isThisPopout={true} />
            </PopoutWindowPortal>}

            {showPointCloud && <Pointcloud panoramoViewer={panoViewer.current} setShowPointCloud={setShowPointCloud}/>}
        </>
    );
}

const PanoviewerRenderTarget = ({innerRef}) => {
    return <div id="panocontainer" ref={innerRef} ></div>
};

const WrapRenderTarget = ({innerRef}) => {
    return <div id="panoWrapTargetContainer" ref={innerRef}></div>
};

const WrapOverviewRenderTarget = ({innerRef}) => {
    return <div id="panoWrapOverviewTargetContainer" ref={innerRef}></div>
};

export default PanoramoMainView;
