// @flow
import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import ImageHolder from './ImageHolder';
import InfoCard from './InfoCard';

import '../../../css/carousel.css';

export default function CarouselTest({ stations, index }) {
	const SlickButtonFix = ({ currentSlide, slideCount, children, ...props }) => (
		<span {...props}>{children}</span>
	);

	const settings = {
		adaptiveHeight: true,
		lazyLoad: 'progressive',
		dots: true,
		speed: 500,
		nextArrow: (
			<SlickButtonFix>
				<FontAwesomeIcon className="next-slick-arrow" icon={faChevronRight} />
			</SlickButtonFix>
		),
		prevArrow: (
			<SlickButtonFix>
				<FontAwesomeIcon className="prev-slick-arrow" icon={faChevronLeft} />
			</SlickButtonFix>
		),
	};

	return (
		<div className="slider-wrapper">
			<Slider {...settings} initialSlide={index}>
				{stations.map((station) => (
					<div className="slick-slide" key={station._id}>
						<h2 className="slick-slide-title">
							<InfoCard current={station} />
						</h2>
						<ImageHolder className="slick-slide-image" img={station} />
					</div>
				))}
			</Slider>
		</div>
	);
}
