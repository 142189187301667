// @flow
import React from 'react';
import InputHelper from "./InputHelper";
import TextBox from '../components/TextBox';


/**
 * Hilfklasse um eine Textbox zu erzeugen
 *
 * @export
 * @class TextBoxHelper
 * @extends {InputHelper}
 */
export default class TextBoxHelper extends InputHelper {
    /**
     * Baut die Textbox
     *
     * @param {any} value Wert der Textbox
     * @param {number} size Groesse der Textbox
     * @returns
     * @memberof TextBoxHelper
     */
    build(value: any, size: number) {        
        return <TextBox inputSize={size} value={value} />;
    }
}