// @flow
/**
 * Hilfklasse fuer die validierung
 *
 * @export
 * @class ValidationArray
 */
export default class ValidationArray {
    id: string;
    error: Array<string> = [];
    warn: Array<string> = [];

    /**
     * Creates an instance of ValidationArray.
     * @param {string} id Id des Arrays
     * @memberof ValidationArray
     */
    constructor(id: string) {
        this.id = id;
    }
}