// @flow
import { toast } from 'react-toastify';

import { TOASTIFY_SEVERITY } from '../../misc/const';

import { TOKEN_EXPIRED_RES, TOKEN_INVALID, ACCESS_FORBIDDEN, LICENSE_INVALID } from '../../misc/types';
import { Dispatch } from '../../misc/flowTypes';
import { FORBIDDEN, UNAUTHORIZED } from '../../misc/httpCodes';
import { _logout, _message, _tokenExpired } from '../loginActions';

/**
 * Dispatched die angegebene Funktion, wenn die Bedingungen zutreffen in dem Fehler.
 * @param  {Function} dispatch Redux Thunk Middleware
 * @param  {Error} err         Der Fehler aus der Http Anfrage
 * @param  {Number} status     Status, der gegen den Status aus dem Fehler geprueft wird
 * @param  {Number} type       Typ, der gegen den Type aus dem Fehler geprueft wird
 * @param  {Function} func     Funktion, die ausgefuehrt wir wenn die Bedingungen zutreffen
 * @param  {Object} args       Objekt mit den zusaetzlichen Parametern
 * @return {Boolean}           Ob die Funktion aufgerufen wurde
 */
export function dispatchIf(dispatch: Dispatch, err: Object, status: number, type: number, func: (any) => mixed, args: ?any = null): boolean {
	const { response } = err;

	if (response.status === status && response.data.type === type) {
		dispatch(func(args));
		return true;
	}
	return false;
}

/**
 * Dispatched, wenn die Fehlermeldung Token expired ist.
 * @param  {Function} dispatch Redux Thunk Middleware
 * @param  {Error} err         Der Fehler aus der Http Anfrage
 * @return {Boolean}           Ob die Funktion aufgerufen wurde
 */
export function dispatchIfTokenExpired(dispatch: Dispatch, err: Object): boolean {
	return dispatchIf(dispatch, err, FORBIDDEN, TOKEN_EXPIRED_RES, _tokenExpired);
}

/**
 * Dispatched, wenn die Fehlermeldung Token invalid ist.
 * @param  {Function} dispatch Redux Thunk Middleware
 * @param  {Error} err         Der Fehler aus der Http Anfrage
 * @return {Boolean}           Ob die Funktion aufgerufen wurde
 */
export function dispatchIfTokenInvalid(dispatch: Dispatch, err: Object): boolean {
	return dispatchIf(dispatch, err, UNAUTHORIZED, TOKEN_INVALID, _tokenExpired);
}

/**
 * Dispatched, wenn die Fehlermeldung Access Forbidden ist
 * @param  {Function} dispatch Redux Thunk Middleware
 * @param  {Error} err         Der Fehler aus der Http Anfrage
 * @return {Boolean}           Ob die Funktion aufgerufen wurde
 */
export function dispatchIfAccessForbidden(dispatch: Dispatch, err: Object): boolean {
	return dispatchIf(dispatch, err, FORBIDDEN, ACCESS_FORBIDDEN, _message, {
		text: 'messages.accessForbidden',
		severity: TOASTIFY_SEVERITY.ERROR,
	});
}

/**
 * Dispatched, wenn die Fehlermeldung LICENSE_INVALID ist
 *
 * @param {Dispatch} dispatch Redux Thunk Middleware
 * @param {Error} err Der Fehler aus der Http Anfrage
 * @param {string} [position=toast.POSITION.TOP_RIGHT] Position der Nachricht
 * @returns {boolean}
 */
export function dispatchIfLicenseInvalid(dispatch: Dispatch, err: Object, position?: string = toast.POSITION.TOP_RIGHT): boolean {
	const result = dispatchIf(dispatch, err, FORBIDDEN, LICENSE_INVALID, _message, {
		text: 'messages.licenseInvalid',
		severity: TOASTIFY_SEVERITY.ERROR,
		position,
	});

	if (result) {
		dispatch(_logout());
	}

	return result;
}

/**
 * Dispatched, wenn die Fehlermeldung Token expired o. Token invalid
 * @param  {Function} dispatch Redux Thunk Middleware
 * @param  {Error} err         Der Fehler aus der Http Anfrage
 * @return {Boolean}           Ob die Funktion aufgerufen wurde
 */
export function dispatchIfDefaultErrors(dispatch: Dispatch, err: Object): boolean {
	if (!dispatchIfTokenExpired(dispatch, err) && !dispatchIfTokenInvalid(dispatch, err) && !dispatchIfAccessForbidden(dispatch, err) && !dispatchIfLicenseInvalid(dispatch, err)) {
		return false;
	}
	return true;
}
