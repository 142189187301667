// @flow
import CanalObject from "./CanalObject";

type CanalObjectsOptions = {
    section: CanalObject,
    lateral: CanalObject,
    manhole: CanalObject
}

/**
 * Hilfsklasse fuer die drei Arten von Kanalobjekten
 *
 * @export
 * @class CanalObjects
 */
export default class CanalObjects {
    section: CanalObject;
    lateral: CanalObject;
    manhole: CanalObject;

    /**
     * Creates an instance of CanalObjects.
     * @param {CanalObjectsOptions} options Die Einstellungen fuer die Arten von Kanalobjekten
     * @memberof CanalObjects
     */
    constructor(options: CanalObjectsOptions) {
        this.section = options.section;
        this.lateral = options.lateral;
        this.manhole = options.manhole;
    }
}