// @flow
import ObjectListTabHelper from '../helper/ObjectListTabHelper';
import FormColumnHelper from '../../form/helper/FormColumnHelper';
import CanalInterface from '../helper/CanalInterface';
import CanalObjects from '../helper/CanalObjects';
import CanalObject from '../helper/CanalObject';

import project from './isybauxml/isybauxml.project';
import objectListTabHelperObjectMasterData from './isybauxml/isybauxml.objectListTabHelper.masterData';
import objectListTabHelperObjectInspection from './isybauxml/isybauxml.objectListTabHelper.inspection';
import canalObjectSectionArray from './isybauxml/isybauxml.canalObject.section';
import canalObjectLateralArray from './isybauxml/isybauxml.canalObject.lateral';
import canalObjectManholeArray from './isybauxml/isybauxml.canalObject.manhole';

import canalObjectSectionInspectionArray from './isybauxml/isybauxml.inspection.canalObject.section';
import canalObjectLateralIspactionArray from './isybauxml/isybauxml.inspection.canalObject.lateral';
import canalObjectManholeInspectionArray from './isybauxml/isybauxml.inspection.canalObject.manhole';

/**
 * Template Beschreibung fuer ISYBAU-XML
 * @type {Object}
 */
// eslint-disable-next-line import/no-anonymous-default-export
export default new CanalInterface({
    version: "2.0",
    name: 'ISYBAU-XML',

    // FormHelper general project description
    project,

    //  FormHelper for object table column options
    objectlist: [
        new ObjectListTabHelper(
            // tab MasterData/Stammdaten
            objectListTabHelperObjectMasterData
        ),
        new ObjectListTabHelper(
            // tab inspection
            objectListTabHelperObjectInspection
        )
    ],

    objectname: "Objektbezeichnung.Schachtbezeichner",

    // Formhelper canalObject overview
    object: new CanalObjects({
        section: new CanalObject(
            // section
            canalObjectSectionArray
        ),
        lateral: new CanalObject(
            // lateral
            canalObjectLateralArray
        ),
        manhole: new CanalObject(
            // Manhole
            canalObjectManholeArray
        )
    }),

    inspectionname: "date",

    // Formhelper canalObject inspection
    inspection: new CanalObjects({
        section: new CanalObject(
            canalObjectSectionInspectionArray
        ),
        lateral: new CanalObject(
            canalObjectLateralIspactionArray
        ),
        manhole: new CanalObject(
            canalObjectManholeInspectionArray
        )
    }),

    station: [
        new FormColumnHelper({
            id: 'isybauxml.skdv',
            title: {
                key: 'station.skdv',
                fallback: 'SKDv'
            },
            accessor: 'SKDv'
        }),
        new FormColumnHelper({
            id: 'isybauxml.sksv',
            title: {
                key: 'station.sksv',
                fallback: 'SKSv'
            },
            accessor: 'SKSv'
        }),
        new FormColumnHelper({
            id: 'isybauxml.skbv',
            title: {
                key: 'station.skbv',
                fallback: 'SKBv'
            },
            accessor: 'SKBv'
        })
    ]
});