// @flow
import React, {Component, Fragment} from 'react';
import {NavItem} from 'react-bootstrap';
import {connect} from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import SettingsView from '../settings/SettingsView';


type Props = {
    username: string
}

type State = {
    show: boolean
}

/**
 * Klasse fuer einen Button zu den Einstellungen in der Navigation
 *
 * @class SettingsNavigationItem
 * @extends {Component<Props, State>}
 */
class SettingsNavigationItem extends Component<Props, State> {

    /**
     * Creates an instance of SettingsNavigationItem.
     * @param {Object} props
     * @memberof SettingsNavigationItem
     */
    constructor(props: Props) {
        super(props);

        this.state = {
            show: false
        }
    }

    /**
     * Zeigt das Modal
     *
     * @memberof SettingsNavigationItem
     */
    showModal = ():void => {
        this.setState({show: true});
    }

    /**
     * Versteckt das Modal
     *
     * @memberof SettingsNavigationItem
     */
    hideModal = ():void => {
        this.setState({show: false});
    }

    /**
     * Rendert die Komponente
     *
     * @returns
     * @memberof SettingsNavigationItem
     */
    render() {
        const {show} = this.state;
        const {username} = this.props;

        return (
            <Fragment>
                <NavItem onClick={this.showModal}>
                    <FontAwesomeIcon icon={faUser}></FontAwesomeIcon>&nbsp;{username}                    
                </NavItem>
                {show ? <SettingsView hide={this.hideModal} /> : null}
            </Fragment>
        );
    }
}

/**
 *  Verbindet die Klasse mit dem Redux Store
 */
const ReduxSettingsNavigationItem = connect(state => {
    return {
        username: state.authentication.name
    }
})(SettingsNavigationItem);

export default ReduxSettingsNavigationItem;

