// @flow
import FormHelper from "../../form/helper/FormHelper";
import ObjectListTabHelper from "./ObjectListTabHelper";
import FormColumnHelper from "../../form/helper/FormColumnHelper";
import CanalObjects from "./CanalObjects";

type interfaceOptions = {
    version: string,
    name: string,
    project: Array<FormHelper>,
    objectlist: Array<ObjectListTabHelper>,
    objectname: string,
    object: CanalObjects,
    inspectionname: string,
    inspection: CanalObjects,
    station: Array<FormColumnHelper>
}

/**
 * Klasse fuer das erstellen einer Schnittstelle
 *
 * @export
 * @class CanalInterface
 */
export default class CanalInterface {
    _options: interfaceOptions;

    /**
     * Creates an instance of CanalInterface.
     * @param {interfaceOptions} options Die Einstellungen der Schnittstelle
     * @memberof CanalInterface
     */
    constructor(options: interfaceOptions) {
        this._options = options;
    }

    get version():string {
        return this._options.version;
    }

    get name():string {
        return this._options.name;
    }

    get project():Array<FormHelper> {
        return this._options.project;
    }

    get objectlist():Array<ObjectListTabHelper> {
        return this._options.objectlist;
    }

    get objectname():string {
        return this._options.objectname;
    }

    get object():CanalObjects {
        return this._options.object;
    }

    get inspectionname():string {
        return this._options.inspectionname;
    }

    get inspection():CanalObjects {
        return this._options.inspection;
    }

    get station():Array<FormColumnHelper> {
        return this._options.station;
    }
}