// @flow
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { CardGroup, Card, Button } from 'react-bootstrap';

import NetPlane from './NetPlaneView';
import withKeyboardCommand from './hoc/withKeyboardCommands';
import { showSideView, objectOpened, objectSelected } from '../actions/viewActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import { getImage } from '../misc/utils';

type Props = {
	showSideView: (boolean) => mixed,
	objectSelected: (Object) => mixed,
	objectOpened: (Object) => mixed,
	settings: Object,
	currentProject: Object,
	currentObject: Object,
	netplane: Object,
	currentOriginal: string,
};

type State = {
	currentObject: Object,
	currentOriginal: string,
};

/**
 * React Komponente, die den Button zum Wechsel auf ein Kanalobjekt rendert
 * und den Netzplan einbindet
 * @type {Component}
 * @constructor
 */
class NetPlaneMainView extends Component<Props, State> {
	/**
	 * Creates an instance of NetPlaneMainView.
	 * @param {Object} props Die Properties der Klasse
	 * @memberof NetPlaneMainView
	 */
	constructor(props: Object) {
		super(props);

		this.state = {
			currentObject: props.currentObject,
			currentOriginal: props.currentOriginal,
		};
	}

	/**
	 * Event fuer Button wenn das ausgewaehlte Kanalobjekt angezeigt werden soll
	 * @memberof NetPlaneMainView
	 */
	showCanalObject = () => {
		const { objectOpened, currentObject } = this.props;

		if (currentObject) {
			objectOpened(currentObject);
		}
	};

	/**
	 * Event wenn ein Kanalobjekt angeklickt wurde
	 * Kanalobjekt als ausgewaehlt setzen
	 * @param  {selectedCanalObject} Object	das Kanalobjekt
	 * @memberof NetPlaneMainView
	 */
	setCanalObject = (selectedCanalObject: Object) => {
		const { objectSelected, showSideView } = this.props;

		objectSelected(selectedCanalObject);
		showSideView(false);

		this.setState({
			currentObject: selectedCanalObject,
		});
	};

	/**
	 * Rendert die Ansicht fuer das Button Card und den Netzplan
	 * @return {JSX} JSX Markup
	 */
	render() {
		const { currentObject } = this.props;
		const { settings, currentProject, netplane } = this.props;
		const { currentOriginal } = this.props;

		return (
			<Fragment>
				<Card>
					<Card.Body >
						<Button onClick={() => this.showCanalObject()} disabled={!currentObject}>
							{currentObject ? getImage(currentObject.type) : getImage('section')}
							&nbsp;<FontAwesomeIcon icon={faAngleDoubleRight}></FontAwesomeIcon>
						</Button>
					</Card.Body>
				</Card>
				<CardGroup id="netplaneview">
					<NetPlane
						setCanalObject={this.setCanalObject}
						showCanalObject={this.showCanalObject}
						settings={settings}
						currentProject={currentProject}
						currentObject={currentObject}
						netplane={netplane}
						currentOriginal={currentOriginal}
						isMainView={true}
					/>
				</CardGroup>
			</Fragment>
		);
	}
}

/**
 *  HOC fuer das Verbinden mit Tastaturkuerzeln
 */
const NetPlaneView = withKeyboardCommand(NetPlaneMainView, [
	{
		key: 'ctrl+right',
		func: (child) => {
			if (child.props.currentObject) {
				child.props.objectOpenend(child.props.currentObject);
			}
		},
	},
]);

/**
 *  Verbindet die Klasse mit dem Redux Store
 */
const ReduxNetPlaneMainView = connect(
	(state) => {
		return {
			settings: state.settings,
			netplane: state.currentNetPlane,
			currentObject: state.currentObject,
			currentProject: state.currentProject,
			currentOriginal: state.currentOriginal,
		};
	},
	{
		showSideView,
		objectOpened,
		objectSelected,
	}
)(NetPlaneView);
export default ReduxNetPlaneMainView;
