// @flow
import React from 'react';
import {Image} from 'react-bootstrap';

import {Trans} from 'react-i18next';

import general from '../templates/General';
import {OBJECTLIST_COLUMN_MODE} from '../misc/const';

import icon1 from '../images/icon1.png';
import section from '../images/section.png';
import manhole from '../images/manhole.png';
import icon2 from '../images/icon2.png';
import mpeg from '../images/mpeg.png';
import mpeg_nonexistent from '../images/mpegnofile.png';
import folder from '../images/folder.png';
import inspection from '../images/document.png';
import station from '../images/stations.png';
import object from '../images/object.png';
import lateral from '../images/lateral.png';
import film from '../images/film.png';
import measurement from '../images/measurement.png';
import photo_big from '../images/photo_big.png';
import photo_medium from '../images/photo_medium.png';
import photo_little from '../images/photo_little.png';
import net_plane from '../images/net_plane.png';
import inflow from '../images/inflow.png';
import outflow from '../images/outflow.png';
import pano from '../images/pano.png';
import panoramo from '../images/panoramo.png';
import panoramonofile from '../images/panoramonofile.png';
import arrowUp from '../images/arrowup.png';
import invalidImage from '../images/InvalidImage.png';

import folder_small from '../images/16/folder.png';
import section_small from '../images/16/section.png';
import manhole_small from '../images/16/manhole.png';
import mpeg_small from '../images/16/mpeg.png';
import lateral_small from '../images/16/lateral.png';
import station_small from '../images/16/station.png';
import inspection_small from '../images/16/inspection.png';
import panoramo_small from '../images/16/panoramo.png';

/**
 * Erstellt einen Titel
 * @param  {string} title Titel
 * @param  {string} image Name des Bildes am Anfang des Titels
 * @return {JSX}       JSX Markup
 */
export function renderTitle(title: string, image: string = ""):React$Element<any> {
    if (image !== "") {
        return (
            <span>
                {getImage(image, "correct-img-size")}
                &nbsp;{title}
            </span>
        );
    }
    else {
        return (
            <span>
                &nbsp;{title}
            </span>
        );
    }
}

/**
 * Erstellt einen Titel der i18n Funktionalitaeten hat
 * @param  {string} title          Text des Titels, falls der key nicht vorhanden ist
 * @param  {string} key            Key der Uebersetzung
 * @param  {String} [image=""]     Name des Bildes
 * @param  {String} [parent="div"] Komponente, die im HTML fuer das JSX gesetzt werden soll
 * @return {JSX}                   JSX Trans Titel
 */
export function renderTransTitle(title: string, key: string, image: string = "", parent: string = "div"): React$Element<any> | string {
   if (image !== "") {
       return (
           <span>
               {getImage(image, "correct-img-size")}
               {getTransObject(key, title, parent)}
           </span>
       );
   }
   else {
       return (
           getTransObject(key, title, parent)
       );
   }
}

/**
 * Erzeugt ein Trans Objekt
 * @param  {string} key              Key der Uebersetzung
 * @param  {string} fallbackText     Text des Objekts, falls der key nicht vorhanden ist
 * @param  {string} [parent="div"]   Komponente, die im HTML fuer das JSX gesetzt werden soll
 * @return {JSX}                     JSX Trans Objekt
 */
export function getTransObject(key: string, fallbackText: string, parent: string = "div"):React$Element<any> | string {
    if (key !== "" && fallbackText !== "") {
        return <Trans i18nKey={key} parent={parent}>{fallbackText}</Trans>;
    }
    return fallbackText;
}

/**
 * Gibt den gesuchten Wert zurueck oder einen leer String wenn dieser nicht gefunden wird.
 * @param  {object} object     Objekt, das den Wert enthalten kann
 * @param  {string} identifier String der als Key im Objekt gesucht wird. Durch
 * einen Punkt koennen mehrere Moeglichkeiten angegeben werden oder in einer tieferen Ebene des Objekts gesucht werden
 * @return {any}            Wert, der gefunden wurde
 */
export function getName(object: Object, identifier: string): any {
    let identifiers: Array<string> = identifier.split('.');
    let current: any = "";

    identifiers.forEach(function(entry: string) {
        if (object.hasOwnProperty(entry) && current === "") {
            current = object[entry];
        }
        else {
            if (current.hasOwnProperty(entry)) {
                current = current[entry];
            }
        }
    });

    return current;
}

/**
 * Erstellt ein Image mit dem gegebenen Wert
 * @param  {string} image Name des Bildes
 * @param  {string} clazz Extra CSS Klasse
 * @return {Image}       Image JSX
 */
export function getImage(image: string, clazz: ?string):?React$Element<any> {

    switch(image) {
        case "icon1":
            return <Image src={icon1} className={clazz} />;
        case "manhole":
            return <Image src={manhole} className={clazz} />;
        case "section":
            return <Image src={section} className={clazz} />;
        case "icon2":
            return <Image src={icon2} className={clazz} />;
        case "mpeg":
            return <Image src={mpeg} className={clazz} />;
        case "mpeg_nonexistent":
            return <Image src={mpeg_nonexistent} className={clazz} />;
        case "folder":
            return <Image src={folder} className={clazz} />;
        case "inspection":
            return <Image src={inspection} className={clazz} />;
        case "object":
            return <Image src={object} className={clazz} />;
        case "station":
            return <Image src={station} className={clazz} />;
        case "lateral":
            return <Image src={lateral} className={clazz} />;
        case "film":
            return <Image src={film} className={clazz} />;
        case "measurement":
            return <Image src={measurement} className={clazz} />;
        case "photo_big":
            return <Image src={photo_big} className={clazz} />;
        case "photo_medium":
            return <Image src={photo_medium} className={clazz} />;
        case "photo_little":
            return <Image src={photo_little} className={clazz} />;
        case "net_plane":
            return <Image src={net_plane} className={clazz} />;
        case "none":
            return <div></div>;
        case "in":
            return <Image src={inflow} className={clazz} />;
        case "out":
            return <Image src={outflow} className={clazz} />;
        case "pano":
            return <Image src={pano} className={clazz} />;
        case "panoramo":
            return <Image src={panoramo} className={clazz} />;
        case "panoramo_nonexistent":
                return <Image src={panoramonofile} className={clazz} />;
        case "arrowUp":
            return <Image src={arrowUp} className={clazz} />;
        case 'invalidImage':
            return <Image fluid src={invalidImage} className={clazz} />;
        default:
            return null;
    }
}

/**
 * Erstellt ein Image mit dem gegebenen Wert
 * @param  {string} image Name des Bildes
 * @param {string} key Key des Bildes
 * @param  {string} clazz Extra CSS Klasse
 * @return {Image}       Image JSX
 */
export function getImageSmall(image: string, key: ?number, clazz: ?string):?React$Element<any> {
    switch(image) {
        case "manhole":
            return <Image src={manhole_small} className={clazz} key={key} />;
        case "section":
            return <Image src={section_small} className={clazz} key={key} />;
        case "mpeg":
            return <Image src={mpeg_small} className={clazz} key={key} />;
        case "folder":
            return <Image src={folder_small} className={clazz} key={key} />;
        case "lateral":
            return <Image src={lateral_small} className={clazz} key={key} />;
        case 'station':
            return <Image src={station_small} className={clazz} key={key} />
        case "inspection":
            return <Image src={inspection_small} className={clazz} key={key} />;
        case "panoramo":
            return <Image src={panoramo_small} className={clazz} key={key} />;
        default:
            return null;
    }
}

/**
 * Hilfsfunktion, die Werte einer Inspektion ermittelt
 * @param  {string} name Name der Variable in der Inspektion
 * @param  {object} obj  Objekt dessen Inspektionen verwendet werden
 * @return {string}      Wert der Variable falls vorhanden
 */
export function findInspectionValue(name: string, obj: Object):string {
    let result: string = "";
    const names: Array<string> = name.split('.');

    if (obj.hasOwnProperty("inspection")) {
        obj.inspection.forEach((inspection) => {
            names.forEach((name) => {
                if (inspection.hasOwnProperty(name)) {
                    result = inspection[name];
                }
            });
        });
    }

    return result;
}

/**
 * Entschluesselt ein Komplexesobjekt
 * @param  {object} val  Das Objekt das zerlegt werden soll
 * @param  {string} mode Der Modus des Objekts
 * @return {Object}      Der Wert aus dem Objekt
 */
export function getComplexValue(val: Object, mode: string): Object {
    let result: Object = val;
    if (val && typeof val !== 'string') {
        if (val.hasOwnProperty(mode) && mode !== 'default') {
            result = val[mode];
        }
    }
    return result;
}

/**
 * Sucht nach den Werten, die in den zusaetzlichen Parametern angegeben sind
 * @param {Object} obj Objekt in dem gesucht wird
 * @param {Array<string>} args Argumente nach denen gesucht wird
 * @return {any} Das Ergebnis des Suche
 */
export function returnIfHasProperty(obj: Object, args: Array<string>):any {
    for (let i = 0; i < args.length; i++) {
        let arg: string = args[i];
        if (obj.hasOwnProperty(arg)) {
            return obj[arg];
        }
    }
}

/**
 * Sucht nach den Werten, die in den zusaetzlichen Arrays angegeben sind
 * @param {Object} obj Objekt in dem gesucht wird
 * @param {Array<Array>} args Argumente nach denen gesucht wird
 * @return {any} Das Ergebnis der Suche
 */
export function returnIfHasPropertyDeepCheck(obj: Object, args: Array<Array<string>>):any {
    let value: Object = obj;

    for (let i = 0; i < args.length; i++) {
        let arg: Array<string> = args[i];

        for (let j = 0; j < arg.length; j++) {
            let val: string = arg[j];
            if (value.hasOwnProperty(val)) {
                value = value[val];
            }
        }
    }

    return value;
}

/**
 * Prueft ob der Benutzer fuer das gewaehlte Interface Objektlisteneinstellungen hat und setzt diese falls noetig
 * @param  {object} settings   Aktuelles settings Objekt
 * @param  {object} _interface Aktuelle Schnittstelle
 * @param  {string} name       Name der Schnittstelle
 * @return {object}            Aktualisierte Settings
 */
export function checkObjectListColumns(settings: Object, _interface: ?Object, name: string): Object {
    if (settings.hasOwnProperty("objectlist")) {
        if (!settings.objectlist.hasOwnProperty(name)) {
            const def: Array<Object> = [] //Immer standard spalten hinzufuegen
            general.objectlist.columns.forEach((col: Object) => {
                let entry: Object = {
                    id: col.id,
                    show: true,
                    mode: 'default'
                }
                def.push(entry);
            });

            if (_interface) {
                let objectlist: Array<Object> = _interface.objectlist;

                objectlist.forEach((list: Object) => {
                    list.columns.forEach((col: Object) => {
                        if (col.duoField) {
                            def.push({
                                id: col.id,
                                show: false,
                                mode: OBJECTLIST_COLUMN_MODE.TEXT
                            });

                            def.push({
                                id: col.id,
                                show: false,
                                mode: OBJECTLIST_COLUMN_MODE.CODE
                            });
                        }
                        else {
                            def.push({
                                id: col.id,
                                show: false,
                                mode: OBJECTLIST_COLUMN_MODE.DEFAULT
                            });
                        }

                    });
                });
                settings.objectlist[name] = def;
            }
            return settings;
        }

        //TODO: checken ob felder noch vorhanden
    }
    return settings;
}

/**
 * Fuehrt die angegeben Funktion aus, wenn das Error Objekt den Kriterien entspricht
 * @param {Object} err Axios Error Objekt
 * @param {Number} status Erwarteter Status
 * @param {number} type Erwarteter Typ
 * @param {Function} func Funktion, die bei zutreffen aufgerufen wird
 * @param {Array} [args=null] Zusaetzliche Argumente
 */
export function dispatchIf(err: Object, status: number, type: number, func: (?mixed) => mixed, args: ?Object = null):void {
    const response: Object = err.response;

    if (response.status === status && response.data.type === type) {
        func(args);
    }
}

/**
 * rundet einen Wert auf eine bestimmte Dezimalstelle
 * @param {Number} value            Wert der gerundet werden soll
 * @param {Number} [precision=0]    Wert fuer die Dezimalstelle
 */
export function precisionRound(value: number, precision: number = 0) {
    let shift = function (value, precision, reverseShift) {
        if (reverseShift) {
            precision = -precision;
        }
        var numArray = ("" + value).split("e");
        return  + (numArray[0] + "e" + (numArray[1] ? (+numArray[1] + precision) : precision));
    };

    return shift(Math.round(shift(value, precision, false)), precision, true);
}

/**
 * Gibt die Einstellugen fuer ein Template zurueck, falls vorhanden
 *
 * @export
 * @param {Object} settings Einstellungen des Benutzers
 * @param {string} cInterface Das Template
 * @param {string} type Typ des Objekts
 * @param {string} view Ausgewaehlter View der Station
 * @returns
 */
export function findSettingsForStationTemplate(settings: Object, cInterface: string, type: string, view: string) {
    if (settings.station.hasOwnProperty(cInterface) && settings.station[cInterface].hasOwnProperty(type) && settings.station[cInterface][type].hasOwnProperty(view)) {
        return settings.station[cInterface][type][view];
    }
    return null;
}

/**
 * Wechselt zu dem target wenn die Bedingung zutrifft
 *
 * @export
 * @param {string} target Wert, der zurueckgegeben wird, wenn die Bedingung zutrifft
 * @param {string} current Wert, der zurueckgegeben wird, wenn die Bedingung nicht zutrifft
 * @param {(string) => boolean} condition Bedingung, die geprueft wird
 * @returns {string}
 */
export function changeToViewTab(target: string, current: string, condition: (string) => boolean):string {
    if (condition(current)) {
        return target;
    }
    return current;
}