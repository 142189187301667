// @flow
import React from 'react';
import FormHelper from "./FormHelper";
import ValidationArray from './ValidationArray';
import type {ValueElement} from './FormHelper';
import TabHelper from "./TabHelper";
import { isEmpty } from '../../misc/validationHelper';
import TabView from '../components/TabView';

type TabCardOptions = {
    id: string,
    defaultActiveKey?: string,
    items?: Array<TabHelper>
}

type TabCardDefaults = {
    id: string,
    defaultActiveKey: string,
    items: Array<TabHelper>
}

const def: TabCardDefaults = {
    id: '',
    defaultActiveKey: '',
    items: []
}

/**
 * Hilfklasse fuer Formtemplates, um ein Card mit Tabs zu erzeugen
 *
 * @export
 * @class TabCardHelper
 * @extends {FormHelper}
 */
export default class TabCardHelper extends FormHelper {
    _options: TabCardDefaults;

    /**
     * Creates an instance of TabCardHelper.
     * @param {TabCardOptions} options Die Einstellungen der Klasse
     * @memberof TabCardHelper
     */
    constructor(options: TabCardOptions) {
        super();

        this._options = {...def, ...options};
    }

    get id():string {
        return this._options.id;
    }

    get defaultActiveKey():string {
        return this._options.defaultActiveKey;
    }

    get items():Array<TabHelper> {
        return this._options.items;
    }

    /**
     * Prueft ob die Einstellungen der Klasse korrekt sind
     *
     * @returns {ValidationArray}
     * @memberof TabCardHelper
     */
    validate():ValidationArray {
        const messages = new ValidationArray(this.id);

        if (isEmpty(this.id)) {
            messages.error.push('Id ist nicht gesetzt!');
        }

        if (isEmpty(this.defaultActiveKey)) {
            messages.error.push('DefaultActiveKey ist nicht gesetzt!');
        }

        if (this.items.length === 0) {
            messages.warn.push('Dieser TabView hat keine Items!');
        }

        return messages;
    }

    /**
     * Baut das TabCard
     *
     * @param {Object} object Das Kanalobjekt
     * @returns {?ValueElement}
     * @memberof TabCardHelper
     */
    build(object: Object):?ValueElement {
        const children = this.items.map(item => {
            const child = item.build(object);
            if (child) {
                return child.element;
            }
            return null;
        });

        return {
            valueSet: true,
            element:
                <TabView
                    key={this.id}
                    id={this.id}
                    defaultActiveKey={this.defaultActiveKey}
                >
                    {children}
                </TabView>
        };
    }
}