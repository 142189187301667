// @flow
import FormColumnHelper from '../../form/helper/FormColumnHelper';

type StationViewOptions = {
    STATION_VIEW_NO_PHOTO: Array<FormColumnHelper>,
    STATION_VIEW_PHOTO: Array<FormColumnHelper>
}

/**
 * Hilfsklasse fuer die Arten von Tabellen einer Station
 *
 * @export
 * @class StationView
 */
export default class StationView {
    STATION_VIEW_NO_PHOTO: Array<FormColumnHelper>;
    STATION_VIEW_PHOTO: Array<FormColumnHelper>

    /**
     * Creates an instance of StationView.
     * @param {StationViewOptions} options
     * @memberof StationView
     */
    constructor(options: StationViewOptions) {
        this.STATION_VIEW_NO_PHOTO = options.STATION_VIEW_NO_PHOTO;
        this.STATION_VIEW_PHOTO = options.STATION_VIEW_PHOTO;
    }
}