// @flow
import React from 'react';
import InputHelper from './InputHelper';
import ComplexTextBox from '../components/ComplexTextBox';

/**
 * Hilfklasse um eine Textbox zu erzeugen
 *
 * @export
 * @class ComplexTextBoxHelper
 * @extends {InputHelper}
 */
export default class ComplexTextBoxHelper extends InputHelper {
    /**
     * Baut die Textbox
     *
     * @param {any} value Wert der Textbox
     * @param {number} size Groesse der Textbox
     * @returns
     * @memberof ComplexTextBoxHelper
     */
    build(value: any, size: number) {
        return <ComplexTextBox inputSize={size} value={value} />
    }
}
