
import CardHelper from '../../../form/helper/CardHelper';
import FieldHelper from '../../../form/helper/FieldHelper';
import CheckboxHelper from '../../../form/helper/CheckboxHelper';
import ComplexTextBoxHelper from '../../../form/helper/ComplexTextBoxHelper';

const inspectionCanalObjectSection = [
    new CardHelper({
        id: 'general',
        title: {
            key: 'common.general',
            fallback: 'Allgemein'
        },
        items: [
            new FieldHelper({
                id: 'inspector',
                title: {
                    key: 'inspection.inspector',
                    fallback: 'Inspekteur'
                },
                accessor: 'NameUntersucher'
            }),
            new FieldHelper({
                id: 'weather',
                title: {
                    key: 'inspection.weather',
                    fallback: 'Wetter'
                },
                accessor: 'Wetter',
                field: new ComplexTextBoxHelper()
            }),
            new FieldHelper({
                id: 'cleaning',
                title: {
                    key: 'inspection.cleaning',
                    fallback: 'Reinigung'
                },
                accessor: 'Reinigung',
                field: new CheckboxHelper()
            }),
            new FieldHelper({
                id: 'temperature',
                title: {
                    key: 'inspection.temperature',
                    fallback: 'Temperatur'
                },
                accessor: 'Temperatur'
            }),
            new FieldHelper({
                id: 'singlePipeLength',
                title: {
                    key: 'isybauxml.inspection.common.singlePipeLength',
                    fallback: 'Regeleinzelrohrlänge'
                },
                accessor: 'Regeleinzelrohrlaenge'
            }),
            new FieldHelper({
                id: 'comment',
                title: {
                    key: 'common.comment',
                    fallback: 'Kommentar'
                },
                accessor: 'Bemerkung'
            })
        ]
    }),
    new CardHelper({
        id: 'inspection',
        title: {
            key: 'inspection.inspection',
            fallback: 'Inspektion'
        },
        items: [
            new FieldHelper({
                id: 'inspectionDirection',
                title: {
                    key: 'inspection.inspectionDirection',
                    fallback: 'Inspektionsrichtung'
                },
                accessor: 'Inspektionsrichtung',
                field: new ComplexTextBoxHelper()
            }),
            new FieldHelper({
                id: 'inspectionDate',
                title: {
                    key: 'inspection.inspectionDate',
                    fallback: 'Inspektionsdatum'
                },
                accessor: 'ISYBAU_XML_Inspektionszeit'
            }),
            new FieldHelper({
                id: 'typeInspection',
                title: {
                    key: 'inspection.typeInspection',
                    fallback: 'Inspektionsart'
                },
                accessor: 'Inspektionsart',
                field: new ComplexTextBoxHelper()
            }),
            new FieldHelper({
                id: 'inspectionLength',
                title: {
                    key: 'inspection.inspectionLength',
                    fallback: 'Inspektionslänge'
                },
                accessor: 'Inspektionslaenge'
            }),
            new FieldHelper({
                id: 'inspectionMethod',
                title: {
                    key: 'isybauxml.inspection.common.inspectionMethod',
                    fallback: 'Inspektionsverfahren'
                },
                accessor: 'Inspektionsverfahren',
                field: new ComplexTextBoxHelper()
            }),
            new FieldHelper({
                id: 'dataMedium',
                title: {
                    key: 'isybauxml.inspection.common.dataMedium',
                    fallback: 'Datenträger'
                },
                accessor: 'Videoablagereferenz'
            }),
            new FieldHelper({
                id: 'drainageMeasures',
                title: {
                    key: 'isybauxml.inspection.common.drainageMeasures',
                    fallback: 'Maßnahmen zur Wasserhaltung'
                },
                accessor: 'Wasserhaltung',
                field: new ComplexTextBoxHelper()
            }),
            new FieldHelper({
                id: 'videoStorageMedium',
                title: {
                    key: 'inspection.videoStorageMedium',
                    fallback: 'Videospeichermedium'
                },
                accessor: 'ISYBAU-XML_H_VideoSpeichermedium',
                field: new ComplexTextBoxHelper()
            }),
            new FieldHelper({
                id: 'photoStorageMedium',
                title: {
                    key: 'inspection.photoStorageMedium',
                    fallback: 'Fotospeichermedium'
                },
                accessor: 'ISYBAU-XML_H_FotoSpeichermedium',
                field: new ComplexTextBoxHelper()
            })
        ]
    }),
    new CardHelper({
        id: 'point',
        title: {
            key: 'inspection.longitudinalPoint',
            fallback: 'Bezugspunkt in Längsrichtung'
        },
        items: [
            new FieldHelper({
                id: 'longitudinalPoint',
                title: {
                    key: 'inspection.longitudinalPoint',
                    fallback: 'Bezugspunkt in Längsrichtung'
                },
                accessor: 'BezugspunktLage',
                columnSize: {
                    lg: 12,
                    md: 12
                },
                size: {
                    lg: 2,
                    md: 2
                },
                field: new ComplexTextBoxHelper()
            }),
            new FieldHelper({
                id: 'distanceTopManhole',
                title: {
                    key: 'inspection.distanceTopManhole',
                    fallback: 'Abstand oberer Schacht'
                },
                accessor: 'IS_CenterPointRefValueTop'
            }),
            new FieldHelper({
                id: 'distanceBottomManhole',
                title: {
                    key: 'inspection.distanceBottomManhole',
                    fallback: 'Abstand unterer Schacht'
                },
                accessor: 'IS_CenterPointRefValueBottom'
            })
        ]
    })
];

export default inspectionCanalObjectSection;