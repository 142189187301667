import {
    stationImgRenderer, 
    imgRenderer, 
    checkBoxRenderer, 
    transColumnRenderer, 
    objectListColumnRenderer, 
    formTableIconDirectionRenderer, 
    tableArrowGegenUntersuchungRenderer
} from './RendererUtils';

/**
 * Klasse die Zellen von Tabellen rendert
 * @constructor
 */
class CellRenderer {
    props = null;
    renderFunctions = {};

    /**
     * Setzt die Properties von dem CellRenderer, die beim rendern zur Verfuegung stehen
     * @param {object} props Objekt mit den Properties
     */
    setProps(props: Object):void {
        this.props = props;
    }

    /**
     * Rendert die Zelle mithilfe des gegebenen Renders
     * @param  {string} name  Name des zu verwendenen Renderes     
     * @param {Object} args Objekt mit den zusaetzlichen Parametern
     * @return {object}       Gerenderte Zelle, wenn der Renderer bekannt ist
     */
    render(name: string, args: Object):Object {                
        if (this.renderFunctions.hasOwnProperty(name)) {
            return this.renderFunctions[name](args, this.props);
        }
        throw new Error("Renderer not found");
    }

    /**
     * Fuegt einen Renderer hinzu
     * @param {string}   name Name des Renderes
     * @param {Function} fn   Funktion, die fuer das rendern genutzt werden soll
     */
    // $FlowFixMe
    addRenderer(name: string, fn: (?mixed) => mixed):void {
        if (name != null && fn != null) {
            this.renderFunctions[name] = fn;
        }
    }
}

const renderer = new CellRenderer();
renderer.addRenderer('stationImgRenderer', stationImgRenderer);
renderer.addRenderer('imgRenderer', imgRenderer);
renderer.addRenderer('checkBoxRenderer', checkBoxRenderer);
renderer.addRenderer('transColumnRenderer', transColumnRenderer);
renderer.addRenderer('objectListColumnRenderer', objectListColumnRenderer);
renderer.addRenderer('formTableIconDirectionRenderer', formTableIconDirectionRenderer);
renderer.addRenderer('tableArrowGegenUntersuchungRenderer', tableArrowGegenUntersuchungRenderer);

export default renderer;
