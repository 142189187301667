import { tokenizedGet, tokenizedPost } from '../API';
import { PATH_SETTINGS, PATH_PROJECT, PATH_OBJECT, PATH_PANORAMO } from '../paths';

export function getProjects(token) {
	return tokenizedGet(PATH_PROJECT, token);
}

export function getProject(projectId, token) {
	return tokenizedGet(`${PATH_PROJECT}/${projectId}`, token);
}

export function getSettings(token) {
	return tokenizedGet(PATH_SETTINGS, token);
}

export function postSettings(settings, token) {
	return tokenizedPost(PATH_SETTINGS, settings, token);
}

export function getObjects(id, token) {
	return tokenizedGet(`${PATH_PROJECT}/${id}/object`, token);
}

export function getStations(id, token) {
	return tokenizedGet(`${PATH_OBJECT}/${id}/station`, token);
}

export function getIPWURL(inspectionId, token) {
    return tokenizedGet(`${PATH_PANORAMO}/${inspectionId}`, token);
}
