// @flow
import CardHelper from '../../form/helper/CardHelper';
import FieldHelper from '../../form/helper/FieldHelper';
import Classification from '../helper/Classification';
import CanalObjects from '../helper/CanalObjects';
import CanalObject from '../helper/CanalObject';

/**
 * Template Beschreibung fuer DWA-M-149-3
 * @type {Object}
 */
// eslint-disable-next-line import/no-anonymous-default-export
export default new Classification({
    name: 'DWA-M 149-3',
    identifiers: [
        'dwa_m_149_3',
        'DWA-M 149-3 2007 Edge',
        'DWA-M 149-3 2007 Manhole',
    ],
    version: '2.0',
    inspection: new CanalObjects({
        section: new CanalObject([
            new CardHelper({
                id: 'dwa_m_149_3',
                title: {
                    key: 'dwa_m_149_3.header',
                    fallback: 'Bewertung DWA-M 149-3'
                },
                items: [
                    new FieldHelper({
                        id: 'evaluationTime',
                        title: {
                            key: 'dwa_m_149_3.evaluationTime',
                            fallback: 'Bewertungszeit'
                        },
                        accessor: 'ISYBAU-XML_EvaluationTime'
                    }),
                    new FieldHelper({
                        id: 'evalPointTightness',
                        title: {
                            key: 'dwa_m_149_3.evalPointTightness',
                            fallback: 'Bew.punkte Dichtheit'
                        },
                        accessor: 'ISYBAU-XML_EvaluationPointsEvalTightness'
                    }),
                    new FieldHelper({
                        id: 'objectClass',
                        title: {
                            key: 'dwa_m_149_3.objectClass',
                            fallback: 'Objektklasse'
                        },
                        accessor: 'Class'
                    }),
                    new FieldHelper({
                        id: 'evalPointStability',
                        title: {
                            key: 'dwa_m_149_3.evalPointStability',
                            fallback: 'Bew.punkte Standsicherheit'
                        },
                        accessor: 'ISYBAU-XML_EvaluationPointsEvalStatic'
                    }),
                    new FieldHelper({
                        id: 'rehabilitationNumber',
                        title: {
                            key: 'dwa_m_149_3.rehabilitationNumber',
                            fallback: 'Sanierungsbedarfszahl'
                        },
                        accessor: 'ISYBAU-XML_EvaluationNumberFinal'
                    }),
                    new FieldHelper({
                        id: 'evalPointsOpReliability',
                        title: {
                            key: 'dwa_m_149_3.evalPointsOpReliability',
                            fallback: 'Bew.punkte Betriebssicherheit'
                        },
                        accessor: 'ISYBAU-XML_EvaluationPointsEvalOperation'
                    })
                ]
            })
        ]),
        lateral: new CanalObject([
            new CardHelper({
                id: 'dwa_m_149_3',
                title: {
                    key: 'dwa_m_149_3.header',
                    fallback: 'Bewertung DWA-M 149-3'
                },
                items: [
                    new FieldHelper({
                        id: 'evaluationTime',
                        title: {
                            key: 'dwa_m_149_3.evaluationTime',
                            fallback: 'Bewertungszeit'
                        },
                        accessor: 'ISYBAU-XML_EvaluationTime'
                    }),
                    new FieldHelper({
                        id: 'evalPointTightness',
                        title: {
                            key: 'dwa_m_149_3.evalPointTightness',
                            fallback: 'Bew.punkte Dichtheit'
                        },
                        accessor: 'ISYBAU-XML_EvaluationPointsEvalTightness'
                    }),
                    new FieldHelper({
                        id: 'objectClass',
                        title: {
                            key: 'dwa_m_149_3.objectClass',
                            fallback: 'Objektklasse'
                        },
                        accessor: 'ISYBAU-XML_Class'
                    }),
                    new FieldHelper({
                        id: 'evalPointStability',
                        title: {
                            key: 'dwa_m_149_3.evalPointStability',
                            fallback: 'Bew.punkte Standsicherheit'
                        },
                        accessor: 'ISYBAU-XML_EvaluationPointsEvalStatic'
                    }),
                    new FieldHelper({
                        id: 'rehabilitationNumber',
                        title: {
                            key: 'dwa_m_149_3.rehabilitationNumber',
                            fallback: 'Sanierungsbedarfszahl'
                        },
                        accessor: 'ISYBAU-XML_EvaluationNumberFinal'
                    }),
                    new FieldHelper({
                        id: 'evalPointsOpReliability',
                        title: {
                            key: 'dwa_m_149_3.evalPointsOpReliability',
                            fallback: 'Bew.punkte Betriebssicherheit'
                        },
                        accessor: 'ISYBAU-XML_EvaluationPointsEvalOperation'
                    })
                ]
            })
        ]),
        manhole: new CanalObject([
            new CardHelper({
                id: 'dwa_m_149_3',
                title: {
                    key: 'dwa_m_149_3.header',
                    fallback: 'Bewertung DWA-M 149-3'
                },
                items: [
                    new FieldHelper({
                        id: 'evaluationTime',
                        title: {
                            key: 'dwa_m_149_3.evaluationTime',
                            fallback: 'Bewertungszeit'
                        },
                        accessor: 'ISYBAU-XML_EvaluationTime'
                    }),
                    new FieldHelper({
                        id: 'evalPointTightness',
                        title: {
                            key: 'dwa_m_149_3.evalPointTightness',
                            fallback: 'Bew.punkte Dichtheit'
                        },
                        accessor: 'ISYBAU-XML_EvaluationPointsEvalTightness'
                    }),
                    new FieldHelper({
                        id: 'objectClass',
                        title: {
                            key: 'dwa_m_149_3.objectClass',
                            fallback: 'Objektklasse'
                        },
                        accessor: 'ISYBAU-XML_Class'
                    }),
                    new FieldHelper({
                        id: 'evalPointStability',
                        title: {
                            key: 'dwa_m_149_3.evalPointStability',
                            fallback: 'Bew.punkte Standsicherheit'
                        },
                        accessor: 'ISYBAU-XML_EvaluationPointsEvalStatic'
                    }),
                    new FieldHelper({
                        id: 'rehabilitationNumber',
                        title: {
                            key: 'dwa_m_149_3.rehabilitationNumber',
                            fallback: 'Sanierungsbedarfszahl'
                        },
                        accessor: 'ISYBAU-XML_EvaluationNumberFinal'
                    }),
                    new FieldHelper({
                        id: 'evalPointsOpReliability',
                        title: {
                            key: 'dwa_m_149_3.evalPointsOpReliability',
                            fallback: 'Bew.punkte Betriebssicherheit'
                        },
                        accessor: 'ISYBAU-XML_EvaluationPointsEvalOperation'
                    })
                ]
            })
        ])
    })
});