// @flow
import Layout from './Layout';
import ThreeToNineLayout from './ThreeToNineLayout';
import FourToEightLayout from './FourToEightLayout';
import FiveToSevenLayout from './FiveToSevenLayout';
// import SixToSixLayout from './SixToSixLayout';
// import ZeroToTwelveLayout from './ZeroToTwelveLayout';
import TwelveToZeroLayout from './TwelveToZeroLayout'

type Option = {
    value: string,
    image: React$Element<any>
}

/**
 * Klasse fuer einen Manager der alle Layouts verwaltet
 *
 * @class LayoutManager
 */
class LayoutManager {
    _layouts: Array<Layout> = [];
    _default: Layout;

    /**
     * Fuegt ein Layout hinzu
     *
     * @param {Layout} layout Das Layout, dass hinzugefuegt wird
     * @param {boolean} [setAsDefault=false] Ob dieses als Standard gesetzt werden soll
     * @memberof LayoutManager
     */
    addLayout(layout: Layout, setAsDefault: boolean = false):void {
        this._layouts.push(layout);

        if (setAsDefault) {
            this._default = layout;
        }
    }

    /**
     * Gibt das Layout mit der Id zurueck, wenn vorhanden, ansonsten das Standard
     *
     * @param {string} id Id des Layouts
     * @returns {Layout}
     * @memberof LayoutManager
     */
    getLayout(id: string):Layout {
        let result = this._default;
        this._layouts.forEach(layout => {
            if (layout.id === id) {
                result = layout;
            }
        })
        return result;
    }

    /**
     * Gibt alle Layouts als Options zurueck fur eine Anzeige in den settings
     *
     * @returns {Array<Option>}
     * @memberof LayoutManager
     */
    getOptions():Array<Option> {
        return this._layouts
            // popout ist nur ein Hilfslayout, für videos und panoramos im Popout
            .filter(e => e.id !== 'popout')
            .map(layout => { return { value: layout.id, image: layout.image }});
    }
}

const manager = new LayoutManager();

manager.addLayout(new ThreeToNineLayout());
manager.addLayout(new FourToEightLayout(), true);
manager.addLayout(new FiveToSevenLayout());
// manager.addLayout(new SixToSixLayout());
// manager.addLayout(new ZeroToTwelveLayout());
manager.addLayout(new TwelveToZeroLayout());
export default manager;