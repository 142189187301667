// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Modal, Container, Row, Card, Tabs, Tab } from 'react-bootstrap';
import { Trans } from 'react-i18next';
import cloneDeep from 'lodash.clonedeep';
import set from 'lodash.set';

import '../../css/settingsview.css';
import { faCheck, faTimes/*, faQuestion*/ } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { _updateSettings } from '../../actions/dataActions';
import GeneralTab from './GeneralTab';
import { GENERAL_KEY } from './tabkeys';
import { renderTransTitle } from '../../misc/utils';

type Props = {
	settings: Object,
	token: string,
	_updateSettings: (Object, string, ?boolean, ?boolean) => mixed,
	hide: () => void,
};

type State = {
	settings: Object,
	help: boolean,
};

/**
 * Klasse fuer ein Modal mit Einstellungen
 *
 * @export
 * @class ViewSettings
 * @extends {Component<Props, State>}
 */
class SettingsView extends Component<Props, State> {
	/**
	 * Creates an instance of ViewSettings.
	 * @param {Object} props Die Properties
	 */
	constructor(props: Object) {
		super(props);

		this.state = {
			settings: cloneDeep(this.props.settings),
			help: false,
		};
	}

	/**
	 * Callback fuer Aenderungen
	 *
	 * @param {string} target Pfad zum Wert im Objekt
	 * @param {any} value Der neue Wert
	 * @memberof SettingsView
	 */
	onChange = (target: string, value: any): void => {
		const { settings } = this.state;

		set(settings, target, value);

		this.setState({ settings });
	};

	/**
	 * speichert die geaenderten Einstellungen
	 */
	saveSettings = (): void => {
		const { hide, _updateSettings } = this.props;
		const { settings } = this.state;

		_updateSettings(settings, true);
		hide();
	};

	/**
	 * Behandelt Tastatur Events
	 *
	 * @param {Object} e Event, dass aufgetreten ist
	 */
	handleKeyPress = (e: SyntheticEvent<>) => {
		if (e.key === 'Enter') {
			this.saveSettings();
		}
	};

	// /**
	//  * Toggelt die Anzeige der Hilfe
	//  *
	//  * @memberof SettingsView
	//  */
	// toggleHelp = () => {
	// 	const { help } = this.state;

	// 	this.setState({
	// 		help: !help,
	// 	});
	// };

	/**
	 * Rendert die Buttons der Einstellung
	 *
	 * @returns {React$Element<any>}
	 * @memberof ViewSettings
	 */
	renderButtons(): React$Element<any> {
		const { hide } = this.props;
		// const { help } = this.state;

		return (
			<Card className="buttonCard">
				<Card.Body >
					<Button className="settings-btn" type="button" onClick={this.saveSettings}>
						<FontAwesomeIcon className="icon-button" icon={faCheck}></FontAwesomeIcon>&nbsp;
						<Trans i18nKey="common.save" parent="span">
							Speichern
						</Trans>
					</Button>
					<Button className="settings-btn" type="button" onClick={hide}>
						<FontAwesomeIcon className="icon-button" icon={faTimes}></FontAwesomeIcon>&nbsp;
						<Trans i18nKey="common.abort" parent="span">
							Abbrechen
						</Trans>
					</Button>
					{/* <Button as={help ? 'primary' : null} className="settings-btn" type="button" onClick={this.toggleHelp} active={help}>
						<FontAwesomeIcon className="icon-button" icon={faQuestion}></FontAwesomeIcon>&nbsp;
						<Trans i18nKey="settings.help" parent="span">
							Hilfe
						</Trans>
					</Button> */}
				</Card.Body>
			</Card>
		);
	}

	/**
	 * Rendert die Komponente
	 * @return {JSX}
	 */
	render() {
		const { settings, help } = this.state;
		const { hide } = this.props;

		return (
			<Modal size="xl" show onHide={hide} onKeyPress={this.handleKeyPress} dialogClassName="modal-x-large">
				<Modal.Header closeButton>
					<Modal.Title>
						<Trans i18nKey="common.settings">Einstellungen</Trans>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Container fluid>
						<Row>{this.renderButtons()}</Row>
						<Row id="settings-tab-container">
                            <Tabs defaultActiveKey={GENERAL_KEY} id="uncontrolled-tab-example" className="no-navbar">
                                <Tab
                                    eventKey={GENERAL_KEY}
                                    key={GENERAL_KEY}
                                    title={renderTransTitle('Allgemein', 'common.general')}
                                    tabClassName="settings-tabs"
                                >
                                    <GeneralTab settings={settings} onChange={this.onChange} help={help} />
                                </Tab>
                            </Tabs>
						</Row>
					</Container>
				</Modal.Body>
			</Modal>
		);
	}
}

/**
 *  Verbindet die Klasse mit dem Redux Store
 */
const ReduxSettingsView = connect(
	(state, ownProps) => {
		return {
			settings: state.settings,
			token: state.authentication.token,
			hide: ownProps.hide,
		};
	},
	{
		_updateSettings,
	}
)(SettingsView);

export default ReduxSettingsView;
