// @flow
import React from 'react';
import Hammer from 'react-hammerjs';

export default function TouchHandler({ onSwipeRight, onSwipeLeft, onPress, children }) {
	return (
		<Hammer onSwipeRight={onSwipeRight} onSwipeLeft={onSwipeLeft} onPress={onPress}>
			{children}
		</Hammer>
	);
}
