// @flow
import { DEFAULT_SETTINGS, PROJECT_LEVEL_VIEWS } from '../misc/const';
import { UPDATE_SETTINGS,
        PROJECTS_RECEIVED,
        PROJECTS_UPDATED,
        OBJECTS_RECEIVED,
        STATIONS_RECEIVED,
        NO_ACTION,
        SWITCH_PROJECT,
        BACK_TO_PROJECT,
        PANO_FILE_URL_RECEIVED } from '../actions/utils/actionTypes';

import * as Request from '../backend/requests/dataRequests';
import { Dispatch, ThunkAction, Action, GetState } from '../misc/flowTypes';
import { dispatchIfDefaultErrors } from './utils/conditionalDispatches';
import { sendErrorMessage } from './loginActions';
import { setShowLoadingSpinner } from './viewActions';
import { makeActionCreator } from './utils/actionCreator';


/**
 * Erstellt eine Action fuer Redux vom Typ SWITCH_PROJECT
 * @param  {Object} project Id des Projekts das gesetzt werden soll
 * @return {Object}         SWITCH_PROJECT Action
 */
export const _switchProject = makeActionCreator(SWITCH_PROJECT, 'project');

/**
 * Erstellt eine Action fuer Redux vom Typ BACK_TO_PROJECT
 * @return {Object}         BACK_TO_PROJECT Action
 */
// $FlowFixMe
export const backToProject = makeActionCreator(BACK_TO_PROJECT);

/**
 * Erfragt die Projekte des angemeldeten Benutzers
 * @param {String} token Der API Token
 * @return {Function} Die Abfrage
 */
export function _requestProjects(): ThunkAction {
	return (dispatch: Dispatch, getState: GetState) => {
		const {
			authentication: { token },
		} = getState();
		Request.getProjects(token)
			.then((result: Object) => {
				dispatch(_projectsReceived(result.data.projects));
			})
			.catch((error: Object) => {
				if (!dispatchIfDefaultErrors(dispatch, error)) {
					dispatch(sendErrorMessage('messages.projectsNotFound'));
				}
			});
	};
}

/**
 * Callback Action fuer den Abruf der Projekte
 * @param {Array} projects Die abgerufenen Projekte
 * @return {Object} PROJECTS_RECEIVED
 */
function _projectsReceived(projects: Array<Object>): Action {
	projects.forEach((project) => {
		project.objects = [];
		for (let field in project.fields) {
			project[field] = project.fields[field];
		}
	});

	return {
		type: PROJECTS_RECEIVED,
		projects,
	};
}

export function _updateProjectsList(): ThunkAction {
	return (dispatch: Dispatch, getState: GetState) => {
		const {
			authentication: { token }
		} = getState();
        dispatch(setShowLoadingSpinner(true));
		Request.getProjects(token)
			.then((result: Object) => {
                let newProjects = result.data.projects;
				dispatch(_updatedProjectsReceived(newProjects));
			})
			.catch((error: Object) => {
				if (!dispatchIfDefaultErrors(dispatch, error)) {
					dispatch(sendErrorMessage('messages.projectsNotFound'));
				}
			})
            .finally(() => dispatch(setShowLoadingSpinner(false)));
	};
}

function _updatedProjectsReceived(newprojects: Array<Object>): Action {

    return {
		type: PROJECTS_UPDATED,
		projects: newprojects,
	};
}

/**
 * Erfragt das Projekt mit der angegebenen ProjektId
 * @param {String} projectId Die ProjektId
 * @param {String} token Der API Token
 * @return {Function} Die Abfrage
 */
export function _requestProject(projectId: string): ThunkAction {
	return (dispatch: Dispatch, getState: GetState) => {
		const {
			authentication: { token },
		} = getState();

		Request.getProject(projectId, token)
			.then((result: Object) => {
				dispatch(_projectReceived(result.data.project));
			})
			.catch((error: Object) => {
				if (!dispatchIfDefaultErrors(dispatch, error)) {
					dispatch(sendErrorMessage('messages.projectsNotFound'));
				}
			});
	};
}

/**
 * Callback Action fuer den Abruf von einem Projekt
 * @param {Object} project Das abgerufene Projekt
 * @return {Object} PROJECTS_RECEIVED
 */
function _projectReceived(project: Object): Action {
	let projects: Array<Object> = [];

	project.objects = [];
	for (let field in project.fields) {
		project[field] = project.fields[field];
	}

	projects.push(project);

	return {
		type: PROJECTS_RECEIVED,
		projects,
	};
}

/**
 * Redux Action fuer die Abfrage der Einstellungen
 * @param {String} token Der API Token
 * @param {Function} callAfter Funktion, die gerufen werden soll nach dem Abruf der Einstellungen
 * @return {Function} Die Redux Action
 */
export function _requestSettings(token, callAfter: () => mixed): ThunkAction {
	return (dispatch: Dispatch) => {
		Request.getSettings(token)
			.then((result: Object) => {
				dispatch(_settingsReceived(result.data.setting));
				callAfter();
			})
			.catch((error: Object) => {
				if (!dispatchIfDefaultErrors(dispatch, error)) {
					dispatch(sendErrorMessage('messages.settingsNotReceived'));
				}
			});
	};
}

/**
 * Redux Action fuer den Erhalt von Einstellungen
 * @param {Object} settings Objekt mit den Einstellungen
 * @param {String} token Der API Token
 * @return {Function} Die Redux Action
 */
function _settingsReceived(settings: Object): ThunkAction {
	return (dispatch: Dispatch) => {
		let values: Object = {};
		if (settings.values) {
			values = settings.values;
		}

		values = { ...DEFAULT_SETTINGS, ...values };
		dispatch(_updateSettings(values, false));
	};
}

/**
 * Redux Action fuer das aktualisieren der Einstellungen
 * @param {Object} settings Die neuen Settings
 * @param {String} token Der API Token
 * @param {Boolean} [save=false] Ob die Einstellungen Serverseitig gespeichert werden sollen
 * @param {Boolean} [refresh=true] Ob die Einstellungen auf im Store aktualisiert werden sollen
 * @return {Function} Die Redux Action
 */
// $FlowFixMe
export function _updateSettings(settings: Object, save: ?boolean = false, refresh: ?boolean = true): ThunkAction | Action {
	if (save) {
		return (dispatch: Dispatch, getState: GetState) => {
			const {
				authentication: { token },
			} = getState();

			Request.postSettings(settings, token)
				.then((result: Object) => {
					dispatch(_updateSettings(result.data.setting.values, false, refresh));
				})
				.catch((error: Object) => {
					if (!dispatchIfDefaultErrors(dispatch, error)) {
						dispatch(sendErrorMessage('messages.settingsNotSaved'));
					}
				});
		};
	} else if (refresh) {
		return {
			type: UPDATE_SETTINGS,
			settings,
		};
	} else {
		return {
			type: NO_ACTION,
		};
	}
}

/**
 * Erzeugt die Action um auf das gegebene Projekt zu wechseln
 * @param {Object} project Projekt auf das gewechselt wird
 * @return {Function} Die Redux Action
 */
export function switchProject(project: Object): ThunkAction {
	return (dispatch: Dispatch, getState: GetState) => {
		const { mainViewTab } = getState();
		if (
			project &&
			project.objects.length === 0 &&
			(mainViewTab === PROJECT_LEVEL_VIEWS.MAIN.OBJECT_LIST_MAIN_VIEW || mainViewTab === PROJECT_LEVEL_VIEWS.MAIN.NET_PLANE_VIEW)
		) {
			dispatch(
				_requestObjects(project, () => {
					dispatch(_switchProject(project));
				})
			);
		} else {
			dispatch(_switchProject(project));
		}
	};
}

/**
 * Erfragt die Objekte des gegebenen Objektes
 * @param {Object} project Projekt, dessen Objekte abgerufen wird
 * @param {String} token Der API Token
 * @param {Function} func Funktion, die gerufen werden soll
 * @return {Function} Die Redux Action
 */
export function _requestObjects(project: Object, func?: () => mixed): ThunkAction {
	return (dispatch: Dispatch, getState: GetState) => {
		const {
			authentication: { token },
		} = getState();

		const id: string = project._id;

		Request.getObjects(id, token)
			.then((result: Object) => {
				dispatch(_objectsReceived(project, result.data.canalObjects));
				if (func) {
					func();
				}
			})
			.catch((error: Object) => {
				if (!dispatchIfDefaultErrors(dispatch, error)) {
					dispatch(sendErrorMessage('messages.objectsNotFound'));
				}

				if (func) {
					func();
				}
			});
	};
}

/**
 * Callback Action fuer den Abruf von Objekten
 * @param {Object} project Projekt, dessen Objekte abgerufen wurde
 * @param {Array} objects Objekte, die abgerufen wurden
 * @return {Object} Die Redux Action
 */
function _objectsReceived(project: Object, objects: Array<Object>): Action {
	const canalObjects: Array<Object> = [];

	objects.forEach((object: Object) => {
		const canalObject: Object = object.canalObject;
		canalObject.inspection = [];
		canalObject.stations = [];

		for (let field in canalObject.fields) {
			canalObject[field] = canalObject.fields[field];
		}

		object.inspections.forEach((inspection: Object) => {
			for (let field in inspection.fields) {
				inspection[field] = inspection.fields[field];
			}
			canalObject.inspection.push(inspection);
		});

		canalObjects.push(canalObject);
	});

	return {
		type: OBJECTS_RECEIVED,
		project,
		canalObjects,
	};
}

/**
 * Erfragt die Stationen des gegebenen Objekts
 * @param {Object} object Objekt, dessen Station erfragt werden
 * @param {String} token Der API Token
 * @param {Function} func Funktion, die gerufen werden soll
 * @return {Function} Die Redux Action
 */
export function _requestStations(object: Object, func: () => mixed): ThunkAction {
	return (dispatch: Dispatch, getState: GetState) => {
		const {
			authentication: { token },
		} = getState();
		const id: string = object._id;

		Request.getStations(id, token)
			.then((result: Object) => {
				dispatch(_stationsReceived(object, result.data.stations));
				func();
			})
			.catch((error: Object) => {
				if (!dispatchIfDefaultErrors(dispatch, error)) {
					dispatch(sendErrorMessage('messages.stationsNotFound'));
				}
				func();
			});
	};
}

/**
 * Callback fuer den Abruf von Stationen
 * @param {Object} object Objekt, dessen Stationen abgerufen wurden
 * @param {Array} stations Stationen, die abgerufen wurden
 * @return {Object} Die Redux Action
 */
function _stationsReceived(object: Object, stations: Array<Object>): Action {
	stations.forEach((station: Object) => {
		for (let field in station.fields) {
			station[field] = station.fields[field];
		}
	});

	return {
		type: STATIONS_RECEIVED,
		object,
		stations,
	};
}

export function requestPanoFileURL(inspectionId: String/*, func: () => mixed*/): ThunkAction {
	return (dispatch: Dispatch, getState: GetState) => {
		const {
			authentication: { token },
		} = getState();
		Request.getIPWURL(inspectionId, token)
			.then(function(result: Object) {
				dispatch(_panoFileURLReceived(result.data.url, inspectionId));
			})
			.catch((error: Object) => {
				if (!dispatchIfDefaultErrors(dispatch, error)) {
					dispatch(sendErrorMessage('messages.panoramoNotFound'));
				}
			});
	};
}

function _panoFileURLReceived(url: String, inspectionId: String): Action {
	return {
		type: PANO_FILE_URL_RECEIVED,
		url,
        inspectionId,
	};
}
