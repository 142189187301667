// @flow
import React, {Component, type ComponentType} from 'react';
import Mousetrap from 'mousetrap';

export type KeyboardCommand = {
    key: string | Array<string>,
    func: (any) => mixed
}

type Props = {}

type State = {
    commands: Array<KeyboardCommand>
}

/**
 * HOC fuer Komponenten die Keyboardevents binden sollen mit Redux Actions
 *
 * @export
 * @param {React$Element<any>} WrappedComponent Die Komponente, die intern genutzt wird
 * @param {Array<KeyboardCommand>} commands Die Befehle
 * @returns
 */
export default function withKeyboardCommand(WrappedComponent: ComponentType<any>, commands: Array<KeyboardCommand>) {
    return class extends Component<Props,State> {
        child: any

        constructor(props: Object) {
            super(props);

            this.state = {
                commands
            }
        }
        
        /**
         * Bindet den Key auf die Funktion
         *
         * @param {(string | Array<string>)} key Key oder Keys, die die Funktion ausloesen sollen
         * @param {(any) => mixed} func Funktion, die gerufen wird, wenn der key gedrueckt wird
         */
        bindKey(key: string | Array<string>, func: (any) => mixed) {
            Mousetrap.bind(key, () => func(this.child));            
        }

        /**
         * React Lifecycle Methode
         * Bindet die Kommandos
         */
        componentDidMount():void {
            const {commands} = this.state;
            commands.forEach((command: KeyboardCommand) => {                
                this.bindKey(command.key, command.func);
            });
        }

        /**
         * React Lifecycle Methode
         * Entfernt die Kommandos
         */
        componentWillUnmount():void {
            const {commands} = this.state;
            commands.forEach((command: KeyboardCommand) => {
                Mousetrap.unbind(command.key);
            });
        }

        /**
         * Rendert die interne Komponente
         *
         * @returns JSX
         */
        render() {
            return <WrappedComponent ref={ ref => this.child = ref } {...this.props} />;
        }
    }
};