import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleLeft } from '@fortawesome/free-solid-svg-icons';
import { getImageSmall } from '../../../misc/utils';

export default function BackToProjectButton({
    active,
    onClick
}) {
    if(!active) return null;

    return (
            <Button onClick={() => onClick()}>
                <FontAwesomeIcon icon={faAngleDoubleLeft}></FontAwesomeIcon>&nbsp;
                {getImageSmall('folder')}
            </Button>
    );
}