// @flow
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import NetPlane from './NetPlaneView';
import { showSideView, objectOpened, objectSelected } from '../actions/viewActions';

type Props = {
	showSideView: (boolean) => mixed,
	objectSelected: (Object) => mixed,
	objectOpened: (Object) => mixed,
	settings: Object,
	currentProject: Object,
	currentObject: Object,
	netplane: Object,
	currentOriginal: string,
};

type State = {
	currentObject: Object,
	currentOriginal: string,
};

/**
 * React Komponente, die den Netzplan im SideView rendert
 * und den Netzplan einbindet
 * @type {Component}
 * @constructor
 */
class NetPlaneSideView extends Component<Props, State> {
	/**
	 * Creates an instance of NetPlaneMainView.
	 * @param {Object} props Die Properties der Klasse
	 * @memberof NetPlaneSideView
	 */
	constructor(props: Object) {
		super(props);

		this.state = {
			currentObject: props.currentObject,
			currentOriginal: props.currentOriginal,
		};
	}

	/**
	 * Event wenn ein Kanalobjekt angeklickt wurde
	 * Kanalobjekt als ausgewaehlt setzen
	 * @param  {selectedCanalObject} Object	das Kanalobjekt
	 * @memberof NetPlaneSideView
	 */
	setCanalObject = (selectedCanalObject: Object) => {
		const { objectOpened, showSideView } = this.props;

		objectSelected(selectedCanalObject);
		objectOpened(selectedCanalObject);
		showSideView(false);
	};

	/**
	 * Rendert die Ansicht fuer das Button Card und den Netzplan
	 * @return {JSX} JSX Markup
	 */
	render() {
		const { settings, currentProject, currentObject, netplane } = this.props;
		const { currentOriginal } = this.props;

		return (
			<Fragment>
				<NetPlane
					setCanalObject={this.setCanalObject}
					settings={settings}
					currentProject={currentProject}
					currentObject={currentObject}
					netplane={netplane}
					currentOriginal={currentOriginal}
					isMainView={false}
				/>
			</Fragment>
		);
	}
}

/**
 *  Verbindet die Klasse mit dem Redux Store
 */
const ReduxNetPlaneSideView = connect(
	(state) => {
		return {
			settings: state.settings,
			netplane: state.currentNetPlane,
			currentObject: state.currentObject,
			currentProject: state.currentProject,
			currentOriginal: state.currentOriginal,
		};
	},
	{
		showSideView,
		objectOpened,
		objectSelected,
	}
)(NetPlaneSideView);
export default ReduxNetPlaneSideView;
