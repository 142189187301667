// @flow
import Layout from './Layout';
import fourToEight from '../images/layout/fourtoeight.png';

/**
 * Klasse fuer ein Layout das die Ansicht auf 4 zu 8 aufteilt
 *
 * @export
 * @class FourToEightLayout
 * @extends {Layout}
 */
export default class FourToEightLayout extends Layout {
    /**
     * Creates an instance of FourToEightLayout.
     * @memberof FourToEightLayout
     */
    constructor() {
        super('fourToEight', fourToEight);

        this.sideMenuHidden.md = true;
        this.sideMenuHidden.lg = true;
        this.left.xs.hidden = true;
        this.left.sm.hidden = true;
        this.left.md.size = 4;
        this.left.lg.size = 4;
        this.right.xs.size = 12;
        this.right.sm.size = 12;
    }
}