// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import CanalInterface from '../templates/helper/CanalInterface';
import { general } from '../templates/General';
import cloneDeep from 'lodash.clonedeep';
import { stationSelected, showSideView, seekToStation } from '../actions/viewActions';

type Props = {
	stationSelected: (Object) => mixed,
	currentStation: Object,
	currentObject: Object,
	currentInterface: CanalInterface,
	showSideView: (boolean) => mixed,
	seekToStation: () => mixed,
};

/**
 * React Komponent, die eine Tabelle fuer Stationen rendert
 *
 * @class StationSideView
 * @extends {Component<Props>}
 */
class StationSideView extends Component<Props> {
	/**
	 * Properties des tr Elements
	 * @param {Object} state Status der Tabelle
	 * @param {Object} rowInfo Informationen ueber die Zeile
	 * @param {Object} column
	 * @param {Object} instance Instanz der Tabelle
	 * @return {Object} Properties fuer das tr Element
	 *
	 * @memberof StationSideView
	 */
	trProps = (state: Object, rowInfo: Object, column: Object, instance: Object) => {
		const { currentStation } = this.props;
		const { stationSelected, showSideView, seekToStation } = this.props;

		const isCurrentStation = currentStation && rowInfo.original._id === currentStation._id;

		return {
			onClick: () => {
				stationSelected(rowInfo.original);
				showSideView(false);
				seekToStation();
			},
			style: {
				background: isCurrentStation ? 'rgba(0, 0, 249, 0.6)' : '',
				color: isCurrentStation ? 'white' : '',
			},
		};
	};

	/**
	 * Rendert eine Tabelle von Stationen
	 *
	 * @return {JSX} JSX Markup
	 * @memberof StationSideView
	 */
	render() {
		const { currentObject, currentInterface } = this.props;
		let data = [];
		if (currentObject.hasOwnProperty('stations')) {
			data = currentObject.stations;
		}

		// $FlowFixMe
		const columns = cloneDeep(general.station[currentObject.type].STATION_VIEW_PHOTO);
		columns.push(...currentInterface.station);

		return (
			<ReactTable
				data={data}
				columns={columns}
				className="-striped -highlight"
				showPagination={false}
				getTrProps={this.trProps}
				pageSize={data.length > 0 ? data.length : 1}
			/>
		);
	}
}

/**
 * Verbindet die Klasse mit dem Redux Store
 */
const ReduxStationSideView = connect(
	(state) => {
		return {
			currentStation: state.currentStation,
			currentObject: state.currentObject,
			currentInterface: state.currentInterface,
		};
	},
	{
		stationSelected,
		showSideView,
		seekToStation,
	}
)(StationSideView);
export default ReduxStationSideView;
