// @flow
import CardHelper from '../../form/helper/CardHelper';
import FieldHelper from '../../form/helper/FieldHelper';
import Classification from '../helper/Classification';
import CanalObjects from '../helper/CanalObjects';
import CanalObject from '../helper/CanalObject';

/**
 * Template Beschreibung fuer DIN1986-30
 * @type {Object}
 */
// eslint-disable-next-line import/no-anonymous-default-export
export default new Classification({
    name: 'DIN 1986-30',
    identifiers: [
        'DIN 1986-30',
        'DIN1986-30',
        'DIN1986-30 Edge',
        'DIN1986-30 Manhole',
    ],
    version: '2.0',
    inspection: new CanalObjects({
        section: new CanalObject([
            new CardHelper({
                id: 'din1986_30',
                title: {
                    key: 'din1986_30.header',
                    fallback: 'Bewertung DIN 1986-30'
                },
                items: [
                    new FieldHelper({
                        id: 'evaluationTime',
                        title: {
                            key: 'din1986_30.evaluationTime',
                            fallback: 'Bewertungszeit'
                        },
                        accessor: 'ISYBAU-XML_EvaluationTime'
                    }),
                    new FieldHelper({
                        id: 'rehabilitationPriority',
                        title : {
                            key: 'din1986_30.rehabilitationPriority',
                            fallback: 'Sanierungspriorität'
                        },
                        accessor: 'ISYBAU-XML_Class_DIN1986_30'
                    })
                ]
            })
        ]),
        lateral: new CanalObject([
            new CardHelper({
                id: 'din1986_30',
                title: {
                    key: 'din1986_30.header',
                    fallback: 'Bewertung DIN 1986-30'
                },
                items: [
                    new FieldHelper({
                        id: 'evaluationTime',
                        title: {
                            key: 'din1986_30.evaluationTime',
                            fallback: 'Bewertungszeit'
                        },
                        accessor: 'ISYBAU-XML_EvaluationTime'
                    }),
                    new FieldHelper({
                        id: 'rehabilitationPriority',
                        title : {
                            key: 'din1986_30.rehabilitationPriority',
                            fallback: 'Sanierungspriorität'
                        },
                        accessor: 'ISYBAU-XML_Class_DIN1986_30'
                    })
                ]
            })
        ]),
        manhole: new CanalObject([
            new CardHelper({
                id: 'din1986_30',
                title: {
                    key: 'din1986_30.header',
                    fallback: 'Bewertung DIN 1986-30'
                },
                items: [
                    new FieldHelper({
                        id: 'evaluationTime',
                        title: {
                            key: 'din1986_30.evaluationTime',
                            fallback: 'Bewertungszeit'
                        },
                        accessor: 'ISYBAU-XML_EvaluationTime'
                    }),
                    new FieldHelper({
                        id: 'rehabilitationPriority',
                        title : {
                            key: 'din1986_30.rehabilitationPriority',
                            fallback: 'Sanierungspriorität'
                        },
                        accessor: 'ISYBAU-XML_Class_DIN1986_30'
                    })
                ]
            })
        ])
    })
});