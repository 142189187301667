// @flow
import React from 'react';
import { connect } from 'react-redux';
// $FlowFixMe
import { Nav } from 'react-bootstrap';

import { getImageSmall } from '../../../misc/utils';
import { backToProject } from '../../../actions/dataActions';

type Props = {
	currentProject: Object,
	backToProject: () => void,
};

/**
 * Erzeugt einen Eintrag in der Navigation fuer das Projekt
 * @param {Object} currentProject Das aktuell gewaehlte Projekt
 */
const ProjectBreadcrumbItem = ({ currentProject, backToProject }: Props) =>
	currentProject ? (
		<Nav.Item key="project" onClick={backToProject}>
			{getImageSmall('folder')}
			&nbsp;{currentProject.name}
		</Nav.Item>
	) : null;

/**
 *  Verbindet die Klasse mit dem Redux Store
 */
const ReduxProjectBreadcrumbItem = connect(
	(state) => {
		return {
			currentProject: state.currentProject,
		};
	},
	{
		backToProject,
	}
)(ProjectBreadcrumbItem);

export default ReduxProjectBreadcrumbItem;
