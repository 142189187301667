
import CardHelper from '../../../form/helper/CardHelper';
import FieldHelper from '../../../form/helper/FieldHelper';
import CheckboxHelper from '../../../form/helper/CheckboxHelper';
import ComplexTextBoxHelper from '../../../form/helper/ComplexTextBoxHelper';

const inspectionCanalObjectManhole = [
    new CardHelper({
        id: 'general',
        title: {
            key: 'common.general',
            fallback: 'Allgemein'
        },
        items: [
            new FieldHelper({
                id: 'inspector',
                title: {
                    key: 'inspection.inspector',
                    fallback: 'Inspekteur'
                },
                accessor: 'NameUntersucher'
            }),
            new FieldHelper({
                id: 'weather',
                title: {
                    key: 'inspection.weather',
                    fallback: 'Wetter'
                },
                accessor: 'Wetter',
                field: new ComplexTextBoxHelper()
            }),
            new FieldHelper({
                id: 'cleaning',
                title: {
                    key: 'inspection.cleaning',
                    fallback: 'Reinigung'
                },
                accessor: 'Reinigung',
                field: new CheckboxHelper()
            }),
            new FieldHelper({
                id: 'temperature',
                title: {
                    key: 'inspection.temperature',
                    fallback: 'Temperatur'
                },
                accessor: 'Temperatur'
            }),
            new FieldHelper({
                id: 'comment',
                title: {
                    key: 'common.comment',
                    fallback: 'Kommentar'
                },
                accessor: 'Bemerkung',
                columnSize: {
                    lg: 12,
                    md: 12
                },
                size: {
                    lg: 2,
                    md: 2
                }
            })
        ]
    }),
    new CardHelper({
        id: 'inspection',
        title: {
            key: 'inspection.inspection',
            fallback: 'Inspektion'
        },
        items: [
            new FieldHelper({
                id: 'typeInspection',
                title: {
                    key: 'inspection.typeInspection',
                    fallback: 'Inspektionsart'
                },
                accessor: 'Inspektionsart',
                field: new ComplexTextBoxHelper()
            }),
            new FieldHelper({
                id: 'inspectionDate',
                title: {
                    key: 'inspection.inspectionDate',
                    fallback: 'Inspektionsdatum'
                },
                accessor: 'ISYBAU_XML_Inspektionszeit'
            }),
            new FieldHelper({
                id: 'inspectionMethod',
                title: {
                    key: 'isybauxml.inspection.common.inspectionMethod',
                    fallback: 'Inspektionsverfahren'
                },
                accessor: 'Inspektionsverfahren',
                field: new ComplexTextBoxHelper()
            }),
            new FieldHelper({
                id: 'inspectionLength',
                title: {
                    key: 'inspection.inspectionLength',
                    fallback: 'Inspektionslänge'
                },
                accessor: 'Inspektionslaenge'
            }),
            new FieldHelper({
                id: 'drainageMeasures',
                title: {
                    key: 'isybauxml.inspection.common.drainageMeasures',
                    fallback: 'Maßnahmen zur Wasserhaltung'
                },
                accessor: 'Wasserhaltung',
                field: new ComplexTextBoxHelper()
            }),
            new FieldHelper({
                id: 'dataMedium',
                title: {
                    key: 'isybauxml.inspection.common.dataMedium',
                    fallback: 'Datenträger'
                },
                accessor: 'Videoablagereferenz'
            }),
            new FieldHelper({
                id: 'horizontalPoint',
                title: {
                    key: 'isybauxml.inspection.manhole.inspection.horizontalPoint',
                    fallback: 'Horizontaler Bezugspunkt'
                },
                accessor: 'BezugspunktHorizontal',
                field: new ComplexTextBoxHelper()
            }),
            new FieldHelper({
                id: 'videoStorageMedium',
                title: {
                    key: 'inspection.videoStorageMedium',
                    fallback: 'Videospeichermedium'
                },
                accessor: 'ISYBAU-XML_S_VideoSpeichermedium',
                field: new ComplexTextBoxHelper()
            }),
            new FieldHelper({
                id: 'verticalPoint',
                title: {
                    key: 'inspection.verticalPoint',
                    fallback: 'Vertikaler Bezugspunkt'
                },
                accessor: 'BezugspunktVertikal',
                field: new ComplexTextBoxHelper()
            }),
            new FieldHelper({
                id: 'photoStorageMedium',
                title: {
                    key: 'inspection.photoStorageMedium',
                    fallback: 'Fotospeichermedium'
                },
                accessor: 'ISYBAU-XML_S_FotoSpeichermedium',
                field: new ComplexTextBoxHelper()
            })
        ]
    })
];

export default inspectionCanalObjectManhole;