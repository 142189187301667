// @flow
import React from 'react';

import FormHelper from "./FormHelper";
import ValidationArray from "./ValidationArray";
import type {ValueElement} from './FormHelper';
import Table from '../components/Table';
import { isEmpty } from '../../misc/validationHelper';
import FormColumnHelper from './FormColumnHelper';


type TableDefault = {
    id: string,
    items: Array<FormColumnHelper>,
    accessor: string
}

const def: TableDefault = {
    id: '',
    items: [],
    accessor: ''
}

/**
 * Hilfklasse fuer Formtemplates, um eine Tabelle zu erzeugen
 *
 * @export
 * @class TableHelper
 * @extends {FormHelper}
 */
export default class TableHelper extends FormHelper {
    _options: TableDefault;

    /**
     * Creates an instance of TableHelper.
     * @param {TableDefault} options Die Einstellugen der Klasse
     * @memberof TableHelper
     */
    constructor(options: TableDefault) {
        super();

        this._options = {...def, ...options};
    }

    get id():string {
        return this._options.id;
    }

    get items():Array<FormColumnHelper> {
        return this._options.items;
    }

    get accessor():string {
        return this._options.accessor;
    }

    /**
     * Prueft ob die Einstellungen der Klasse korrekt sind
     *
     * @returns {ValidationArray}
     * @memberof TableHelper
     */
    validate():ValidationArray {
        const messages = new ValidationArray(this.id);

        if (isEmpty(this.id)) {
            messages.error.push('Id ist nicht gesetzt!');
        }

        if (this.items.length === 0) {
            messages.warn.push('Keine Spalten vorhanden!');
        }

        if (isEmpty(this.accessor)) {
            messages.error.push('Accessor darf nicht leer sein');
        }

        return messages;
    }

    /**
     * Baut die Tabelle
     *
     * @param {Object} object Das Kanalobjekt
     * @returns {ValueElement}
     * @memberof TableHelper
     */
    build(object: Object):ValueElement {
        const rows = object[this.accessor];

        return {
            valueSet: true,
            element: 
                <Table key={this.id} columns={this.items} rows={rows} />
        };
    }
}