// @flow
import isybauxml from './interface/isybauxml';
import dwam150 from './interface/dwam150';
import arbeitshilfenabwasser from './classification/Arbeitshilfenabwasser';
import din1986_30 from './classification/Din1986-30';
import dwa_m_149_3 from './classification/DWA-M-149-3';
import CanalInterface from './helper/CanalInterface';
import Classification from './helper/Classification';

/**
 * Klasse, die die Templates verwaltet
 * @type {Object}
 * @constructor
 */
class TemplateEngine {
    interfaces: Array<CanalInterface> = [];
    classifications: Array<Classification> = [];

    /**
     * Registriert eine Schnittstelle
     * @param  {CanalInterface} canalInterface Das Template der Schnittstelle
     * @return {void}
     */
    registerInterface(canalInterface: CanalInterface):void {
        if (canalInterface != null) {
            this.interfaces.push(canalInterface);
        }
    }

    /**
     * Registriert eine Klassifizierung     
     * @param  {Classification} value Das Template der Klassifizierung
     * @return {void}
     */
    registerClassification(classification: Classification):void {
        if (classification != null) {
            this.classifications.push(classification);
        }
    }

    /**
     * Liefert das hinterlegte Template fuer die gegebene Schnittstelle zurueck. Wenn der key unbekannt ist wird null zurueck gegeben.
     * @param  {string} key Name der Schnittstelle
     * @return {CanalInterface}     Template der Schnittstelle
     */
    getInterface(key: string):?CanalInterface {
        let result = null;
        this.interfaces.forEach(canalInterface => {
            if (canalInterface.name === key) {
                result = canalInterface;
            }
        });
        return result;
    }

    /**
     * Liefert das hinterlegte Template fuer die gegebene Klassifizierung zurueck. Wenn der key unbekannt ist wird null zurueck gegeben.
     * @param  {string} key Name der Klassifizierung
     * @return {object}     Template der Klassifizierung
     */
    getClassification(key: string):?Classification {
        let result = null;
        this.classifications.forEach(classification => {
            if (classification.identifiers.includes(key)) {
                result = classification;
            }
        })
        if(!result){
            console.error(`Keine Klassifikation für key ${key}`);
        }
        
        return result;
    }
}

let templateEngine: TemplateEngine = new TemplateEngine();

//Interfaces
templateEngine.registerInterface(isybauxml);
templateEngine.registerInterface(dwam150);

//codingsystems
templateEngine.registerClassification(arbeitshilfenabwasser);
templateEngine.registerClassification(din1986_30);
templateEngine.registerClassification(dwa_m_149_3);

export default templateEngine;
