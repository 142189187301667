// @flow
import React from 'react';
import { connect } from 'react-redux';
import { NavItem } from 'react-bootstrap';

import CanalInterface from '../../../templates/helper/CanalInterface';
import { OBJECT_LEVEL_VIEWS } from '../../../misc/const';
import { getImageSmall, getName } from '../../../misc/utils';
import { objectOpened } from '../../../actions/viewActions';

type Props = {
	objectOpened: (Object, string) => mixed,
	currentObject: Object,
	currentInterface: CanalInterface,
};

/**
 * Erzeugt einen Eintrag in der Navigation um auf die Objektseite zu gelangen
 * @param {Object} currentObject Das aktuell gewaehelte Object
 * @param {Object} currentInterface Das aktuelle Interface
 * @param {Function} objectOpened Redux Action um das Objekt auszuwaehlen
 */
const ObjectBreadcrumbItem = ({ currentObject, currentInterface, objectOpened }: Props) =>
	currentObject ? (
		<NavItem key="object" onClick={() => objectOpened(currentObject, OBJECT_LEVEL_VIEWS.MAIN.OBJECT_MAIN_VIEW)}>
			{getImageSmall(currentObject.type)}
			&nbsp;{getName(currentObject, currentInterface.objectname)}
		</NavItem>
	) : null;

/**
 *  Verbindet die Klasse mit dem Redux Store
 */
const ReduxObjectBreadcrumbItem = connect(
	(state) => {
		return {
			currentObject: state.currentObject,
			currentInterface: state.currentInterface,
		};
	},
	{
		objectOpened,
	}
)(ObjectBreadcrumbItem);

export default ReduxObjectBreadcrumbItem;
