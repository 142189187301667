// @flow
import axios from 'axios';
import * as Environment from '../misc/helper/environmentHelper';

const env = process.env.NODE_ENV;

const getUrl = () => {
	if (env === 'production') {
		return window.location.origin;
	} else {
        return `http://${window.location.hostname}:${Environment.apiPort}`;
	}
};

export const getUrlWithPath = (path) => {
	if (env === 'production') {
		return path;
	} else {
		return `http://${window.location.hostname}:${Environment.apiPort}${path}`;
	}
};

const axiosInstance = axios.create({
	baseURL: getUrl(),
	responseType: 'json',
	headers: {'Access-Control-Allow-Origin': '*'},
});

// declare a response interceptor for logout on invalid token
axiosInstance.interceptors.response.use(
	(response) => response,
	(error) => {
		return Promise.reject(error);
	}
);

/**
 * Wrapper fuer ein GET in axios
 * @param  {String} path   Der Pfad zur Resource
 * @param  {Object} header Objekt mit den Custom Headern
 * @return {Promise}       Den axios Promise
 */
export function get(path: string, header: ?Object): Promise<Object> {
	return axiosInstance.get(path, header);
}

/**
 * Wrapper fuer ein GET mit einem Token
 * @param {String} path Der Pfad zur Resource
 * @param {String} token Der Token
 * @return {Promise} Den axios Promise
 */
export function tokenizedGet(path: string, token: string): Promise<Object> {
	return axiosInstance.get(path, { headers: { 'x-access-token': token } });
}

/**
 * Wrapper fuer ein POST in axios
 * @param  {String} path    Der Pfad zur Resource
 * @param  {Object} payload Das Objekt das bei der Anfrage an die Resource mitgesendet werden soll
 * @param  {Object} header  Objekt mit den Custom Headern
 * @return {Promise}        Den axiso Promise
 */
export function post(path: string, payload: Object, header: ?Object): Promise<Object> {
	return axiosInstance.post(path, payload, header);
}

/**
 * Wrapper fuer ein POST mit einem Token in axios
 * @param  {String} path    Der Pfad zur Resource
 * @param  {Object} payload Das Objekt das bei der Anfrage an die Resource mitgesendet werden soll
 * @param  {String} token   Der Token
 * @return {Promise}        Den axiso Promise
 */
export function tokenizedPost(path: string, payload: Object, token: string, addHeader: ?Object): Promise<Object> {
	let headers: Object = {
		'x-access-token': token,
	};

	if (addHeader) {
		headers = Object.assign({}, headers, addHeader);
	}

	return axiosInstance.post(path, payload, { headers });
}

/**
 * Wrapper fuer ein DELETE in axios
 * @param {String} path Der Pfad zur Resource
 * @param {Object} header Objekt mit den Custom Headern
 * @return {Promise} Den axios Promise
 */
export function del(path: string, header: ?Object): Promise<Object> {
	return axiosInstance.delete(path, header);
}

/**
 * Wrapper fuer ein DELETE mit einem Token in axios
 * @param {String} path Der Pfad zur Resource
 * @param {String} token Der Token
 * @return {Promise} Den axios Promise
 */
export function tokenizedDel(path: string, token: string): Promise<Object> {
	return del(path, { headers: { 'x-access-token': token } });
}
