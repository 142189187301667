// flow
// $FlowFixMe
import React, { createRef, Component, Fragment } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';

import { ResizeSensor } from 'css-element-queries';

import { Map, TileLayer, ZoomControl, ScaleControl, Tooltip, LayersControl, type Viewport } from 'react-leaflet';
import { Pane, LayerGroup, Polyline, Circle, ImageOverlay } from 'react-leaflet';

import { Container, Row, Col, Badge } from 'react-bootstrap';
import Slider from 'rc-slider';
import { Trans } from 'react-i18next';
import i18next from 'i18next';

import withKeyboardCommand from './hoc/withKeyboardCommands';
import { NETPLANE_EPSG, NETPLANE_PROPERTIES, NETPLANE_CANALOBJTYPE, NETPLANE_SEWERSYSTEM, NETPLANE_UNITSYSTEM, NETPLANE_SYSTEMCOLOR, NETPLANE_GEOMETRYTYPE } from '../misc/const';
import { PATH_PROJECT } from '../backend/paths';

import epsg from 'epsg-index/all.json';
import proj4 from 'proj4';
import * as Leaflet from 'leaflet';
import { v4 as uuid4 } from 'uuid';

import 'rc-slider/assets/index.css';
import 'leaflet/dist/leaflet.css';
import '../css/viewmap.css';

type Props = {
	setCanalObject: (Object) => mixed,
	showCanalObject: (Object) => ?mixed,
	settings: Object,
	currentProject: Object,
	currentObject: Object,
	netplane: Object,
	currentOriginal: ?string,
	isMainView: ?Boolean
};

type State = {
	mapSet: boolean,
	animate: boolean,
	scaleControl: {
		metric: boolean,
		imperial: boolean,
	},
	canalObjTooltipOver: boolean,
	canalObjTooltipShow: boolean,
	sliderValNetplan: number,
	sliderValMap: number,
	opacityValMap: number,
	opacityValNetplan: number,
	showDescriptions: boolean,
	showSections: boolean,
	showManholes: boolean,
	showLaterals: boolean,
	viewport: Object,
	maxBounds: Object,
	currentObject: Object,
};

const MAP_DEFAULTS = {
	MINZOOM: 13,
	MAXZOOM: 19,
	HOVER: 8,
	CANALOBJTOOLTIPOVER: true,
	CANALOBJTOOLTIPSHOW: false,
	SLIDERVALNETPLAN: 1,
	SLIDERVALMAP: 1,
	OPACITYVALMAP: 1.0,
	OPACITYVALNETPLAN: 1.0,
	SHOWDESCRIPTIONS: false,
	SHOWSECTIONS: true,
	SHOWMANHOLES: true,
	SHOWLATERALS: true,
	VIEWPORT: {
		center: [54.3151887, 10.1931154],
		zoom: 15,
	},
	BOUNDSEXTEND: 0.1,
	INDEXSECTIONS: 610,
	INDEXLATERALS: 615,
	INDEXMANHOLES: 620,
	INDEXTOOLTIPS: 650,
	INDEXOVERLAYIMAGES: 605,
	INDEXOVERLAYLANDREGISTER: 606,
	INDEXSECTIONSSELECTED: 609,
	INDEXLATERALSSELECTED: 609,
	INDEXMANHOLESSELECTED: 629,
};

const { Overlay } = LayersControl;

/**
 * React Komponente, die die Ansicht fuer den Netzplan rendert
 * @type {Component}
 * @constructor
 */
class NetPlaneView extends Component<Props, State> {
	valueNetplaneOpacity: number = 1.0;
	valueMapOpacity: number = 1.0;

	yCoordinateRef: ?Label;
	xCoordinateRef: ?Label;

	/**
	 * Creates an instance of NetPlaneView.
	 * @param {Object} props Die Properties der Klasse
	 * @memberof NetPlaneView
	 */
	constructor(props: Object) {
		super(props);

		this.state = {
			mapSet: false,
			animate: true,
			scaleControl: {
				metric: true,
				imperial: false,
			},
			canalObjTooltipOver: MAP_DEFAULTS.CANALOBJTOOLTIPOVER,
			canalObjTooltipShow: MAP_DEFAULTS.CANALOBJTOOLTIPSHOW,
			sliderValNetplan: MAP_DEFAULTS.SLIDERVALNETPLAN,
			sliderValMap: MAP_DEFAULTS.SLIDERVALMAP,
			opacityValNetplan: MAP_DEFAULTS.OPACITYVALNETPLAN,
			opacityValMap: MAP_DEFAULTS.OPACITYVALMAP,
			showDescriptions: MAP_DEFAULTS.SHOWDESCRIPTIONS,
			showSections: MAP_DEFAULTS.SHOWSECTIONS,
			showManholes: MAP_DEFAULTS.SHOWMANHOLES,
			showLaterals: MAP_DEFAULTS.SHOWLATERALS,
			viewport: MAP_DEFAULTS.VIEWPORT,
			currentObject: props.currentObject,
		};
	}

	mapRef = createRef();
	
	/**
	 * React Lifecycle Methode
	 * initialisiert den Netzplan neu wenn die Groesse sich aendert
	 *
	 * @memberof NetPlaneView
	 */
	componentDidMount(): void {
		const component = ReactDOM.findDOMNode(this);

		new ResizeSensor(component, () => {
			if (!this.state.mapSet) {
				this.configureMap();
			}
		});

		if (component && component.clientWidth !== 0 && !this.state.mapSet) {
			this.configureMap();
		}
	}

	/**
	 * React Lifecycle Methode
	 * initialisiert den Netzplan neu wenn
	 * das Projekt wechselt
	 * sich die Einstellungen geaendert haben
	 * ein Kanalobjekt ausgewaehlt wurde - den Mittelpunkt des Netzplans auf Kanalobjekt setzen
	 *
	 * @param {Object} prevProps Objekt, mit den Properties vor dem Update
	 * @memberof NetPlaneView
	 */
	componentDidUpdate(prevProps: Object) {
		const { netplane, settings, currentOriginal, isMainView } = this.props;

		if (prevProps.netplane._id !== netplane._id || prevProps.settings.lang !== settings.lang || (currentOriginal && prevProps.currentOriginal !== currentOriginal)) {			
			if(!isMainView){
				this.mapRef = createRef();
			}			
			this.configureMap();
		}
	}

	/**
	 * setzt die Initialisierungswerte fuer den Netzplan
	 * @memberof NetPlaneView
	 */
	configureMap = () => {
		let { scaleControl } = this.state;
		const { netplane, currentObject, currentProject } = this.props;

		const map = this.mapRef.current;

		if (map != null) {
			map.leafletElement.invalidateSize(false);
		}

		if (netplane) {
			if (currentProject.hasOwnProperty('unitsystem')) {
				const unitSystem: string = currentProject.unitsystem;

				if (unitSystem === NETPLANE_UNITSYSTEM.METRIC) {
					scaleControl = { metric: true, imperial: false };
				} else if (unitSystem === NETPLANE_UNITSYSTEM.IMPERIAL) {
					scaleControl = { metric: false, imperial: true };
				}
			}

			let centerCoordinates: Object = { lat: netplane.centerCoordinates.yCoordinate, lng: netplane.centerCoordinates.xCoordinate };
			let zoom: number = MAP_DEFAULTS.VIEWPORT.zoom;

			if (currentObject) {
				const currentObjectCenterCoordinates: ?Object = this.getCurrentCanalObjectCoordinates(currentObject);

				if (currentObjectCenterCoordinates) {
					centerCoordinates = currentObjectCenterCoordinates;
				}

				zoom = MAP_DEFAULTS.MAXZOOM - 1;
			}

			const viewport: Object = {
				center: [centerCoordinates.lat, centerCoordinates.lng],
				zoom,
			};

			const maxBounds: LatLngBounds = map != null ? this.getMaxMapBounds(map) : null;

			// if (maxBounds) {
			// 	this.getOfflineMap(maxBounds);
			// }

			this.setState({
				mapSet: true,
				scaleControl,
				canalObjTooltipOver: MAP_DEFAULTS.CANALOBJTOOLTIPOVER,
				canalObjTooltipShow: MAP_DEFAULTS.CANALOBJTOOLTIPSHOW,
				sliderValNetplan: MAP_DEFAULTS.SLIDERVALNETPLAN,
				sliderValMap: MAP_DEFAULTS.SLIDERVALMAP,
				opacityValMap: MAP_DEFAULTS.OPACITYVALMAP,
				opacityValNetplan: MAP_DEFAULTS.OPACITYVALNETPLAN,
				showDescriptions: MAP_DEFAULTS.SHOWDESCRIPTIONS,
				showSections: MAP_DEFAULTS.SHOWSECTIONS,
				showManholes: MAP_DEFAULTS.SHOWMANHOLES,
				showLaterals: MAP_DEFAULTS.SHOWLATERALS,
				currentObject: currentObject,
				viewport,
				maxBounds,
			});

			this.valueNetplaneOpacity = 1.0;
			this.valueMapOpacity = 1.0;
		}
	};

	/**
	 * !!!! wird nicht verwendet - ist schon fuer Offline !!!!
	 * zeigt eine Liste mit URLs an die fuer den Offline-Modus benoetigt werden
	 * @param {LatLngBounds} latlngBounds		der Koordinaten des Kartenbereiches in dem sich alle Kanalobjekte befinden
	 * @memberof NetPlaneView
	 */
	getOfflineMap = (latlngBounds: LatLngBounds) => {
		// https://github.com/veltman/xyz-affair
		let tiles: Array<any> = [];

		for (let z: number = MAP_DEFAULTS.MINZOOM; z <= MAP_DEFAULTS.MAXZOOM; z++) {
			tiles = tiles.concat(this.getMapTiles(latlngBounds, z));
		}

		for (let tile of tiles) {
			console.log('https://myurl.de/' + tile.z + '/' + tile.x + '/' + tile.y + '.png');
		}
	};

	/**
	 * !!!! wird nicht verwendet - ist schon fuer Offline !!!!
	 * gibt eine Liste mit Punktobjekten fuer die Tiles zurueck
	 * @param {LatLngBounds} latlngBounds		der Koordinatenbereich der Karte
	 * @returns {Array<Object>}					Liste mit Punktobjekten fuer die Tiles
	 * @memberof NetPlaneView
	 */
	getMapTiles = (latlngBounds: LatLngBounds, zoom: number): Array<Object> => {
		const min: Object = this.getMapTilesPoints(latlngBounds.getNorth(), latlngBounds.getWest(), zoom);
		const max: Object = this.getMapTilesPoints(latlngBounds.getSouth(), latlngBounds.getEast(), zoom);
		let tiles: Array<Object> = [];

		for (let x: number = min.x; x <= max.x; x++) {
			for (let y: number = min.y; y <= max.y; y++) {
				tiles.push({
					x: x,
					y: y,
					z: zoom,
				});
			}
		}

		return tiles;
	};

	/**
	 * !!!! wird nicht verwendet - ist schon fuer Offline !!!!
	 * gibt ein berechnetes Punkteobjekt zurueck
	 * @param {number} lat		der latitude Wert
	 * @param {number} lng		der longitude Wert
	 * @param {number} zoom		der Zoomfaktor
	 * @returns {Object}		berechnetes Punkteobjekt
	 * @memberof NetPlaneView
	 */
	getMapTilesPoints = (lat: number, lng: number, zoom: number): Object => {
		const R: number = 6378137;
		const sphericalScale: number = 0.5 / (Math.PI * R);

		const d: number = Math.PI / 180;
		const max: number = 1 - 1e-15;
		const sin: number = Math.max(Math.min(Math.sin(lat * d), max), -max);
		const scale: number = 256 * Math.pow(2, zoom);

		var point: Object = {
			x: R * lng * d,
			y: (R * Math.log((1 + sin) / (1 - sin))) / 2,
		};

		point.x = Math.floor((scale * (sphericalScale * point.x + 0.5)) / 256);
		point.y = Math.floor((scale * (-sphericalScale * point.y + 0.5)) / 256);

		return point;
	};

	/**
	 * Event der Karte wenn sich die Ansicht geaendert hat
	 * @param  {Viewport} viewport	die neue Ansicht
	 * @memberof NetPlaneView
	 */
	handleViewportChanged = (viewport: Viewport) => {
		this.setState({
			viewport,
		});
	};

	/**
	 * Event fuer Overlay - overlayadd - overlayremove
	 * Sichtbarkeit der Beschreibung fuer Kanalobjekte umsetzen wenn die Beschreibung aktiviert ist
	 * @param  {string} layerName	der Name des Overlay-Elements
	 * @param  {boolean} show		der Wert ob die Kanalobjekte angezeigt werden sollen
	 * @memberof NetPlaneView
	 */
	handleShowDescriptions = (layerName: string, show: boolean) => {
		const textDescription: string = this.getTransElementText('netplane.descriptions');
		const textSections: string = this.getTransElementText('netplane.sections');
		const textManholes: string = this.getTransElementText('netplane.manholes');
		const textLaterals: string = this.getTransElementText('netplane.laterals');

		switch (layerName) {
			case textDescription:
				this.setState({
					showDescriptions: show,
				});
				break;
			case textSections:
				this.setState({
					showSections: show,
				});
				break;
			case textManholes:
				this.setState({
					showManholes: show,
				});
				break;
			case textLaterals:
				this.setState({
					showLaterals: show,
				});
				break;
			default:
				break;
		}
	};

	/**
	 * Event fuer Slider - OnChange - onAfterChange
	 * manuelle Aenderung der Sichtbarkeit fuer den Netzplan durch Slider
	 * @param  {number} value der Wert der Sichtbarkeit von 0 bis 1
	 * @memberof NetPlaneView
	 */
	handleOnChangeSliderValNetplan = (value: number) => {
		this.valueNetplaneOpacity = value;
	};

	handleOnAfterChangeSliderValNetplan = (value: number) => {
		this.setState({
			opacityValNetplan: this.valueNetplaneOpacity,
		});
	};

	/**
	 * Event fuer Slider - OnChange - onAfterChange
	 * manuelle Aenderung der Sichtbarkeit fuer die Karte durch Slider
	 * @param  {number} value der Wert der Sichtbarkeit von 0 bis 1
	 * @memberof NetPlaneView
	 */
	handleOnChangeSliderValMap = (value: number) => {
		this.valueMapOpacity = value;
	};

	handleOnAfterChangeSliderValMap = (value: number) => {
		this.setState({
			opacityValMap: this.valueMapOpacity,
		});
	};

	/**
	 * Event fuer den Mouse-Zeiger wenn sich dieser ueber die Karte mit Netzplan bewegt
	 * Hochwert und Rechtswert aktualisieren
	 * @param  {Object} evt	das Event
	 * @memberof NetPlaneView
	 */
	handleMouseOver = (evt: Object) => {
		const { netplane } = this.props;

		let xCoordinate = ReactDOM.findDOMNode(this.xCoordinateRef);
		let yCoordinate = ReactDOM.findDOMNode(this.yCoordinateRef);

		const transformMouseCoordinate: Object = this.transformEPSG(NETPLANE_EPSG, netplane.projectedCoordinateSystem);
		const transformCoordinate: Object = transformMouseCoordinate.forward({ x: evt.latlng.lng, y: evt.latlng.lat });

		if (xCoordinate instanceof HTMLElement) {
			xCoordinate.innerHTML = `${this.getTransElementText('netplane.xCoordinate')} ${transformCoordinate.x.toFixed(2)}`;
		}

		if (yCoordinate instanceof HTMLElement) {
			yCoordinate.innerHTML = `${this.getTransElementText('netplane.yCoordinate')} ${transformCoordinate.y.toFixed(2)}`;
		}
	};

	/**
	 * Event wenn die Karte geladen wurde
	 * dann die Events definieren auf die reagiert werden soll
	 * @param  {Object} evt	das Event
	 * @memberof NetPlaneView
	 */
	handleWhenReady = (evt: Object) => {
		const { currentObject } = this.state;

		const map = this.mapRef.current;

		if (map != null) {
			// $FlowFixMe
			map.leafletElement.on('overlayadd', (elem: Layer) => {
				this.handleShowDescriptions(elem.name, true);
			});

			// $FlowFixMe
			map.leafletElement.on('overlayremove', (elem: Layer) => {
				this.handleShowDescriptions(elem.name, false);
			});

			if (currentObject) {
				const centerCoordinates: ?Object = this.getCurrentCanalObjectCoordinates(currentObject);

				if (centerCoordinates) {
					setTimeout(function () {
						map.leafletElement.flyTo(Leaflet.latLng(centerCoordinates.lat, centerCoordinates.lng), MAP_DEFAULTS.MAXZOOM);
					}, 1000);
				}
			}
		}
	};

	/**
	 * Event wenn ein Kanalobjekt angeklickt wurde
	 * dann wird auf der NetPlanMainView der Button frei geschaltet
	 * um sich den Netztpaln auf der linken Seite anzuzeigen
	 * und es werden die Informationen zum Objekt angezeigt
	 * @param  {string} id	die Id des Kanalobjektes
	 * @memberof NetPlaneView
	 */
	handleCanalObjectClick = (id: ?string) => {
		let { currentObject } = this.state;
		const { setCanalObject, currentProject } = this.props;

		let findCanalObject: boolean = false;
		let objectsCounter: number = 0;
		const objectsAmount: number = currentProject.objects.length;

		if (id && objectsAmount > 0) {
			do {
				const object: Object = currentProject.objects[objectsCounter];

				if (object.canalObjectId === id) {
					findCanalObject = true;

					currentObject = object;
				}

				objectsCounter++;
			} while (objectsCounter < objectsAmount && !findCanalObject);

			if (findCanalObject) {
				setCanalObject(currentObject);
			}
		}
	};

	/**
	 * Event wenn ein Kanalobjekt doppelt angeklickt wurde
	 * dann wird in die Objektansicht gewechselt
	 * @param  {string} id	die Id des Kanalobjektes
	 * @memberof NetPlaneView
	 */
	handleCanalObjectDblClick = (id: ?string) => {
		let { currentObject } = this.state;
		const { setCanalObject, showCanalObject, currentProject } = this.props;

		let findCanalObject: boolean = false;
		let objectsCounter: number = 0;
		const objectsAmount: number = currentProject.objects.length;

		if (id && objectsAmount > 0) {
			do {
				const object: Object = currentProject.objects[objectsCounter];

				if (object.canalObjectId === id) {
					findCanalObject = true;

					currentObject = object;
				}

				objectsCounter++;
			} while (objectsCounter < objectsAmount && !findCanalObject);

			if (findCanalObject) {
				setCanalObject(currentObject);

				if (showCanalObject) {
					showCanalObject();
				}
			}
		}
	};

	/**
	 * gibt die Koordinaten des Kartenbereiches zurueck in dem sich alle Kanalobjekte befinden
	 * @param {Object} map		die Karte als Objekte
	 * @returns {LatLngBounds}	die Daten der Kanalobjekte
	 * @memberof NetPlaneView
	 */
	getMaxMapBounds(map: Object): LatLngBounds {
		const { netplane } = this.props;

		let maxLatLngBounds: LatLngBounds = map.leafletElement.getBounds();

		let featureCounter: number = 0;
		const featureAmount: number = netplane.features.length;
		let geometryCoordinates: Array<any> = [];

        if(featureAmount === 0) {

            return maxLatLngBounds.pad(0.5);
        }

		do {
			const feature: Object = netplane.features[featureCounter];

			if (feature.hasOwnProperty('properties') && feature.hasOwnProperty('geometry')) {
				const featureProperties: Object = feature.properties;
				const featureGeometry: Object = feature.geometry;

				if (featureProperties.hasOwnProperty(NETPLANE_PROPERTIES.FIELD_CANALOBJTYPE)) {
					if (featureGeometry.hasOwnProperty('coordinates') && featureGeometry.hasOwnProperty('type')) {
						if (featureGeometry.type === NETPLANE_GEOMETRYTYPE.POINT) {
							geometryCoordinates.push(featureGeometry.coordinates);
						} else if (featureGeometry.type === NETPLANE_GEOMETRYTYPE.LINESTRING) {
							const geometryCoordinatesHelper: Array<any> = featureGeometry.coordinates;
							for (let geometryCoordinateHelper of geometryCoordinatesHelper) {
								geometryCoordinates.push(geometryCoordinateHelper);
							}
						} else if (featureGeometry.type === NETPLANE_GEOMETRYTYPE.POLYGON) {
							const geometryCoordinatesHelper: Array<any> = featureGeometry.coordinates[0];
							for (let geometryCoordinateHelper of geometryCoordinatesHelper) {
								geometryCoordinates.push(geometryCoordinateHelper);
							}
						}
					}
				}
			}

			featureCounter++;
		} while (featureCounter < featureAmount);

		if (geometryCoordinates.length) {
			let maxBounds: Array<LatLng> = [];

			for (let geometryCoordinate of geometryCoordinates) {
				if (geometryCoordinate[0] !== 0 && geometryCoordinate[1] !== 0) {
					const latlng: LatLng = Leaflet.latLng(geometryCoordinate[1], geometryCoordinate[0]);
					maxBounds.push(latlng);
				}
			}

			maxLatLngBounds = Leaflet.latLngBounds(maxBounds);
		}

		// um die Haelfte der Karte ausdehnen
		return maxLatLngBounds.pad(0.5);
	}

	/**
	 * transformiert die Koordinaten zwischen zwei Koordinatensystem um
	 * @param  {string} from	das gegebene Koordinatensystem
	 * @param  {string} to		in das neue Koordinatensystem
	 * @return {LatLng}			die neuen Koordinaten
	 * @memberof NetPlaneView
	 */
	transformEPSG = (from: string, to: string) => {
		const leadingEPSG: RegExp = /^epsg:/i;

		from = from.replace(leadingEPSG, '');
		to = to.replace(leadingEPSG, '');

		const fromEPSG: ?Object = epsg[from];
		const toEPSG: ?Object = epsg[to];

		if (!fromEPSG) {
			throw new Error(from + ' ' + this.getTransElementText('messages.epsgNotFound'));
		}

		if (!toEPSG) {
			throw new Error(to + ' ' + this.getTransElementText('messages.epsgNotFound'));
		}

		return proj4(fromEPSG.proj4, toEPSG.proj4);
	};

	/**
	 * gibt einen uebersetzten Text zurueck
	 * @param  {string} textKey definierter Key
	 * @return {string}         uebersetzter Text
	 * @memberof NetPlaneView
	 */
	getTransElementText(textKey: string): string {
		return i18next.t(textKey);
	}

	/**
	 * gibt einen uebersetzten Text mit Platzhalter zurueck
	 * @param  {string} textKey			der definierte Key
	 * @param  {Object} placeholder 	der Platzhalter
	 * @return {string}         		der uebersetzte Text
	 * @memberof NetPlaneView
	 */
	getTransElementTextPlaceholder(textKey: string, placeholder: Object): string {
		return i18next.t(textKey, placeholder);
	}

	/**
	 * gibt die Anzahl alle Kanalobjekte eines bestimmten Types zurueck
	 * @param {string} canalObjectType	der Type der Kanalobjekte
	 * @return {number} counter
	 * @memberof NetPlaneView
	 */
	getAmountCanalObjects(canalObjectType: string): number {
		const { netplane } = this.props;

		let counter: number = 0;

		for (let feature of netplane.features) {
			if (feature.hasOwnProperty('properties') && feature.hasOwnProperty('geometry')) {
				const featureProperties: Object = feature.properties;
				const featureGeometry: Object = feature.geometry;

				if (featureProperties.hasOwnProperty(NETPLANE_PROPERTIES.FIELD_CANALOBJTYPE) && featureProperties[NETPLANE_PROPERTIES.FIELD_CANALOBJTYPE] === canalObjectType) {
					if (featureGeometry.hasOwnProperty('coordinates') && featureGeometry.hasOwnProperty('type')) {
						counter++;
					}
				}
			}
		}

		return counter;
	}

	/**
	 * gibt fuer ein ausgewaehltes Kanalobjekt die Koordinaten zurueck
	 * @param {Object} currentCanalObject	das ausgewaehlte Kanalobjekt
	 * @return {Object} latlngCoordinates
	 * @memberof NetPlaneView
	 */
	getCurrentCanalObjectCoordinates(currentCanalObject: Object): ?Object {
		const { netplane } = this.props;

		let latlngCoordinates: ?Object = null;
		let featureCounter: number = 0;
		const featureAmount: number = netplane.features.length;

		do {
			const feature: Object = netplane.features[featureCounter];

			if (feature.hasOwnProperty('properties') && feature.hasOwnProperty('geometry')) {
				const featureProperties: Object = feature.properties;
				const featureGeometry: Object = feature.geometry;

				if (featureProperties.hasOwnProperty(NETPLANE_PROPERTIES.FIELD_CANALOBJTYPE)) {
					if (featureGeometry.hasOwnProperty('coordinates') && featureGeometry.hasOwnProperty('type')) {
						let geometryCoordinates: Array<any> = [];

						if (
							featureGeometry.type === NETPLANE_GEOMETRYTYPE.LINESTRING ||
							featureGeometry.type === NETPLANE_GEOMETRYTYPE.POINT ||
							featureGeometry.type === NETPLANE_GEOMETRYTYPE.POLYGON
						) {
							geometryCoordinates =
								featureGeometry.type === NETPLANE_GEOMETRYTYPE.LINESTRING || featureGeometry.type === NETPLANE_GEOMETRYTYPE.POINT
									? featureGeometry.coordinates
									: featureGeometry.coordinates[0];
						}

						latlngCoordinates = this.getCurrentCanalObjectLatLng(featureGeometry.type, featureProperties, geometryCoordinates, currentCanalObject.canalObjectId);
					}
				}
			}

			featureCounter++;
		} while (featureCounter < featureAmount && !latlngCoordinates);

		return latlngCoordinates;
	}

	/**
	 * gibt fuer ein ausgewaehltes Kanalobjekt die Geo Koordinaten zurueck
	 * @param {string} featureGeometryType		der geometrische Typ
	 * @param {Object} featureProperties		die Daten des Kanalobjektes
	 * @param {Array<any>} geometryCoordinates	alle definierten Koordinaten fuer ein Kanalobjekt
	 * @param {string} id						die Id des ausgewaehlten Kanalobjektes
	 * @return {Object} latlngCoordinates
	 * @memberof NetPlaneView
	 */
	getCurrentCanalObjectLatLng(featureGeometryType: string, featureProperties: Object, geometryCoordinates: Array<any>, id: string): ?Object {
		let latlngCoordinates: ?Object = null;
		let canalObjID: string = '';

		if (featureProperties.hasOwnProperty(NETPLANE_PROPERTIES.FIELD_DB_ID)) {
			canalObjID = featureProperties[NETPLANE_PROPERTIES.FIELD_DB_ID];

			if (canalObjID === id) {
				if (featureGeometryType === NETPLANE_GEOMETRYTYPE.LINESTRING || featureGeometryType === NETPLANE_GEOMETRYTYPE.POLYGON) {
					for (let geometryCoordinate of geometryCoordinates) {
						latlngCoordinates = { lat: geometryCoordinate[1], lng: geometryCoordinate[0] };
					}
				} else if (featureGeometryType === NETPLANE_GEOMETRYTYPE.POINT) {
					latlngCoordinates = { lat: geometryCoordinates[1], lng: geometryCoordinates[0] };
				}
			}
		}

		return latlngCoordinates;
	}

	/**
	 * gibt die Daten alle Kanalobjekte eines bestimmten Types zurueck
	 * @param {LatLngBounds} mapBounds	der Bereich der sichtbaren Karte
	 * @param {string} canalObjectType	der Type der Kanalobjekte
	 * @param {string} keyName			der Name als Zusatz fuer den Key
	 * @return {Array} canalObjectsData
	 * @memberof NetPlaneView
	 */
	// $FlowFixMe
	getBoundsCanalObjects(mapBounds: LatLngBounds, canalObjectType: string, keyName: string): Array<?Object> {
		const { netplane } = this.props;

		let canalObjectsCounter: number = 0;
		let canalObjectsData: Array<?Object> = [];

		for (let feature of netplane.features) {
			if (feature.hasOwnProperty('properties') && feature.hasOwnProperty('geometry')) {
				const featureProperties: Object = feature.properties;
				const featureGeometry: Object = feature.geometry;

				if (featureProperties.hasOwnProperty(NETPLANE_PROPERTIES.FIELD_CANALOBJTYPE) && featureProperties[NETPLANE_PROPERTIES.FIELD_CANALOBJTYPE] === canalObjectType) {
					if (featureGeometry.hasOwnProperty('coordinates') && featureGeometry.hasOwnProperty('type')) {
						const geometryCoordinates: Array<any> = this.getGeometryCoordinates(featureGeometry);

						const canalObjectData: ?Object = this.createCanalObjects(mapBounds, keyName, canalObjectsCounter, geometryCoordinates, featureProperties);

						if (canalObjectData) {
							canalObjectsData.push(canalObjectData);
						}
					}
				}

				canalObjectsCounter++;
			}
		}

		return canalObjectsData;
	}

	getGeometryCoordinates(featureGeometry: Object): Array<any> {
		let geometryCoordinates: Array<any> = [];

		if (featureGeometry.type === NETPLANE_GEOMETRYTYPE.LINESTRING || featureGeometry.type === NETPLANE_GEOMETRYTYPE.POLYGON) {
			geometryCoordinates = featureGeometry.type === NETPLANE_GEOMETRYTYPE.LINESTRING ? featureGeometry.coordinates : featureGeometry.coordinates[0];
		} else if (featureGeometry.type === NETPLANE_GEOMETRYTYPE.POINT) {
			geometryCoordinates = [featureGeometry.coordinates];
		}

		return geometryCoordinates;
	}

	/**
	 * gibt die Daten fuer ein Kanalobjekt zurueck
	 * @param {LatLngBounds} mapBounds	der Bereich der sichtbaren Karte
	 * @param {string} keyName			der Name als Zusatz fuer den Key
	 * @param {string} canalObjectType	der Type der Kanalobjekte
	 * @param {number} canalObjectsCounter	der Zaehler fuer alle Kanalobjekte
	 * @param {Array} geometryCoordinates	alle definierten Koordinaten fuer ein Kanalobjekt
	 * @param {Object} featureProperties	die Daten des Kanalobjektes
	 * @return {Array} canalObjectsData
	 * @memberof NetPlaneView
	 */
	createCanalObjects(mapBounds: LatLngBounds, keyName: string, canalObjectsCounter: number, geometryCoordinates: Array<any>, featureProperties: Object): ?Object {
		let canalObjectData: ?Object = null;
		const canalObjName: string = featureProperties.hasOwnProperty(NETPLANE_PROPERTIES.FIELD_NAME) ? featureProperties[NETPLANE_PROPERTIES.FIELD_NAME] : '';
		const canalObjType: string = featureProperties.hasOwnProperty(NETPLANE_PROPERTIES.FIELD_CANALOBJTYPE) ? featureProperties[NETPLANE_PROPERTIES.FIELD_CANALOBJTYPE] : '';
		const canalObjSystem: string = featureProperties.hasOwnProperty(NETPLANE_PROPERTIES.FIELD_SEWERSYSTEM) ? featureProperties[NETPLANE_PROPERTIES.FIELD_SEWERSYSTEM] : '';
		const canalObjID: string = featureProperties.hasOwnProperty(NETPLANE_PROPERTIES.FIELD_DB_ID) ? featureProperties[NETPLANE_PROPERTIES.FIELD_DB_ID] : '';
		const canalObjDiameter: number = featureProperties.hasOwnProperty(NETPLANE_PROPERTIES.FIELD_DIAMETER) ? featureProperties[NETPLANE_PROPERTIES.FIELD_DIAMETER] : 0;

		const mapExtend: number = this.getMapBoundsExtend();
		const latlngCoordinates: Array<any> = this.getCanalObjectCoordinatesMapBounds(mapBounds, mapExtend, geometryCoordinates);

		if (latlngCoordinates.length > 0) {
			const objectName: string = keyName + canalObjectsCounter;
			canalObjectData = {
				key: objectName,
				position: latlngCoordinates,
				id: canalObjID,
				name: canalObjName,
				type: canalObjType,
				system: canalObjSystem,
				diam: canalObjDiameter,
			};
		}

		return canalObjectData;
	}

	/**
	 * gibt den uebersetzten Text fuer ein Kanaltyp zurueck
	 * @param {String} typeText	der Kanaltyp Name der uebersetzt werden soll
	 * @return {String} canalObjectTypeText
	 * @memberof NetPlaneView
	 */
	getCanalObjectTypeText(typeText: string): string {
		let canalObjectTypeText: string = '';

		switch (typeText) {
			case NETPLANE_CANALOBJTYPE.SECTION:
				canalObjectTypeText = this.getTransElementText('netplane.section');
				break;
			case NETPLANE_CANALOBJTYPE.LATERAL:
				canalObjectTypeText = this.getTransElementText('netplane.lateral');
				break;
			case NETPLANE_CANALOBJTYPE.MANHOLE:
				canalObjectTypeText = this.getTransElementText('netplane.manhole');
				break;
			default:
				break;
		}

		return canalObjectTypeText;
	}

	/**
	 * gibt den uebersetzten Text fuer ein Kanalsystem zurueck
	 * @param {String} systemText	der Kanalsystem Name der uebersetzt werden soll
	 * @return {String} canalObjectSystemText
	 * @memberof NetPlaneView
	 */
	getCanalObjectSystemText(systemText: string): string {
		let canalObjectSystemText: string = '';

		switch (systemText) {
			case NETPLANE_SEWERSYSTEM.COMBINED:
				canalObjectSystemText = this.getTransElementText('netplane.combined');
				break;
			case NETPLANE_SEWERSYSTEM.STORMWATER:
				canalObjectSystemText = this.getTransElementText('netplane.stormwater');
				break;
			case NETPLANE_SEWERSYSTEM.WASTEWATER:
				canalObjectSystemText = this.getTransElementText('netplane.wastewater');
				break;
			default:
				break;
		}

		return canalObjectSystemText;
	}

	/**
	 * gibt fuer ein Kanalsystem die definierte Farbe zurueck
	 * @param {string} canalSystem			die Kanalsystem
	 * @return {string} canalSystemColor	die Farbe als HEX-Wert
	 * @memberof NetPlaneView
	 */
	getCanalSystemColor = (canalSystem: ?string): string => {
		let canalSystemColor: string = NETPLANE_SYSTEMCOLOR.COMBINED;

		switch (canalSystem) {
			case NETPLANE_SEWERSYSTEM.STORMWATER:
				canalSystemColor = NETPLANE_SYSTEMCOLOR.STORMWATER;
				break;
			case NETPLANE_SEWERSYSTEM.WASTEWATER:
				canalSystemColor = NETPLANE_SYSTEMCOLOR.WASTEWATER;
				break;
			case NETPLANE_SEWERSYSTEM.SELECTED:
				canalSystemColor = NETPLANE_SYSTEMCOLOR.SELECTED;
				break;
			default:
				break;
		}

		return canalSystemColor;
	};

	/**
	 * wandelt den definierten Durchmesser in einen des Zoom-Wertes der Karte angepassten Wert um
	 * @param {number} diam			der defenierte Durchmesser
	 * @param {number} standard		der Defaultwert
	 * @return {number} diameter	der umgerechnete Durchmesser
	 * @memberof NetPlaneView
	 */
	getManholeZoomRadius = (diam: number, standard: number): number => {
		let radius: number = standard;

		const map = this.mapRef.current;

		if (map != null) {
			const zoom: number = map.leafletElement.getZoom();

			switch (zoom) {
				case 15:
					radius = diam * 5;
					break;
				case 16:
					radius = diam * 4;
					break;
				case 17:
					radius = diam * 3;
					break;
				case 18:
					radius = diam * 2;
					break;
				default:
					break;
			}
		}

		return radius;
	};

	/**
	 * wandelt den definierten Durchmesser in einen des Zoom-Wertes der Karte angepassten Pixelwert um
	 * @param {number} diam		der defenierte Durchmesser
	 * @param {number} lat		der lat Wert aus der Position
	 * @param {number} lng		der lng Wert aus der Position
	 * @param {number} zoom		der Zoomwert
	 * @param {number} add		der zusaetzliche Wertd
	 * @return {number} pixel	der berechnete Pixelwert fuer die Karte
	 * @memberof NetPlaneView
	 */
	getLinePixel = (diam: number, lat: number, lng: number, zoom: number, add: number = 0): number => {
		let pixel: number = 2;

		const earthCircumference: number = 40075017;
		const latitudeRadians: number = lat * (Math.PI / 180);

		pixel = diam / ((earthCircumference * Math.cos(latitudeRadians)) / Math.pow(2, zoom + 8));
		pixel = Math.round(pixel) + add;

		return pixel < 2 ? 2 : pixel;
	};

	/**
	 * Konvertiert einen Durchmesser in mm
	 * @param {number} diam			der defenierte Durchmesser
	 * @return {number} diameter	der umgerechnete Durchmesser
	 * @memberof NetPlaneView
	 */
	convertDiammeter = (diam: number): number => {
		let diameter: number = diam;

		if (diameter < 100) {
			diameter = diam * 1000;
		}

		return diameter;
	};

	/**
	 * gibt die zusaetzliche Ausdehnung der Karte angepasst an den Zoom-Wert zurueck
	 * @return {number} extend	der angepasste Wert der Ausdehnung
	 * @memberof NetPlaneView
	 */
	getMapBoundsExtend = (): number => {
		let extend: number = MAP_DEFAULTS.BOUNDSEXTEND;

		const map = this.mapRef.current;

		if (map != null) {
			const zoom: number = map.leafletElement.getZoom();

			switch (zoom) {
				case 17:
					extend = MAP_DEFAULTS.BOUNDSEXTEND + 0.1;
					break;
				case 18:
					extend = MAP_DEFAULTS.BOUNDSEXTEND + 0.2;
					break;
				case 19:
					extend = MAP_DEFAULTS.BOUNDSEXTEND + 0.3;
					break;
				default:
					break;
			}
		}

		return extend;
	};

	/**
	 * gibt die Koordinaten fuer ein Kanalobjekt zurueck wenn dieses sich im sichtbaren Bereich der Karte befindet
	 * @param {LatLngBounds} mapBounds		die Koordinaten des sichtbaren Bereichs der Karte
	 * @param {number} mapExtend			die zusaetzliche Ausdehnung der Karte
	 * @param {Array} geometryCoordinates	die Koordinaten des Kanalobjektes
	 * @memberof NetPlaneView
	 */
	getCanalObjectCoordinatesMapBounds(mapBounds: LatLngBounds, mapExtend: number, geometryCoordinates: Array<any>): Array<any> {
		let latlngCoordinates: Array<any> = [];

		const latMin: number = mapBounds.pad(mapExtend).getSouth();
		const latMax: number = mapBounds.pad(mapExtend).getNorth();
		const lngMin: number = mapBounds.pad(mapExtend).getWest();
		const lngMax: number = mapBounds.pad(mapExtend).getEast();

		for (let geometryCoordinate of geometryCoordinates) {
			const latlngCoordinate: Object = { x: geometryCoordinate[0], y: geometryCoordinate[1] };

			if (latlngCoordinate.y >= latMin && latlngCoordinate.y <= latMax && latlngCoordinate.x >= lngMin && latlngCoordinate.x <= lngMax) {
				latlngCoordinates.push([latlngCoordinate.y, latlngCoordinate.x]);
			}
		}

		return latlngCoordinates;
	}

	/**
	 * gibt fuer eine Menge von Kanalobjekten
	 * die dafuer erzeugten Polylines zurueck
	 * @param {Array<any>} canalObjectsData	die Daten der Kanalobjekte
	 * @param {number} zoom					der Zoomwert der Karte
	 * @param {boolean} clickable			Polyline-Objekt ist anklickbar mit Tooltip
	 * @returns {Array<any>}
	 * @memberof NetPlaneView
	 */
	getPolylineCanalObjects(canalObjectsData: Array<any>, zoom: number, clickable: boolean): Array<any> {
		const { opacityValNetplan } = this.state;

		let canalObjects: Array<any> = [];

		for (let canalObjectData of canalObjectsData) {
			const objectColor: string = this.getCanalSystemColor(canalObjectData.system);
			const canalObject: Polyline = this.getPolyline(canalObjectData, zoom, objectColor, opacityValNetplan, clickable);

			canalObjects.push(canalObject);
		}

		return canalObjects;
	}

	/**
	 * gibt fuer das ausgewaehlte Kanalobjekt eine Polyline zurueck
	 * @param {Array<any>} canalObjectsData	die Daten der Kanalobjekte
	 * @param {number} zoom					der Zoomwert der Karte
	 * @returns {Polyline}
	 * @memberof NetPlaneView
	 */
	getSelectedPolylineCanalObjects(canalObjectsData: Array<any>, zoom: number): Polyline {
		const { currentObject } = this.props;
		const { opacityValNetplan } = this.state;

		let canalObject: Polyline = null;

		let canalObjectsCounter: number = 0;
		const canalObjectsAmount: number = canalObjectsData.length;

		if (canalObjectsAmount > 0) {
			do {
				const canalObjectData: Object = canalObjectsData[canalObjectsCounter];

				if (currentObject.canalObjectId === canalObjectData.id) {
					// const selectedOpacity: number = opacityValNetplan < 0.4 ? opacityValNetplan : 0.4;
					const selectedOpacity: number = opacityValNetplan;
					const objectColor: string = NETPLANE_SYSTEMCOLOR.SELECTED;

					canalObject = this.getPolyline(canalObjectData, zoom, objectColor, selectedOpacity, false, MAP_DEFAULTS.HOVER);

					canalObjectsCounter = canalObjectsAmount;
				}

				canalObjectsCounter++;
			} while (canalObjectsCounter < canalObjectsAmount);
		}

		return canalObject;
	}

	/**
	 * gibt fuer den Netzplan ein Polyline-Objekt zurueck
	 * @param {Object} canalObjectData		die Daten des Kanalobjektes
	 * @param {number} zoom					der Zoomwert der Karte
	 * @param {string} color				die Farbe des Kanalobjektes
	 * @param {number} opacity				der Sichtbarkeitswert des Polyline-Objektes
	 * @param {boolean} clickable			Polyline-Objekt ist anklickbar mit Tooltip
	 * @param {number} add					zusaetzlicher Wert der auf die Linienstaerke addiert wird
	 * @returns {Polyline}
	 * @memberof NetPlaneView
	 */
	getPolyline(canalObjectData: Object, zoom: number, color: string, opacity: number, clickable: boolean, add: number = 0): Polyline {
		// $FlowFixMe
		const mapBounds: Bounds = Leaflet.bounds(canalObjectData.position);
		// $FlowFixMe
		const mapPosition: Point = mapBounds.getCenter(false);
		const mapPositionLat: number = mapPosition.x;
		const mapPositionLng: number = mapPosition.y;

		let canalObject: Polyline = (
			<Polyline
				key={uuid4()}
				color={color}
				weight={this.getLinePixel(canalObjectData.diam, mapPositionLat, mapPositionLng, zoom, add)}
				positions={canalObjectData.position}
				opacity={opacity}
			></Polyline>
		);

		if (clickable) {
			canalObject = (
				<Polyline
					key={uuid4()}
					color={this.getCanalSystemColor(canalObjectData.system)}
					weight={this.getLinePixel(canalObjectData.diam, mapPositionLat, mapPositionLng, zoom, MAP_DEFAULTS.HOVER)}
					positions={canalObjectData.position}
					opacity={0}
					onClick={() => this.handleCanalObjectClick(canalObjectData.id)}
					onDblClick={() => this.handleCanalObjectDblClick(canalObjectData.id)}
				>
					{this.renderCanalObjectTooltip(canalObjectData.name, canalObjectData.type, canalObjectData.system, canalObjectData.diam)}
				</Polyline>
			);
		}

		return canalObject;
	}

	/**
	 * gibt fuer eine Menge von Kanalobjekten
	 * die dafuer erzeugten Circles zurueck
	 * @param {Array<any>} canalObjectsData	die Daten der Kanalobjekte
	 * @param {number} opacity				der Sichtbarkeitswert des Circle-Objektes
	 * @param {boolean} clickable			Circle-Objekt ist anklickbar mit Tooltip
	 * @returns {Array<any>}
	 * @memberof NetPlaneView
	 */
	getCircleCanalObjects(canalObjectsData: Array<any>, opacity: number, clickable: boolean): Array<any> {
		let canalObjects: Array<any> = [];

		for (let canalObjectData of canalObjectsData) {
			const objectColor: string = this.getCanalSystemColor(canalObjectData.system);
			const canalObject: Circle = this.getCircle(canalObjectData, objectColor, opacity, clickable);

			canalObjects.push(canalObject);
		}

		return canalObjects;
	}

	/**
	 * gibt fuer das ausgewaehlte Kanalobjekt ein Circle zurueck
	 * @param {Array<any>} canalObjectsData	die Daten der Kanalobjekte
	 * @returns {Circle}
	 * @memberof NetPlaneView
	 */
	getSelectedCircleCanalObjects(canalObjectsData: Array<any>): Circle {
		const { currentObject } = this.props;
		const { opacityValNetplan } = this.state;

		let canalObject: Circle = null;

		let canalObjectsCounter: number = 0;
		const canalObjectsAmount: number = canalObjectsData.length;

		if (canalObjectsAmount > 0) {
			do {
				const canalObjectData: Object = canalObjectsData[canalObjectsCounter];

				if (currentObject.canalObjectId === canalObjectData.id) {
					const objectColor: string = NETPLANE_SYSTEMCOLOR.SELECTED;

					canalObject = this.getCircle(canalObjectData, objectColor, opacityValNetplan, false);

					canalObjectsCounter = canalObjectsAmount;
				}

				canalObjectsCounter++;
			} while (canalObjectsCounter < canalObjectsAmount);
		}

		return canalObject;
	}

	/**
	 * gibt fuer den Netzplan ein Circle-Objekt zurueck
	 * @param {Object} canalObjectData		die Daten des Kanalobjektes
	 * @param {string} color				die Farbe des Kanalobjektes
	 * @param {number} opacity				der Sichtbarkeitswert des Circle-Objektes
	 * @param {boolean} clickable			Circle-Objekt ist anklickbar mit Tooltip
	 * @returns {Circle}
	 * @memberof NetPlaneView
	 */
	getCircle(canalObjectData: Object, color: string, opacity: number, clickable: boolean): Circle {
		const canalObjectBounds: Bounds = Leaflet.bounds(canalObjectData.position);
		const canalObjectCenter: Point = canalObjectBounds.getCenter(false);
		const centerPosition: Array<number> = [canalObjectCenter.x, canalObjectCenter.y];

		let canalObject: Circle = (
			<Circle
				key={uuid4()}
				center={centerPosition}
				radius={this.getManholeZoomRadius(canalObjectData.diam, 1)}
				weight={10}
				color={color}
				fill={true}
				fillColor="#FDFEFE"
				fillOpacity={1.0}
				opacity={opacity}
			></Circle>
		);

		if (clickable) {
			canalObject = (
				<Circle
					key={uuid4()}
					center={centerPosition}
					radius={this.getManholeZoomRadius(canalObjectData.diam, 1)}
					weight={1}
					color={this.getCanalSystemColor(canalObjectData.system)}
					fill={true}
					fillColor="#FDFEFE"
					fillOpacity={1.0}
					opacity={opacity}
					onClick={() => this.handleCanalObjectClick(canalObjectData.id)}
					onDblClick={() => this.handleCanalObjectDblClick(canalObjectData.id)}
				>
					{this.renderCanalObjectTooltip(canalObjectData.name, canalObjectData.type, canalObjectData.system, canalObjectData.diam)}
				</Circle>
			);
		}

		return canalObject;
	}

	/**
	 * gibt die Daten der Kanalobjekte zurueck
	 * die angezeigt werden sollen
	 * @param {Object} map		die Karte als Objekte
	 * @returns {Array}			die Daten der Kanalobjekte
	 * @memberof NetPlaneView
	 */
	getDescriptionData(map: Object): Array<?Object> {
		const { showSections, showManholes, showLaterals } = this.state;
		const mapBounds: LatLngBounds = map.leafletElement.getBounds();

		let descriptionData: Array<?Object> = [];

		if (showSections) {
			const sectionsData: Array<?Object> = this.getBoundsCanalObjects(mapBounds, NETPLANE_CANALOBJTYPE.SECTION, 'section_desc_');
			descriptionData.push.apply(descriptionData, sectionsData);
		}

		if (showManholes) {
			const manholesData: Array<?Object> = this.getBoundsCanalObjects(mapBounds, NETPLANE_CANALOBJTYPE.MANHOLE, 'manhole_desc_');
			descriptionData.push.apply(descriptionData, manholesData);
		}

		if (showLaterals) {
			const lateralsData: Array<?Object> = this.getBoundsCanalObjects(mapBounds, NETPLANE_CANALOBJTYPE.LATERAL, 'lateral_desc_');
			descriptionData.push.apply(descriptionData, lateralsData);
		}

		return descriptionData;
	}

	/**
	 * Rendert das ZoomControl oben links
	 * @return {JSX} JSX Markup
	 * @memberof NetPlaneView
	 */
	renderMapZoomControl(): ?React$Element<any> {
		const zoomIn: string = this.getTransElementText('netplane.zoomIn');
		const zoomOut: string = this.getTransElementText('netplane.zoomOut');

		const map = this.mapRef.current;

		if (map != null) {
			return <ZoomControl zoomInTitle={zoomIn} zoomOutTitle={zoomOut} />;
		} else {
			return null;
		}
	}

	/**
	 * Rendert das Copyright unten rechts
	 * @return {JSX} JSX Markup
	 * @memberof NetPlaneView
	 */
	renderMapTileLayer(): ?React$Element<any> {
		const { opacityValMap } = this.state;

		return (
			<TileLayer
				opacity={opacityValMap}
				minZoom={MAP_DEFAULTS.MINZOOM}
				maxZoom={MAP_DEFAULTS.MAXZOOM}
				attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
				url={this.props.settings.tileserver}
			/>
		);
	}

	/**
	 * Rendert die Scala fuer die Entfernung unter Verwendung von metric oder imperial unten links
	 * @return {JSX} JSX Markup
	 * @memberof NetPlaneView
	 */
	renderMapScaleControl(): ?React$Element<any> {
		const { scaleControl } = this.state;

		const map = this.mapRef.current;

		if (map != null) {
			return <ScaleControl maxWidth={200} metric={scaleControl.metric} imperial={scaleControl.imperial} />;
		} else {
			return null;
		}
	}

	/**
	 * Rendert das Overlay mit dem Namen als Tooltip fuer jedes einzelne Kanalobjekt
	 * wenn diese angezeigt werden sollen
	 * @return {JSX} JSX Markup
	 * @memberof NetPlaneView
	 */
	renderOverlayDescriptions(): ?React$Element<any> {
		const { showDescriptions } = this.state;

		const textDescription: string = this.getTransElementText('netplane.descriptions');
		const map = this.mapRef.current;

		let descriptionData: Array<?Object> = [];

		if (map != null) {
			descriptionData = this.getDescriptionData(map);
		}

		if (descriptionData.length > 0) {
			let descriptionTooltips: Array<any> = [];

			if (showDescriptions) {
				for (let description of descriptionData) {
					const descriptionBounds: Bounds = Leaflet.bounds(description.position);
					const descriptionCenter: Point = descriptionBounds.getCenter(false);
					let centerPosition: Array<number> = [descriptionCenter.x, descriptionCenter.y];

					// Hilfslinie erzeugen um den Mittelpunkt zu erhalten
					if (description.type === NETPLANE_CANALOBJTYPE.SECTION || description.type === NETPLANE_CANALOBJTYPE.LATERAL) {
						const helperPolyline: Polyline = Leaflet.polyline(description.position, { opacity: 0 }).addTo(map.leafletElement);
						const helperLatLng: LatLng = helperPolyline.getCenter();

						centerPosition = [helperLatLng.lat, helperLatLng.lng];

						helperPolyline.remove();
					}

					const tooltipDirection: string = description.type === NETPLANE_CANALOBJTYPE.MANHOLE ? 'top' : 'auto';

					const descriptionTooltip: Circle = (
						<Circle key={uuid4()} center={centerPosition} radius={1} weight={1} fill={false} opacity={0}>
							<Tooltip direction={tooltipDirection} offset={[2, 2]} opacity={0.7} permanent={true}>
								{description.name}
							</Tooltip>
						</Circle>
					);

					descriptionTooltips.push(descriptionTooltip);
				}
			}

			return (
				<Overlay checked={showDescriptions} className="overlayDescriptions" name={textDescription}>
					<LayerGroup>
						<Fragment>{descriptionTooltips}</Fragment>
					</LayerGroup>
				</Overlay>
			);
		} else {
			return null;
		}
	}

	/**
	 * Rendert das Overlay fuer Luftbilder
	 * @return {JSX} JSX Markup
	 * @memberof NetPlaneView
	 */
	renderOverlayImages(): ?React$Element<any> {
		const { netplane } = this.props;

		const map = this.mapRef.current;

		if (map != null && netplane) {
			let overlayImages: Array<React$Element<any>> = [];

			if (netplane.hasOwnProperty('AerialView')) {
				const textAerialView: string = this.getTransElementText('netplane.arialView');

				let aerialViews: Array<Object> = netplane.AerialView;

				aerialViews.sort((a, b) => (a.order > b.order ? 1 : -1));

				for (let aerialView of aerialViews) {
					overlayImages.push(this.getOverlayImage(textAerialView, aerialView, MAP_DEFAULTS.INDEXOVERLAYIMAGES));
				}
			}

			return overlayImages;
		} else {
			return null;
		}
	}

	/**
	 * Rendert das Overlay fuer ein Luftbild oder fuer ein Kataster
	 * @param {string} art				der Text fuer die Anzeige in den Optionen des Netzplanes
	 * @param {Object} overlayObject	das Objekt mit den Informationen zum Luftbild oder zum Kataster
	 * @param {number} zIndex			der Index fuer die Reihenfolge der Darstellung auf dem netzplan
	 * @return {JSX} JSX Markup
	 * @memberof NetPlaneView
	 */
	getOverlayImage(art: string, overlayObject: Object, zIndex: number): ?React$Element<any> {
		const { opacityValMap } = this.state;
		const { netplane } = this.props;

		const overlayName: string = `${art} | ${overlayObject.overlayName}`;
		const imageOverlayUrl: string = `${PATH_PROJECT}/${netplane.projectId}/${overlayObject.fileId}`;

		const imageOverlayUrlBounds: Array<any> = [
			[overlayObject.yNW, overlayObject.xNW],
			[overlayObject.ySE, overlayObject.xSE],
		];

		const imageOverlay: ImageOverlay = <ImageOverlay url={imageOverlayUrl} bounds={imageOverlayUrlBounds} opacity={opacityValMap}></ImageOverlay>;

		return (
			<Overlay checked={false} name={overlayName}>
				<LayerGroup>
					<Fragment>
						<Pane style={{ zIndex: zIndex }}>{imageOverlay}</Pane>
					</Fragment>
				</LayerGroup>
			</Overlay>
		);
	}

	/**
	 * Rendert das Overlay fuer Kataster
	 * @return {JSX} JSX Markup
	 * @memberof NetPlaneView
	 */
	renderOverlaysLandRegister(): ?React$Element<any> {
		const { netplane } = this.props;

		const map = this.mapRef.current;

		if (map != null && netplane.hasOwnProperty('LandRegister')) {
			const textLandRegister: string = this.getTransElementText('netplane.landRegister');
			let overlaysLandRegister: Array<React$Element<any>> = [];

			for (let landRegister of netplane.LandRegister) {
				if (landRegister.hasOwnProperty('fileData')) {
					overlaysLandRegister.push(this.renderOverlayLandRegisterData(textLandRegister, landRegister.overlayName, landRegister.fileData));
				} else if (landRegister.hasOwnProperty('fileId')) {
					overlaysLandRegister.push(this.getOverlayImage(textLandRegister, landRegister, MAP_DEFAULTS.INDEXOVERLAYLANDREGISTER));
				}
			}

			return overlaysLandRegister;
		} else {
			return null;
		}
	}

	/**
	 * Rendert das Overlay fuer ein Kataster
	 * nur mit Daten
	 * @param {string} art				der Text fuer die Anzeige in den Optionen des Netzplanes
	 * @param {string} name				der Name des Katasters fuer die Anzeige in den Optionen des Netzplanes
	 * @param {Object} landRegister		das Objekt mit den Informationen zum Kataster
	 * @return {JSX} JSX Markup
	 * @memberof NetPlaneView
	 */
	renderOverlayLandRegisterData(art: string, name: string, landRegister: Object): ?React$Element<any> {
		if (landRegister.hasOwnProperty('entities')) {
			let registerElements: Array<React$Element<any>> = [];

			const overlayName: string = `${art} | ${name}`;
			const entities: Array<?Object> = landRegister.entities;

			for (let entitie of entities) {
				if (entitie.type === 'CIRCLE') {
					registerElements.push(this.renderOverlayLandRegisterDataCircle(entitie));
				} else if (entitie.type === 'LINE' || entitie.type === 'POLYLINE' || entitie.type === 'LWPOLYLINE') {
					registerElements.push(this.renderOverlayLandRegisterDataLine(entitie));
				} else if (entitie.type === 'TEXT') {
					registerElements.push(this.renderOverlayLandRegisterDataText(entitie));
				}
			}

			return (
				<Overlay checked={false} name={overlayName}>
					<LayerGroup>
						<Fragment>
							<Pane style={{ zIndex: MAP_DEFAULTS.INDEXOVERLAYLANDREGISTER }}>{registerElements}</Pane>
						</Fragment>
					</LayerGroup>
				</Overlay>
			);
		} else {
			return null;
		}
	}

	/**
	 * Rendert einen Kreis fuer das im Kataster definierte Objekt
	 * @param {Object} entity					das Objekt mit den Informationen
	 * @return {JSX} JSX Markup
	 * @memberof NetPlaneView
	 */
	renderOverlayLandRegisterDataCircle(entity: Object): ?React$Element<any> {
		const { opacityValNetplan } = this.state;

		const centerPosition: Array<any> = [entity.center.y, entity.center.x];

		const dxfCircle: Circle = (
			<Circle key={uuid4()} center={centerPosition} radius={entity.radius} weight={1} color="#000000" fill={false} opacity={opacityValNetplan}></Circle>
		);

		return dxfCircle;
	}

	/**
	 * Rendert eine Linie fuer das im Kataster definierte Objekt
	 * @param {Object} entity					das Objekt mit den Informationen
	 * @return {JSX} JSX Markup
	 * @memberof NetPlaneView
	 */
	renderOverlayLandRegisterDataLine(entity: Object): ?React$Element<any> {
		const { opacityValNetplan } = this.state;

		let latlngCoordinates: Array<any> = [];

		for (let geometryCoordinate of entity.vertices) {
			latlngCoordinates.push([geometryCoordinate.y, geometryCoordinate.x]);
		}

		const dxfPolyline: Polyline = <Polyline key={uuid4()} color="#000000" weight={1} positions={latlngCoordinates} opacity={opacityValNetplan}></Polyline>;

		return dxfPolyline;
	}

	/**
	 * Rendert einen Text fuer das im Kataster definierte Objekt
	 * @param {Object} entity					das Objekt mit den Informationen
	 * @return {JSX} JSX Markup
	 * @memberof NetPlaneView
	 */
	renderOverlayLandRegisterDataText(entity: Object): ?React$Element<any> {
		const { viewport, opacityValNetplan } = this.state;

		if (entity.hasOwnProperty('startPoint')) {
			let centerPosition: Array<any> = [entity.startPoint.y, entity.startPoint.x];

			if (entity.hasOwnProperty('endPoint')) {
				const pointStart: Point = Leaflet.point(entity.startPoint.x, entity.startPoint.y);
				const pointEnd: Point = Leaflet.point(entity.endPoint.x, entity.endPoint.y);
				const pointBounds: Bounds = Leaflet.bounds(pointStart, pointEnd);
				const pointPosition: Point = pointBounds.getCenter(false);

				centerPosition = [pointPosition.y, pointPosition.x];
			}

			let tooltip: ?Tooltip = null;

			if (viewport.zoom >= 18) {
				tooltip = (
					<Tooltip className="tooltip-land-register" direction="center" opacity={opacityValNetplan} permanent={true}>
						{entity.text}
					</Tooltip>
				);
			}

			const dxfText: Circle = (
				<Circle key={uuid4()} center={centerPosition} radius={1} weight={1} fill={false} opacity={0}>
					{tooltip}
				</Circle>
			);

			return dxfText;
		} else {
			return null;
		}
	}

	/**
	 * Rendert das Overlay fuer Haltungen
	 * @return {JSX} JSX Markup
	 * @memberof NetPlaneView
	 */
	renderOverlaySection(): ?React$Element<any> {
		const { showSections } = this.state;

		const textSections: string = this.getTransElementText('netplane.sections');

		return this.renderOverlayPolylines(textSections, showSections, NETPLANE_CANALOBJTYPE.SECTION, 'section_', MAP_DEFAULTS.INDEXSECTIONS);
	}

	/**
	 * Rendert das Overlay fuer Leitungen
	 * @return {JSX} JSX Markup
	 * @memberof NetPlaneView
	 */
	renderOverlayLaterals(): ?React$Element<any> {
		const { showLaterals } = this.state;

		const textLaterals: string = this.getTransElementText('netplane.laterals');

		return this.renderOverlayPolylines(textLaterals, showLaterals, NETPLANE_CANALOBJTYPE.LATERAL, 'lateral_', MAP_DEFAULTS.INDEXLATERALS);
	}

	/**
	 * Rendert das Overlay fuer Haltungen oder Leitungen
	 * @param {string} overlayName			der Name fuer das Overlay
	 * @param {boolean} showOverlay			ob die Kanalobjekte angezeigt werden sollen
	 * @param {string} canalObjectType		der Typ der Kanalobjekte
	 * @param {string} canalObjectKey		der zusaetzliche key-Name fuer die einzelnen Kanalobjekte
	 * @param {number} paneIndex			der zIndex fuer fuer die dargestellten Polylines
	 * @return {JSX} JSX Markup
	 * @memberof NetPlaneView
	 */
	renderOverlayPolylines(overlayName: string, showOverlay: boolean, canalObjectType: string, canalObjectKey: string, paneIndex: number): ?React$Element<any> {
		let canalObjectsData: Array<any> = [];
		let canalObjectAmount: number = 0;

		const map = this.mapRef.current;

		if (map != null) {
			const mapLatLngBounds: LatLngBounds = map.leafletElement.getBounds();

			canalObjectsData = this.getBoundsCanalObjects(mapLatLngBounds, canalObjectType, canalObjectKey);
			canalObjectAmount = this.getAmountCanalObjects(canalObjectType);
		}

		if (canalObjectsData.length > 0 || canalObjectAmount > 0) {
			const canalObjects: Array<any> = showOverlay ? this.getPolylineCanalObjects(canalObjectsData, map.leafletElement.getZoom(), false) : [];

			return (
				<Overlay checked={showOverlay} name={overlayName}>
					<LayerGroup>
						<Fragment>
							<Pane style={{ zIndex: paneIndex }}>{canalObjects}</Pane>
						</Fragment>
					</LayerGroup>
				</Overlay>
			);
		} else {
			return null;
		}
	}

	/**
	 * Rendert das Overlay fuer Schaechte
	 * @return {JSX} JSX Markup
	 * @memberof NetPlaneView
	 */
	renderOverlayManholes(): ?React$Element<any> {
		const { showManholes, opacityValNetplan } = this.state;

		const textManholes: string = this.getTransElementText('netplane.manholes');

		let canalObjectsData: Array<any> = [];
		let canalObjectAmount: number = 0;

		const map = this.mapRef.current;

		if (map != null) {
			const mapLatLngBounds: LatLngBounds = map.leafletElement.getBounds();

			canalObjectsData = this.getBoundsCanalObjects(mapLatLngBounds, NETPLANE_CANALOBJTYPE.MANHOLE, 'manhole_');
			canalObjectAmount = this.getAmountCanalObjects(NETPLANE_CANALOBJTYPE.MANHOLE);
		}

		if (canalObjectsData.length > 0 || canalObjectAmount > 0) {
			const canalObjects: Array<any> = showManholes ? this.getCircleCanalObjects(canalObjectsData, opacityValNetplan, true) : [];

			return (
				<Overlay checked={showManholes} name={textManholes}>
					<LayerGroup>
						<Fragment>
							<Pane style={{ zIndex: MAP_DEFAULTS.INDEXMANHOLES }}>{canalObjects}</Pane>
						</Fragment>
					</LayerGroup>
				</Overlay>
			);
		} else {
			return null;
		}
	}

	/**
	 * Rendert fuer ein Kanalobjekt das Tooltip-Element
	 * @param {string} name		der Names des Kanalobjektes
	 * @param {string} type		der Typ des Kanalobjektes
	 * @param {string} system	das System des Kanalobjektes
	 * @param {number} diam		der Durchmesser des Kanalobjektes
	 * @return {JSX} JSX Markup
	 * @memberof NetPlaneView
	 */
	renderCanalObjectTooltip = (name: string, type: string, system: string, diam: number): ?React$Element<any> => {
		const { scaleControl } = this.state;

		const textCanalObject: string = this.getTransElementText('netplane.canalObject');
		const textCanalObjectName: string = this.getTransElementText('netplane.canalObjectName');
		const textCanalObjectType: string = this.getTransElementText('netplane.canalObjectType');
		const textCanalObjectSystem: string = this.getTransElementText('netplane.canalObjectSystem');

		let unit: string = NETPLANE_UNITSYSTEM.METRIC_UNIT;
		let diameter: number = this.convertDiammeter(diam);

		// Umrechnung mm in inch
		if (scaleControl.imperial) {
			unit = NETPLANE_UNITSYSTEM.IMPERIAL_UNIT;
			diameter = (diameter / 25.4).toFixed(2);
		}

		const placeholder: Object = { unit: unit };
		const textCanalObjectDiameter: string = this.getTransElementTextPlaceholder('netplane.canalObjectDiameter', placeholder);

		return (
			<Tooltip pane="popupPane" direction="auto" offset={[2, 2]} opacity={0.7} sticky permanent={false}>
				<strong>{textCanalObject}</strong>
				<br />
				{textCanalObjectName} <strong>{name}</strong>
				<br />
				{textCanalObjectType} <strong>{this.getCanalObjectTypeText(type)}</strong>
				<br />
				{textCanalObjectSystem} <strong>{this.getCanalObjectSystemText(system)}</strong>
				<br />
				{textCanalObjectDiameter} <strong>{diameter}</strong>
			</Tooltip>
		);
	};

	/**
	 * Rendert das LayersControl fuer die Anzeige von Haltungen, Schaechte, Leitungen, Bezeichnungen
	 * Luftbilder wenn vorhanden
	 * Kataster wenn vorhanden
	 * @return {JSX} JSX Markup
	 * @memberof NetPlaneView
	 */
	renderLayersControl(): ?React$Element<any> {
		const map = this.mapRef.current;

		if (map != null) {
			return (
				<LayersControl position="topright">
					{this.renderOverlaySection()}
					{this.renderOverlayLaterals()}
					{this.renderOverlayManholes()}
					{this.renderOverlayDescriptions()}
					{this.renderOverlayImages()}
					{this.renderOverlaysLandRegister()}
				</LayersControl>
			);
		} else {
			return null;
		}
	}

	/**
	 * Rendert das ausgewaehlte Kanalobjekt
	 * @return {JSX} JSX Markup
	 * @memberof NetPlaneView
	 */
	renderCanalObjectsSelected(): ?React$Element<any> {
		const { currentObject } = this.props;

		const map = this.mapRef.current;

		if (currentObject && map != null) {
			const mapBounds: LatLngBounds = map.leafletElement.getBounds();
			const zoom: number = map.leafletElement.getZoom();
			const selectedObjectType: string = currentObject.type;

			let selectedCanalObject: any = null;

			switch (selectedObjectType.toLowerCase()) {
				case NETPLANE_CANALOBJTYPE.SECTION.toLowerCase():
					selectedCanalObject = this.renderSelectedSection(mapBounds, zoom);
					break;
				case NETPLANE_CANALOBJTYPE.LATERAL.toLowerCase():
					selectedCanalObject = this.renderSelectedLateral(mapBounds, zoom);
					break;
				case NETPLANE_CANALOBJTYPE.MANHOLE.toLowerCase():
					selectedCanalObject = this.renderSelectedManhole(mapBounds);
					break;
				default:
					break;
			}

			return selectedCanalObject;
		} else {
			return null;
		}
	}

	/**
	 * Rendert fuer die sichtbaren Kanalobjekte die Tooltip-Elemente
	 * @return {JSX} JSX Markup
	 * @memberof NetPlaneView
	 */
	renderCanalObjectsTooltip(): ?React$Element<any> {
		const { showSections, showLaterals, showManholes } = this.state;

		let canalObjectsTooltip: Array<any> = [];

		if (showSections) {
			const canalSectionsTooltip: Array<any> = this.renderSectionsTooltip();

			canalObjectsTooltip.push.apply(canalObjectsTooltip, canalSectionsTooltip);
		}

		if (showLaterals) {
			const canalLateralsTooltip: Array<any> = this.renderLateralsTooltip();

			canalObjectsTooltip.push.apply(canalObjectsTooltip, canalLateralsTooltip);
		}

		if (showManholes) {
			const canalManholesTooltip: Array<any> = this.renderManholesTooltip();

			canalObjectsTooltip.push.apply(canalObjectsTooltip, canalManholesTooltip);
		}

		return <Pane style={{ zIndex: MAP_DEFAULTS.INDEXTOOLTIPS }}>{canalObjectsTooltip}</Pane>;
	}

	/**
	 * Rendert fuer die Kanalobjekte - Haltungen - die Tooltip-Elemente
	 * @return {JSX} JSX Markup
	 * @memberof NetPlaneView
	 */
	renderSectionsTooltip(): Array<React$Element<any>> {
		let canalObjectsTooltip: Array<React$Element<any>> = [];

		const map = this.mapRef.current;

		if (map != null) {
			const mapBounds: LatLngBounds = map.leafletElement.getBounds();

			const canalObjectsData: Array<any> = this.getBoundsCanalObjects(mapBounds, NETPLANE_CANALOBJTYPE.SECTION, 'section_tip_');
			const canalObjectsAmount: number = this.getAmountCanalObjects(NETPLANE_CANALOBJTYPE.SECTION);

			if (canalObjectsData.length > 0 || canalObjectsAmount > 0) {
				canalObjectsTooltip = this.getPolylineCanalObjects(canalObjectsData, map.leafletElement.getZoom(), true);
			}
		}

		return canalObjectsTooltip;
	}

	/**
	 * Rendert fuer die Kanalobjekte - Leitungen - die Tooltip-Elemente
	 * @return {JSX} JSX Markup
	 * @memberof NetPlaneView
	 */
	renderLateralsTooltip(): Array<React$Element<any>> {
		let canalObjectsTooltip: Array<React$Element<any>> = [];

		const map = this.mapRef.current;

		if (map != null) {
			const mapBounds: LatLngBounds = map.leafletElement.getBounds();

			const canalObjectsData: Array<any> = this.getBoundsCanalObjects(mapBounds, NETPLANE_CANALOBJTYPE.LATERAL, 'lateral_tip_');
			const canalObjectsAmount: number = this.getAmountCanalObjects(NETPLANE_CANALOBJTYPE.LATERAL);

			if (canalObjectsData.length > 0 || canalObjectsAmount > 0) {
				canalObjectsTooltip = this.getPolylineCanalObjects(canalObjectsData, map.leafletElement.getZoom(), true);
			}
		}

		return canalObjectsTooltip;
	}

	/**
	 * Rendert fuer die Kanalobjekte - Schaechte - die Tooltip-Elemente
	 * @return {JSX} JSX Markup
	 * @memberof NetPlaneView
	 */
	renderManholesTooltip(): Array<React$Element<any>> {
		let canalObjectsTooltip: Array<React$Element<any>> = [];

		const map = this.mapRef.current;

		if (map != null) {
			const mapBounds: LatLngBounds = map.leafletElement.getBounds();

			const canalObjectsData: Array<any> = this.getBoundsCanalObjects(mapBounds, NETPLANE_CANALOBJTYPE.MANHOLE, 'manhole_tip_');
			const canalObjectsAmount: number = this.getAmountCanalObjects(NETPLANE_CANALOBJTYPE.MANHOLE);

			if (canalObjectsData.length > 0 || canalObjectsAmount > 0) {
				canalObjectsTooltip = this.getCircleCanalObjects(canalObjectsData, 0, true);
			}
		}

		return canalObjectsTooltip;
	}

	/**
	 * Rendert nur das ausgewaehlte Kanalobjekt - Haltung
	 * @param {LatLngBounds} mapBounds		die Koordinaten des sichtbaren Bereichs der Karte
	 * @param {number} zoom					der Zoomwert der Karte
	 * @return {JSX} JSX Markup
	 * @memberof NetPlaneView
	 */
	renderSelectedSection(mapBounds: LatLngBounds, zoom: number): ?React$Element<any> {
		const { showSections } = this.state;

		const canalObjectsData: Array<any> = this.getBoundsCanalObjects(mapBounds, NETPLANE_CANALOBJTYPE.SECTION, 'section_selected_');
		const canalObjectAmount: number = this.getAmountCanalObjects(NETPLANE_CANALOBJTYPE.SECTION);

		if (showSections && (canalObjectsData.length > 0 || canalObjectAmount > 0)) {
			return <Pane style={{ zIndex: MAP_DEFAULTS.INDEXSECTIONSSELECTED }}>{this.getSelectedPolylineCanalObjects(canalObjectsData, zoom)}</Pane>;
		} else {
			return null;
		}
	}

	/**
	 * Rendert nur das ausgewaehlte Kanalobjekt - Leitung
	 * @param {LatLngBounds} mapBounds		die Koordinaten des sichtbaren Bereichs der Karte
	 * @param {number} zoom					der Zoomwert der Karte
	 * @return {JSX} JSX Markup
	 * @memberof NetPlaneView
	 */
	renderSelectedLateral(mapBounds: LatLngBounds, zoom: number): ?React$Element<any> {
		const { showLaterals } = this.state;

		const canalObjectsData: Array<any> = this.getBoundsCanalObjects(mapBounds, NETPLANE_CANALOBJTYPE.LATERAL, 'lateral_selected_');
		const canalObjectAmount: number = this.getAmountCanalObjects(NETPLANE_CANALOBJTYPE.LATERAL);

		if (showLaterals && (canalObjectsData.length > 0 || canalObjectAmount > 0)) {
			return <Pane style={{ zIndex: MAP_DEFAULTS.INDEXLATERALSSELECTED }}>{this.getSelectedPolylineCanalObjects(canalObjectsData, zoom)}</Pane>;
		} else {
			return null;
		}
	}

	/**
	 * Rendert nur das ausgewaehlte Kanalobjekt - Schacht
	 * @param {LatLngBounds} mapBounds		die Koordinaten des sichtbaren Bereichs der Karte
	 * @return {JSX} JSX Markup
	 * @memberof NetPlaneView
	 */
	renderSelectedManhole(mapBounds: LatLngBounds): ?React$Element<any> {
		const { showManholes } = this.state;

		const canalObjectsData: Array<any> = this.getBoundsCanalObjects(mapBounds, NETPLANE_CANALOBJTYPE.MANHOLE, 'manhole_selected_');
		const canalObjectAmount: number = this.getAmountCanalObjects(NETPLANE_CANALOBJTYPE.MANHOLE);

		if (showManholes && (canalObjectsData.length > 0 || canalObjectAmount > 0)) {
			return <Pane style={{ zIndex: MAP_DEFAULTS.INDEXMANHOLESSELECTED }}>{this.getSelectedCircleCanalObjects(canalObjectsData)}</Pane>;
		} else {
			return null;
		}
	}

	/**
	 * Rendert die Slider fuer die Transparenz der KArte und dem Netzplan
	 * @param {string} className		der class Name
	 * @param {number} defaultValue		der gesetzte Wert
	 * @param {mixed} onAfterChange		die Funktion nach der Aenderung
	 */
	renderOpacitySlider(className: string, value: number, defaultValue: number, onChange: mixed, onAfterChange: mixed): ?React$Element<any> {
		return (
			<Slider
				className={className}
				value={value}
				onChange={onChange}
				onAfterChange={onAfterChange}
				trackStyle={{ backgroundColor: '#337ab7' }}
				step={0.1}
				min={0}
				max={1}
				handleStyle={{
					borderColor: '#337ab7',
					height: 20,
					width: 20,
					marginLeft: -10,
					marginTop: -8,
				}}
			></Slider>
		);
	}

	/**
	 * Rendert die Komponente
	 * @return {JSX}
	 */
	render() {
		const { animate, sliderValNetplan, sliderValMap, viewport, maxBounds } = this.state;

		return (
			<Container fluid>
				<Row>
					<Col xs={12} sm={12} md={6} lg={6} className="text-left">
						<Trans i18nKey="netplane.netplane" parent="span">
							Netzplan
						</Trans>
						:&nbsp;&nbsp;
						{this.renderOpacitySlider(
							'sliderPlan',
							this.valueNetplaneOpacity,
							sliderValNetplan,
							this.handleOnChangeSliderValNetplan,
							this.handleOnAfterChangeSliderValNetplan
						)}
					</Col>
					<Col xs={12} sm={12} md={6} lg={6} className="text-right">
						<Trans i18nKey="netplane.map" parent="span">
							Karte
						</Trans>
						:&nbsp;&nbsp;
						{this.renderOpacitySlider('sliderMap', this.valueMapOpacity, sliderValMap, this.handleOnChangeSliderValMap, this.handleOnAfterChangeSliderValMap)}
					</Col>
				</Row>
				<Row className="row-map">
					<Col id="colMap" xs={12} sm={12} md={12} lg={12} className="text-center map-container">
						<Map
							id={uuid4()}
							className="map"
							preferCanvas={true}
							animate={animate}
							onMouseMove={this.handleMouseOver}
							whenReady={this.handleWhenReady}
							ref={this.mapRef}
							boxZoom={true}
							zoomControl={false}
							maxZoom={MAP_DEFAULTS.MAXZOOM}
							onViewportChanged={this.handleViewportChanged}
							viewport={viewport}
							maxBounds={maxBounds}
						>
							{this.renderMapZoomControl()}
							{this.renderMapTileLayer()}
							{this.renderMapScaleControl()}
							{this.renderCanalObjectsSelected()}
							{this.renderCanalObjectsTooltip()}
							{this.renderLayersControl()}
						</Map>
					</Col>
				</Row>
				<Row>
					<Col xs={12} sm={12} md={12} lg={12}>
						<Badge id="labelRechtswert" ref={(xCoordinateRef) => (this.xCoordinateRef = xCoordinateRef)}>
							<Trans i18nKey="netplane.xCoordinate">Rechtswert:</Trans>
						</Badge>
						<Badge id="labelHochwert" ref={(yCoordinateRef) => (this.yCoordinateRef = yCoordinateRef)} style={{ marginLeft: '10px' }}>
							<Trans i18nKey="netplane.yCoordinate">Hochwert:</Trans>
						</Badge>
					</Col>
				</Row>
			</Container>
		);
	}
}

/**
 * HOC fuer das Verbinden mit Tastaturkuerzeln
 */
const NetView = withKeyboardCommand(NetPlaneView, [
	{
		key: 'ctrl+right',
		func: (child) => {
			if (child.props.currentObject) {
				child.props.objectOpenend(child.props.currentObject);
			}
		},
	},
]);

/**
 * Verbindet die Klasse mit dem Redux Store
 */
const ReduxNetplaneView = connect((state) => {
	return {
		settings: state.settings,
		netplane: state.currentNetPlane,
		currentObject: state.currentObject,
		currentProject: state.currentProject,
	};
}, {})(NetView);
export default ReduxNetplaneView;
