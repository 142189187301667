// @flow
import React, { useState, useRef, useEffect } from 'react';
import {Card, Form, FormGroup, Col, FormControl, Button, InputGroup, Image, ButtonToolbar} from 'react-bootstrap';

import gifLoader from '../../images/Eclipse.gif';
import '../../css/login.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faLock, faSignInAlt } from '@fortawesome/free-solid-svg-icons';

/**
 * Komponente fuer die Anmeldung
 * @type {Login}
 */
export default function Login ({
    callback,
    passwordCallback,
    loader,
    title,
    username,
    password,
    button,
    passwordButton
}) {
    const usernameRef = useRef();
    const passwordRef = useRef();
    
    const [ready, setReady] = useState(false);

    useEffect(() => {
        usernameRef.current.focus();
    }, [])

    /**
     * Ruft den Callback auf wenn die Anmeldung betaetigt wird
     */
    const onLogin = ():void => {
        // $FlowFixMe
        callback(usernameRef.current.value, passwordRef.current.value);
    }

    
    /**
     * prueft ob die Eingabefelder korrekt gefuellt sind und setzt den Status
     */
    const checkFields = ():void => {
        // $FlowFixMe
        setReady( usernameRef.current.value.trim() !== '' && passwordRef.current.value.trim() !== '' );        
    }

    /**
     * Workaround fuer Submit Probleme im Firefox
     * @param {Event} e Das Event das ausgeloest wurde
     */
    const handleKeyPress = (e: SyntheticEvent<>):void => {        
        if (e.key === 'Enter' && !loader && ready) {
            onLogin();
        }
    }  
   
    return (
        <div>
            {loader ?
                <Image
                    src={gifLoader}
                    className="login-loader">
                </Image>
                : null
            }
            <h1 className='headerText'>IKAS evolution WebViewer</h1>
            <Card onKeyPress={handleKeyPress}>

                <Card.Header>{title}</Card.Header>

                <Card.Body>
                    <Form>
                        <FormGroup className='mb-3'>
                            <Col sm={12}>
                                <InputGroup>
                                    <InputGroup.Text id="basic-addon1">
                                        <FontAwesomeIcon icon={faUser}></FontAwesomeIcon>
                                    </InputGroup.Text>
                                    <FormControl
                                        type="email"
                                        placeholder={username}
                                        ref={usernameRef}
                                        disabled={loader}
                                        onChange={checkFields}
                                    >
                                    </FormControl>
                                </InputGroup>
                            </Col>
                        </FormGroup>
                        <FormGroup className='mb-3'>
                            <Col sm={12}>
                                <InputGroup>
                                    <InputGroup.Text>
                                        <FontAwesomeIcon icon={faLock}></FontAwesomeIcon>
                                    </InputGroup.Text>
                                    <FormControl
                                        type="password"
                                        placeholder={password}
                                        ref={passwordRef}
                                        disabled={loader}
                                        onChange={checkFields}
                                    >
                                    </FormControl>
                                </InputGroup>
                            </Col>
                        </FormGroup>
                        </Form>  
                        <div className='mb-3'>
                            <Col sm={12} >
                                <ButtonToolbar>
                                    <Button
                                        onClick={onLogin}
                                        disabled={loader || !ready}
                                    >
                                        <FontAwesomeIcon icon={faSignInAlt}></FontAwesomeIcon> {button}
                                    </Button>
                                </ButtonToolbar>                                
                            </Col>
                        </div>
                    
                </Card.Body>
            </Card>
        </div>
    );
    
}