import CardHelper from '../../../form/helper/CardHelper';
import FieldHelper from '../../../form/helper/FieldHelper';


const Project = [
            new CardHelper({
                id: 'general',
                title: {
                    key: 'common.general',
                    fallback: 'Allgemein'
                },
                items: [
                    new FieldHelper({
                        id: 'projectname',
                        title: {
                            key: 'common.projectname',
                            fallback: 'Projektname'
                        },
                        accessor: 'DefaultNameProject'
                    }),
                    new FieldHelper({
                        id: 'orderrule',
                        title: {
                            key: 'common.orderrule',
                            fallback: 'Auftragsregel'
                        },
                        accessor: 'FriendlyNameRessource'
                    })
                ]
            }),
            new CardHelper({
                id: 'isybau',
                title: {
                    key: 'isybauxml.project.isybau.header',
                    fallback: 'ISYBAU-XML-Auftrag'
                },
                items: [
                    new FieldHelper({
                        id: 'orderName',
                        title: {
                            key: 'isybauxml.project.isybau.orderName',
                            fallback: 'Auftragsbezeichnung'
                        },
                        accessor: 'Auftragsbezeichnung'
                    }),
                    new FieldHelper({
                        id: 'jobNumber',
                        title: {
                            key: 'isybauxml.project.isybau.jobNumber',
                            fallback: 'Auftragsnummer'
                        },
                        accessor: 'Auftragsnummer'
                    }),
                    new FieldHelper({
                        id: 'jobReference',
                        title: {
                            key: 'isybauxml.project.isybau.jobReference',
                            fallback: 'Auftragskennung'
                        },
                        accessor: 'Auftragskennung'
                    }),
                    new FieldHelper({
                        id: 'orderDate',
                        title: {
                            key: 'isybauxml.project.isybau.orderDate',
                            fallback: 'Auftragsdatum'
                        },
                        accessor: 'Auftragsdatum'
                    }),
                    new FieldHelper({
                        id: 'orderType',
                        title: {
                            key: 'isybauxml.project.isybau.orderType',
                            fallback: 'Auftragsart'
                        },
                        accessor: 'Auftragsart'
                    }),
                    new FieldHelper({
                        id: 'inspectionPlace',
                        title: {
                            key: 'isybauxml.project.isybau.inspectionPlace',
                            fallback: 'Inspektionsort'
                        },
                        accessor: 'Inspektionsort'
                    }),
                    new FieldHelper({
                        id: 'inspectionPurpose',
                        title: {
                            key: 'isybauxml.project.isybau.inspectionPurpose',
                            fallback: 'Inspektionszweck'
                        },
                        accessor: 'Inspektionszweck'
                    }),
                    new FieldHelper({
                        id: 'contractor',
                        title: {
                            key: 'isybauxml.project.isybau.contractor',
                            fallback: 'Auftragnehmer'
                        },
                        accessor: 'Auftragnehmer'
                    }),
                    new FieldHelper({
                        id: 'datastatus',
                        title: {
                            key: 'isybauxml.project.isybau.datastatus',
                            fallback: 'Datenstatus'
                        },
                        accessor: 'ISYBAU-XML_DatenStatus'
                    }),
                    new FieldHelper({
                        id: 'project.comment',
                        title: {
                            key: 'isybauxml.project.isybau.comment',
                            fallback: 'Kommentar'
                        },
                        accessor: 'ISYBAU-XML_DatenStatus_Kommentar'
                    })
                ]
            }),
            new CardHelper({
                id: 'property',
                title: {
                    key: 'isybauxml.project.property.header',
                    fallback: 'Liegenschaft'
                },
                items: [
                    new FieldHelper({
                        id: 'propertyNumber',
                        title: {
                            key: 'isybauxml.project.property.propertyNumber',
                            fallback: 'Liegenschaftsnummer'
                        },
                        accessor: 'Liegenschaftsnummer'
                    }),
                    new FieldHelper({
                        id: 'street',
                        title: {
                            key: 'common.street',
                            fallback: 'Straße'
                        },
                        accessor: 'Liegenschaftsstrasse'
                    }),
                    new FieldHelper({
                        id: 'objectNumber',
                        title: {
                            key: 'isybauxml.project.property.objectNumber',
                            fallback: 'Objektnummer'
                        },
                        accessor: 'Objektnummer'
                    }),
                    new FieldHelper({
                        id: 'postcode',
                        title: {
                            key: 'isybauxml.project.property.postcode',
                            fallback: 'PLZ'
                        },
                        accessor: 'LiegenschaftsPLZ'
                    }),
                    new FieldHelper({
                        id: 'propertyName',
                        title: {
                            key: 'isybauxml.project.property.propertyName',
                            fallback: 'Liegenschaftsbezeichnung'
                        },
                        accessor: 'Liegenschaftsbezeichnung'
                    }),
                    new FieldHelper({
                        id: 'place',
                        title: {
                            key: 'isybauxml.project.property.place',
                            fallback: 'Ort'
                        },
                        accessor: 'Liegenschaftsort'
                    }),
                    new FieldHelper({
                        id: 'propertyUse',
                        title: {
                            key: 'isybauxml.project.property.propertyUse',
                            fallback: 'Liegenschaftsnutzung'
                        },
                        accessor: 'Liegenschaftsnutzung'
                    })
                ]
            }),
            new CardHelper({
                id: 'office',
                title: {
                    key: 'isybauxml.project.office.header',
                    fallback: 'Zuständige Dienststellen'
                },
                items: [
                    new FieldHelper({
                        id: 'responsibility',
                        title: {
                            key: 'isybauxml.project.office.responsibility',
                            fallback: 'Zuständigkeit'
                        },
                        accessor: 'Zustaendigkeit'
                    }),
                    new FieldHelper({
                        id: 'officeAdministration',
                        title: {
                            key: 'isybauxml.project.office.officeAdministration',
                            fallback: 'Dienststelle Verwaltend'
                        },
                        accessor: 'DienststelleVerwaltend'
                    }),
                    new FieldHelper({
                        id: 'buildingOfficeAdministration',
                        title: {
                            key: 'isybauxml.project.office.buildingOfficeAdministration',
                            fallback: 'Dienststelle Hausverwaltend'
                        },
                        accessor: 'DienststelleHausverwaltend'
                    }),
                    new FieldHelper({
                        id: 'constructionInspectionOffice',
                        title: {
                            key: 'isybauxml.project.office.constructionInspectionOffice',
                            fallback: 'Dienststelle Bauaufsicht'
                        },
                        accessor: 'DienststelleBauaufsicht'
                    }),
                    new FieldHelper({
                        id: 'constructionOffice',
                        title: {
                            key: 'isybauxml.project.office.constructionOffice',
                            fallback: 'Dienststelle Baudurchführung'
                        },
                        accessor: 'DienststelleBaudurchfuehrung'
                    }),
                    new FieldHelper({
                        id: 'noAuthorityConstructionExecution',
                        title: {
                            key: 'isybauxml.project.office.noAuthorityConstructionExecution',
                            fallback: 'Nr. Dienststelle Baudurchführung'
                        },
                        accessor: 'NummerDienststelleBaudurchfuehrung'
                    }),
                    new FieldHelper({
                        id: 'inspectionSite',
                        title: {
                            key: 'isybauxml.project.office.inspectionSite',
                            fallback: 'Zuständigkeitsbereich'
                        },
                        accessor: 'Zustaendigkeitsbereich'
                    }),
                    new FieldHelper({
                        id: 'reference',
                        title: {
                            key: 'isybauxml.project.office.reference',
                            fallback: 'Aktenzeichen'
                        },
                        accessor: 'Aktenzeichen'
                    }),
                    new FieldHelper({
                        id: 'wasteWaterDisposal',
                        title: {
                            key: 'isybauxml.project.office.wasteWaterDisposal',
                            fallback: 'Abwasserbeseitigunspflicht'
                        },
                        accessor: 'Abwasserbeseitigungspflicht'
                    }),
                    new FieldHelper({
                        id: 'waterAuthority',
                        title: {
                            key: 'isybauxml.project.office.waterAuthority',
                            fallback: 'Wasserbehoerde'
                        },
                        accessor: 'Wasserbehoerde'
                    }),
                    new FieldHelper({
                        id: 'dischargePermit',
                        title: {
                            key: 'isybauxml.project.office.dischargePermit',
                            fallback: 'Ablauf Einleitungsgenehmigung'
                        },
                        accessor: 'AblaufEinleitungsgenehmigung'
                    }),
                    new FieldHelper({
                        id: 'comment',
                        title: {
                            key: 'isybauxml.project.office.comment',
                            fallback: 'Kommentar'
                        },
                        accessor: 'Kommentar'
                    })
                ]
            }),
            new CardHelper({
                id: 'geometry',
                title: {
                    key: 'common.geometry',
                    fallback: 'Geometrie'
                },
                items: [
                    new FieldHelper({
                        id: 'coordinateSystemHeight',
                        title: {
                            key: 'isybauxml.project.geometry.coordinateSystemHeight',
                            fallback: 'Koordinatensystem (Höhe)'
                        },
                        accessor: 'Koordinatensystem_Hoehe'
                    }),
                    new FieldHelper({
                        id: 'coordinateSystemPosition',
                        title: {
                            key: 'isybauxml.project.geometry.coordinateSystemPosition',
                            fallback: 'Koordinatensystem (Lage)'
                        },
                        accessor: 'Koordinatensystem_Lage'
                    })
                ]
            })

];

export default Project;