// @flow
import React from 'react';
import InputHelper from "./InputHelper";
import FormCheckbox from "../components/FormCheckbox";

/**
 * Hilfsklasse fuer Formtemplates um eine Checkbox zu erzeugen
 *
 * @export
 * @class CheckboxHelper
 * @extends {InputHelper}
 */
export default class CheckboxHelper extends InputHelper {
    /**
     * Erzeugt eine Checkbox
     *
     * @param {any} value Wert der Checkbox
     * @param {number} size Groesse des Feldes. Hier nicht verwendet
     * @returns {FormCheckbox}
     * @memberof CheckboxHelper
     */
    build(value: any, size: number):?React$Element<any> {
        return <FormCheckbox checked={value} />;
    }
}