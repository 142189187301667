// @flow
/**
 * Template fuer Hilfklassen die ein Eingabeelement erzeugen
 *
 * @export
 * @class InputHelper
 */
export default class InputHelper {
    /**
     * Baut das Eingabeelement
     *
     * @param {*} value Wert des Elements
     * @param {number} size Groesse des Elements
     * @returns {?React$Element<any>}
     * @memberof InputHelper
     */
    build(value: any, size: number):?React$Element<any> {
        return null;
    }
}