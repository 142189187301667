// @flow
import { PROJECT_LEVEL_VIEWS } from '../misc/const';
import {
	SET_MAIN_VIEW_TAB,
	OBJECT_SELECTED,
	OBJECT_OPENED,
	SHOW_SIDE_VIEW,
	SWITCH_STATION_VIEW,
	STATION_SELECTED,
	NEXT_STATION_IMAGE,
	SEEK_STATION,
	ADD_STATION_VIDEO_VIEW_INSTANCE,
	REMOVE_STATION_VIDEO_VIEW_INSTANCE,
	UPDATE_STATION_VIDEO_VIEW_INSTANCE,
	ADD_PANO_VIEW_INSTANCE,
	UPDATE_PANO_VIEW_INSTANCE,
    REMOVE_PANO_VIEW_INSTANCE,
	SET_SESSION_PARAMETER,
	SET_REPORT_SESSION,
	ORIGINAL_SELECTED,
    SET_SHOW_LOADING_SPINNER
} from '../actions/utils/actionTypes';

import { Dispatch, ThunkAction, GetState } from '../misc/flowTypes';
import { _requestStations, _requestObjects } from './dataActions';
import { makeActionCreator } from './utils/actionCreator';

/**
 * Middleware, die Stationen abruft bevor das Objekt geoffnet wird
 * @param  {Object} object Objekt das ausgewaehlt wurde
 * @param  {string} [targetView=null] Name des View, falls einer explizit geoeffnet werden soll
 * @return {Function} Die Redux Action
 */
// $FlowFixMe
export function objectOpened(object: Object, targetView: ?string = null): ThunkAction {
	return (dispatch: Dispatch) => {
		if (object && object.stations.length === 0) {
			dispatch(
				_requestStations(object, () => {
					dispatch(_objectOpened(object, targetView));
				})
			);
		} else {
			dispatch(_objectOpened(object, targetView));
		}
	};
}

/**
 * Erstellt eine Action fuer Redux vom Typ OBJECT_OPENED
 * @param  {Object} object Objekt das ausgewaehlt wurde
 * @param  {string} [targetView=null] Name des View, falls einer explizit geoeffnet werden soll
 * @return {Object}     OBJECT_SELECTED Action
 */
export const _objectOpened = makeActionCreator(OBJECT_OPENED, 'object', 'targetView');

/**
 * Fuehrt bestimmte Funktion aus bevor die eigentliche Action ausgefuehrt wird
 * @param {string} key   Name des Tabs der gesetzt werden soll
 * @return {Object}      SET_MAIN_VIEW_TAB Action
 */
export function setMainViewTab(key: string): ThunkAction {
	return (dispatch: Dispatch, getState: GetState) => {
		const { currentProject } = getState();

		if (currentProject && currentProject.objects.length === 0 && (key === PROJECT_LEVEL_VIEWS.MAIN.OBJECT_LIST_MAIN_VIEW || key === PROJECT_LEVEL_VIEWS.MAIN.NET_PLANE_VIEW)) {
			dispatch(
				_requestObjects(currentProject, () => {
					dispatch(_setMainViewTab(key));
				})
			);
		} else {
			dispatch(_setMainViewTab(key));
		}
	};
}

/**
 * Erzeugt die Action zum Wechsel, des Tabs
 * @param {String} key Name des Tabs der gesetzt werden soll
 * @return {Object} Die Redux Action
 */
export const _setMainViewTab = makeActionCreator(SET_MAIN_VIEW_TAB, 'key');

/**
 * Erstellt eine Action fuer Redux vom Typ OBJECT_SELECTED
 * @param  {Object} object Objekt das ausgewaehlt wurde
 * @return {Object}     OBJECT_SELECTED Action
 */
// $FlowFixMe
export const objectSelected = makeActionCreator(OBJECT_SELECTED, 'object');

/**
 * Erstellt eine Action fuer Redux vom Typ SHOW_SIDE_VIEW
 * @param  {boolean} show Ob der View angezeigt werden soll
 * @return {Object}      SHOW_SIDE_VIEW Action
 */
// $FlowFixMe
export const showSideView = makeActionCreator(SHOW_SIDE_VIEW, 'show');

/**
 * Erstellt eine Action fuer Redux vom Typ SWITCH_STATION_VIEW
 * @param  {string} view View, der angezeigt werden soll
 * @return {Object}      SWITCH_STATION_VIEW Action
 */
// $FlowFixMe
export const switchStationView = makeActionCreator(SWITCH_STATION_VIEW, 'view');

/**
 * Erstellt eine Action fuer Redux vom Typ STATION_SELECTED
 * @param  {object} station Station, die angezeigt werden soll
 * @return {Object}         STATION_SELECTED Action
 */
// $FlowFixMe
export const stationSelected = makeActionCreator(STATION_SELECTED, 'station');

/**
 * Erstellt eine Actioen fuer Redux vom Typ SEEK_STATION
 *
 * @export
 * @returns {Action} SEEK_STATION Action
 */
export const seekToStation = makeActionCreator(SEEK_STATION);

export const addStationVideoViewInstance = makeActionCreator(ADD_STATION_VIDEO_VIEW_INSTANCE, 'instance');

export const removeStationVideoViewInstance = makeActionCreator(REMOVE_STATION_VIDEO_VIEW_INSTANCE, 'instance');

export const updateStationVideoViewInstance = makeActionCreator(UPDATE_STATION_VIDEO_VIEW_INSTANCE, 'instance', 'updateStationView');

export const addPanoViewInstance = makeActionCreator(ADD_PANO_VIEW_INSTANCE, 'instance');

export const updatePanoViewInstance = makeActionCreator(UPDATE_PANO_VIEW_INSTANCE, 'instance', 'updatePanoView');

export const removePanoViewInstance = makeActionCreator(REMOVE_PANO_VIEW_INSTANCE, 'instance');

/**
 * Erstellt eine Action fuer Redux vom Typ NEXT_STATION_IMAGE
 * @param  {string} direction Richtung des naechsten Bildes
 * @return {Object}           NEXT_STATION_IMAGE Action
 */
// $FlowFixMe
export const nextStationPicture = makeActionCreator(NEXT_STATION_IMAGE, 'direction');

/**
 * Erstellt eine Action fuer Redux vom Typ SET_SESSION_PARAMETER
 *
 * @export
 * @param {string} target Parameter, der in der Session ueberschrieben werden soll
 * @param {*} value Wert fuer den Parameter
 * @returns {Action}
 */
export const setSessionParameter = makeActionCreator(SET_SESSION_PARAMETER, 'target', 'value');

/**
 * Erstellt eine Action fuer Redux vom Typ ORIGINAL_SELECTED
 * @param  {Object} object Objekt das ausgewaehlt wurde
 * @return {Object}     ORIGINAL_SELECTED Action
 */
// $FlowFixMe
export const originalSelected = makeActionCreator(ORIGINAL_SELECTED, 'object');

/**
 * Erstellt eine Action fuer Redux vom Typ SET_REPORT_SESSION
 *
 * @export
 * @param {*} session Wert der in der Session gespeichert wird
 * @returns {Action}
 */
export const setReportSession = makeActionCreator(SET_REPORT_SESSION, 'session');

/**
 * Erstellt eine Action, die die Objekte des Projekts laedt, falls noch nicht geschehen und der ReportViewer aufgerufen wird
 *
 * @export
 * @returns {ThunkAction}
 */
export function loadReportObjects(): ThunkAction {
	return (dispatch: Dispatch, getState: GetState) => {
		const { currentProject: project } = getState();

		if (project && project.objects.length === 0) {
			dispatch(_requestObjects(project));
		}
	};
}

/**
 * Erstellt eine Action fuer Redux vom Typ SET_SHOW_LOADING_SPINNER
 *
 * @export
 * @param {*} session Wert der in der Session gespeichert wird
 * @returns {Action}
 */
export const setShowLoadingSpinner = makeActionCreator(SET_SHOW_LOADING_SPINNER, 'show');
