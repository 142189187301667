import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import { initReactI18next  } from 'react-i18next';
import HttpBackend from 'i18next-http-backend';

i18n.use(detector)
	.use(HttpBackend)
	.use(initReactI18next ) // passes i18n down to react-i18next
	.init({
		fallbackLng: 'en', // use en if detected lng is not available
		debug: true,
		interpolation: {
			escapeValue: false, // not needed for react as it escapes by default
		},

		// Namespaces are a feature in i18next internationalization framework which allows you to separate translations that get loaded into multiple files
		ns: ['translations'],
		defaultNS: 'translations',

		// react i18next special options (optional)
		react: {
            useSuspense: true,
			withRef: false,
			bindI18n: 'languageChanged loaded',
			bindStore: 'added removed',
			nsMode: 'default',
		},
	});

export default i18n;
