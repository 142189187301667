import { useEffect, useRef } from 'react';

export default function Pointcloud({panoramoViewer, setShowPointCloud}) {
    const pointCloudTargetContainer = useRef(null);
    const pointCloudOverviewTargetContainer = useRef(null);

    // react on change of "showPointCloud", display/hide point cloud
    useEffect(() => {
        async function openPointCloud() {
            panoramoViewer.setRenderTargets({
                pointCloudDiv: pointCloudTargetContainer.current,
                pointCloudOverviewDiv: pointCloudOverviewTargetContainer.current
            });
            await panoramoViewer.openPointCloud();
        }
        openPointCloud();
        document.querySelector('#pointclouddiv').requestFullscreen();
        document.querySelector("#pointclouddiv").addEventListener("fullscreenchange", () => {
            if(!document.fullscreenElement) {setShowPointCloud(false); }
        });

        return () => {
            panoramoViewer.disposePointCloud();
        }
    }, [panoramoViewer, setShowPointCloud]);

    return (
        <div id="pointclouddiv">
            <PointCloudRenderTarget innerRef={pointCloudTargetContainer}/>

            <PointCloudOverviewRenderTarget innerRef={pointCloudOverviewTargetContainer} />
        </div>
    );
}

const PointCloudRenderTarget = (({innerRef}) => <div id="pointcloudmain" ref={innerRef} className="pointcloud"></div>);

const PointCloudOverviewRenderTarget = (({innerRef}) => <div id="pointcloudoverview" ref={innerRef} className="pointcloud"></div>);