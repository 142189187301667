import { useSelector } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import { translateString } from '../../i18n/utils';

export default function LoadingSpinner() {
    const showSpinner = useSelector(state => state.showLoadingSpinner);

    return (
        <Modal
            fullsreen={'true'}
            show={showSpinner}
            className='d-flex justify-content-center align-items-center'
        >
            <div className='d-flex flex-column align-items-center p-3'>
                <Spinner as='span' animation="border" role="status" className='m-2'/>
                <span >{translateString('common.loadingText')}</span>
            </div>
        </Modal>
    );
}