// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Row } from 'react-bootstrap';
import FormBuilder from '../form/FormBuilder';
import CanalInterface from '../templates/helper/CanalInterface';
import withKeyboardCommand from './hoc/withKeyboardCommands';
import { objectOpened } from '../actions/viewActions';

type Props = {
	currentProject: Object,
	currentInterface: CanalInterface,
	currentObject: Object,
	objectOpened: (Object) => mixed,
};

/**
 * React Komponente, die eine Form fuer ein Projekt rendert
 * @type {Component}
 * @constructor
 */
class ProjectMainView extends Component<Props> {
	formBuilder: FormBuilder = new FormBuilder();

	/**
	 * Rendert die Form fuer das Projekt
	 * @return {JSX} JSX Markup
	 */
	render() {
		const { currentProject, currentInterface } = this.props;
		const form: React$Element<any> = this.formBuilder.build(currentProject, currentInterface.project);

		return (
			<Form className="projectview">
                <Row className="no-margin">
                    {form}
                </Row>
			</Form>
		);
	}
}

/**
 *  HOC fuer das Verbinden mit Tastaturkuerzeln
 */
const ProjectView = withKeyboardCommand(ProjectMainView, [
	{
		key: 'ctrl+right',
		func: (child) => {
			if (child.props.currentObject) {
				child.props.objectOpened(child.props.currentObject);
			}
		},
	},
]);

/**
 *  Verbindet die Klasse mit dem Redux Store
 */
const ReduxProjectMainView = connect(
	(state) => {
		return {
			currentProject: state.currentProject,
			currentInterface: state.currentInterface,
			currentObject: state.currentObject,
		};
	},
	{ objectOpened }
)(ProjectView);
export default ReduxProjectMainView;
