// @flow
import React, { Component } from 'react';

import { Button, ButtonGroup, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkSquareAlt } from '@fortawesome/free-solid-svg-icons';
import { getTransObject } from '../misc/utils';
import PopoutWindowPortal from './PopoutWindowPortal';
import StationVideoView from './StationVideoView';
import { connect } from 'react-redux';
import { _updateSettings } from '../actions/dataActions';
import { removeStationVideoViewInstance } from '../actions/viewActions';
import '../css/videoplayer.css';

const INSTANCE_NAME = 'StationVideoPopoutBtn_Component';

/**
 * Beispiel wie das funktionieren könnte:
 * https://codepen.io/iamrewt/pen/WYbPWN
 */
type Props = {
	settings: Object,
	_updateSettings: (Object, string, ?boolean, ?boolean) => mixed,
};

type State = {
	settings: Object,
};

/**
 * React Komponente, die den Knopf fuer das Popup zeigt und bei Klick das neue Fenster mit dem Video oeffnet
 * @export
 * @class StationVideoPopoutBtn
 */
class StationVideoPopoutBtn extends Component<Props, State> {
	constructor(props: Object) {
		super(props);

		this.state = {
			showWindowPortalPopOut: false,
			originSettings: this.props.settings,
		};

		this.showPopOutVideo = this.showPopOutVideo.bind(this);
		this.closeWindowPortal = this.closeWindowPortal.bind(this);
	}

	// This sets the above state variable
	setPopoutOpen(open: boolean) {
		this.setState({
			showWindowPortalPopOut: open,
		});
	}

	componentDidMount() {
		window.addEventListener('beforeunload', () => {
			this.closeWindowPortal();
		});
	}

	/**
	 * Shall render the popout Video in new Window and resize original Window left side to full width, remembers old setting in originSettings
	 * @returns void
	 */
	showPopOutVideo(): void {
		const { settings, _updateSettings } = this.props;

		_updateSettings({ ...settings, layout: 'popout' }, false, true);
		this.setPopoutOpen(true);
	}

	/**
	 * Shall close the popout Video and resize original Window back to old settings from originSettings
	 */
	closeWindowPortal() {
		const { _updateSettings, removeStationVideoViewInstance } = this.props;
		const { settings, originSettings } = this.state;

		removeStationVideoViewInstance(INSTANCE_NAME);
		_updateSettings({ ...settings, layout: originSettings.layout }, false, true);
		this.setPopoutOpen(false);
	}

	/**
	 * Rendert den Button fuer das PopOut Fenster
	 * @return {JSX} JSX Markup
	 */
	render() {
		return (
			<>
				{this.state.showWindowPortalPopOut && (
					<PopoutWindowPortal closeWindowPortal={this.closeWindowPortal} title='Film'>
						<StationVideoView showStationVideoPopoutBtn={false} instance={INSTANCE_NAME} />
					</PopoutWindowPortal>
				)}
				<ButtonGroup>
					<OverlayTrigger rootClose placement='top' overlay={<Tooltip id='tooltipVideoPopOut'>{getTransObject('video.popout', 'Neues Fenster', 'span')}</Tooltip>}>
						<Button type='button' className='video-control-button' onClick={this.showPopOutVideo}>
							{<FontAwesomeIcon className='video-control-icon' icon={faExternalLinkSquareAlt}></FontAwesomeIcon>}
						</Button>
					</OverlayTrigger>
				</ButtonGroup>
			</>
		);
	}
}

/**
 *  Verbindet die Klasse mit dem Redux Store
 */
const StationVideoPopoutConnect = connect(
	(state, ownProps) => {
		return {
			settings: state.settings,
		};
	},
	{
		_updateSettings,
		removeStationVideoViewInstance,
	}
)(StationVideoPopoutBtn);

export default StationVideoPopoutConnect;
