// @flow
import ReactDOM from 'react-dom';
import React from 'react';
import '../css/videoplayerpopout.css';

type Props = {
	settings: Object,
	_updateSettings: (Object, string, ?boolean, ?boolean) => mixed,
};

type State = {
	settings: Object,
};

/**
 * React Komponente, die den Knopf fuer das Popup zeigt und bei Klick das neue Fenster mit dem Video oeffnet
 * @export
 * @class PopoutWindowPortal
 */
export default class PopoutWindowPortal extends React.PureComponent<Props, State> {
	constructor(props) {
		super(props);

		this.state = {
			externalWindow: null,
			containerElement: null,
		};
	}

	componentDidMount() {
		const windowHeight = window.innerHeight;
		const windowWidth = window.innerWidth;
		const windowDimension = 'width=' + windowWidth + ',height=' + windowHeight;
		const windowPosition = 'left=0,top=0';
		const externalWindow = window.open('', '', windowDimension + ',' + windowPosition);

		let containerElement = null;
		if (externalWindow) {
			containerElement = externalWindow.document.createElement('div');
			containerElement.setAttribute('id', 'POPOUTPORTAL');
			externalWindow.document.body.appendChild(containerElement);

			// Copy the app's styles into the new window
			const stylesheets = Array.from(document.styleSheets);
			stylesheets.forEach((stylesheet) => {
				if (stylesheet.cssRules) {
					// true for inline styles
					const newStyleEl = externalWindow.document.createElement('style');

					Array.from(stylesheet.cssRules).forEach((cssRule) => {
						newStyleEl.appendChild(externalWindow.document.createTextNode(cssRule.cssText));
					});

					externalWindow.document.head.appendChild(newStyleEl);
				} else if (stylesheet.href) {
					// true for stylesheets loaded from a URL
					const newLinkEl = externalWindow.document.createElement('link');

					newLinkEl.rel = 'stylesheet';
					newLinkEl.href = stylesheet.href;
					externalWindow.document.head.appendChild(newLinkEl);
				}
			});

            externalWindow.document.head.insertAdjacentHTML("beforeend", `<style>body{padding-top: 0 !important}</style>`);

			externalWindow.document.title = this.props.title;
			// Make sure the window closes when the component unloads
			externalWindow.addEventListener('beforeunload', () => {
				this.props.closeWindowPortal();
			});
		}

		this.setState({
			externalWindow: externalWindow,
			containerElement: containerElement,
		});
	}

	componentWillUnmount() {
		if (this.state.externalWindow) {
			this.state.externalWindow.close();
		}
	}

	render() {
		if (!this.state.containerElement) {
			return null;
		}

		// Render this component's children into the root element of the popout window
		return ReactDOM.createPortal(this.props.children, this.state.containerElement);
	}
}
