// @flow
import CanalObjects from "./CanalObjects";

type ClassificationOptions = {
    name: string,
    version: string,
    inspection: CanalObjects
}

/**
 * Klasse fuer die Erstellung einer Klassifizierung
 *
 * @export
 * @class Classification
 */
export default class Classification {
    _options: ClassificationOptions

    /**
     * Creates an instance of Classification.
     * @param {ClassificationOptions} options Die Einstellungen fuer die Klassifizierung
     * @memberof Classification
     */
    constructor(options: ClassificationOptions) {
        this._options = options;
    }

    get name():string {
        return this._options.name;
    }

    get identifiers():array {
        return this._options.identifiers;
    }

    get version():string {
        return this._options.version;
    }

    get inspection():CanalObjects {
        return this._options.inspection;
    }
}