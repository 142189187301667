// @flow
import React from 'react';
import {SortableElement} from 'react-sortable-hoc';
import {getTransObject} from '../../misc/utils';

import '../../css/objectlist.css';

type SortableType = {
    value: Object,
    mode: string
}

/**
 * HOC fuer SortableElement
 * Erstellt ein sortierbaren Listeneintrag
 * @param {object} value Der Value fuer das Element
 * @param {string} mode Der Modus des Elements
 * @type {SortableElement}
 */
const ObjectListSortableItem = SortableElement(({value, mode}: SortableType) => {
    const header: React$Element<any> | () => mixed = value.Header();        
    const type : ?Array<?React$Element<any>> = value.getTypesAsImage();    

    return (
        <li className="list-group-item sortableHelper larger-list">
            {header}
            {mode === 'text' ?
                <span key="99" className="highlight-settings-type"> 
                    ({getTransObject("common.T", "T", "span")})
                </span> : null
            }
            {mode === 'code' ?
                <span key="99" className="highlight-settings-type"> 
                    ({getTransObject("common.K", "K", "span")})
                </span> : null
            }
            <span className="objectlist-right">
                {type}
            </span>
        </li>
    );
});

export default ObjectListSortableItem
