// @flow
import React, {Fragment} from 'react';
import {Col} from 'react-bootstrap';
import FormHelper from "./FormHelper";
import CardHelper from "./CardHelper";
import ValidationArray from "./ValidationArray";
import { isEmpty } from "../../misc/validationHelper";
import type {ValueElement} from './FormHelper';

type CardCardDefaults = {
    id: string,
    items: Array<CardHelper>
}

const def: CardCardDefaults = {
    id: '',
    items: []
}

/**
 * Hilfklasse fuer Formtemplates, um Card im Card zu erzeugen
 *
 * @export
 * @class CardCardHelper
 * @extends {FormHelper}
 */
export default class CardCardHelper extends FormHelper {
    _options: CardCardDefaults;

    /**
     * Creates an instance of CardCardHelper.
     * @param {CardCardDefaults} options Die Einstellungen der Klasse
     * @memberof CardCardHelper
     */
    constructor(options: CardCardDefaults) {
        super();

        this._options = {...def, ...options};
    }

    get id():string {
        return this._options.id;
    }

    get items():Array<CardHelper> {
        return this._options.items;
    }

    /**
     * Prueft ob die Einstellungen der Klasse korrekt sind
     *
     * @returns {ValidationArray}
     * @memberof CardCardHelper
     */
    validate():ValidationArray {
        const messages = new ValidationArray(this.id);

        if (isEmpty(this.id)) {
            messages.error.push('Id ist nicht gesetzt!');
        }

        if (this.items.length === 0) {
            messages.warn.push('Dieses Card hat keine Items!');
        }

        return messages;
    }

    /**
     * Baut die Card im Card
     *
     * @param {Object} object Das Kanalobjekt
     * @returns {?ValueElement}
     * @memberof CardCardHelper
     */
    build(object: Object):?ValueElement {
        const size: number = parseInt(12 / this.items.length, 10);

        const children = this.items.map((item, index) => {
            const result = item.build(object);
            if (result) {
                return (
                    <Col
                        xs={12}
                        sm={12}
                        md={12}
                        lg={size}
                        key={this.id + index}
                        className="no-padding"
                    >
                        {result.element}
                    </Col>
                )
            }
            return null;
        });

        return {
            valueSet: true,
            element:
                <Fragment key={this.id}>
                    {children}
                </Fragment>
        }
    }
}