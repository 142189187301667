// @flow
//state objects
import { DEFAULT_SETTINGS, DEFAULT_SESSION, OBJECT_LEVEL_VIEWS, STATION_VIEW_ONLY_PHOTO, PROJECT_LEVEL_VIEWS, SWITCH_PROJECT_VIEWS, OBJECT_OPENED_VIEWS, FILM_TAB_OPENED_VIEWS } from '../misc/const';
import {
	SEEK_STATION,
	ADD_STATION_VIDEO_VIEW_INSTANCE,
	REMOVE_STATION_VIDEO_VIEW_INSTANCE,
	UPDATE_STATION_VIDEO_VIEW_INSTANCE,
	ADD_PANO_VIEW_INSTANCE,
	UPDATE_PANO_VIEW_INSTANCE,
    REMOVE_PANO_VIEW_INSTANCE,
	SET_SESSION_PARAMETER,
	SET_REPORT_SESSION,
	SWITCH_PROJECT,
	OBJECT_OPENED,
	OBJECT_SELECTED,
	STATION_SELECTED,
	NEXT_STATION_IMAGE,
	PROJECTS_RECEIVED,
	OBJECTS_RECEIVED,
	STATIONS_RECEIVED,
	ORIGINAL_SELECTED,
    PANO_FILE_URL_RECEIVED,
    PROJECTS_UPDATED
} from '../actions/utils/actionTypes';

import templateEngine from '../templates/TemplateEngine';
import { checkObjectListColumns, changeToViewTab } from '../misc/utils';
import { Action } from '../misc/flowTypes';

import CanalInterface from '../templates/helper/CanalInterface';
import { isEmpty } from '../misc/validationHelper';
import _ from 'lodash';

/**
 * Setzt den Status fuer die Action PROJECTS_RECEIVED
 * @param {Object} state Der aktuelle Status
 * @param {Array} projects Die neuen Projekte
 * @return {Object} Der neue Status
 */
function projectsReceived(state: Object, projects: Array<Object>): Object {
	let currentProject: ?Object = null;
	let currentInterface: ?CanalInterface = null;
	let currentClassification: ?Object = null;
	let currentNetPlane: ?Object = null;
	let settings: ?Object = null;
	let originSettings: ?Object = null;

	if (state.hasOwnProperty('settings')) {
		settings = state.settings;
	} else {
		settings = DEFAULT_SETTINGS;
	}

	if (state.hasOwnProperty('originSettings')) {
		originSettings = state.originSettings;
	} else {
		originSettings = DEFAULT_SETTINGS;
	}

	if (projects && state.currentProject == null) {
		state.projects = projects;

		if (projects.length > 0) {
			currentProject = state.projects[0];
			currentInterface = templateEngine.getInterface(currentProject.interface);
			currentClassification = currentProject.classification;
			state.reportSession.objectId = currentProject._id;
		}
	} else {
		return state;
	}

	if (currentProject) {
		currentNetPlane = checkNetPlane(currentProject);
	}

	return Object.assign({}, state, {
		settings,
		templateEngine,
		currentProject,
		currentNetPlane,
		currentInterface,
		currentClassification,
		originSettings,
	});
}

/**
 * Setzt den state fuer die Action PROJECTS_RECEIVED
 * @param {Object} state the actual state
 * @param {Array} projects the new project
 * @return {Object} the new state
 */
function projectsUpdated(state: Object, newProjects: Array<Object>): Object {

    // compare old wit new projects and mark new projects
    const newProjectIDs = _.differenceBy(newProjects, state.projects, '_id').map(p => p._id);
    if(newProjectIDs.length > 0) {
        newProjects = newProjects.map(p => {  return {...p, newItem: newProjectIDs.includes(p._id) }});
    }
    // change structure of projects
    const projects = newProjects.map(project => {
        project.objects = [];
        for (let field in project.fields) {
            project[field] = project.fields[field];
        }
        return project;
    })

    return { ...state, projects };
}

/**
 * Setzt den Status fuer neue Objekte
 * @param {Object} state Der aktuelle Status
 * @param {Object} project Das Projekt, welches neue Objekte bekommt
 * @param {Array} objects Die neuen Objekte
 * @return {Object} Der neue Status
 */
function objectsReceived(state: Object, project: Object, objects: Array<Object>): Object {
	state.projects.forEach((p: Object) => {
		if (p._id === project._id) {
			p.objects = objects;
		}
	});

	const currentProject = { ...state.currentProject };

	return Object.assign({}, state, { currentProject });
}

/**
 * Setzt den Status fuer neue Stationen
 * @param {Object} state Der aktuelle Status
 * @param {Object} object Das Objekt zu dem die Stationen gehoeren
 * @param {Array} stations Die neuen Stationen
 * @return {Object} Der neue Status
 */
function stationsReceived(state: Object, object: Object, stations: Array<Object>): Object {
	state.projects.forEach((p: Object) => {
		if (p._id === object.projectId) {
			p.objects.forEach((o: Object) => {
				if (o._id === object._id) {
					o.stations = stations;
				}
			});
		}
	});

	const currentProject = { ...state.currentProject };

	return Object.assign({}, state, { currentProject });
}

/**
 * Prueft ob fuer das ausgewaehlte Projekt ein Netzplan definiert ist
 * @param  {object} project   Aktuelles Projekt als Objekt
 * @return {Object}           Pfad zum Json Netzplan
 */
function checkNetPlane(project: Object): ?Object {
	let netPlane: ?Object = null;

	if (project.hasOwnProperty('netplane')) {
		netPlane = project.netplane;
	}

	return netPlane;
}

/**
 * Setzt die Report Session zurueck
 *
 * @param {Object} project Projekt zu dem die Session gehoert
 * @returns {Object} Die neue Session
 */
function refreshReportSession(project: Object): Object {
	const reportSession = {};
	reportSession.objectId = project._id;
	reportSession.values = {};

	return reportSession;
}

/**
 * Fuehrt die Action SWITCH_PROJECT aus. Setzt das neue Projekt als currentProject
 * @param  {object} state   Aktueller Status
 * @param  {object} project Id des Projekts, das als currentProject gesetzt werden soll
 * @return {object}         Neuer Status mit dem alten gemerged
 */
function switchProject(state: Object, project: Object): Object {
	let currentProject: Object = state.currentProject;
	let currentNetPlane: ?Object = state.currentNetPlane;
	let currentTab: string = state.mainViewTab;
	let reportSession = state.reportSession;
	currentTab = changeToViewTab(PROJECT_LEVEL_VIEWS.MAIN.PROJECT_MAIN_VIEW, currentTab, (current) => !PROJECT_LEVEL_VIEWS.MAIN.hasOwnProperty(current));

	if (state.hasOwnProperty('projects') && project != null) {
		let newProject: ?Object = null;
		state.projects.forEach((p: Object) => {
			if (p._id === project._id) {
				newProject = p;
			}
		});

		if (newProject != null) {
			currentProject = newProject;

			reportSession = refreshReportSession(currentProject);
		}
	} else {
		return state;
	}

	const currentInterface: ?CanalInterface = templateEngine.getInterface(currentProject.interface);
	const currentClassification: Object = currentProject.classification;
	let settings: Object = state.settings;
	settings = checkObjectListColumns(settings, currentInterface, currentProject.interface);
	currentNetPlane = checkNetPlane(currentProject);

	currentTab = changeToViewTab(PROJECT_LEVEL_VIEWS.MAIN.PROJECT_MAIN_VIEW, currentTab, (current) => _.isEmpty(currentNetPlane));

	return Object.assign(
		{},
		state,
		{
			settings,
			currentProject,
			currentNetPlane,
			currentInterface,
			currentClassification,
			currentStation: null,
			currentObject: null,
			reportSession,
		},
		SWITCH_PROJECT_VIEWS,
		{
			mainViewTab: currentTab,
		}
	);
}

/**
 * Prueft ob das uebergebene Objekt teil des aktuellen Projekts ist und gibt es dann zurueck
 * @param  {object} state         Aktueller Status
 * @param  {object} openendObject Objekt, dass geoeffnet wurde
 * @return {object}               Wenn das Objekt teil des Projekts ist, ansonsten null
 */
function getObjectFromProject(state: Object, openendObject: Object): ?Object {
	let object = null;

	if (state.currentProject != null && openendObject != null) {
		const project: Object = state.currentProject;

		if (project.hasOwnProperty('objects') && project.objects.length > 0) {
			project.objects.forEach((o: Object) => {
				if (o._id === openendObject._id) {
					object = o;
				}
			});
		}
	}
	return object;
}

/**
 * Fuehrt die Action OBJECT_SELECTED aus. Setzt das currentObject mit dem selektierten Objekt
 * @param  {object} state          Aktueller Status
 * @param  {object} selectedObject Objekt, das selektiert wurde
 * @return {object}                Aktualisierter Status
 */
function objectSelected(state: Object, selectedObject: Object): Object {
	const currentObject: ?Object = getObjectFromProject(state, selectedObject);

	if (currentObject != null) {
		let currentStation: ?Object = state.currentStation;

		if (state.currentObject && currentObject._id !== state.currentObject._id) {
			currentStation = null;
		}

		return Object.assign({}, state, {
			currentObject,
			currentStation,
		});
	} else {
		return state;
	}
}

/**
 * Hilfsfunktion um die Session zu leeren, wenn das Objekt gewechselt wird
 *
 * @param {Object} currentObject Aktuelles Objekt
 * @param {Object} newObject Objekt, das ausgewaehlt wurde
 * @param {Object} session Die aktuelle Session
 * @returns Die neue Session
 */
function setSession(currentObject: Object, newObject: Object, session: Object) {
	if (currentObject && newObject && (currentObject._id !== newObject._id || session.objectId === '')) {
		session.values = { ...DEFAULT_SESSION.values };
		session.objectId = newObject._id;
	}
	return session;
}

/**
 * Prueft ob das Objekt einen Film hat
 *
 * @param {Object} object
 * @returns
 */
function hasVideo(object: Object) {
	let hasVideo: boolean = false;
	object.inspection.forEach((inspection) => {
		if (!isEmpty(inspection.videoRef)) {
			hasVideo = true;
		}
	});
	return hasVideo;
}

/**
 * Prueft ob das Objekt einen Panoramo Film hat
 *
 * @param {Object} object
 * @returns
 */
function hasPanoramo(object: Object) {
	let hasPanoramo: boolean = false;
	object.inspection.forEach((inspection) => {
		if (!isEmpty(inspection.panoramoRef)) {
			hasPanoramo = true;
		}
	});
	return hasPanoramo;
}

/**
 * Fuehrt die Action OBJECT_SELECTED aus. Setzt das currentObject mit dem selektierten Objekt und oeffnet die Ansichten
 * @param  {object} state          Aktueller Status
 * @param  {object} openendObject Objekt, das selektiert wurde
 * @param  {string} [targetView=null] Name des View, falls einer explizit geoeffnet werden soll
 * @return {object}                Aktualisierter Status
 */
function objectOpened(state: Object, openendObject: Object, targetView: ?string = null): Object {
	const newState: Object = objectSelected(state, openendObject);

	if (newState.currentObject != null) {
		let targetViews: Object = OBJECT_OPENED_VIEWS;
		const object: Object = newState.currentObject;
		let currentTab: string = state.mainViewTab;

		currentTab = changeToViewTab(OBJECT_LEVEL_VIEWS.MAIN.OBJECT_MAIN_VIEW, currentTab, (current) => !OBJECT_LEVEL_VIEWS.MAIN.hasOwnProperty(current));

		if (targetView) {
			currentTab = targetView;
		}

		currentTab = changeToViewTab(
			OBJECT_LEVEL_VIEWS.MAIN.OBJECT_MAIN_VIEW,
			currentTab,
			(current) => (current === OBJECT_LEVEL_VIEWS.MAIN.INSPECTION_MAIN_VIEW || current === OBJECT_LEVEL_VIEWS.MAIN.STATION_MAIN_VIEW) && object.inspection.length === 0
		);

		currentTab = changeToViewTab(OBJECT_LEVEL_VIEWS.MAIN.OBJECT_MAIN_VIEW, currentTab, (current) => current === OBJECT_LEVEL_VIEWS.MAIN.STATION_MAIN_VIEW && object.stations.length === 0);

		currentTab = changeToViewTab(OBJECT_LEVEL_VIEWS.MAIN.PANORAMO_MAIN_VIEW, currentTab, (current) => {
			if (current === OBJECT_LEVEL_VIEWS.MAIN.STATION_VIDEO_VIEW) {
				return hasPanoramo(object);
			}
			return false;
		});

		currentTab = changeToViewTab(OBJECT_LEVEL_VIEWS.MAIN.STATION_VIDEO_VIEW, currentTab, (current) => {
			if (current === OBJECT_LEVEL_VIEWS.MAIN.PANORAMO_MAIN_VIEW) {
				return hasVideo(object);
			}
			return false;
		});

		currentTab = changeToViewTab(OBJECT_LEVEL_VIEWS.MAIN.OBJECT_MAIN_VIEW, currentTab, (current) => {
			if (current === OBJECT_LEVEL_VIEWS.MAIN.STATION_VIDEO_VIEW) {
				return !hasVideo(object);
			} else if (current === OBJECT_LEVEL_VIEWS.MAIN.PANORAMO_MAIN_VIEW) {
				return !hasPanoramo(object);
			}
			return false;
		});

		if (currentTab === OBJECT_LEVEL_VIEWS.MAIN.STATION_VIDEO_VIEW || currentTab === OBJECT_LEVEL_VIEWS.MAIN.PANORAMO_MAIN_VIEW) {
			targetViews = FILM_TAB_OPENED_VIEWS;
		}

		const session = setSession(state.currentObject, newState.currentObject, newState.session);

		return Object.assign(
			{},
			newState,
			targetViews,
			{
				mainViewTab: currentTab,
			},
			{
				session,
			}
		);
	} else {
		return state;
	}
}

/**
 * Fuehrt die Action STATION_SELECTED aus. Setzt die gewaehlte Station, wenn diese nicht null ist
 * @param  {object} state   Aktueller Status
 * @param  {object} station Station, die gesetzt werden soll
 * @return {object}         Aktualisierter Status
 */
function stationSelected(state: Object, station: Object): Object {
	if (station) {
		return Object.assign({}, state, {
			currentStation: station,
		});
	}
	return state;
}

/**
 * Selektiert die naechste Station
 *
 * @param {Object} state Aktueller Status
 * @param {string} direction Richtung des Stationswechsel
 * @return {Object}    Aktualisierter Status
 */
function nextStationPicture(state: Object, direction: string): Object {
	if (direction && state.currentStationView === STATION_VIEW_ONLY_PHOTO) {
		const stations: Array<Object> = state.currentObject.stations;
		let index: number = 0;

		if (state.currentStation) {
			stations.forEach((s: Object, sIndex: number) => {
				if (s._id === state.currentStation._id) {
					index = sIndex;
				}
			});
		} else {
			index = 0;
		}

		index = direction === 'left' ? index - 1 : index + 1;
		return stationSelected(state, stations[index - 1]);
	}

	return state;
}

/**
 * Setzt den Status fuer die Station, damit der Videoplayer weiss das er zu dieser Station springen soll
 *
 * @param {Object} state Aktueller Status
 * @returns {Object} Aktualisierter Status
 */
function seekStation(state: Object): Object {
	const instances = state.stationVideoViewInstances || [];
	state.seekStation = true;

	if (instances.length > 0) {
		state.stationVideoViewInstances = instances.map((i) => {
			return { ...i, updateStationView: true };
		});
	}

	return { ...state };
}

function addStationVideoViewInstance(state: Object, instance): Object {
	const instances = state.stationVideoViewInstances || [];

	if (instances.length > 0) {
		const hasInstance = instances.find((i) => i.name === instance);
		if (!hasInstance) instances.push({ name: instance, updateStationView: false });
	} else {
		instances.push({ name: instance, updateStationView: false });
	}

	return { ...state, stationVideoViewInstances: instances };
}

function addPanoViewInstance(state: Object, instance): Object {
	// TODO Anpassung pruefen!!!
	const instances = state.panoViewInstances || [];

	if (instances.length > 0) {
		const hasInstance = instances.find((i) => i.name === instance);
		if (!hasInstance) instances.push({ name: instance, updatePanoView: false });
	} else {
		instances.push({ name: instance, updatePanoView: false });
	}

    return { ...state, panoViewInstances: instances };
}

function removeStationVideoViewInstance(state: Object, instance): Object {
	let instances = state.stationVideoViewInstances || [];

	if (instances.length > 0) {
		instances = instances.filter((i) => i.name !== instance);
	}

	return { ...state, stationVideoViewInstances: instances };
}

function updateStationVideoViewInstance(state: Object, instance, updateStationView): Object {
	let instances = state.stationVideoViewInstances || [];
	let hasUpdatedAllInstances = false;

	if (instances.length > 0) {
		instances = instances.map((i) => {
			if (i.name === instance) return { ...i, updateStationView: updateStationView };
			return i;
		});

		hasUpdatedAllInstances = !instances.some((i) => i.updateStationView === true);

		if (hasUpdatedAllInstances) state.seekStation = false;
	} else {
		state.seekStation = false;
	}

	return { ...state, stationVideoViewInstances: instances };
}

function updatePanoViewInstance(state: Object, instance, updatePanoView): Object {
	// TODO Anpassung pruefen!!!
	let instances = state.panoViewInstances || [];
	let hasUpdatedAllInstances = false;

	if (instances.length > 0) {
		instances = instances.map((i) => {
			if (i.name === instance) return { ...i, updatePanoView: updatePanoView };
			return i;
		});

		hasUpdatedAllInstances = !instances.some((i) => i.updatePanoView === true);

		if (hasUpdatedAllInstances) state.initialized = false;
	} else {
		state.initialized = false;
	}

	return { ...state, panoViewInstances: instances };
}

function removePanoramoViewInstance(state: Object, instance): Object {
	let instances = state.panoViewInstances || [];

	if (instances.length > 0) {
		instances = instances.filter((i) => i.name !== instance);
	}

	return { ...state, panoViewInstances: instances };
}


/**
 * Setzt den Wert in der Session
 *
 * @param {Object} state Aktueller Status mit der Session
 * @param {String} target Name des Parameters
 * @param {*} value Wert der in den Parameter geschrieben wird
 * @returns Den aktualisierten Status
 */
function setSessionParameter(state: Object, target: String, value: any): Object {
	const session = state.session;
	session.values[target] = value;
	state.session = { ...session };
	return state;
}

/**
 * Fuehrt die Action ORIGINAL_SELECTED aus. Setzt currentOriginal mit der OriginalId aus dem selektierten Objekt
 * @param  {object} state          Aktueller Status
 * @param  {object} selectedObject Objekt, das selektiert wurde
 * @return {object}                Aktualisierter Status
 */
function originalSelected(state: Object, selectedObject: Object): Object {
	const currentObject: ?Object = getObjectFromProject(state, selectedObject);

	if (currentObject != null) {
		return Object.assign({}, state, {
			currentOriginal: currentObject.originalId,
		});
	} else {
		return state;
	}
}

/**
 * Setzt in der Report Session die gegebenen Werte
 *
 * @param {Object} state  Aktueller Status
 * @param {Object} session Die neue Sessionwerte
 * @returns {Object} Aktualisierter Status
 */
function setReportSession(state: Object, session: Object): Object {
	state.reportSession.values = session;

	return { ...state };
}

function setPanoUrl(state: Object, url: String, inspectionId: String): Object {
    let inspectionKey = null;
    state.currentObject.inspection.forEach(function(i,key) {
        if(i._id === inspectionId) {
            inspectionKey = key;
        }
    });
    state.currentObject.inspection[inspectionKey].panoFileUrl = url;
    return { ...state }
}

/**
 * Funktion, die vom Redux aufgerufen wird, wenn actions dispatched werden
 * @param  {object} state Aktueller Status
 * @param  {object} action                Inhalt der Action mit den Werten
 * @return {object}                       Neuer Status mit dem alten gemerged
 */
// eslint-disable-next-line import/no-anonymous-default-export
export default function (state: Object, action: Action): Object {
	switch (action.type) {
		case PROJECTS_RECEIVED:
            return projectsReceived(state, action.projects);
        case PROJECTS_UPDATED:
            return projectsUpdated(state, action.projects);
		case OBJECTS_RECEIVED:
			return objectsReceived(state, action.project, action.canalObjects);
		case STATIONS_RECEIVED:
			return stationsReceived(state, action.object, action.stations);
		case SWITCH_PROJECT:
			return switchProject(state, action.project);
		case OBJECT_OPENED:
			return objectOpened(state, action.object, action.targetView);
		case OBJECT_SELECTED:
			return objectSelected(state, action.object);
		case STATION_SELECTED:
			return stationSelected(state, action.station);
		case NEXT_STATION_IMAGE:
			return nextStationPicture(state, action.direction);
		case SEEK_STATION:
			return seekStation(state);
		case ADD_STATION_VIDEO_VIEW_INSTANCE:
			return addStationVideoViewInstance(state, action.instance);
		case REMOVE_STATION_VIDEO_VIEW_INSTANCE:
			return removeStationVideoViewInstance(state, action.instance);
		case UPDATE_STATION_VIDEO_VIEW_INSTANCE:
			return updateStationVideoViewInstance(state, action.instance, action.updateStationView);
		case ADD_PANO_VIEW_INSTANCE:
			return addPanoViewInstance(state, action.instance);
		case UPDATE_PANO_VIEW_INSTANCE:
			return updatePanoViewInstance(state, action.instance, action.updatePanoView);
        case REMOVE_PANO_VIEW_INSTANCE:
            return removePanoramoViewInstance(state, action.instance);
		case SET_SESSION_PARAMETER:
			return setSessionParameter(state, action.target, action.value);
		case ORIGINAL_SELECTED:
			return originalSelected(state, action.object);
		case SET_REPORT_SESSION:
			return setReportSession(state, action.session);
        case PANO_FILE_URL_RECEIVED:
            return setPanoUrl(state, action.url, action.inspectionId);
		default:
			return state;
	}
}
