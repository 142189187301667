import CardHelper from '../../../form/helper/CardHelper';
import FieldHelper from '../../../form/helper/FieldHelper';
import ComplexTextBoxHelper from '../../../form/helper/ComplexTextBoxHelper';
import CheckboxHelper from '../../../form/helper/CheckboxHelper';
import CardCardHelper from '../../../form/helper/CardCardHelper';

const canalObjectLeteralArray = [
    new CardHelper({
        id: 'general',
        title: {
            key: 'common.general',
            fallback: 'Allgemein'
        },
        items: [
            new FieldHelper({
                id: 'lateralName',
                title: {
                    key: 'object.lateralName',
                    fallback: 'Leitungsname'
                },
                accessor: 'Objektbezeichnung'
            }),
            new FieldHelper({
                id: 'constructionYear',
                title: {
                    key: 'object.constructionYear',
                    fallback: 'Baujahr'
                },
                accessor: 'Baujahr'
            }),
            new FieldHelper({
                id: 'lateralLength',
                title: {
                    key: 'object.lateralLength',
                    fallback: 'Leitungslänge'
                },
                accessor: 'Laenge'
            }),
            new FieldHelper({
                id: 'material',
                title: {
                    key: 'object.material',
                    fallback: 'Material'
                },
                accessor: 'Material',
                field: new ComplexTextBoxHelper()
            }),
            new FieldHelper({
                id: 'sewerSystem',
                title: {
                    key: 'isybauxml.object.common.sewerSystem',
                    fallback: 'Kanalsystem'
                },
                accessor: 'Entwaesserungsart',
                field: new ComplexTextBoxHelper()
            }),
            new FieldHelper({
                id: 'status',
                title: {
                    key: 'isybauxml.object.common.status',
                    fallback: 'Status'
                },
                accessor: 'Status',
                field: new ComplexTextBoxHelper()
            }),
            new FieldHelper({
                id: 'comment',
                title: {
                    key: 'common.comment',
                    fallback: 'Kommentar'
                },
                accessor: 'Kommentar',
                columnSize: {
                    lg: 12,
                    md: 12
                },
                size: {
                    lg: 2,
                    md: 2
                }
            })
        ]
    }),
    new CardHelper({
        id: 'position',
        title: {
            key: 'isybauxml.object.common.layer',
            fallback: 'Lage'
        },
        items: [
            new FieldHelper({
                id: 'district',
                title: {
                    key: 'common.district',
                    fallback: 'Ortsteil'
                },
                accessor: 'Ortsteil',
                field: new ComplexTextBoxHelper()
            }),
            new FieldHelper({
                id: 'terrainType',
                title: {
                    key: 'common.terrainType',
                    fallback: 'Lage im Verkehr'
                },
                accessor: 'LageOberflaeche',
                field: new ComplexTextBoxHelper()
            }),
            new FieldHelper({
                id: 'street',
                title: {
                    key: 'common.street',
                    fallback: 'Straße'
                },
                accessor: 'Strassenname',
                field: new ComplexTextBoxHelper()
            }),
            new FieldHelper({
                id: 'floodingArea',
                title: {
                    key: 'object.floodingArea',
                    fallback: 'Überschw.-Gebiet'
                },
                accessor: 'Ueberschwemmungsgebiet',
                field: new CheckboxHelper()
            }),
            new FieldHelper({
                id: 'comment',
                title: {
                    key: 'common.comment',
                    fallback: 'Kommentar'
                },
                accessor: 'KommentarLage',
                columnSize: {
                    lg: 12,
                    md: 12
                },
                size: {
                    lg: 2,
                    md: 2
                }
            })
        ]
    }),
    new CardHelper({
        id: 'connectionpoint',
        items: [
            new CardCardHelper({
                id: 'connectionpointCard',
                items: [
                    new CardHelper({
                        id: 'upperConnectionPoint',
                        title: {
                            key: 'object.upperConnectionPoint',
                            fallback: 'Oberer Anschlusspunkt'
                        },
                        items: [
                            new FieldHelper({
                                id: 'name',
                                title: {
                                    key: 'object.object',
                                    fallback: 'Bezugsobjekt FB'
                                },
                                accessor: 'ObererAnschlusspunkt.fields.NameAnschlusspunkt',
                                columnSize: {
                                    lg: 12,
                                    md: 12
                                },
                                size: {
                                    lg: 3,
                                    md: 3,
                                }
                            }),
                            new FieldHelper({
                                id: 'pointCode',
                                title: {
                                    key: 'object.fields.pointCode.code',
                                    fallback: 'Punktkennung'
                                },
                                accessor: 'ObererAnschlusspunkt.fields.Punktkennung_Oben.code',
                                columnSize: {
                                    lg: 5,
                                    md: 5,
                                    sm: 6,
                                    xs: 6
                                },
                                size: {
                                    lg: 7,
                                    md: 7,
                                    sm: 8,
                                    xs: 8
                                },
                                fieldCssClass: 'correct-padding',
                                field: new ComplexTextBoxHelper()
                            }),
                            new FieldHelper({
                                id: 'nameConnectionPoint',
                                accessor: 'ObererAnschlusspunkt.fields.NameAnschlusspunkt',
                                columnSize: {
                                    lg: 7,
                                    md: 7,
                                    sm: 6,
                                    xs: 6
                                },
                                size: {
                                    lg: 0,
                                    md: 0,
                                    sm: 0,
                                    xs: 0
                                }
                            }),
                            new FieldHelper({
                                id: 'upperReferencePoint',
                                title: {
                                    key: 'isybauxml.object.common.referencePoint',
                                    fallback: 'Übergabepunkt'
                                },
                                accessor: 'ObererAnschlusspunkt.fields.Uebergabepunkt',
                                columnSize: {
                                    lg: 12,
                                    md: 12
                                },
                                size: {
                                    lg: 3,
                                    md: 3
                                },
                                field: new CheckboxHelper()
                            }),
                            new FieldHelper({
                                id: 'upperDrainage',
                                title: {
                                    key: 'object.Drainage',
                                    fallback: 'Drainage'
                                },
                                accessor: 'ObererAnschlusspunkt.fields.DrainageAngeschlossen',
                                columnSize: {
                                    lg: 12,
                                    md: 12
                                },
                                size: {
                                    lg: 3,
                                    md: 3
                                },
                                field: new CheckboxHelper()
                            }),
                            new FieldHelper({
                                id: 'material',
                                title: {
                                    key: 'object.material',
                                    fallback: 'Material FB'
                                },
                                accessor: 'ObererAnschlusspunkt.fields.MaterialAnschlussdaten',
                                columnSize: {
                                    lg: 12,
                                    md: 12
                                },
                                size: {
                                    lg: 3,
                                    md: 3
                                },
                                field: new ComplexTextBoxHelper()
                            }),
                            new FieldHelper({
                                id: 'upperInvertLevel',
                                title: {
                                    key: 'object.invertLevel',
                                    fallback: 'Sohlhöhe'
                                },
                                accessor: 'ObererAnschlusspunkt.fields.Sohlhoehe',
                                columnSize: {
                                    lg: 12,
                                    md: 12
                                },
                                size: {
                                    lg: 3,
                                    md: 3
                                }
                            }),
                            new FieldHelper({
                                id: 'upperDistancePipe',
                                title: {
                                    key: 'object.distancePipe',
                                    fallback: 'Abstand Rohranfang zur Schachtmitte'
                                },
                                accessor: 'ObererAnschlusspunkt.fields.DistanceManholeCenter',
                                columnSize: {
                                    lg: 12,
                                    md: 12
                                },
                                size: {
                                    lg: 5,
                                    md: 5
                                }
                            })
                        ]
                    }),
                    new CardHelper({
                        id: 'lowerConnectionPoint',
                        title: {
                            key: 'object.lowerConnectionPoint',
                            fallback: 'Unterer Anschlusspunkt'
                        },
                        items: [
                            new FieldHelper({
                                id: 'name',
                                title: {
                                    key: 'object.object',
                                    fallback: 'Bezugsobjekt'
                                },
                                accessor: 'UntererAnschlusspunkt.fields.Objektbezeichner',
                                columnSize: {
                                    lg: 12,
                                    md: 12
                                },
                                size: {
                                    lg: 3,
                                    md: 3,
                                }
                            }),
                            new FieldHelper({
                                id: 'pointCode',
                                title: {
                                    key: 'object.pointCode',
                                    fallback: 'Punktkennung'
                                },
                                accessor: 'UntererAnschlusspunkt.fields.Punktkennung_Unten.code',
                                columnSize: {
                                    lg: 5,
                                    md: 5,
                                    sm: 6,
                                    xs: 6
                                },
                                size: {
                                    lg: 7,
                                    md: 7,
                                    sm: 8,
                                    xs: 8
                                },
                                fieldCssClass: 'correct-padding',
                                field: new ComplexTextBoxHelper()
                            }),
                            new FieldHelper({
                                id: 'nameConnectionPoint',
                                accessor: 'UntererAnschlusspunkt.fields.NameAnschlusspunkt',
                                columnSize: {
                                    lg: 7,
                                    md: 7,
                                    sm: 6,
                                    xs: 6
                                },
                                size: {
                                    lg: 0,
                                    md: 0,
                                    sm: 0,
                                    xs: 0
                                }
                            }),
                            new FieldHelper({
                                id: 'lowerReferencePoint',
                                title: {
                                    key: 'isybauxml.object.common.referencePoint',
                                    fallback: 'Übergabepunkt'
                                },
                                accessor: 'UntererAnschlusspunkt.fields.Uebergabepunkt',
                                columnSize: {
                                    lg: 12,
                                    md: 12
                                },
                                size: {
                                    lg: 3,
                                    md: 3
                                },
                                field: new CheckboxHelper()
                            }),
                            new FieldHelper({
                                id: 'lowerDrainage',
                                title: {
                                    key: 'object.Drainage',
                                    fallback: 'Drainage'
                                },
                                accessor: 'UntererAnschlusspunkt.fields.DrainageAngeschlossen',
                                columnSize: {
                                    lg: 12,
                                    md: 12
                                },
                                size: {
                                    lg: 3,
                                    md: 3
                                },
                                field: new CheckboxHelper()
                            }),
                            new FieldHelper({
                                id: 'material',
                                title: {
                                    key: 'object.material',
                                    fallback: 'Material FB'
                                },
                                accessor: 'UntererAnschlusspunkt.fields.MaterialAnschlussdaten',
                                columnSize: {
                                    lg: 12,
                                    md: 12
                                },
                                size: {
                                    lg: 3,
                                    md: 3
                                },
                                field: new ComplexTextBoxHelper()
                            }),
                            new FieldHelper({
                                id: 'lowerInvertLevel',
                                title: {
                                    key: 'object.invertLevel',
                                    fallback: 'Sohlhöhe'
                                },
                                accessor: 'UntererAnschlusspunkt.fields.Sohlhoehe',
                                columnSize: {
                                    lg: 12,
                                    md: 12
                                },
                                size: {
                                    lg: 3,
                                    md: 3
                                }
                            }),
                            new FieldHelper({
                                id: 'lowerDistancePipe',
                                title: {
                                    key: 'object.distancePipe',
                                    fallback: 'Abstand Rohranfang zur Schachtmitte'
                                },
                                accessor: 'UntererAnschlusspunkt.fields.DistanceManholeCenter',
                                columnSize: {
                                    lg: 12,
                                    md: 12
                                },
                                size: {
                                    lg: 5,
                                    md: 5
                                }
                            })
                        ]
                    }),
                ]
            })
        ]
    }),
    new CardHelper({
        id: 'connectionmaincanal',
        title: {
            key: 'object.connectionmaincanal',
            fallback: 'Anschluss im Hauptkanal'
        },
        items: [
            new FieldHelper({
                id: 'conname',
                title: {
                    key: 'common.name',
                    fallback: 'Name'
                },
                accessor: 'ConnectionMainCanal.name',
                columnSize: {
                    lg: 12,
                    md: 12
                },
                size: {
                    lg: 2,
                    md: 2
                }
            })
        ]
    }),
    new CardHelper({
        id: 'geometry',
        title: {
            key: 'common.geometry',
            fallback: 'Geometrie'
        },
        items: [
            new FieldHelper({
                id: 'profileType',
                title: {
                    key: 'object.profileType',
                    fallback: 'Profilart'
                },
                accessor: 'Profilart',
                field: new ComplexTextBoxHelper()
            }),
            new FieldHelper({
                id: 'profileHeight',
                title: {
                    key: 'object.profileHeight',
                    fallback: 'Profilhöhe'
                },
                accessor: 'Profilhoehe'
            }),
            new FieldHelper({
                id: 'sdr',
                title: {
                    key: 'isybauxml.object.common.sdr',
                    fallback: 'SDR'
                },
                accessor: 'SDR'
            }),
            new FieldHelper({
                id: 'profileWidth',
                title: {
                    key: 'object.profileWidth',
                    fallback: 'Profilbreite'
                },
                accessor: 'Profilbreite'
            }),
            new FieldHelper({
                id: 'specialProfile',
                title: {
                    key: 'isybauxml.object.common.specialProfile',
                    fallback: 'Sonderprofil vorhanden'
                },
                accessor: 'SonderprofilVorhanden',
                field: new CheckboxHelper()
            }),
            new FieldHelper({
                id: 'außendurchmesser',
                title: {
                    key: 'isybauxml.object.common.outerDiameter',
                    fallback: 'Außendurchmesser'
                },
                accessor: 'Aussendurchmesser'
            }),
            new FieldHelper({
                id: 'dimensionOrigin',
                title: {
                    key: 'isybauxml.object.common.dimensionOrigin',
                    fallback: 'Herkunft der Profilmaße'
                },
                accessor: 'HerkunftProfilmasse',
                field: new ComplexTextBoxHelper()
            }),
            new FieldHelper({
                id: 'profileID',
                title: {
                    key: 'isybauxml.object.common.profileID',
                    fallback: 'ProfilID'
                },
                accessor: 'ProfilID'
            })
        ]
    }),
    new CardHelper({
        id: 'envparams',
        title: {
            key: 'object.environmentalParameters',
            fallback: 'Umweltparameter'
        },
        items: [
            new FieldHelper({
                id: 'wastewaterType',
                title: {
                    key: 'isybauxml.object.common.wastewaterType',
                    fallback: 'Abwasserart'
                },
                accessor: 'Abwasserart',
                field: new ComplexTextBoxHelper()
            }),
            new FieldHelper({
                id: 'waterConservationArea',
                title: {
                    key: 'object.waterConservationArea',
                    fallback: 'Wasserschutzzone'
                },
                accessor: 'Wasserschutzzone',
                field: new ComplexTextBoxHelper()
            }),
            new FieldHelper({
                id: 'sewageSubstances',
                title: {
                    key: 'isybauxml.object.common.sewageSubstances',
                    fallback: 'AbwasserartWGS'
                },
                accessor: 'AbwasserartWGS',
                field: new ComplexTextBoxHelper()
            }),
            new FieldHelper({
                id: 'soilType',
                title: {
                    key: 'isybauxml.object.common.soilType',
                    fallback: 'Bodenart'
                },
                accessor: 'Bodenart',
                field: new ComplexTextBoxHelper()
            }),
            new FieldHelper({
                id: 'groundwaterDistance',
                title: {
                    key: 'object.groundwaterDistance',
                    fallback: 'Grundwasserabstand'
                },
                accessor: 'GWabstand',
                field: new ComplexTextBoxHelper()
            })
        ]
    }),
    new CardHelper({
        id: 'sanitation',
        onlyWithValues: true,
        title: {
            key: 'isybauxml.object.common.rehabilitation',
            fallback: 'Sanierung'
        },
        items: [
            new FieldHelper({
                id: 'interiorProtection',
                title: {
                    key: 'object.interiorProtection',
                    fallback: 'Innenschutz'
                },
                accessor: 'Innenschutz',
                field: new ComplexTextBoxHelper()
            }),
            new FieldHelper({
                id: 'coating',
                title: {
                    key: 'object.coating',
                    fallback: 'Auskleidung'
                },
                accessor: 'Auskleidung',
                field: new ComplexTextBoxHelper()
            }),
            new FieldHelper({
                id: 'typeCoating',
                title: {
                    key: 'isybauxml.object.common.typeCoating',
                    fallback: 'Art der Auskleidung'
                },
                accessor: 'ArtAuskleidung',
                field: new ComplexTextBoxHelper()
            }),
            new FieldHelper({
                id: 'coatMaterial',
                title: {
                    key: 'isybauxml.object.common.coatMaterial',
                    fallback: 'Auskl. Material'
                },
                accessor: 'MaterialAuskleidung',
                field: new ComplexTextBoxHelper()
            })
        ]
    }),
    new CardHelper({
        id: 'misc',
        title: {
            key: 'common.other',
            fallback: 'Sonstiges'
        },
        items: [
            new FieldHelper({
                id: 'oldLateralName',
                title: {
                    key: 'isybauxml.object.lateral.other.oldLateralName',
                    fallback: 'Alter Leitungsname'
                },
                accessor: 'AlteObjektbezeichnung'
            }),
            new FieldHelper({
                id: 'strang',
                title: {
                    key: 'isybauxml.object.common.strang',
                    fallback: 'Strang'
                },
                accessor: 'Strang'
            }),
            new FieldHelper({
                id: 'functionLateral',
                title: {
                    key: 'isybauxml.object.lateral.other.functionLateral',
                    fallback: 'Funktion der Leitung'
                },
                accessor: 'LeitungsFunktion',
                field: new ComplexTextBoxHelper()
            }),
            new FieldHelper({
                id: 'pressurizingMethod',
                title: {
                    key: 'isybauxml.object.common.pressurizingMethod',
                    fallback: 'Druckverfahren'
                },
                accessor: 'Druckverfahren',
                field: new ComplexTextBoxHelper()
            }),
            new FieldHelper({
                id: 'materialOrigin',
                title: {
                    key: 'isybauxml.object.common.materialOrigin',
                    fallback: 'Herkunft des Materials'
                },
                accessor: 'HerkunftMaterial',
                field: new ComplexTextBoxHelper()
            }),
            new FieldHelper({
                id: 'nominalPositivePressure',
                title: {
                    key: 'isybauxml.object.common.nominalPositivePressure',
                    fallback: 'Nenndruck'
                },
                accessor: 'Nenndruck'
            }),
            new FieldHelper({
                id: 'property',
                title: {
                    key: 'isybauxml.object.common.property',
                    fallback: 'Eigentum FB'
                },
                accessor: 'Eigentum'
            }),
            new FieldHelper({
                id: 'lisaGUID',
                title: {
                    key: 'isybauxml.object.common.lisaGUID',
                    fallback: 'LISA-GUID'
                },
                accessor: 'LISA-GUID'
            }),
        ]
    })
];

export default canalObjectLeteralArray;