// @flow

/**
 * Standardwert fuer die Verzoegerung
 */
const DEFAULT_DELAY = 10000;

/**
 * Hilfsklasse fuer eine verzoegerte Ausfuehrung der Funktion
 *
 * @export
 * @class DelayExecutor
 */
export default class DelayExecutor {
    _delay: number;
    _func: () => mixed;
    _handle: ?TimeoutID;

    /**
     * Creates an instance of DelayExecutor.
     * @param {number} [delay=DEFAULT_DELAY] Zeit bis zur Ausfuehrung in Millisekunden
     * @memberof DelayExecutor
     */
    constructor(delay: number = DEFAULT_DELAY) {
        this._delay = delay;
    }

    /**
     * Verzoegert die Ausfuehrung der uebergebenen Methode. Wenn diese Funktion wieder aufgerufen wird.
     * Wird die Ausfuehrung abgebrochen und erneut verzoegert
     *
     * @param {() => mixed} func Funktion, die verzoegert werden soll
     * @memberof DelayExecutor
     */
    run(func: () => mixed) {
        this._func = func;

        this.cancel();

        this._handle = setTimeout(this._func, this._delay);
    }

    /**
     * Bricht die Ausfuehrung der Methode ab
     *
     * @memberof DelayExecutor
     */
    cancel() {
        if (this._handle) {
            clearTimeout(this._handle);
            this._handle = null;
        }
    }
}