// @flow
import React from 'react';
import {ColumnSize, Title} from '../../misc/flowTypes';
import ValidationArray from './ValidationArray';
import { isEmpty, checkRange } from '../../misc/validationHelper';
import { getName, getComplexValue } from '../../misc/utils';
import { OBJECTLIST_RENDERER_MODE } from '../../misc/const';
import TextBoxHelper from './TextBoxHelper';
import InputHelper from './InputHelper';
import FormField from '../components/FormField';
import FormHelper from './FormHelper';
import type {ValueElement} from './FormHelper';

type FieldSize = {
    label: ColumnSize,
    field: ColumnSize
}

type FieldDefaults = {
    id: string,
    accessor: string,
    title?: Title,
    size: ColumnSize,
    columnSize: ColumnSize,
    field: InputHelper,
    fieldCssClass: string
}

type FieldOptions = {
    id: string,
    accessor: string,
    title?: Title,
    size?: ColumnSize,
    columnSize?: ColumnSize,
    field?: InputHelper,
    fieldCssClass?: string
}

const def: FieldDefaults = {
    id: '',
    accessor: '',
    title: {
        key: '',
        fallback: ''
    },
    size: { 
        xs: 4,
        sm: 4,
        md: 4,
        lg: 4
    },    
    columnSize: {
        xs: 12,
        sm: 12,
        md: 6,
        lg: 6
    },
    field: new TextBoxHelper(),    
    fieldCssClass: ''
}

/**
 * Hilfsklasse fuer Formtemplates um ein Feld zu erzeugen, dass aus Label und einem Element besteht
 *
 * @export
 * @class FieldHelper
 * @extends {FormHelper}
 */
export default class FieldHelper extends FormHelper {
    _options: FieldDefaults;

    /**
     * Creates an instance of FieldHelper.
     * @param {FieldOptions} options Einstellungen der Klasse
     * @memberof FieldHelper
     */
    constructor(options: FieldOptions) {
        super();

        const title = {...def.title, ...options.title};
        const size = {...def.size, ...options.size};
        const columnSize = {...def.columnSize, ...options.columnSize};

        this._options = {...def, ...options, ...{title, size, columnSize}};
    }

    get id():string {
        return this._options.id;
    }

    get accessor():string {
        return this._options.accessor;
    }

    get title():Title {
        return this._options.title;
    }

    get size():ColumnSize {
        return this._options.size;
    }

    get columnSize():ColumnSize {
        return this._options.columnSize;
    }

    get field():InputHelper {
        return this._options.field;
    }

    get fieldCssClass():string {
        return this._options.fieldCssClass;
    }

    /**
     * Prueft ob die Einstellungen der Klasse korrekt sind
     *
     * @returns {ValidationArray}
     * @memberof FieldHelper
     */
    validate():ValidationArray {
        const messages = new ValidationArray(this.id);

        if (isEmpty(this.id)) {
            messages.error.push('Id ist nicht gesetzt!');
        }

        if (isEmpty(this.accessor)) {
            messages.error.push('Accessor ist nicht gesetzt!');
        }

        if (!checkRange(0,12, this.size.xs) || !checkRange(0,12, this.size.sm) || !checkRange(0,12, this.size.md) || !checkRange(0,12, this.size.lg)) {
            messages.warn.push('Label befindet sich außerhalb des gültigen Wertebereiches (0-12)');
        }

        if (!checkRange(0,12, this.columnSize.xs) || !checkRange(0,12, this.columnSize.sm) || !checkRange(0,12, this.columnSize.md) || !checkRange(0,12, this.columnSize.lg)) {
            messages.warn.push('Spalte befindet sich außerhalb des gültigen Wertebereiches (1-11)');
        }

        return messages;
    }

    /**
     * Berechnet die Groesse des Feldes anhand des Labels
     *
     * @returns {FieldSize}
     * @memberof FieldHelper
     */
    determineSizes():FieldSize {
        return {
            label: this.size,
            field: {
                xs: 12 - this.size.xs,
                sm: 12 - this.size.sm,
                md: 12 - this.size.md,
                lg: 12 - this.size.lg
            }
        }
    }

    /**
     * Ermittelt den Wert des Objektes fuer diese Klasse
     *
     * @param {Object} object Das Kanalobjekt
     * @returns {any}
     * @memberof FieldHelper
     */
    determineValue(object: Object):any {
        let value: any = getName(object, this.accessor);

        if (this.field instanceof TextBoxHelper) {
            value = getComplexValue(value, OBJECTLIST_RENDERER_MODE.TEXT);
        }
        return value;
    }

    /**
     * Baut das Feld
     *
     * @param {Object} object Das Kanalobjekt
     * @returns {?ValueElement}
     * @memberof FieldHelper
     */
    build(object: Object):?ValueElement {
        const fieldSize = this.determineSizes();
        const value = this.determineValue(object);
        
        return {
            valueSet: value && !isEmpty(value),
            element: 
                <FormField
                    key={this.id}
                    title={this.title}
                    columnSize={this.columnSize}
                    label={fieldSize.label}
                    field={fieldSize.field}
                    fieldCssClass={this.fieldCssClass}
                >
                    {this.field.build(value, fieldSize.field.sm)}
                </FormField>
        }
    }
}
