// @flow
import StationView from "./StationView";

type StationOptions = {
    section: StationView,
    lateral: StationView,
    manhole: StationView
}

/**
 * Hilfsklasse fuer die Stationstabellen
 *
 * @export
 * @class Station
 */
export default class Station {
    section: StationView;
    lateral: StationView;
    manhole: StationView;

    /**
     * Creates an instance of Station.
     * @param {StationOptions} options Einstellung der Spalten fuer die unterschiedlichen Arten von Kanalobjekten
     * @memberof Station
     */
    constructor(options: StationOptions) {
        this.section = options.section;        
        this.lateral = options.lateral;
        this.manhole = options.manhole;
    }
}