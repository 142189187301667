// @flow
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, Card, ButtonToolbar, ButtonGroup, Container } from 'react-bootstrap';
import ReactTable from 'react-table';

import StationCarousel from './StationCarousel';
import IconButton from './common/IconButton';

import { getImage, getImageSmall } from '../misc/utils';
import { STATION_VIEW_NO_PHOTO, STATION_VIEW_ONLY_PHOTO, STATION_VIEW_PHOTO } from '../misc/const';
import general from '../templates/General';
import cloneDeep from 'lodash.clonedeep';
import { findSettingsForStationTemplate } from '../misc/utils';
import DelayExecutor from '../misc/DelayExecutor.js';
import CanalInterface from '../templates/helper/CanalInterface';
import FormColumnHelper from '../form/helper/FormColumnHelper';
import withKeyboardCommand from './hoc/withKeyboardCommands';
import { AiOutlineCloudDownload } from 'react-icons/ai';
import {translateString} from '../i18n/utils';

import { switchStationView, stationSelected, nextStationPicture } from '../actions/viewActions';
import { backToProject, _updateSettings } from '../actions/dataActions';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleLeft } from '@fortawesome/free-solid-svg-icons';

type Props = {
	switchStationView: (string) => mixed,
	stationSelected: (Object) => mixed,
	currentStationView: string,
	currentStation: Object,
	currentObject: Object,
	currentInterface: CanalInterface,
	backToProject: () => mixed,
	settings: Object,
	_updateSettings: (Object, string, ?boolean, ?boolean) => mixed,
	token: string,
	nextStationPicture: (string) => mixed,
};

type State = {
	columns: Array<Object>,
};

/**
 * React Komponente, die eine Tabelle von Station rendert
 * @type {Component}
 * @constructor
 */
class StationMainView extends Component<Props, State> {
	delayExecutor: DelayExecutor;

	/**
	 * Creates an instance of StationMainView.
	 * @param {Object} props
	 * @memberof StationMainView
	 */
	constructor(props: Object) {
		super(props);

		this.state = {
			columns: [],
		};

		this.delayExecutor = new DelayExecutor();
	}

	/**
	 * React Lifecyclemethode
	 * Setzt die Spalten der Tabelle
	 *
	 * @memberof StationMainView
	 */
	UNSAFE_componentWillMount(): void {
		const { currentStationView } = this.props;
		if (currentStationView !== STATION_VIEW_ONLY_PHOTO) {
			this.loadAndMapColumns();
		}
	}

	/**
	 * React Lifecycle Methode
	 * Setzt die Spalten der Tabelle, wenn sich die Ansicht oder das Objekt aendert
	 *
	 * @param {Object} prevProps Objekt, mit den Properties vor dem Update
	 * @memberof StationMainView
	 */
	componentDidUpdate(prevProps: Object) {
		const { currentStationView, currentObject } = this.props;
		if ((prevProps.currentStationView !== currentStationView && currentStationView !== STATION_VIEW_ONLY_PHOTO) || prevProps.currentObject._id !== currentObject._id) {
			this.loadAndMapColumns();
		}
	}

	/**
	 * Callback beim Wechsel der aktiven Ansicht, der die Redux Action triggert
	 * @param  {string} value Ansicht, die angezeigt werden soll
	 * @return {void}
	 */
	onChange = (value: string): void => {
		this.props.switchStationView(value);
	};

	/**
	 * Properties des tr Elements
	 * @param  {object} state    Status der Tabelle
	 * @param  {object} rowInfo  Informationen ueber die Zeile
	 * @param  {object} column
	 * @param  {object} instance Instanz der Tabelle
	 * @return {object}          Properties fuer das tr Element
	 */
	trProps = (state: Object, rowInfo: Object, column: Object, instance: Object) => {
		const { currentStation } = this.props;
		const { stationSelected } = this.props;

		return {
			onClick: () => {
				stationSelected(rowInfo.original);
			},
			style: {
				background: currentStation && rowInfo.original._id === currentStation._id ? 'rgba(0, 0, 249, 0.6)' : '',
				color: currentStation && rowInfo.original._id === currentStation._id ? 'white' : '',
			},
		};
	};

	/**
	 * Callback fuer das Resize event bei den Tabellenspalten
	 * Speichert diese Aenderungen in den Einstellungen
	 * @param {Array<Object>} resizedColumns Spalten die veraendert werden
	 *
	 * @memberof StationMainView
	 */
	onColumnResize = (resizedColumns: Array<Object>): void => {
		const { settings, currentInterface, currentObject, currentStationView } = this.props;
		const { _updateSettings } = this.props;
		const type = currentObject.type;
		const name = currentInterface.name;

		let columnSettings = settings.station[name][type][currentStationView];
		columnSettings.forEach((column) => {
			resizedColumns.forEach((resized) => {
				if (resized.id === column.id) {
					column.width = resized.value;
					this.delayExecutor.run(() => _updateSettings(settings, true, false));
				}
			});
		});
	};

	/**
	 * Rendert die Tabelle
	 * @param  {Array} data     Array mit den Tabellendaten
	 * @param  {Array} columns  Array mit den Spalten der Tabelle
	 * @return {JSX}            JSX Markup der Tabelle
	 */
	renderStationView(data: Array<Object>, columns: Array<Object>): React$Element<any> {
		return (
			<ReactTable
				data={data}
				columns={columns}
				className="-striped -highlight"
				showPagination={false}
				getTrProps={this.trProps}
				pageSize={data.length > 0 ? data.length : 1}
				onResizedChange={this.onColumnResize}
			/>
		);
	}

	/**
	 * Setzt fuer eine neue bzw unbekannte Schnittstelle Informationen in den Einstellungen
	 *
	 * @param {Array<Object>} columns Spalten der Tabelle
	 * @memberof StationMainView
	 */
	addNewTypeOfStationsToSettings(columns: Array<Object>): void {
		const { currentObject, currentInterface, settings, currentStationView } = this.props;

		const type = currentObject.type;
		const name = currentInterface.name;

		if (!settings.station.hasOwnProperty(name)) {
			settings.station[name] = {};
		}

		if (!settings.station[name].hasOwnProperty(type)) {
			settings.station[name][type] = {};
		}

		if (!settings.station[name][type].hasOwnProperty(currentStationView)) {
			settings.station[name][type][currentStationView] = [];
		}

		columns.forEach((column) => {
			settings.station[name][type][currentStationView].push({
				id: column.id,
			});
		});
	}

	/**
	 * Setzt die Einstellugen fuer die Spalten oder fuegt
	 *
	 * @param {Array<Object>} columns Spalten der Tabelle
	 * @returns
	 * @memberof StationMainView
	 */
	hydrateWithSettings(columns: Array<Object>) {
		if (columns.length > 0) {
			const { currentObject, currentInterface, settings, currentStationView } = this.props;

			const type = currentObject.type;
			const name = currentInterface.name;

			const tableSettings = findSettingsForStationTemplate(settings, name, type, currentStationView);
			if (tableSettings) {
				tableSettings.forEach((tableSetting) => {
					columns.forEach((column) => {
						if (tableSetting.id === column.id) {
							column.width = tableSetting.width;
						}
					});
				});
			} else {
				this.addNewTypeOfStationsToSettings(columns);
			}
		}
		return columns;
	}

	/**
	 * Laedt und setzt die Spalten fuer die Tabelle
	 *
	 * @memberof StationMainView
	 */
	loadAndMapColumns() {
		const { currentObject, currentInterface, currentStationView } = this.props;

		let columns = [];
		const type = currentObject.type;

		// $FlowFixMe
		const template: Object = cloneDeep(general.station[type]);
		const interfaceTemplate: Array<FormColumnHelper> = currentInterface.station;

		switch (currentStationView) {
			case STATION_VIEW_NO_PHOTO:
				columns = template.STATION_VIEW_NO_PHOTO.concat(interfaceTemplate);
				break;
			case STATION_VIEW_PHOTO:
				columns = template.STATION_VIEW_PHOTO.concat(interfaceTemplate);
				break;
			default:
				break;
		}

		columns = this.hydrateWithSettings(columns);

		this.setState({
			columns,
		});
	}

    downloadPicture() {
        const { currentStation } = this.props;
        const link = document.createElement('a');
        link.href = currentStation.photoUrl;
        link.setAttribute('download', 'PanoramoScreenshot');
        document.body.appendChild(link);
        link.click();
    }

    renderDownloadButton() {
        if(!this.props.currentStation?.photoUrl || this.props.currentStationView !== STATION_VIEW_PHOTO) {
            return null;
        }
        return (
            <IconButton
                text={translateString('station.downloadPhoto')}
                disabled={this.props.currentStation?.photoUrl === ""}
                onClick={() => this.downloadPicture()}
                icon=<AiOutlineCloudDownload className='icon-button' />
            />
        );

    }

	/**
	 * Rendert eine Table von Stationen
	 * @return {JSX} JSX Markup
	 */
	render() {
		const { currentObject, currentStation } = this.props;
		const { currentStationView } = this.props;
		const { backToProject, stationSelected } = this.props;
		const { columns } = this.state;

		let data: Array<Object> = [];

		if (currentObject.hasOwnProperty('stations')) {
			data = currentObject.stations;
		}

		let view: ?React$Element<any> = null;
		if (currentStationView === STATION_VIEW_ONLY_PHOTO) {
			view = <StationCarousel stationSelected={stationSelected} currentObject={currentObject} currentStation={currentStation}></StationCarousel>;
		} else {
			view = this.renderStationView(data, columns);
		}

        const downloadButtonDivStyle = {minWidth: 100, display: 'flex', justifyContent: 'end'};

		return (
			<Fragment>
				<Card>
					<Card.Body >
						<Container fluid>
							<div className="d-flex justify-content-between me-3">
								<div>
									<Button onClick={() => backToProject()}>
										<FontAwesomeIcon icon={faAngleDoubleLeft}></FontAwesomeIcon>&nbsp;
										{getImageSmall('folder')}
									</Button>
								</div>
								<div>
									<ButtonToolbar>
										<ButtonGroup type="radio" name="options" defaultValue={currentStationView}>
											<Button
                                                active={currentStationView === STATION_VIEW_NO_PHOTO}
                                                onClick={() => this.onChange(STATION_VIEW_NO_PHOTO)}>
                                                        {getImage('photo_little')}
                                            </Button>
											<Button
                                                active={currentStationView === STATION_VIEW_PHOTO}
                                                onClick={() => this.onChange(STATION_VIEW_PHOTO)}>
                                                    {getImage('photo_medium')}
                                            </Button>
											<Button
                                                active={currentStationView === STATION_VIEW_ONLY_PHOTO}
                                                onClick={() => this.onChange(STATION_VIEW_ONLY_PHOTO)}>
                                                    {getImage('photo_big')}
                                            </Button>
										</ButtonGroup>
									</ButtonToolbar>
								</div>
                                <div  style={downloadButtonDivStyle}>
                                    {this.renderDownloadButton()}
                                </div>
							</div>
						</Container>
					</Card.Body>
				</Card>
				{view}
			</Fragment>
		);
	}
}

/**
 *  HOC fuer das Verbinden mit Tastaturkuerzeln
 */
const StationView = withKeyboardCommand(StationMainView, [
	{
		key: 'ctrl+left',
		func: (child) => child.props.backToProject(),
	},
	{
		key: 'left',
		func: (child) => child.props.nextStationPicture('left'),
	},
	{
		key: 'right',
		func: (child) => child.props.nextStationPicture('right'),
	},
]);

/**
 * Verbindet die Klasse mit dem Redux Store
 */
const ReduxStationMainView = connect(
	(state) => {
		return {
			currentStationView: state.currentStationView,
			currentStation: state.currentStation,
			currentObject: state.currentObject,
			currentInterface: state.currentInterface,
			settings: state.settings,
			token: state.authentication.token,
		};
	},
	{
		switchStationView,
		stationSelected,
		backToProject,
		_updateSettings,
		nextStationPicture,
	}
)(StationView);
export default ReduxStationMainView;
