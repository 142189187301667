import ObjectListColumnHelper from '../../helper/ObjectListColumnHelper';
import {OBJECTLIST_RENDERER_MODE} from '../../../misc/const';
import {findInspectionValue} from '../../../misc/utils';

const objectListTabHelperInspection = {
    id: 'inspection',
    title: {
        key: 'main.inspection',
        fallback: 'Untersuchung'
    },
    sort: true,
    columns: [
        new ObjectListColumnHelper({
            id: 'inspection.Bemerkung',
            title: {
                key: 'common.remark',
                fallback: 'Bemerkung'
            },
            types: {
                section: true,
                lateral: true,
                manhole: true
            },
            accessor: (d: Object) => findInspectionValue("Bemerkung", d)
        }),
        new ObjectListColumnHelper({
            id: 'inspection.BewpunkteBetriebssicherheit',
            title: {
                key: 'dwa_m_149_3.evalPointsOpReliabilityLong',
                fallback: 'Bew.punkte Betriebssicherheit (DWA-Klass.)'
            },
            types: {
                section: true,
                lateral: true,
                manhole: true
            },
            accessor: (d: Object) => findInspectionValue("EvaluationPointsEvalOperation", d)
        }),
        new ObjectListColumnHelper({
            id: 'inspection.BewpunkteDichtheit',
            title: {
                key: 'dwa_m_149_3.evalPointTightnessLong',
                fallback: 'Bew.punkte Dichtheit (DWA-Klass.)'
            },
            types: {
                section: true,
                lateral: true,
                manhole: true
            },
            accessor: (d: Object) => findInspectionValue("EvaluationPointsEvalTightness", d),
        }),
        new ObjectListColumnHelper({
            id: 'inspection.BewpunkteStandsicherheit',
            title: {
                key: 'dwa_m_149_3.evalPointStabilityLong',
                fallback: 'Bew.punkte Standsicherheit (DWA-Klass.)'
            },
            types: {
                section: true,
                lateral: true,
                manhole: true
            },
            accessor: (d: Object) => findInspectionValue("EvaluationPointsEvalStatic", d)
        }),
        new ObjectListColumnHelper({
            id: 'inspection.Bewertungsdatum',
            title: {
                key: 'common.evaluationTime',
                fallback: 'Bewertungsdatum'
            },
            types: {
                section: true,
                lateral: true,
                manhole: true
            },
            accessor: (d: Object) => findInspectionValue("EvaluationTime", d)
        }),
        new ObjectListColumnHelper({
            id: 'inspection.BezugspunktHorizontal',
            title: {
                key: 'isybauxml.inspection.manhole.inspection.horizontalPoint',
                fallback: 'Horizontaler Bezugspunkt'
            },
            types: {
                manhole: true
            },
            accessor: (d: Object) => findInspectionValue("BezugspunktHorizontal", d),
            duoField: true
        }),
        new ObjectListColumnHelper({
            id: 'inspection.BezugspunktLaengsrichtung',
            title: {
                key: 'inspection.longitudinalPoint',
                fallback: 'Bezugspunkt in Längsrichtung'
            },
            types: {
                section: true,
                lateral: true
            },
            accessor: (d: Object) => findInspectionValue("BezugspunktLage", d),
            duoField: true
        }),
        new ObjectListColumnHelper({
            id: 'inspection.BezugspunktVertikal',
            title: {
                key: 'inspection.verticalPoint',
                fallback: 'Vertikaler Bezugspunkt'
            },
            types: {
                manhole: true
            },
            accessor: (d: Object) => findInspectionValue("BezugspunktVertikal", d),
            duoField: true
        }),
        new ObjectListColumnHelper({
            id: 'inspection.EndgueltigeObjektanzahl',
            title: {
                key: 'arbeitshilfenAbwasser.finalObjectNumberLong',
                fallback: 'Endgültige Objektanzahl (A.A.-Klass.)'
            },
            types: {
                section: true,
                lateral: true,
                manhole: true
            },
            accessor: (d: Object) => findInspectionValue("EvaluationNumberFinal", d)
        }),
        new ObjectListColumnHelper({
            id: 'inspection.Fotoanzahl',
            title: {
                key: 'common.pictureCount',
                fallback: 'Fotoanzahl'
            },
            types: {
                section: true,
                lateral: true,
                manhole: true
            },
            accessor: (d: Object) => findInspectionValue("Fotoanzahl", d)
        }),
        new ObjectListColumnHelper({
            id: 'inspection.Fotospeichermedium',
            title: {
                key: 'inspection.photoStorageMedium',
                fallback: 'Fotospeichermedium'
            },
            types: {
                section: true,
                lateral: true,
                manhole: true
            },
            accessor: (d: Object) => findInspectionValue("ISYBAU-XML_H_FotoSpeichermedium.ISYBAU-XML_L_FotoSpeichermedium.ISYBAU-XML_S_FotoSpeichermedium", d),
            duoField: true
        }),
        new ObjectListColumnHelper({
            id: 'inspection.Inspektionsart',
            title: {
                key: 'inspection.typeInspection',
                fallback: 'Inspektionsart'
            },
            types: {
                section: true,
                lateral: true
            },
            accessor: (d: Object) => findInspectionValue("Inspektionsart", d),
            duoField: true
        }),
        new ObjectListColumnHelper({
            id: 'inspection.Inspektionsdatum',
            title: {
                key: 'inspection.inspectionDate',
                fallback: 'Inspektionsdatum'
            },
            types: {
                section: true,
                lateral: true,
                manhole: true
            },
            accessor: (d: Object) => findInspectionValue("Inspektionsbeginn", d)
        }),
        new ObjectListColumnHelper({
            id: 'inspection.Inspektionslaenge',
            title: {
                key: 'inspection.inspectionLength',
                fallback: 'Inspektionslänge'
            },
            types: {
                section: true,
                lateral: true,
                manhole: true
            },
            accessor: (d: Object) => findInspectionValue("Inspektionslaenge", d)
        }),
        new ObjectListColumnHelper({
            id: 'inspection.Inspektionsrichtung',
            title: {
                key: 'inspection.inspectionDirection',
                fallback: 'Inspektionsrichtung'
            },
            types: {
                section: true,
                lateral: true
            },
            accessor: (d: Object) => findInspectionValue("Inspektionsrichtung", d),
            duoField: true
        }),
        new ObjectListColumnHelper({
            id: 'inspection.Inspektionsverfahren',
            title: {
                key: 'isybauxml.inspection.common.inspectionMethod',
                fallback: 'Inspektionsverfahren'
            },
            types: {
                section: true,
                lateral: true,
                manhole: true
            },
            accessor: (d: Object) => findInspectionValue("Inspektionsverfahren", d),
            duoField: true
        }),
        new ObjectListColumnHelper({
            id: 'inspection.Inspektionszeit',
            title: {
                key: 'inspection.inspectionTime',
                fallback: 'Inspektionszeit'
            },
            types: {
                section: true,
                lateral: true,
                manhole: true
            },
            accessor: (d: Object) => findInspectionValue("", d) //TODO:
        }),
        new ObjectListColumnHelper({
            id: 'inspection.MassgebendeQuantifizierung',
            title: {
                key: 'isybauxml.inspection.common.definingQuanti',
                fallback: 'Massgebende Quantifizierung'
            },
            types: {
                section: true,
                lateral: true,
                manhole: true
            },
            accessor: (d: Object) => findInspectionValue("", d) //TODO:
        }),
        new ObjectListColumnHelper({
            id: 'inspection.MassgebenderSchaden',
            title: {
                key: 'isybauxml.inspection.common.definingDamage',
                fallback: 'Massgebender Schaden'
            },
            types: {
                section: true,
                lateral: true,
                manhole: true
            },
            accessor: (d: Object) => findInspectionValue("", d)
        }),
        new ObjectListColumnHelper({
            id: 'inspection.NameUntersucher',
            title: {
                key: 'inspection.inspector',
                fallback: 'Inspekteur'
            },
            types: {
                section: true,
                lateral: true,
                manhole: true
            },
            accessor: (d: Object) => findInspectionValue("NameUntersucher", d)
        }),
        new ObjectListColumnHelper({
            id: 'inspection.Objektklasse',
            title: {
                key: 'arbeitshilfenAbwasser.objectClass',
                fallback: 'Objektklasse'
            },
            types: {
                section: true,
                lateral: true,
                manhole: true
            },
            accessor: (d: Object) => findInspectionValue("Class", d)
        }),
        new ObjectListColumnHelper({
            id: 'inspection.ObjektklasseManuell',
            title: {
                key: 'arbeitshilfenAbwasser.manualObjectClassLong',
                fallback: 'Objektklasse manuell (A.A. Klass.)'
            },
            types: {
                section: true,
                lateral: true,
                manhole: true
            },
            accessor: (d: Object) => findInspectionValue("ISYBAU-XML_ClassManu", d)
        }),
        new ObjectListColumnHelper({
            id: 'inspection.ObjektklasseVergeben',
            title: {
                key: 'arbeitshilfenAbwasser.objectClassGivenLong',
                fallback: 'Objektklasse vergeben (A.A. Klass.)'
            },
            types: {
                section: true,
                lateral: true,
                manhole: true
            },
            accessor: (d: Object) => findInspectionValue("ISYBAU-XML_ClassUser", d)
        }),
        new ObjectListColumnHelper({
            id: 'inspection.Regeleinzelrohllaenge',
            title: {
                key: 'isybauxml.inspection.common.singlePipeLength',
                fallback: 'Regeleinzelrohllänge'
            },
            types: {
                section: true,
                lateral: true
            },
            accessor: (d: Object) => findInspectionValue("Regeleinzelrohllaenge", d)
        }),
        new ObjectListColumnHelper({
            id: 'inspection.Reinigung',
            title: {
                key: 'inspection.cleaning',
                fallback: 'Reinigung'
            },
            types: {
                section: true,
                lateral: true,
                manhole: true
            },
            accessor: (d: Object) => findInspectionValue("Reinigung", d),
            columnType: OBJECTLIST_RENDERER_MODE.CHECKBOX
        }),
        new ObjectListColumnHelper({
            id: 'inspection.SanBedarfszahl',
            title: {
                key: 'dwa_m_149_3.rehabilitationNumberLong',
                fallback: 'Sanierungsbedarfzahl (DWA-Klass.)'
            },
            types: {
                section: true,
                lateral: true,
                manhole: true
            },
            accessor: (d: Object) => findInspectionValue("EvaluationNumberFinal", d)
        }),
        new ObjectListColumnHelper({
            id: 'inspection.SanPrioritaet',
            title: {
                key: 'din1986_30.rehabilitationPriorityLong',
                fallback: 'Sanierungspriorität (DIN 1986-30)'
            },
            types: {
                section: true,
                lateral: true,
                manhole: true
            },
            accessor: (d: Object) => findInspectionValue("Class", d)
        }),
        new ObjectListColumnHelper({
            id: 'inspection.Temperatur',
            title: {
                key: 'inspection.temperature',
                fallback: 'Temperatur'
            },
            types: {
                section: true,
                lateral: true,
                manhole: true
            },
            accessor: (d: Object) => findInspectionValue("Temperatur", d)
        }),
        new ObjectListColumnHelper({
            id: 'inspection.Videospeichermedium',
            title: {
                key: 'inspection.videoStorageMedium',
                fallback: 'Videospeichermedium'
            },
            types: {
                section: true,
                lateral: true,
                manhole: true
            },
            accessor: (d: Object) => findInspectionValue("ISYBAU-XML_S_VideoSpeichermedium.ISYBAU-XML_L_VideoSpeichermedium.ISYBAU-XML_H_VideoSpeichermedium", d),
            duoField: true
        }),
        new ObjectListColumnHelper({
            id: 'inspection.VorlaeufigeObjektanzahl',
            title: {
                key: 'arbeitshilfenAbwasser.preliminaryObjectNumberLong',
                fallback: 'Vorläufige Objektanzahl (A.A.-Klass.)'
            },
            types: {
                section: true,
                lateral: true,
                manhole: true
            },
            accessor: (d: Object) => findInspectionValue("EvaluationPreliminaryNumber", d)
        }),
        new ObjectListColumnHelper({
            id: 'inspection.Wasserhaltung',
            title: {
                key: 'isybauxml.inspection.common.drainageMeasures',
                fallback: 'Maßnahmen zur Wasserhaltung'
            },
            types: {
                section: true,
                lateral: true,
                manhole: true
            },
            accessor: (d: Object) => findInspectionValue("Wasserhaltung", d),
            duoField: true
        }),
        new ObjectListColumnHelper({
            id: 'inspection.Wetter',
            title: {
                key: 'inspection.weather',
                fallback: 'Wetter'
            },
            types: {
                section: true,
                lateral: true,
                manhole: true
            },
            accessor: (d: Object) => findInspectionValue("Wetter", d),
            duoField: true
        })
    ]
};

export default objectListTabHelperInspection;