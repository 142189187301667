// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Row, Card, Button, Tab, Tabs, Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleLeft } from '@fortawesome/free-solid-svg-icons';

import FormBuilder from '../form/FormBuilder';
import { getTransObject, getImageSmall } from '../misc/utils';
import general from '../templates/General';
import FormHelper from '../form/helper/FormHelper';
import Classification from '../templates/helper/Classification';
import CanalInterface from '../templates/helper/CanalInterface';
import withKeyboardCommand from './hoc/withKeyboardCommands';
import { backToProject } from '../actions/dataActions';

type Props = {
	templateEngine: Object,
	currentObject: Object,
	currentInterface: CanalInterface,
	currentClassification: Object,
	backToProject: () => mixed,
};

/**
 * React Komponente fuer den Untersuchungstab
 * @type {Component}
 * @constructor
 */
class InspectionMainView extends Component<Props> {
	formBuilder: FormBuilder = new FormBuilder();

	/**
	 * Rendert eine Tab Ansicht wenn mehr als eine Inspektion vorhanden ist
	 * @param {Array<FormHelper>} template Das Template fuer die Inspektion
	 * @return {JSX} Die Tab Ansicht
	 */
	renderMultiInspection(template: Array<FormHelper>): React$Element<any> {
		const { currentObject } = this.props;
		const main: Object = general.inspectionMain;
		const secondary: Object = general.inspectionSecondary;

		return (
			<Tabs defaultActiveKey={0} id='inspecttabs'>
				<Tab eventKey={0} title={getTransObject(main.key, main.value, 'span')} key={0}>
					<Row id='inspectionviewfirst' className="flex-column no-margin">
						<Container fluid>{this.formBuilder.build(currentObject.inspection[0], template)}</Container>
					</Row>
				</Tab>
				<Tab eventKey={1} title={getTransObject(secondary.key, secondary.value, 'span')} key={1}>
					<Row id='inspectionviewsecond' className="flex-column no-margin">
						<Container fluid>{this.formBuilder.build(currentObject.inspection[1], template)}</Container>
					</Row>
				</Tab>
			</Tabs>
		);
	}

	/**
	 * Rendert die Untersuchungsseite
	 * @return {JSX} JSX Markup
	 */
	render() {
		let form = null;
		const { templateEngine, currentInterface, currentObject, currentClassification } = this.props;
		const { backToProject } = this.props;

		// $FlowFixMe
		let template: Array<FormHelper> = currentInterface.inspection[currentObject.type].data;

		if (currentClassification && currentClassification.hasOwnProperty(currentObject.type) && currentClassification[currentObject.type] !== '') {

			const codingTemplate: Classification = templateEngine.getClassification(currentClassification[currentObject.type]);

			if (codingTemplate !== null) {
				const objectTemplate: Array<FormHelper> = codingTemplate.inspection[currentObject.type].data;

				template = template.concat(objectTemplate);
			}
		}

		if (currentObject.inspection.length > 1) {
			form = this.renderMultiInspection(template);
		} else if (currentObject.inspection.length === 1) {
			form = <Row id='inspectionviewfirst' className="flex-column no-margin">{this.formBuilder.build(currentObject.inspection[0], template)}</Row>;
		}

		return (
			<>
                <Form>
                    <Card>
                        <Card.Body>
                            <Button onClick={() => backToProject()}>
                                <FontAwesomeIcon icon={faAngleDoubleLeft}></FontAwesomeIcon>&nbsp;
                                {getImageSmall('folder')}
                            </Button>
                        </Card.Body>
                    </Card>
                </Form>
				<Form id="objectview">
                    {form}
                </Form>
			</>
		);
	}
}

/**
 * HOC fuer das Verbinden mit Tastaturkuerzeln
 */
const InspectionView = withKeyboardCommand(InspectionMainView, [
	{
		key: 'ctrl+left',
		func: (child) => child.props.backToProject(),
	},
]);

/**
 *  Verbindet die Klasse mit dem Redux Store
 */
const ReduxInspectionMainView = connect(
	(state) => {
		return {
			templateEngine: state.templateEngine,
			currentObject: state.currentObject,
			currentInterface: state.currentInterface,
			currentClassification: state.currentClassification,
		};
	},
	{
		backToProject,
	}
)(InspectionView);
export default ReduxInspectionMainView;
