// @flow
import React, { useEffect } from 'react';
import { Image } from 'react-bootstrap';
import { run as runHolder } from 'holderjs/holder';

import { translateString } from '../../../i18n/utils';

export default function ImageHolder({ className, img }) {
    if (img.hasOwnProperty('photoUrl') && img.photoUrl !== '' && img.fileExists === true) {
       return <WithImage className={className} photoUrl={img.photoUrl}></WithImage>;
	}

	if (img.hasOwnProperty('photoUrl') && img.photoUrl !== '' && img.fileExists === false) {
		return <WithoutImage className={className} message='messages.imageNotExported' />;
	}

    return <WithoutImage className={className} message='messages.imageNotFound' />;
}

function WithImage({ photoUrl, className }) {
    return <Image fluid src={photoUrl} className={`center-img ${className}`}></Image>;
}

function WithoutImage({ className, message }) {
	useEffect(() => {
		runHolder('.image-placeholder');
	});

	return <Image fluid src={`holder.js/960x544?text=${translateString(message)}`} className={`image-placeholder center-img ${className}`}></Image>;
}
