// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavItem } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { Trans } from 'react-i18next';
import { _logout } from '../../actions/loginActions';

type Props = {
	_logout: () => mixed,
};

/**
 * Klasse fuer einen Button fuer den Logout in der Navigation
 *
 * @class LogoutNavigationItem
 * @extends {Component<Props>}
 */
class LogoutNavigationItem extends Component<Props> {
	render() {
		const { _logout } = this.props;
		return (
			<NavItem onClick={_logout}>
				<FontAwesomeIcon icon={faSignOutAlt}></FontAwesomeIcon>&nbsp;
				<Trans i18nKey="common.logout" parent="span">
					Abmelden
				</Trans>
			</NavItem>
		);
	}
}

/**
 *  Verbindet die Klasse mit dem Redux Store
 */
const ReduxLogoutNavigationItem = connect(
	(state) => {
		return {
			lang: state.settings.lang,
		};
	},
	{ _logout }
)(LogoutNavigationItem);

export default ReduxLogoutNavigationItem;
