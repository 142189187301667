// @flow
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Card, Button, Container, Row } from 'react-bootstrap';
import ReactTable from 'react-table';
import { getImage, checkObjectListColumns } from '../../misc/utils';
import general from '../../templates/General';
import ObjectListSetting from './ObjectListSetting';
import cloneDeep from 'lodash.clonedeep';
import { v4 as uuid4 } from 'uuid';
import deepEqual from 'deep-equal';
import DelayExecutor from '../../misc/DelayExecutor.js';
import CanalInterface from '../../templates/helper/CanalInterface';
import withKeyboardCommand from '../hoc/withKeyboardCommands';

import { showSideView, objectSelected, objectOpened } from '../../actions/viewActions';
import { _updateSettings } from '../../actions/dataActions';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import { originalSelected } from '../../actions/viewActions';

type Props = {
	token: string,
	showSideView: (boolean) => mixed,
	settings: Object,
	objectSelected: (Object) => mixed,
	objectOpened: (Object) => mixed,
	originalSelected: (Object) => mixed,
	currentProject: Object,
	currentObject: Object,
	currentInterface: CanalInterface,
	_updateSettings: (Object, string, ?boolean, ?boolean) => mixed,
};

type State = {
	columns: Array<Object>,
};

/**
 * React Komponente, die eine Tabelle im MainView rendert
 * @type {Component}
 * @constructor
 */
class ObjectListMainView extends Component<Props, State> {
	delayExecutor: DelayExecutor;

	/**
	 * Creates an instance of ObjectListMainView.
	 * @param {Object} props Die Properties
	 * @memberof ObjectListMainView
	 */
	constructor(props: Object) {
		super(props);

		this.state = {
			columns: [],
		};

		this.delayExecutor = new DelayExecutor();
	}

	/**
	 * React Lifecycle Methode, die vor dem Rendern gerufen wird
	 * @return {void}
	 */
	UNSAFE_componentWillMount(): void {
		this.updateColumns();
	}

	/**
	 * React Lifecycle Methode, die gerufen wird wen die Properties aktualisert wurden
	 * @return {void}
	 */
	componentDidUpdate(prevProps: Object, prevState: Object): void {
		const { currentProject, settings } = this.props;

		if (prevProps.currentProject._id !== currentProject._id) {
			this.updateColumns();
		}

		if (!deepEqual(prevProps.settings, settings)) {
			this.updateColumns();
		}
	}

	/**
	 * Laedt die Spalten und gibt nur die zurueck, die der Benutzer gewaehlt hat
	 * @return {Array} Array von Spalten
	 */
	loadAndMapColumns(settings: Array<Object>): Array<Object> {
		const { currentInterface } = this.props;
		let result: Array<Object> = [];
		let columns: Array<Object> = general.objectlist.columns;

		currentInterface.objectlist.forEach((list) => {
			columns = columns.concat(list.columns);
		});

		settings.forEach((setting, sIndex) => {
			columns.forEach((col) => {
				if (col.id === setting.id) {
					let c: Object = cloneDeep(col);
					c.id = uuid4();
					c._id = setting.id;
					c.width = setting.width;
					c.show = setting.show;
					c.mode = setting.mode;
					result.push(c);
				}
			});
		});

		return result;
	}

	/**
	 * Properties des tr Elements
	 * @param  {object} state    Status der Tabelle
	 * @param  {object} rowInfo  Informationen ueber die Zeile
	 * @param  {object} column
	 * @param  {object} instance Instanz der Tabelle
	 * @return {object}          Properties fuer das tr Element
	 */
	trProps = (state: Object, rowInfo: Object, column: Object, instance: Object): Object => {
		const { currentObject } = this.props;
		const { objectSelected, showSideView, objectOpened } = this.props;
		const { originalSelected } = this.props;

		return {
			onClick: (e) => {
				objectSelected(rowInfo.original);
				originalSelected(rowInfo.original);
				showSideView(false);
			},
			onDoubleClick: (e) => {
				objectOpened(rowInfo.original);
			},
			style: {
				background: currentObject && rowInfo.original._id === currentObject._id ? 'rgba(0, 0, 249, 0.6)' : '',
				color: currentObject && rowInfo.original._id === currentObject._id ? 'white' : '',
                height: '40px',
			},
		};
	};

	/**
	 * Aktualisiert die Spalten dieser Objektliste
	 * @param  {Array<Object>} [_settings=null] Objekt mit den Spalten oder null
	 * @return {void}
	 */
	updateColumns(_settings: ?Array<Object> = null): void {
		const { currentProject, settings, currentInterface } = this.props;
		const { _updateSettings } = this.props;

		if (_settings) {
			settings.objectlist[currentProject.interface] = _settings;
			_updateSettings(settings, true);
		}

		if (!_settings) {
			_settings = settings.objectlist[currentProject.interface];
			if (!_settings) {
				const temp = checkObjectListColumns(settings, currentInterface, currentProject.interface);
				_settings = temp.objectlist[currentProject.interface];
			}
		}

		const columns: Array<Object> = this.loadAndMapColumns(_settings);

		this.setState({
			columns,
		});
	}

	/**
	 * Callback fuer das Resize event bei den Tabellenspalten
	 * Speichert diese Aenderungen in den Einstellungen
	 * @param {Array<Object>} resizedColumns Spalten die veraendert wurden
	 *
	 * @memberof ObjectListMainView
	 */
	onColumnResize = (resizedColumns: Array<Object>): void => {
		const { columns } = this.state;
		const { settings, currentProject, _updateSettings } = this.props;

		columns.forEach((column) => {
			resizedColumns.forEach((resize) => {
				if (column.id === resize.id) {
					settings.objectlist[currentProject.interface].forEach((col) => {
						if (col.id === column._id && col.mode === column.mode) {
							col.width = resize.value;
							this.delayExecutor.run(() => _updateSettings(settings, true, false));
						}
					});
				}
			});
		});
	};

	/**
	 * Rendert eine Liste der GIS Objekte im MainView
	 * @return {JSX} JSX Markup
	 */
	render() {
		const { currentProject, currentObject, settings, currentInterface } = this.props;
		const { objectOpened } = this.props;
		const { columns } = this.state;
		const objects: Array<Object> = currentProject.objects;

		return (
			<Fragment>
				<Container fluid>
					<Row>
						<Card className="buttonCard">
							<Card.Body >
								<Button onClick={() => objectOpened(currentObject)} disabled={!currentObject}>
									{currentObject ? getImage(currentObject.type) : getImage('section')}
									&nbsp;<FontAwesomeIcon icon={faAngleDoubleRight}></FontAwesomeIcon>
								</Button>
								<ObjectListSetting
									settings={settings}
									currentInterface={currentInterface}
									currentProject={currentProject}
									update={(settings) => this.updateColumns(settings)}
								/>
							</Card.Body>
						</Card>
					</Row>
				</Container>
				<ReactTable
					data={objects}
					columns={columns}
					className="-striped -highlight tableSize"
					showPagination={false}
					getTrProps={this.trProps}
					pageSize={objects ? objects.length : 1}
					onResizedChange={this.onColumnResize}
                    showPageSizeOptions={true}
				/>
			</Fragment>
		);
	}
}

/**
 *  HOC fuer das Verbinden mit Tastaturkuerzeln
 */
const ObjectListView = withKeyboardCommand(ObjectListMainView, [
	{
		key: 'ctrl+right',
		func: (child) => {
			if (child.props.currentObject) {
				child.props.objectOpened(child.props.currentObject);
			}
		},
	},
]);

/**
 *  Verbindet die Klasse mit dem Redux Store
 */
const ReduxObjectListMainView = connect(
	(state) => {
		return {
			currentProject: state.currentProject,
			currentInterface: state.currentInterface,
			currentObject: state.currentObject,
			settings: state.settings,
			token: state.authentication.token,
		};
	},
	{
		showSideView,
		objectSelected,
		objectOpened,
		originalSelected,
		_updateSettings,
	}
)(ObjectListView);

export default ReduxObjectListMainView;
