// @flow
//views
/**
 * Viewkeys, die zum Einsatz kommen, wenn die Projektebene angezeigt wird
 */
export const PROJECT_LEVEL_VIEWS = {
	MAIN: {
		PROJECT_MAIN_VIEW: 'PROJECT_MAIN_VIEW',
		NET_PLANE_VIEW: 'NET_PLANE_VIEW',
		OBJECT_LIST_MAIN_VIEW: 'OBJECT_LIST_MAIN_VIEW',
	},
	SIDE: {
		PROJECT_SIDE_VIEW: 'PROJECT_SIDE_VIEW',
	},
};

/**
 * Viewkeys, die zum Einsatz kommen, wenn die Objekteben angezeigt wird
 */
export const OBJECT_LEVEL_VIEWS = {
	MAIN: {
		OBJECT_MAIN_VIEW: 'OBJECT_MAIN_VIEW',
		INSPECTION_MAIN_VIEW: 'INSPECTION_MAIN_VIEW',
		STATION_MAIN_VIEW: 'STATION_MAIN_VIEW',
		STATION_VIDEO_VIEW: 'STATION_VIDEO_VIEW',
		PANORAMO_MAIN_VIEW: 'PANORAMO_MAIN_VIEW',
	},
	SIDE: {
		OBJECT_LIST_SIDE_VIEW: 'OBJECT_LIST_SIDE_VIEW',
		STATION_SIDE_VIEW: 'STATION_SIDE_VIEW',
		NETPLANE_SIDE_VIEW: 'NETPLANE_SIDE_VIEW',
		INSPECTION_SIDE_VIEW: 'INSPECTION_SIDE_VIEW',
		OBJECT_SIDE_VIEW: 'OBJECT_SIDE_VIEW'
	},
};

//station
export const STATION_VIEW_NO_PHOTO: string = 'STATION_VIEW_NO_PHOTO';
export const STATION_VIEW_PHOTO: string = 'STATION_VIEW_PHOTO';
export const STATION_VIEW_ONLY_PHOTO: string = 'STATION_VIEW_ONLY_PHOTO';

//languages
export const LANG: Array<Object> = [
	{
		name: 'Deutsch',
		key: 'de',
	},
	{
		name: 'English',
		key: 'en',
	},
];

// TODO: PanoConstants dann uebergeben
export const PANO_UNITSYSTEMS = {
	METRIC: 'metric',
	IMPERIAL: 'imperial',
};

export const DEFAULT_SETTINGS: Object = {
	objectlist: {},
	lang: 'en',
	layout: 'threeToNine',
	station: {},
};

export type OBJECT_SESSION = {
	objectId: string,
	values: Object,
};

export const DEFAULT_SESSION: OBJECT_SESSION = {
	objectId: '',
	values: {},
};

export const DEFAULT_REPORT_SESSION: OBJECT_SESSION = {
	objectId: '',
	values: {},
};

//states
export const DEFAULT_STATE: Object = {
	projects: null,
	settings: DEFAULT_SETTINGS,
	templateEngine: null,
	mainViewTab: PROJECT_LEVEL_VIEWS.MAIN.PROJECT_MAIN_VIEW,
	sideViewMenu: false,
	currentProject: null,
	currentNetPlane: null,
	currentObject: null,
	currentStation: null,
	currentInterface: null,
	currentClassification: null,
	currentStationView: STATION_VIEW_NO_PHOTO,
	components: {
		PROJECT_SIDE_VIEW: true,
		OBJECT_LIST_SIDE_VIEW: false,
		STATION_SIDE_VIEW: false,
		PROJECT_MAIN_VIEW: true,
		NET_PLANE_VIEW: true,
		OBJECT_LIST_MAIN_VIEW: true,
		OBJECT_MAIN_VIEW: false,
		INSPECTION_MAIN_VIEW: false,
		STATION_MAIN_VIEW: false,
		STATION_VIDEO_VIEW: false,
		NETPLANE_SIDE_VIEW: false,
		PANORAMO_MAIN_VIEW: false,
	},
	authentication: {
		token: '',
		name: '',
	},
	resetLogin: false,
	seekStation: false,
	session: DEFAULT_SESSION,
	reportSession: DEFAULT_REPORT_SESSION,
    showLoadingSpinner: false
};

export const SWITCH_PROJECT_VIEWS: Object = {
	currentObject: null,
	components: {
		PROJECT_SIDE_VIEW: true,
		OBJECT_LIST_SIDE_VIEW: false,
		STATION_SIDE_VIEW: false,
		PROJECT_MAIN_VIEW: true,
		NET_PLANE_VIEW: true,
		OBJECT_LIST_MAIN_VIEW: true,
		OBJECT_MAIN_VIEW: false,
		INSPECTION_MAIN_VIEW: false,
		STATION_MAIN_VIEW: false,
		STATION_VIDEO_VIEW: false,
		NETPLANE_SIDE_VIEW: false,
		PANORAMO_MAIN_VIEW: false,
	},
};

export const BACK_TO_PROJECT_VIEWS: Object = {
	components: {
		PROJECT_SIDE_VIEW: true,
		OBJECT_LIST_SIDE_VIEW: false,
		STATION_SIDE_VIEW: false,
		PROJECT_MAIN_VIEW: true,
		NET_PLANE_VIEW: true,
		OBJECT_LIST_MAIN_VIEW: true,
		OBJECT_MAIN_VIEW: false,
		INSPECTION_MAIN_VIEW: false,
		STATION_MAIN_VIEW: false,
		STATION_VIDEO_VIEW: false,
		NETPLANE_SIDE_VIEW: false,
		PANORAMO_MAIN_VIEW: false,
	},
};

export const OBJECT_OPENED_VIEWS: Object = {
	components: {
		PROJECT_SIDE_VIEW: false,
		OBJECT_LIST_SIDE_VIEW: true,
		STATION_SIDE_VIEW: false,
		PROJECT_MAIN_VIEW: false,
		NET_PLANE_VIEW: false,
		OBJECT_LIST_MAIN_VIEW: false,
		OBJECT_MAIN_VIEW: true,
		INSPECTION_MAIN_VIEW: true,
		STATION_MAIN_VIEW: true,
		STATION_VIDEO_VIEW: true,
		NETPLANE_SIDE_VIEW: true,
		PANORAMO_MAIN_VIEW: true,
	},
};

//Hnweis: wird an 2 Stellen verwendet: dataReduser und viewStateReducer
export const FILM_TAB_OPENED_VIEWS: Object = {
	components: {
		PROJECT_SIDE_VIEW: false,
		OBJECT_LIST_SIDE_VIEW: true,
		STATION_SIDE_VIEW: true,
		PROJECT_MAIN_VIEW: false,
		NET_PLANE_VIEW: false,
		OBJECT_LIST_MAIN_VIEW: false,
		OBJECT_MAIN_VIEW: true,
		OBJECT_SIDE_VIEW: true,
		INSPECTION_MAIN_VIEW: true,
		INSPECTION_SIDE_VIEW: true,
		STATION_MAIN_VIEW: true,
		STATION_VIDEO_VIEW: true,
		NETPLANE_SIDE_VIEW: true,
		PANORAMO_MAIN_VIEW: true,
	},
};

//objectlist
export const OBJECTLIST_RENDERER_MODE: Object = {
	TEXT: 'text',
	IMG: 'img',
	CHECKBOX: 'checkbox',
};

export const OBJECTLIST_COLUMN_MODE: Object = {
	DEFAULT: 'default',
	TEXT: 'text',
	CODE: 'code',
};

export const FIELD_TYPE: Object = {
	TEXT: 'text',
	CHECKBOX: 'checkbox',
	EMPTY: 'empty',
};

//toastify severity
export const TOASTIFY_SEVERITY: Object = {
	INFO: 'info',
	SUCCESS: 'success',
	ERROR: 'error',
};

// Netzplan
export const NETPLANE_EPSG: string = 'EPSG:4326';

export const NETPLANE_PROPERTIES: Object = {
	FIELD_DB_ID: 'FIELD_DB_I',
	FIELD_NAME: 'FIELD_NAME',
	FIELD_DIAMETER: 'FIELD_DIAM',
	FIELD_SEWERSYSTEM: 'FIELD_SEWE',
	FIELD_CANALOBJTYPE: 'FLIED_CANA',
	FIELD_SECTION: 'FIELD_SECT',
	FIELD_SECTION_OUT_OF_SHOW_REAL_DIAMETER: 'FIELD_SE01',
	DXF_COLOR: 'DXF_COLOR',
	FIELD_USELOCALPARAMS: 'FIELD_USEL',
	FIELD_CAN_SNAP_SHAPE: 'FIELD_CAN_',
	FIELD_SHAPEKIND: 'FIELD_SHAP',
};

export const NETPLANE_CANALOBJTYPE: Object = {
	SECTION: 'Section',
	MANHOLE: 'Manhole',
	LATERAL: 'Lateral',
};

export const NETPLANE_SEWERSYSTEM: Object = {
	COMBINED: 'Combined',
	STORMWATER: 'Stormwater',
	WASTEWATER: 'Wastewater',
};

export const NETPLANE_SYSTEMCOLOR: Object = {
	COMBINED: '#17A589',
	STORMWATER: '#2E86C1',
	WASTEWATER: '#784212',
	SELECTED: '#FA693E',
};

export const NETPLANE_FEATUREPROPERTIES: Object = {
	TITLE: 'Title',
};

export const NETPLANE_UNITSYSTEM: Object = {
	METRIC: 'metric',
	IMPERIAL: 'imperial',
	METRIC_UNIT: 'mm',
	IMPERIAL_UNIT: 'in',
};

export const NETPLANE_GEOMETRYTYPE: Object = {
	LINESTRING: 'LineString',
	POINT: 'Point',
	POLYGON: 'Polygon',
};

export type SLIDER_DEFAULTS = {
	MIN: number,
	MAX: number,
	STEP: number,
	MARKS: Object,
};

export const PANOVIEWER_ZOOM_DEFAULTS: SLIDER_DEFAULTS = {
	MIN: 50,
	MAX: 160,
	STEP: 1,
	MARKS: {
		'50': 50,
		'75': 75,
		'100': 100,
		'125': 125,
		'150': 150,
		'160': 160,
	},
};

export const PANOVIEWER_KEYBOARD_SPEED_DEFAULTS: SLIDER_DEFAULTS = {
	MIN: 1,
	MAX: 100,
	STEP: 1,
	MARKS: {
		'1': 1,
		'25': 25,
		'50': 50,
		'75': 75,
		'100': 100,
	},
};

export const PANOVIEWER_KEYBOARD_ACCELERATION_DEFAULTS: SLIDER_DEFAULTS = {
	MIN: 0.1,
	MAX: 20,
	STEP: 0.1,
	MARKS: {
		'0.1': 0.1,
		'1': 1,
		'5': 5,
		'10': 10,
		'15': 15,
		'20': 20,
	},
};

export const PANOVIEWER_KEYBOARD_FRICTION_DEFAULTS: SLIDER_DEFAULTS = {
	MIN: 0,
	MAX: 1,
	STEP: 0.01,
	MARKS: {
		'0': 0,
		'0.25': 0.25,
		'0.5': 0.5,
		'0.75': 0.75,
		'1': 1,
	},
};
