// @flow
import React, {Fragment} from 'react'
import FormHelper from './helper/FormHelper';
import ValidationArray from './helper/ValidationArray';

/**
 * Klasse, welche Formulare auf Basis einen Templates erzeugen kann
 *
 * @export
 * @class FormBuilder
 */
export default class FormBuilder {
    /**
     * Erzeugt das Formular
     *
     * @param {Object} object Das Kanalobjekt
     * @param {Array<Object>} template Das Template des Formulars
     * @returns {React$Element<any>}
     * @memberof FormBuilder
     */
    build(object: Object, template: Array<Object>):React$Element<any> {
        const form: Array<?React$Element<any>> = template.map((item: FormHelper) => {
            const result = item.build(object);
            if (result) {
                return result.element;
            }
            return null;
        });

        return (
            <Fragment>
                {form}
            </Fragment>
        )
    }

    /**
     * Prueft das Template auf Fehler
     *
     * @param {Array<Object>} template
     * @returns {Array<ValidationArray>}
     * @memberof FormBuilder
     */
    validate(template: Array<Object>):Array<ValidationArray> {
        const messages = [];
        template.forEach(item => {
            if (typeof item.validate === 'function') {
                const result = item.validate();
                if (result.error.length > 0 || result.warn.length > 0) {
                    messages.push(result);
                }
                
                if (item.items) {
                    messages.concat(this.validate(item.items));
                }
            }
            
        });

        return messages;
    }
}