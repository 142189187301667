// @flow
import React from 'react';
import {Trans} from 'react-i18next';
import {Tab, ListGroup} from 'react-bootstrap';
import i18next from 'i18next';
import {Title} from '../../misc/flowTypes';
import ObjectListColumnHelper from './ObjectListColumnHelper';

type ObjectListTabOptions = {
    id: string,
    title: Title,
    columns: Array<ObjectListColumnHelper>,
    sort?: boolean
}

type ObjectListTabDefaults = {
    id: string,
    title: Title,
    columns: Array<ObjectListColumnHelper>,
    sort: boolean
}

const def: ObjectListTabDefaults = {
    id: '',
    title: {
        key: '',
        fallback: ''
    },
    columns: [],
    sort: false
}

/**
 * Hilfsklasse fuer einen Tab in den Einstellungen der Spalten fuer die Objektliste
 *
 * @export
 * @class ObjectListTabHelper
 */
export default class ObjectListTabHelper {
    _options: ObjectListTabDefaults;

    /**
     * Creates an instance of ObjectListTabHelper.
     * @param {ObjectListTabOptions} options Einstellungen des Tabs
     * @memberof ObjectListTabHelper
     */
    constructor(options: ObjectListTabOptions) {
        const title = {...def.title, ...options.title};

        this._options = {...def, ...options, ...{title}};
    }

    get id(): string {
        return this._options.id;
    }

    get title(): Title {
        return this._options.title;
    }    

    get columns(): Array<any> {
        return this._options.columns;
    }

    get sort():boolean {
        return this._options.sort;
    }

    /**
     * Gibt den Tabtitel als React Element zurueck
     *
     * @returns {React$Element<any>}
     * @memberof ObjectListTabHelper
     */
    getTitleAsElement(): React$Element<any> {
        return <Trans i18nKey={this.title.key} parent='span'>{this.title.fallback}</Trans>;
    }

    /**
     * Rendert die Listeneintraege des Tabs
     *
     * @param {(Object, string) => mixed} onClick Klick auf Auswahl
     * @returns {React$Element<any>}
     * @memberof ObjectListTabHelper
     */
    renderColumns(onClick: (Object, string) => mixed):React$Element<any> {
        let items = this.columns.map((col, index: number) => col.build(onClick, index));

        if (this.sort) {
            items = items.sort((a: React$Element<any>, b: React$Element<any>):number => {
                let valA: string = 'a';
                let valB: string = 'b';

                if (a.props.children[0] && a.props.children[0].props.hasOwnProperty('children')) {
                    valA = a.props.children[0].props.i18nKey;
                    valA = i18next.t(valA);
                }

                if (b.props.children[0] && b.props.children[0].props.hasOwnProperty('children')) {
                    valB = b.props.children[0].props.i18nKey;
                    valB = i18next.t(valB);
                }

                return valA.localeCompare(valB);
            })
        }

        return (
            <ListGroup>
                {items}
            </ListGroup>
        )
    }

    /**
     * Baut den Tab
     *
     * @param {number} index Index des Tabs
     * @param {(Object, string) => mixed} onClick Klick auf Auswahl
     * @param {Array<Object>} cols Einstellungen fuer die Spalten
     * @returns {React$Element<any>}
     * @memberof ObjectListTabHelper
     */
    build(index: number, onClick: (Object, string) => mixed, cols: Array<Object>):React$Element<any> {
        this.updateColumnState(cols);

        return (
            <Tab 
                eventKey={index} 
                key={index} 
                title={this.getTitleAsElement()}
            >
                {this.renderColumns(onClick)}
            </Tab>
        )
    }

    /**
     * Aktualisiert die Sichtbarkeit der Spalten dieses Tabs
     *
     * @param {Array<Object>} cols Einstellungen fuer die Spalten
     * @memberof ObjectListTabHelper
     */
    updateColumnState(cols: Array<Object>) {
        this.columns.forEach(col => {
            cols.forEach(c => {
                if(col.id === c.id) {                    
                    col[c.mode] = c.show;
                }
            })
        });
    }
}