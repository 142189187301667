import { post, get, tokenizedGet } from '../API';
import { PATH_PING, PATH_AUTH, PATH_WHOAMI } from '../paths';

export function getPing() {
	return get(PATH_PING);
}

export function postLogin(mail, password) {
	return post(PATH_AUTH, {
		mail: mail,
		password: password,
	});
}

export function getWhoAmI(token) {
	return tokenizedGet(PATH_WHOAMI, token);
}
