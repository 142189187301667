// @flow
import ColumnTypeHelper from './ColumnTypeHelper';

/**
 * Hilfklasse fuer eine Spalte eines Layouts
 *
 * @export
 * @class ColumnHelper
 */
export default class ColumnHelper {
    xs: ColumnTypeHelper;
    sm: ColumnTypeHelper;
    md: ColumnTypeHelper;
    lg: ColumnTypeHelper;

    /**
     * Creates an instance of ColumnHelper.
     * @param {(target: string, size: number) => void} sizeCallback Callback fuer die Aenderung der Groesse
     * @memberof ColumnHelper
     */
    constructor(sizeCallback: (target: string, size: number) => void) {
        this.xs = new ColumnTypeHelper(
            (size) => sizeCallback('xs', size)
        );
        this.sm = new ColumnTypeHelper(
            (size) => sizeCallback('sm', size)
        );
        this.md = new ColumnTypeHelper(
            (size) => sizeCallback('md', size)
        );
        this.lg = new ColumnTypeHelper(
            (size) => sizeCallback('lg', size)
        );
    }
}