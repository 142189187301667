// @flow
import React from 'react';

import SideMenuHelper from './SideMenuHelper';
import ColumnHelper from './ColumnHelper';
import {Image} from 'react-bootstrap';

const MAX_SIZE: number = 12;

/**
 * Klasse fuer eine Layout Vorlage
 *
 * @export
 * @class Layout
 */
export default class Layout {
    id: string;
    image: React$Element<any>;
    sideMenuHidden: SideMenuHelper;
    left: ColumnHelper;
    right: ColumnHelper;

    /**
     * Creates an instance of Layout.
     * @param {string} id Id des Layouts
     * @param {string} image Bild des Layouts 
     * @memberof Layout
     */
    constructor(id: string, image: string) {
        this.id = id;
        this.image = <Image src={image}></Image>;
        this.sideMenuHidden = new SideMenuHelper();
        this.left = new ColumnHelper(
            (target: string, size: number) => this.sizeCallback('left', target, size)
        );
        this.right = new ColumnHelper(
            (target: string, size: number) => this.sizeCallback('right', target, size)
        );
    }

    /**
     * Callback fuer die Aenderung der Groesse
     * Passt die andere Seite des Layouts automatisch mit an
     *
     * @property {string} origin Herkunft der Aenderung
     * @property {string} target Ziel der Aenderung
     * @property {number} size Groesse die gesetzt wurde
     * 
     * @memberof Layout
     */
    sizeCallback = (origin: string, target: string, size: number) => {
        if (origin === 'left') {
            // $FlowFixMe
            this.right[target]._size = MAX_SIZE - size;
        }
        else if (origin === 'right') {
            // $FlowFixMe
            this.left[target]._size = MAX_SIZE - size;
        }
    }
}