import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import registerServiceWorker from './registerServiceWorker';

import { Provider } from 'react-redux';

import App from './components/App';
import FallbackUI from './components/common/FallbackUI';
import { _init } from './actions/loginActions';
import store from './store';

import './i18n/i18n';
import './css/index.css';

// Redux Store erstellen und Daten von dummy laden
store.dispatch(_init());

const container = document.getElementById('root');
const root = createRoot(container);
// React rendern
root.render(
	<Provider store={store}>
        <Suspense fallback={<FallbackUI />}>
		    <App />
		</Suspense>
	</Provider>
);
registerServiceWorker();
