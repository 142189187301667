
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

export default function IconButton({text, icon, disabled, onClick}) {
    return (
        <OverlayTrigger style={{position:'fixed'}} trigger={["hover", "focus"]} overlay={<Tooltip>{text}</Tooltip>}>
            <Button disabled={disabled} onClick={onClick}>
                {icon}
            </Button>
        </OverlayTrigger>
    );
}