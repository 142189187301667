// @flow
//views
import { DEFAULT_STATE, PROJECT_LEVEL_VIEWS, BACK_TO_PROJECT_VIEWS, FILM_TAB_OPENED_VIEWS, OBJECT_LEVEL_VIEWS, OBJECT_OPENED_VIEWS } from '../misc/const';
//actions
import { SET_MAIN_VIEW_TAB, BACK_TO_PROJECT, SHOW_SIDE_VIEW, SWITCH_STATION_VIEW, SET_SHOW_LOADING_SPINNER } from '../actions/utils/actionTypes';
import { Action } from '../misc/flowTypes';
import { changeToViewTab } from '../misc/utils';

/**
 * Springt zum aktuellen Projekt in der variable currentProject zurueck
 * @param  {object} state Aktueller Status
 * @return {object}       Aktualisierter Status
 */
function backToProject(state: Object): Object {
	if (state.currentProject) {
		let currentTab: string = state.mainViewTab;

		currentTab = changeToViewTab(PROJECT_LEVEL_VIEWS.MAIN.PROJECT_MAIN_VIEW, currentTab, (current) => !PROJECT_LEVEL_VIEWS.MAIN.hasOwnProperty(currentTab));

		return Object.assign({}, state, BACK_TO_PROJECT_VIEWS, {
			mainViewTab: currentTab,
		});
	}

	return state;
}

/**
 * Fuehrt die Action SET_MAIN_VIEW_TAB aus. Setzt den Key fuer den aktiven Tab im MainView
 * @param {object} state Aktueller Status
 * @param {string} key   Key des neuen aktiven Tabs
 * @return {object} Neuer Status mit dem alten gemerged
 */
function setMainViewTab(state: Object, key: string): Object {
	if (state.hasOwnProperty('mainViewTab')) {

		let targetViews: Object = FILM_TAB_OPENED_VIEWS;

		if (PROJECT_LEVEL_VIEWS.MAIN.hasOwnProperty(key)) {
			targetViews = BACK_TO_PROJECT_VIEWS;
		} else if (OBJECT_LEVEL_VIEWS.MAIN.hasOwnProperty(key) && key !== OBJECT_LEVEL_VIEWS.MAIN.STATION_VIDEO_VIEW && key !== OBJECT_LEVEL_VIEWS.MAIN.PANORAMO_MAIN_VIEW) {
			targetViews = OBJECT_OPENED_VIEWS;
		}

		return { ...state, ...targetViews, ...{ mainViewTab: key } };
	} else {
		return state;
	}
}

/**
 * Fuehrt die Action SHOW_SIDE_VIEW aus und aktualisiert ob die Seitenansicht angezeigt werden soll. (Nur Tablet und kleiner)
 * @param  {object} state Aktueller Status
 * @param  {boolean} show  Ob die Seitenansicht gezeigt werden soll
 * @return {object}       Aktualisierter Status
 */
function showSideView(state: Object, show: boolean): Object {
	return Object.assign({}, state, {
		sideViewMenu: show,
	});
}

/**
 * Fuehr die Action SWITCH_STATION_VIEW aus und aktualisiert den aktuellen View der Stationen
 * @param  {object} state Aktueller Status
 * @param  {string} view  View der angezeigt werden soll
 * @return {object}       Aktualisierter Status
 */
function switchStationView(state: Object, view: string): Object {
	return Object.assign({}, state, {
		currentStationView: view,
	});
}

/**
 * Fuehr die Action SET_SHOW_LOADING_SPINNER aus und zeigt bzw. versteckt LoadingSpinner
 * @param  {object} state Aktueller Status
 * @param  {boolean} show  Soll Spinner gezeigt werden
 * @return {object}       Aktualisierter Status
 */
function setShowLoadingSpinner(state: Object, show: boolean): Object {
    return {
        ...state,
        showLoadingSpinner: show
    }
}

/**
 * Funktion, die vom Redux aufgerufen wird, wenn actions dispatched werden
 * @param  {object} state Aktueller Status
 * @param  {object} action                Inhalt der Action mit den Werten
 * @return {object}                       Neuer Status mit dem alten gemerged
 */
// eslint-disable-next-line import/no-anonymous-default-export
export default function (state: Object = DEFAULT_STATE, action: Action): Object {
	switch (action.type) {
		case BACK_TO_PROJECT:
			return backToProject(state);
		case SET_MAIN_VIEW_TAB:
			return setMainViewTab(state, action.key);
		case SHOW_SIDE_VIEW:
			return showSideView(state, action.show);
		case SWITCH_STATION_VIEW:
			return switchStationView(state, action.view);
        case SET_SHOW_LOADING_SPINNER:
            return setShowLoadingSpinner(state, action.show)
		default:
			return state;
	}
}
