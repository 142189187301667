// @flow
import React from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas'
import '../../css/Sidebar.css';

type Props = {
    show: boolean,
    onHide: () => mixed,
    title: string | React$Element<any>,
    body: React$Element<any>
}

/**
 * Komponente fuer das Seitenmenu
 * @param {boolean} show Ob das Seitenmenu angezeigt wird
 * @param {() => mixed} onHide Callback fuer das Schliessenevent
 * @param {string | React$Element<any>} title Titel des Seitenmenues
 * @param {React$Element<any>} body Inhalt des Seitenmenues
 */
const SideMenu = ({show, onHide, title, body}: Props) => (
    <Offcanvas
        className='Sidebar left'
        show={show}
        onHide={onHide}
        autoFocus
        keyboard
    >
        <Offcanvas.Header closeButton>
            <Offcanvas.Title>{title}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
            {body}
        </Offcanvas.Body>
    </Offcanvas>
);

export default SideMenu;