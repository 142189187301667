// @flow
import CardHelper from '../../form/helper/CardHelper';
import FieldHelper from '../../form/helper/FieldHelper';
import Classification from '../helper/Classification';
import CanalObjects from '../helper/CanalObjects';
import CanalObject from '../helper/CanalObject';

/**
 * Template Beschreibung fuer Arbeitshilfenabwasser
 * @type {Object}
 */
// eslint-disable-next-line import/no-anonymous-default-export
export default new Classification({
    name: 'Arbeitshilfen Abwasser',
    identifiers: [
        'Arbeitshilfen Abwasser',
        'M149-3 ISYBAU Edge',
        'M149-3 ISYBAU Manhole',
    ],
    version: '2.0',
    inspection: new CanalObjects({
        section: new CanalObject([
            new CardHelper({
                id: 'rating',
                title: {
                    key: 'arbeitshilfenAbwasser.header',
                    fallback: 'Bewertung Arbeitshilfen Abwasser'
                },
                items: [
                    new FieldHelper({
                        id: 'evaluationTime',
                        title: {
                            key: 'arbeitshilfenAbwasser.evaluationTime',
                            fallback: 'Bewertungszeit'
                        },
                        accessor: 'ISYBAU-XML_EvaluationTime'
                    }),
                    new FieldHelper({
                        id: 'preliminaryObjectNumber',
                        title: {
                            key: 'arbeitshilfenAbwasser.preliminaryObjectNumber',
                            fallback: 'Vorläufige Objektanzahl'
                        },
                        accessor: 'ISYBAU-XML_EvaluationPreliminaryNumber'
                    }),
                    new FieldHelper({
                        id: 'objectClass',
                        title: {
                            key: 'arbeitshilfenAbwasser.objectClass',
                            fallback: 'Objektklasse'
                        },
                        accessor: 'ISYBAU-XML_Class'
                    }),
                    new FieldHelper({
                        id: 'finalObjectNumber',
                        title: {
                            key: 'arbeitshilfenAbwasser.finalObjectNumber',
                            fallback: 'Endgültige Objektanzahl'
                        },
                        accessor: 'ISYBAU-XML_EvaluationNumberFinal'
                    }),
                    new FieldHelper({
                        id: 'manualObjectClass',
                        title: {
                            key: 'arbeitshilfenAbwasser.manualObjectClass',
                            fallback: 'Objektklasse manuell'
                        },
                        accessor: 'ISYBAU-XML_ClassManu'
                    }),
                    new FieldHelper({
                        id: 'extraPoints',
                        title: {
                            key: 'arbeitshilfenAbwasser.extraPoints',
                            fallback: 'Zusatzpunkte'
                        },
                        accessor: 'ISYBAU-XML_EvaluationAdditionalPoints'
                    }),
                    new FieldHelper({
                        id: 'objectClassGiven',
                        title: {
                            key: 'arbeitshilfenAbwasser.objectClassGiven',
                            fallback: 'Objektklasse vergeben'
                        },
                        accessor: 'ISYBAU-XML_ClassUser'
                    })
                ]
            })
        ]),
        lateral: new CanalObject([
            new CardHelper({
                id: 'rating',
                title: {
                    key: 'arbeitshilfenAbwasser.header',
                    fallback: 'Bewertung Arbeitshilfen Abwasser'
                },
                items: [
                    new FieldHelper({
                        id: 'evaluationTime',
                        title: {
                            key: 'arbeitshilfenAbwasser.evaluationTime',
                            fallback: 'Bewertungszeit'
                        },
                        accessor: 'ISYBAU-XML_EvaluationTime'
                    }),
                    new FieldHelper({
                        id: 'preliminaryObjectNumber',
                        title: {
                            key: 'arbeitshilfenAbwasser.preliminaryObjectNumber',
                            fallback: 'Vorläufige Objektanzahl'
                        },
                        accessor: 'ISYBAU-XML_EvaluationPreliminaryNumber'
                    }),
                    new FieldHelper({
                        id: 'objectClass',
                        title: {
                            key: 'arbeitshilfenAbwasser.objectClass',
                            fallback: 'Objektklasse'
                        },
                        accessor: 'ISYBAU-XML_Class'
                    }),
                    new FieldHelper({
                        id: 'finalObjectNumber',
                        title: {
                            key: 'arbeitshilfenAbwasser.finalObjectNumber',
                            fallback: 'Endgültige Objektanzahl'
                        },
                        accessor: 'ISYBAU-XML_EvaluationNumberFinal'
                    }),
                    new FieldHelper({
                        id: 'manualObjectClass',
                        title: {
                            key: 'arbeitshilfenAbwasser.manualObjectClass',
                            fallback: 'Objektklasse manuell'
                        },
                        accessor: 'ISYBAU-XML_ClassManu'
                    }),
                    new FieldHelper({
                        id: 'extraPoints',
                        title: {
                            key: 'arbeitshilfenAbwasser.extraPoints',
                            fallback: 'Zusatzpunkte'
                        },
                        accessor: 'ISYBAU-XML_EvaluationAdditionalPoints'
                    }),
                    new FieldHelper({
                        id: 'objectClassGiven',
                        title: {
                            key: 'arbeitshilfenAbwasser.objectClassGiven',
                            fallback: 'Objektklasse vergeben'
                        },
                        accessor: 'ISYBAU-XML_ClassUser'
                    })
                ]
            })
        ]),
        manhole: new CanalObject([
            new CardHelper({
                id: 'rating',
                title: {
                    key: 'arbeitshilfenAbwasser.header',
                    fallback: 'Bewertung Arbeitshilfen Abwasser'
                },
                items: [
                    new FieldHelper({
                        id: 'evaluationTime',
                        title: {
                            key: 'arbeitshilfenAbwasser.evaluationTime',
                            fallback: 'Bewertungszeit'
                        },
                        accessor: 'ISYBAU-XML_EvaluationTime'
                    }),
                    new FieldHelper({
                        id: 'preliminaryObjectNumber',
                        title: {
                            key: 'arbeitshilfenAbwasser.preliminaryObjectNumber',
                            fallback: 'Vorläufige Objektanzahl'
                        },
                        accessor: 'ISYBAU-XML_EvaluationPreliminaryNumber'
                    }),
                    new FieldHelper({
                        id: 'objectClass',
                        title: {
                            key: 'arbeitshilfenAbwasser.objectClass',
                            fallback: 'Objektklasse'
                        },
                        accessor: 'ISYBAU-XML_Class'
                    }),
                    new FieldHelper({
                        id: 'finalObjectNumber',
                        title: {
                            key: 'arbeitshilfenAbwasser.finalObjectNumber',
                            fallback: 'Endgültige Objektanzahl'
                        },
                        accessor: 'ISYBAU-XML_EvaluationNumberFinal'
                    }),
                    new FieldHelper({
                        id: 'manualObjectClass',
                        title: {
                            key: 'arbeitshilfenAbwasser.manualObjectClass',
                            fallback: 'Objektklasse manuell'
                        },
                        accessor: 'ISYBAU-XML_ClassManu'
                    }),
                    new FieldHelper({
                        id: 'extraPoints',
                        title: {
                            key: 'arbeitshilfenAbwasser.extraPoints',
                            fallback: 'Zusatzpunkte'
                        },
                        accessor: 'ISYBAU-XML_EvaluationAdditionalPoints'
                    }),
                    new FieldHelper({
                        id: 'objectClassGiven',
                        title: {
                            key: 'arbeitshilfenAbwasser.objectClassGiven',
                            fallback: 'Objektklasse vergeben'
                        },
                        accessor: 'ISYBAU-XML_ClassUser'
                    })
                ]
            })
        ])
    })
});