// @flow
import React from 'react';
import {Container, Image, Button} from 'react-bootstrap';
import {Trans} from 'react-i18next';

import logo from '../../images/ibak.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';

type Props = {
    _logout: () => mixed
}

/**
 * Rendert die Ansicht fuer kein Projekt vorhanden
 * @param {() => mixed} _logout Funktion fuer den Logout
 */
const NoProject = ({_logout}: Props) => (
    <Container fluid>
        <Image
            src={logo}
            className="logo"
            responsive
        />
        <h1 className="text-center">IKAS Evolution WebViewer</h1>
        <h3 className="text-center">
            <Trans i18nKey="start.noProject">...</Trans>
        </h3>
        <div className="text-center">
            <Button
                type="button"
                onClick={_logout}
            >
                <FontAwesomeIcon icon={faSignOutAlt}></FontAwesomeIcon>&nbsp;
                <Trans i18nKey="common.logout" parent="span">Abmelden</Trans>
            </Button>
        </div>
    </Container>
);

export default NoProject;